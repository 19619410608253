import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { Input, Button, Image } from 'antd';

import { postRegister, postSendVerificationCode } from '../../api/index'
import { openNotification } from '../../tools/notification'
import { viewCode } from '../../tools/viewCode';

import BlockMovement from '../../Components/SpecialEffects/BlockMovement/Index';
import "./Index.scss"
import OpenEye from "../../assets/web/open_eye.png"
import OffEye from "../../assets/web/off_eye.png"

let secondInterval = null;
const WebRegister = (props) => {

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery()
    const inviteId = query.get("inviteId")
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const [nickName, setNickName] = useState('')
    const [invitationCode, setInvitationCode] = useState(inviteId)

    const [sendingCode, setSendingCode] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        return () => {
            setIsChecked(false)
            setSendingCode(false)
            setPassword('')
            setEmail('')
            setCode('')
            setSeconds(0)
        }
    }, [])

    useEffect(() => {
        setInvitationCode(inviteId)
    }, [inviteId])

    useEffect(() => {
        if (seconds === 0) {
            secondInterval && clearInterval(secondInterval);
        } else if (seconds === 60) {
            secondInterval && clearInterval(secondInterval);
            secondInterval = setInterval(() => {
                setSeconds((n) => {
                    return n - 1;
                });
            }, 1000);
        }
    }, [seconds]);

    const register = async () => {
        let emailG = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{1,7}$/;
        let passwordG = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
        if (!isChecked) { return }
        if (!password || !email || !code) return openNotification(t("user.passwordEmailNull"))
        if (nickName.length > 16) return openNotification(t("user.nicknameError"))
        if (!emailG.test(email)) return openNotification(t("user.emailError"))
        if (!passwordG.test(password)) return openNotification(t("user.passwordError"))
        if (password !== confirmPassword) return openNotification(t("user.passwordConfirmError"))
        postRegister({
            password: password,
            email: email,
            verificationCode: code,
            nickname: nickName,
            inviterUUID: invitationCode
        }).then((res) => {
            if (!res.data.code) {
                window._czc && window._czc.push(["_trackEvent", "注册完成", "点击"]);
                openNotification(t("user.registerSuccess"))
                navigate('/webLogin', { replace: true })
            } else {
                if (res.data.code === 2012) {
                    navigate("/WebRegister", { replace: true })
                }
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            }
        }).catch((err) => {
            openNotification(t("responseCode.networkFailed"))
        })
    }

    const sendVerificationCode = async () => {
        if (sendingCode) {
            return
        }
        let emailG = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{1,7}$/;
        if (!emailG.test(email)) return openNotification(t("user.emailError"))
        setSendingCode(true)
        postSendVerificationCode({ email: email, action: "register" }).then((res) => {
            if (!res.data.code) {
                setSeconds(60)
                openNotification(t("user.sendVerificationCodeSuccess"))
            } else {
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            }
        }).catch((err) => {
            openNotification(t("responseCode.networkFailed"))
        }).finally(() => {
            setSendingCode(false)
        })
    }

    return (
        <div className="web">
            <BlockMovement />
            <div className="logo"></div>
            <div className="form">
                <div className="web_title">{t('user.registerAccount')}</div>
                <Input className="form_input" suffix={" "} onChange={(e) => { setEmail(e.target.value.trim()) }} value={email} placeholder={t('user.emailAccount')} />
                <Input.Password
                    className="form_input"
                    placeholder={t('user.newPassword')}
                    value={password}
                    onChange={(e) => { setPassword(e.target.value.trim()) }}
                    iconRender={(visible) => (visible ? <Image preview={false} src={OpenEye} /> : <Image preview={false} src={OffEye} />)}
                />
                <Input.Password
                    className="form_input"
                    value={confirmPassword}
                    onChange={(e) => { setConfirmPassword(e.target.value.trim()) }}
                    placeholder={t("user.confirmPassword")}
                    iconRender={(visible) => (visible ? <Image preview={false} src={OpenEye} /> : <Image preview={false} src={OffEye} />)}
                />
                <Input className="form_input" suffix={" "} onChange={(e) => { setNickName(e.target.value.trim()) }} value={nickName} placeholder={t('user.nickname')} />
                <Input className="form_input suffix_code_father" onChange={(e) => { setCode(e.target.value.trim()) }} value={code} placeholder={t('user.verificationCode')}
                    suffix={seconds === 0 ? <Button loading={sendingCode} onClick={() => { sendVerificationCode() }} className={sendingCode ? "suffix_code disabled" : "suffix_code"}>{t('user.getVerificationCode')}</Button> :
                        <span className="suffix_code disabled">{seconds + t("user.second")}</span>}
                />
                {inviteId ?
                    <div className='form_input_lock'> {inviteId} </div> :
                    <Input className="form_input" suffix={" "} onChange={(e) => { setInvitationCode(e.target.value.trim()) }} value={invitationCode} placeholder={t('user.invitationCode')} />
                }
                <div className="form_checkbox">
                    <Button onClick={() => { setIsChecked(!isChecked) }} className={isChecked ? "selected_button" : "unselected_button"}></Button>
                    {t("user.registrationAgreement")}
                </div>
                <div>
                    <Button onClick={() => { register() }} className={!isChecked ? "button_common disabled_button" : "button_common primary_button"}>{t("user.registrationCompleted")}</Button>
                </div>
                <div className="form_space_between">
                    <span>{ }</span>
                    <Link to="/webLogin" onClick={() => { window._czc && window._czc.push(["_trackEvent", "去登录", "点击"]); }} className="form_link">{t("user.haveAccount")}</Link>
                </div>
            </div>
        </div>
    )
}

export default WebRegister
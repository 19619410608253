import styles from './Index.module.scss'

const tetrisBackMovement = () => {
    return <div className={styles.tetrisBackMovement}>
        <div className={styles.loader}>
            {
                new Array(6).fill("span").map((item, index) => {
                    return <span key={index}>
                    </span>
                })
            }
        </div>
    </div>
}

export default tetrisBackMovement;

import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Image, Modal, Dropdown } from 'antd'

import { buyTicket, freeTicket, getSwapTokenTRate } from '../../../api/index'
import { HaveFreeTicket } from '../../../redux/actions/localSettings/localActions'

import { openNotification } from '../../../tools/notification'
import { viewCode } from '../../../tools/viewCode'
import { FREE_TICKET_TIME, TICKETPRICE, TOKENNAME } from '../../../tools/gameConfig'
import { VSWAP_CONTRACT_ADDRESS } from '../../../tools/tokenConfig'
import { updateTickets } from '../../../tools/utils'

import PublicPopup from '../../../Components/PublicComponents/PublicPopup'
import './Index.scss'
import AddIcon from '../../../assets/public_icon/add_icon.svg'
import RemoveIcon from '../../../assets/public_icon/remove_icon.svg'

function Index(props) {
    const { t } = useTranslation()
    const handleClick = (action) => {
        props.onClose(action)
    }
    const [count, setCount] = useState(1)
    const [total, setTotal] = useState(0)
    const [price, setPrice] = useState(0)
    const [loadingFree, setLoadingFree] = useState(false)
    const haveFreeTicket = useSelector(state => state.localSetting.haveFreeTicket)
    const [freeState, setFreeState] = useState(false)

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
        getPrice()
        return () => {
        }
    }, [])

    useEffect(() => {
        getHaveFreeTicker()
    }, [haveFreeTicket])

    useEffect(() => {
        setTotal((price * count).toFixed(3))
    }, [count, price])

    const remove1 = () => {
        if (count > 2) {
            setCount(p => p - 1)
        } else {
            setCount(1)
        }
    }

    const add1 = () => {
        setCount(p => p + 1)
    }

    const updateUserInfo = () => {
        props.updateUserInfo()
    }

    const getHaveFreeTicker = () => {
        if (haveFreeTicket.type) {
            setFreeState(true)
        } else {
            let newDate = new Date()
            if (newDate.getTime() - haveFreeTicket.time > FREE_TICKET_TIME) {
                setFreeState(true)
            } else {
                if (updateTickets(haveFreeTicket.time)) {
                    setFreeState(true)
                } else {
                    setFreeState(false)
                }
            }
        }
    }

    const BuyTicket = () => {
        if (confirmLoading) { return }
        if (total <= 0) { return }
        setIsModalOpen(true)
    }

    const FreeTicket = () => {
        if (loadingFree) { return }
        setLoadingFree(true)
        freeTicket().then((res) => {
            setLoadingFree(false)
            if (!res.data.code) {
                openNotification(t("ticket.claimedSuccessful"))
                props.HaveFreeTicket(false)
                setFreeState(false)
                updateUserInfo()
            } else {
                if (res.data.code === 3008) {
                    setFreeState(false)
                    openNotification(t("ticket.haveClaimedNextCome"))
                    props.HaveFreeTicket(false)
                } else {
                    openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                }
            }
        }).catch((err) => {
            setLoadingFree(false)
            console.log(err)
        })
    }

    const getPrice = async () => {
        try {
            let TET2USD = ((await getSwapTokenTRate({ vswapContract: VSWAP_CONTRACT_ADDRESS }))?.data?.data?.tetrisTokenExchange?.usd || 0)
            setPrice(TICKETPRICE * 1 / (TET2USD))
        } catch (error) {
            console.log(error)
        }
    }

    const handleOk = () => {
        setConfirmLoading(true)
        buyTicket({
            ticketsNumber: count,
        }).then(res => {
            setConfirmLoading(false)
            setIsModalOpen(false)
            if (!res.data.code) {
                if (res.data.data.ticketsNumber === count) {
                    openNotification(t("ticket.purchaseSuccessful"))
                    updateUserInfo()
                } else {
                    openNotification(t("ticket.purchaseUnsuccessful"))
                }
            } else {
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            }
        }).catch(err => {
            setIsModalOpen(false)
            setConfirmLoading(false)
            openNotification(t("responseCode.networkFailed"))
        })
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    return (
        <PublicPopup
            title={<Dropdown
                dropdownRender={(menu) => (
                    <div style={{ background: "#40077e", whiteSpace: "pre-wrap", borderRadius: "1vw", width: "34vw", height: "auto", color: "#fff", fontFamily: "Noto Sans", padding: "1.5vw", boxSizing: "border-box", fontSize: "0.78vw", position: "relative" }}>
                        {t("titleContent.ticket")}
                        <a style={{ marginLeft: "0.5vw", color: "#f16e30", textDecoration: "underline", fontSize: "0.8vw" }} href={t("titleContent.ticketLink")} target="_blank">{t("titleContent.more")}</a>
                    </div>
                )}
                trigger={['click', 'hover']}
                placement='bottom' >
                <div style={{ display: "flex", gap: "0.5vw", alignItems: "center", justifyContent: "center" }}>{t("ticket.ticket")}<div className="price_info_ic"></div></div>
            </Dropdown>}
            closefun={(action) => handleClick(action)}>
            <div className='ticker-wrapper'>
                <div className='left' >
                    <div className='top'>
                        <div className='ticker-type'>{t("ticket.competitiveTickets")}</div>
                        <div className='ticker-img-bg'>
                            <div className="ticker-img"></div>
                        </div>
                        <div className='buttom'>
                            <div className='total'>{TOKENNAME} : {total <= 0 ? "..." : total}</div>
                            <div className='option'>
                                <Button className='arrow_button' onClick={remove1}>
                                    <Image preview={false} src={RemoveIcon} />
                                </Button>
                                <div className='count'>{count}</div>
                                <Button className='arrow_button' onClick={add1}>
                                    <Image preview={false} src={AddIcon} />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={confirmLoading || total <= 0 ? 'button_common disabled_button' : "button_common secondary_button"} onClick={BuyTicket}>
                        {t("ticket.buyNow")}
                    </div>
                </div>
                <div className='left'>
                    <div className='top'>
                        <div className='ticker-type'>{t("ticket.freeTicket")}</div>
                        <div className='ticker-img-free'></div>
                    </div>
                    <div className='button_common disabled_button'> {t("ticket.freeTicket")} </div>
                    {/* {freeState ?
                        <div className={loadingFree ? 'button_common disabled_button' : "button_common secondary_button"} onClick={FreeTicket}> {t("ticket.freeClaim")}</div> :
                        <div className='button_common disabled_button'> {t("ticket.haveClaimed")} </div>
                    } */}
                </div>
            </div>
            <Modal title={t("ticket.confirmTicket")} confirmLoading={confirmLoading}
                okText={t("mint.confirm")} cancelText={t("gameChooseModel.close")}
                open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                {t("ticket.confirmTicektContent1") + ' ' + total + ' ' + t("ticket.confirmTicektContent2") + ' ' + count + ' ' + t("ticket.confirmTicektContent3")}
            </Modal>
        </PublicPopup>
    )
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = { HaveFreeTicket };
export default connect(mapStateToProps, mapDispatchToProps)(Index);
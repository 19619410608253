import React from 'react';
import { Spin, Table } from 'antd';

import NoData from './NoData';
import styles from '../../scss/PublicComponentsStyle/CommonTable.module.scss'

const CommonTable = ({ dataSource, columns, nodataTitle, loading }) => {

    return (
        <div id={styles.common_table}>
            {loading ? <Spin /> : <Table
                pagination={false}
                dataSource={dataSource}
                columns={columns}
                // scroll={{
                //     y: "100%",
                // }}
                showHeader={dataSource && dataSource.length > 0}
                locale={{ emptyText: <NoData title={nodataTitle} /> }}
            />}
        </div>
    )
}

export default CommonTable
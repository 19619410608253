import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { Button, Image, Input, Spin } from 'antd';
import { useTranslation } from 'react-i18next'

import { MoveResult, roomFull } from '../../redux/actions/sockets/socketsActions';
import { copy, openNotification } from '../../tools/notification'
import { socket } from "../../socket/socket";

// eg avatar
import avatarDefault from "../../assets/hall/avatar_default.png";
import '../../scss/ChooseGameType/CreateRoom.scss'

function CreateRoom(props) {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const competitiveList = useSelector((state) => state.sockets.roomPlayers);
    const spectatorList = useSelector((state) => state.sockets.roomViewers);
    const roomDetail = useSelector((state) => state.sockets.roomDetail);
    const currentUser = useSelector((state) => state.sockets.currentUser);

    const [join, SetJoin] = useState(false)
    const [joinId, setJoinId] = useState("")

    const [roomHost, setRoomHost] = useState({})
    const [roomInviteLink, setRoomInviteLink] = useState('')

    const [isLeaved, setISLeaved] = useState(false)

    const [Name, setName] = useState("")
    const [changeName, setChangeName] = useState(false)
    const [updateNameLoading, setUpdateNameLoading] = useState(false)

    const [Maxplayers, setMaxplayers] = useState(8)
    const [updateMaxplayersLoading, setUpdateMaxplayersLoading] = useState(false)

    const [startgameLoading, setStartgameLoading] = useState(false)


    const onClose = () => {
        props.onClose()
    }

    useEffect(() => {
        if (competitiveList.length) {
            setRoomHost(competitiveList.find((item) => item.admin))
        } else {
            setRoomHost({})
        }
    }, [competitiveList])

    useEffect(() => {
        if (isLeaved && !roomDetail.id) {
            onClose()
        }
        if (roomDetail.id) {
            let link = window.location.origin + `/?roommodel=${roomDetail.mode}&id=${roomDetail.id}&inviteType=room&type=invite`
            setRoomInviteLink(link)
        }
        if (changeName && Name === roomDetail.name) {
            setUpdateNameLoading(false)
            closeUpdateRoomName()
        }
        if (roomDetail.maxplayers === Maxplayers) {
            setUpdateMaxplayersLoading(false)
        }
        if (roomDetail.state === 1) {
            const data = props.model === 'props' ? { nft: props.mostPowerfulNft } : {}
            navigate("/game#room", { replace: true, state: data })
        }
    }, [roomDetail])

    useEffect(() => {
        if (props.roomfull && !roomDetail.id) {
            openNotification(t("gameChooseModel.roomFullJoinFailed"))
            props.roomFull(false)
            SetJoin(true)
            socket.emit("viewer_join_room", { id: joinId })
            props.onClose("viewRoom")
        }
    }, [props.roomfull])

    useEffect(() => {
        if (props.model && props.type) {
            if (props.type === "createdRoom") {
                socket.emit("create_room", { name: "room" + new Date().getTime(), mode: props.model, maxplayers: 8, nftIds: props.mostPowerfulNft.ID })
            } else if (props.type === "joinRoom") {
            } else if (props.type === "viewRoom") {
            }
        }
    }, [props.model, props.type])

    useEffect(() => {
        SetJoin(false)
    }, [currentUser])

    useEffect(() => {
        if (joinId && !roomDetail.id) {
            socket.emit("join_room", { id: joinId, nftIds: props.mostPowerfulNft.ID })
        }
    }, [joinId, roomDetail.id])


    const leaveRoom = () => {
        socket.emit("leaveRoom", (res) => {
            if (res.code === 0) {
                props.MoveResult()
            }
        })
        setISLeaved(true)
        if (window.location.search) {
            navigate("/", { replace: true })
        }
    }

    const viewerJoinRoom = () => {
        if (join) { return }
        SetJoin(true)
        socket.emit("viewer_join_room", { id: roomDetail.id })
        props.onClose("viewRoom")
    }

    const viewerToPlayer = () => {
        if (join) { return }
        SetJoin(true)
        setJoinId(roomDetail.id)
        socket.emit("leaveRoom", (res) => {
            if (res.code === 0) {
                props.MoveResult()
            }
        })
        props.onClose("joinRoom")
    }

    const roomNameOnChange = (e) => {
        setName(e.target.value)
    }
    const ChangeName = () => {
        if (currentUser.admin) {
            setChangeName(true)
        }
    }
    const updateRoomName = () => {
        if (updateNameLoading) { return }
        if (roomDetail.name != Name && Name) {
            setUpdateNameLoading(true)
            socket.emit("update_room",
                { id: roomDetail.id, name: Name, maxplayers: roomDetail.maxplayers },
                (res) => {
                    if (res.code) {
                        closeUpdateRoomName()
                        openNotification(res.msg)
                    }
                }
            )
        } else {
            closeUpdateRoomName()
        }
    }
    const closeUpdateRoomName = () => {
        setChangeName(false)
    }

    const startGame = () => {
        if (startgameLoading) { return }
        if (competitiveList.length < 2) { return }
        setStartgameLoading(true)
        socket.emit("startgame", { room: roomDetail.id }, (res) => { console.log(res) });
    }

    const updateMaxplayers = (type) => {
        if (!currentUser.admin) { return }
        if (updateMaxplayersLoading) { return }
        setUpdateMaxplayersLoading(true)
        let newMax = roomDetail.maxplayers
        if (type === "+") {
            newMax++
        } else {
            newMax--
        }
        setMaxplayers(newMax)
        socket.emit("update_room",
            { id: roomDetail.id, name: roomDetail.name, maxplayers: newMax },
            (res) => {
                if (res.code) {
                    setUpdateMaxplayersLoading(false)
                    openNotification(res.msg)
                }
            }
        )
    }

    return (
        <div className='choose_game_div'>
            <div className="create_room">
                <div className="room_header">
                    <div className="user_msg">
                        {changeName ? <Input className='input_title' autoFocus defaultValue={roomDetail.name} onChange={roomNameOnChange} onBlur={roomNameOnChange} /> :
                            <div className="title_title">{roomDetail.name}</div>}
                        {changeName ?
                            <>
                                <Button className='button_save' disabled={updateNameLoading} onClick={updateRoomName} loading={updateNameLoading}>{t("mint.confirm")}</Button>
                                {updateNameLoading ? "" : <Button className='button_cancel' onClick={closeUpdateRoomName}>{t("gameChooseModel.close")}</Button>}
                            </>
                            : currentUser.admin ? <div className='edit_ic' onClick={ChangeName}></div>
                                : ""}
                        <div className="title_suffix">
                            <div className="title_score">{t("gameChooseModel.points")}：<span>322</span></div>
                            <div className="title_score">{t("gameChooseModel.ranking")}：<span>12</span></div>
                        </div>
                    </div>
                    <div className="inter">
                        <div className="inter_link">{roomInviteLink}</div>
                        <div className='copy_ic' onClick={() => { copy(roomInviteLink) }}></div>
                    </div>
                </div>
                {
                    roomDetail.id && !isLeaved ?
                        <div className="room_content">
                            <div className="competitive_seats">
                                <div className="host">
                                    <div className="host_title">
                                        {t("gameChooseModel.homeowner")}
                                    </div>
                                    <div className="avatar">
                                        <Image className="av" preview={false} fallback={avatarDefault} src={roomHost?.url} />
                                    </div>
                                    <div className="username">{roomHost?.name}</div>
                                </div>
                                <div className="competitive_seats_right">
                                    <div className="members">
                                        {
                                            competitiveList.map((item, index) => {
                                                return (
                                                    <div key={item.name} className="item">
                                                        <Image className="avatar" preview={false} fallback={avatarDefault} src={item.url} />
                                                        <div className="username">{item.name}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            competitiveList.length < roomDetail.maxplayers ?
                                                new Array((roomDetail.maxplayers || 7) - (competitiveList.length || 0)).fill(true).map((item, index) => {
                                                    return (
                                                        <div className="item" key={index + item}>
                                                            <div className="avatar">
                                                                {updateMaxplayersLoading ?
                                                                    <Spin /> :
                                                                    <>
                                                                        {!currentUser.admin || (roomDetail.maxplayers === 2 && !index) ? "" : <div className="locked_ic" onClick={() => { updateMaxplayers("-") }}></div>}
                                                                        {!currentUser.admin || (roomDetail.maxplayers === 2 && !index) ? <div className="accumulate_ic"></div> : <div className="accumulate_ic_none"></div>}
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className="username">{t("gameChooseModel.waiting")}</div>
                                                        </div>
                                                    )
                                                }) : ""
                                        }
                                        {
                                            roomDetail.maxplayers < 8 ? new Array(8 - (roomDetail.maxplayers || 1)).fill(false).map((item, index) => {
                                                return (
                                                    <div className="item" key={index + item}>
                                                        <div className="avatar">
                                                            {updateMaxplayersLoading ?
                                                                <Spin /> :
                                                                <>
                                                                    {currentUser.admin ? <div className="unlocked_ic" onClick={() => { updateMaxplayers("+") }}></div> : ""}
                                                                    {currentUser.admin ? <div className="decumulate_ic_none"></div> : <div className="decumulate_ic"></div>}
                                                                </>
                                                            }
                                                        </div>
                                                        <div className="username">{t("gameChooseModel.locked")}</div>
                                                    </div>
                                                )
                                            }) : ""
                                        }
                                    </div>
                                    <div className="button_list">
                                        {currentUser.admin && competitiveList.length > 1 ? <Button className='button_purple' loading={startgameLoading} disabled={startgameLoading} onClick={startGame}><div className="startup_ic"></div> {t("gameChooseModel.startGame")}</Button> : ""}
                                        {!currentUser.viewer ?
                                            <Button disabled={join} className={join ? "disabled" : "button_yellow"} onClick={() => { viewerJoinRoom() }} ><div className="watch_ic"></div>{t("gameChooseModel.spectating")}</Button> :
                                            <Button disabled={join} className={join ? "disabled" : "button_yellow"} onClick={() => { viewerToPlayer() }} ><div className="watch_ic"></div>{t("gameChooseModel.join")}</Button>
                                        }
                                        <Button className='button_red' onClick={() => { leaveRoom() }}>
                                            <div className="exit_ic"></div>
                                            {t("gameChooseModel.exit")}
                                        </Button>
                                    </div>
                                    <div className="competitive_number">{competitiveList.length + "/" + (roomDetail.maxplayers || 0)}</div>
                                </div>
                            </div>
                            <div className="spectator_seats">
                                <div className="content_title">{t("gameChooseModel.spectator")}</div>
                                <div className="spectator">
                                    {
                                        spectatorList.map((item, index) => {
                                            return (
                                                <div key={item.name} className="item">
                                                    <Image className="avatar" preview={false} fallback={avatarDefault} src={item.url} />
                                                    <div className="username">{item.name}</div>
                                                </div>
                                            )
                                        })
                                    }
                                    {/* {
                                    spectatorList.length < 15 ? new Array(15 - spectatorList.length).fill(false).map((item, index) => {
                                        return (
                                            <div className="item" key={index}>
                                                <div className="avatar">
                                                </div>
                                            </div>
                                        )
                                    }) : ""
                                } */}
                                </div>
                            </div>
                        </div> : <div className="room_content"><Spin /></div>
                }

            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    roomfull: state.sockets.roomFull,
});

const mapDispatchToProps = { MoveResult, roomFull };

export default connect(mapStateToProps, mapDispatchToProps)(CreateRoom);
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../scss/Game/home.scss";
import Room from "../../Components/Game/room";
import Game from "../../Components/Game/game";
import { ToastContainer } from "react-toastify";
import { connect, useSelector } from "react-redux";
import { IMAGE_BASEURL } from "../../tools/gameConfig";
import { clearChatMessages, newPLayer, newRoom, newTetriminos } from "../../redux/actions/sockets/socketsActions";

// background music
import soundSliant from "../../assets/tetrisSoundSliant.mp3";

function Home(props) {
  const navigate = useNavigate()
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

  const [created, setcreated] = useState(false);
  const [start, setstart] = useState(true);
  const [Sound, setSound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [interGameType, setInterGameType] = useState("");
  const [audio, setAudio] = useState(new Audio(soundSliant));
  const [audioSliant] = useState(new Audio(soundSliant));
  const roomDetail = useSelector((state) => state.sockets.roomDetail);
  const SoundSetting = useSelector((state) => state.localSetting.soundSettings.music)

  const query = useQuery()
  const gameLevel = query.get("gameLevel")
  const gamePower = query.get("gamePower")
  // update roomName mode
  useEffect(() => {
    if (!roomDetail?.name) {
      setcreated(false)
    } else {
      setcreated(true)
    }
  }, [roomDetail])

  useEffect(() => {
    setLoading(created)
  }, [created])

  useEffect(() => {
    if (SoundSetting) {
      setAudio(new Audio(IMAGE_BASEURL + (Math.floor(Math.random() * 8) + 1) + ".mp3"))
    }
  }, [SoundSetting])

  useEffect(() => {
    !start && Sound && SoundSetting ? audio.play() : audio.pause()
    !start && Sound && SoundSetting ? audioSliant.pause() : audioSliant.play()
    return () => {
      audio.pause()
      audioSliant.pause()
    }
  }, [start, Sound, SoundSetting])

  //Play sound when its ended
  useEffect(() => {
    audio.addEventListener("ended", () => {
      if (SoundSetting) {
        audio.play()
      }
    });
    return () => {
      audio.pause()
    }
  }, [audio]);

  useEffect(() => {
    audioSliant.addEventListener("ended", () => {
      if (!SoundSetting) {
        audioSliant.play()
      }
    });
    return () => {
      audioSliant.pause()
    }
  }, [audioSliant]);

  useEffect(() => {
    isload()
    if (window.location.hash === "#invite") {
      setInterGameType("invite")
    } else if (window.location.hash === "#making") {
      setInterGameType("making")
    } else if (window.location.hash === "#room") {
      setInterGameType("room")
    }
    return () => {
      setcreated(false)
      setstart(true)
      setSound(false)
      setInterGameType("")
    }
  }, [])


  const isload = () => {
    if (localStorage.getItem("loading") === "true" || !props.userName) {
      localStorage.removeItem("loading")
      navigate('/', { replace: true })
    } else {
      setLoading(false)
    }
  }

  return (
    <div className="home">
      {/* if room created */}
      {created ?
        <Game data={{ start, setstart, audio, setSound, interGameType, gameLevel, gamePower }} />
        // if room not created ===> created room
        : <Room interGameType={interGameType} loading={loading} gameLevel={gameLevel} />
      }
      <ToastContainer />
    </div>
  );
}

const mapStatetoProps = (state) => ({
  stages: state.sockets.stages,
  tetriminos: state.sockets.tetriminos,
  userName: state.sockets.userName,
});

const mapDispatchtoProps = { clearChatMessages, newPLayer, newRoom, newTetriminos };
export default connect(mapStatetoProps, mapDispatchtoProps)(Home);

import React, { useState, useEffect, useRef } from 'react';
import Flipper from './Flipper';
import './flipClock.scss';

const FlipClock = ({ endTime, ended }) => {
    const timerRef = useRef(null);
    const flipperRefs = useRef([]);
    const [currentTime, setCurrentTime] = useState("");

    useEffect(() => {
        init();
        run();
        return () => clearInterval(timerRef.current);
    }, []);

    const init = () => {
        const now = endTime - new Date().getTime();
        if (now > 0) {
            const nowTimeStr = formatTime(now, 'hmmss');
            setCurrentTime(nowTimeStr);
            setTimeout(() => {
                for (let i = 0; i < flipperRefs.current.length; i++) {
                    flipperRefs.current[i].setFront(nowTimeStr[i]);
                }
            }, 1);
        } else {
            ended()
        }
    };

    const run = () => {
        timerRef.current = setInterval(() => {
            const now = endTime - new Date().getTime();
            if (now > 1000) {
                const nowTimeStr = formatTime(now, 'hmmss');
                const nextTimeStr = formatTime(now - 1000, 'hmmss');
                for (let i = 0; i < flipperRefs.current.length; i++) {
                    if (nowTimeStr[i] !== nextTimeStr[i]) {
                        flipperRefs.current[i].flipDown(nowTimeStr[i], nextTimeStr[i]);
                    }
                }
                setCurrentTime(nowTimeStr);
            } else {
                clearInterval(timerRef.current)
                ended()
            }
        }, 1000);
    };

    const formatTime = (milliseconds, timeFormat) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        const timeObject = {
            'h+': hours,
            'm+': minutes,
            's+': seconds,
        };
        for (let k in timeObject) {
            if (new RegExp(`(${k})`).test(timeFormat)) {
                const str = timeObject[k] + '';
                timeFormat = timeFormat.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1 ? str : padLeftZero(str)
                );
            }
        }
        return timeFormat;
    };

    const padLeftZero = (str) => {
        return ('00' + str).slice(-2);
    };

    return (
        <div className="FlipClock">
            {Array.from({ length: currentTime.length - 4 }).map((_, i) => (
                <Flipper
                    ref={(el) => (flipperRefs.current[i] = el)}
                    key={i}
                />
            ))}
            {currentTime.length > 4 ? <em>:</em> : ""}
            {currentTime.length > 3 ? <Flipper ref={(el) => (flipperRefs.current[currentTime.length - 4] = el)} /> : ""}
            {currentTime.length > 2 ? <Flipper ref={(el) => (flipperRefs.current[currentTime.length - 3] = el)} /> : ""}
            {currentTime.length > 2 ? <em>:</em> : ""}
            {currentTime.length > 1 ? <Flipper ref={(el) => (flipperRefs.current[currentTime.length - 2] = el)} /> : ""}
            {currentTime.length > 0 ? <Flipper ref={(el) => (flipperRefs.current[currentTime.length - 1] = el)} /> : ""}
        </div>
    );
};

export default FlipClock;

import React, { useEffect, useState } from 'react'
import { Button, Image, Spin } from 'antd'
import { t } from 'i18next'

import { getSwapTokenTRate, getVSYS2USD, postListNft } from '../../api'

import { IMAGE_BASEURL } from '../../tools/gameConfig'
import { viewCode } from '../../tools/viewCode'
import { openNotification } from '../../tools/notification'
import { VSWAP_CONTRACT_ADDRESS } from '../../tools/tokenConfig'

import PublicPopup from '../PublicComponents/PublicPopup'
import "../../scss/Market/ListNftConfirm.scss"

function ListNftConfirm({ nftDetailList, onClose, onBack, onConfirm, TET2USD }) {

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [TET2USDCOPY, setTET2USDCOPY] = useState(0)

    const close = () => {
        if (message == "success") {
            onConfirm()
        } else {
            onClose()
        }
    }

    useEffect(async () => {
        if (!TET2USD) {
            try {
                setTET2USDCOPY((await getSwapTokenTRate({ vswapContract: VSWAP_CONTRACT_ADDRESS }))?.data?.data?.tetrisTokenExchange?.usd || 0)
            } catch (error) {
                setTET2USDCOPY(0)
                console.log(error)
            }
        } else {
            setTET2USDCOPY(TET2USD)
        }
    }, [TET2USD])

    const list = () => {
        setLoading(true)
        let ListNFTs = []
        nftDetailList.map((item) => {
            let nft = {}
            nft.price = item.price
            nft.userNFTId = item.UserNftID || item.ID
            nft.expireDuration = item.expirationTime / 1
            ListNFTs.push(nft)
        })
        window._czc && window._czc.push(["_trackEvent", "NFT上架确认页", "点击", "上架NFT"]);
        postListNft({ ListNFTs }).then((res) => {
            if (res.data.code) {
                setMessage("filed")
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            } else {
                setMessage("success")
            }
        }).catch(() => {
            openNotification(t("responseCode.networkFailed"))
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className='list_nft_confirm'>
            <PublicPopup title={t("market.list")} isOverlay closefun={close}>
                <div className="nft_content">
                    <div className="nft_list">
                        {nftDetailList.map((nftDetail, index) => {
                            return (
                                <div className='nft_detail' key={nftDetail.ID || index}>
                                    <div className="nft_right">
                                        <Image
                                            className={"nft_img"}
                                            preview={false}
                                            src={nftDetail?.NftAttributes?.content ? IMAGE_BASEURL + nftDetail?.NftAttributes.content + ".png?imageView2/1/w/100/h/100" : ""}
                                            placeholder={
                                                <Image
                                                    preview={false}
                                                    className={"nft_img"}
                                                    src={nftDetail?.NftAttributes?.content ? IMAGE_BASEURL + nftDetail?.NftAttributes.content + ".png?imageView2/1/w/50/h/50" : ""}
                                                />
                                            }
                                        />
                                        <div className='nft_series'>
                                            <div className="series">
                                                <div className='series_ic'></div>
                                                <div>{t("market.tetrisSeries")}</div>
                                            </div>
                                            <div>{t("market.tetrisSeries") + " #" + nftDetail?.NftTokenIndex}</div>
                                        </div>
                                    </div>
                                    <div className='nft_item'>
                                        <div className="nft_item_title">{t("market.price")}</div>
                                        <div className='nft_item_content'>
                                            <div className="price">{nftDetail?.price}</div>
                                            <div className='price_ic'></div>
                                        </div>
                                        <div className='nft_item_usd'>
                                            {"$" + (nftDetail?.price * TET2USDCOPY).toFixed(2)}
                                        </div>
                                    </div>
                                    <div className="nft_item">
                                        <div className="nft_item_title">{t("market.listExpiration")}</div>
                                        <div className='nft_item_content'>{t(`market.hour${nftDetail.expirationTime}`)}</div>
                                    </div>
                                </div>)
                        })}
                    </div>
                </div>
                <div className='nft_message'>
                    {loading ? <Spin />
                        : message == "success" ?
                            <>
                                <div className="success_ic"></div>
                                <div className='message_title message_success'>{t("market.listSuccess")}</div>
                            </>
                            : message == "filed" ?
                                <>
                                    <div className="filed_ic"></div>
                                    <div className='message_title message_filed'>{t("market.listFiled")}</div>
                                </>
                                : ""
                    }
                </div>
                <div className="nft_button">
                    {!loading ? message ?
                        <Button onClick={close} className='button_common secondary_button'>{t("market.confirm")}</Button> :
                        <>
                            <Button onClick={list} className='button_common primary_button'>{t("market.confirm")}</Button>
                            <Button onClick={onBack} className='button_common secondary_button'>{t("market.cancel")}</Button>
                        </>
                        : ""
                    }
                </div>
            </PublicPopup>
        </div>
    )
}

export default ListNftConfirm
import store from '../../store'

export const SetSound = (settings) => {
    store.dispatch({
        type: "SET_SOUND",
        settings: settings,
    });
};

export const HaveFreeTicket = (type) => {
    store.dispatch({
        type: "HAVE_FREE_TICKET",
        freeTicket: type,
    });
}

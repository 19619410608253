import { TOKEN_ADDRESS, VSYS_ADMIN_ACCOUNT } from './tokenConfig.js'
import { checkWalletVersion } from './utils.js';

const Network = process.env.REACT_APP_DEPLOY_NET
const NetworkName = Network === "T" ? "Testnet" : "Mainnet"

async function getInfo() {
    const info = await window.vsys.request({
        method: 'info'
    });
    return info;
}

async function getAddress() {
    const address = await window.vsys.request({
        method: 'address'
    });
    return address;
}

async function getPubKey() {
    const {
        publicKey
    } = await window.vsys.request({
        method: 'publicKey'
    })
    return publicKey
}

// Distinguish whether to download plug-in wallet, whether to log in, whether the main network
export async function getMessage() {
    if (window.vsys && window.vsys.isInstalled) { } else {
        return {
            result: false,
            type: 'download',
        }
    }
    const {
        network, version
    } = await getInfo()
    if (!checkWalletVersion(version)) {
        return {
            result: false,
            type: 'version',
            version: version
        }
    }
    const {
        address, message
    } = await getAddress()
    if (!address) {
        if (message == "User denied the website access extension wallet") {
            return {
                result: false,
                type: 'success',
            }
        }
        return {
            result: false,
            type: 'login',
        }
    }
    if (network != NetworkName && address) {
        const net = await switchNetwork(Network)
        if (net.result) {
            const results = await getMessage()
            return results
        } else {
            return {
                result: false,
                type: "switchNetwork",
            }
        }
    }

    return {
        result: true,
        msg: address
    }
}


//sign 
export async function signContent(content) {
    const pubKey = await getPubKey()
    const { signature } = await window.vsys.request({
        method: 'signContent',
        params: {
            publicKey: pubKey,
            content: content,
        },
    })
    return { signature, pubKey }
}

export async function getVSYSAmount() {
    const amount = await window.vsys.request({
        method: 'amount',
    });
    return amount;
}

export async function getTokenAmount(id) {
    const tokenBalance = await window.vsys.request({
        method: 'tokenAmount',
        params: { tokenId: id },
    });
    return tokenBalance;
}

export async function sendToken(amount) {
    const pubKey = await getPubKey()
    const res = await window.vsys.request({
        method: 'send',
        params: {
            tokenId: TOKEN_ADDRESS,
            publicKey: pubKey,
            recipient: VSYS_ADMIN_ACCOUNT,
            amount: amount,
            description: "The user send tetris token to admin account.",
        },
    });
    return res;
}

export async function send(amount) {
    const pubKey = await getPubKey()
    const res = await window.vsys.request({
        method: 'send',
        params: {
            publicKey: pubKey,
            recipient: VSYS_ADMIN_ACCOUNT,
            amount: amount,
            description: "The user send vsys to admin account.",
        },
    });
    return res;
}

export async function sendNFT(nftTokenID) {
    const pubKey = await getPubKey()
    const res = await window.vsys.request({
        method: 'sendNFT',
        params: {
            tokenId: nftTokenID,
            publicKey: pubKey,
            recipient: VSYS_ADMIN_ACCOUNT,
            description: "The user send tetris NFT to admin account.",
        },
    });
    return res;
}

export async function switchNetwork(networkType) {
    const res = await window.vsys.request({
        method: 'switchNetwork',
        params: {
            networkType: networkType
        }
    })
    return res
}


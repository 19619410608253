import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { Input, Button } from 'antd';

import { postSendVerificationCode } from '../../api';
import { openNotification } from '../../tools/notification'
import { viewCode } from '../../tools/viewCode';

import BlockMovement from '../../Components/SpecialEffects/BlockMovement/Index';
import "./Index.scss"

const WebRecoverPassword = (props) => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [sendingCode, setSendingCode] = useState(false)

    useEffect(() => {
        return () => {
            setEmail('')
            setSendingCode(false)
        }
    }, [])

    const next = () => {
        if (sendingCode) {
            return
        }
        setSendingCode(true)
        let emailG = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{1,7}$/;
        if (!email) return openNotification(t("user.emailNull"))
        if (!emailG.test(email)) return openNotification(t("user.emailError"))
        postSendVerificationCode({ email: email, action: "reset password" }).then((res) => {
            if (!res.data.code) {
                openNotification(t("user.sendVerificationCodeSuccess"))
                navigate(`/webResetPassword`, { replace: true, state: email })
            } else {
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            }
        }).catch((err) => {
            openNotification(t("responseCode.networkFailed"))
        }).finally(() => {
            setSendingCode(false)
        })
    }
    const previous = () => {
        navigate("/webLogin", { replace: true })
    }

    return (
        <div className="web">
            <BlockMovement />
            <div className="logo"></div>
            <div className="form">
                <div className="web_title">{t("user.forgotPassword")}</div>
                <Input className="form_input" suffix={" "} onChange={(e) => { setEmail(e.target.value.trim()) }} value={email} placeholder={t("user.interEmail")} />
                <div className="form_space_between">
                    <Button className="button_common secondary_button" onClick={previous}>{t("user.previous")}</Button>
                    <Button className="button_common primary_button" onClick={next}>{t("user.next")}</Button>
                </div>
                <div className="form_space_between">
                    <span className="form_link">
                        {/* {t("user.thirdPartyAccount")} */}
                    </span>
                    <Link className="form_link" onClick={() => { window._czc && window._czc.push(["_trackEvent", "去注册", "点击"]); }} to="/webRegister" >{t("user.register")}</Link>
                </div>
            </div>
        </div>
    )
}

export default WebRecoverPassword
const initialState = {
  token: "",
  mintNftTransaction: [],
  mintNftVSYSTransaction: [],
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        token: action.token,
      };
    case "ADD_NFT_TRANSACTION":
      let mintNft = state.mintNftTransaction || []
      mintNft.map((item) => {
        if (item?.key === action.transaction.key) {
          item = action.transaction
        }
      })
      let type = mintNft.find((item) => {
        return item?.key === action.transaction.key
      })
      if (!type?.key) {
        return {
          ...state,
          mintNftTransaction: [...mintNft, action.transaction]
        }
      } else {
        return {
          ...state,
          mintNftTransaction: [...mintNft]
        }
      }
    case "MOVE_NFT_TRANSACTION":
      let newArray = state.mintNftTransaction.filter((item) => {
        return item.key !== action.key
      })
      return {
        ...state,
        mintNftTransaction: newArray
      }
    case "ADD_VSYS_NFT_TRANSACTION":
      let mintNft1 = state.mintNftVSYSTransaction || []
      mintNft1.map((item) => {
        if (item?.key === action.transaction.key) {
          item = action.transaction
        }
      })
      let type1 = mintNft1.find((item) => {
        return item?.key === action.transaction.key
      })
      if (!type1?.key) {
        return {
          ...state,
          mintNftVSYSTransaction: [...mintNft1, action.transaction]
        }
      } else {
        return {
          ...state,
          mintNftVSYSTransaction: [...mintNft1]
        }
      }
    case "MOVE_VSYS_NFT_TRANSACTION":
      let newArray1 = state.mintNftVSYSTransaction.filter((item) => {
        return item.key !== action.key
      })
      return {
        ...state,
        mintNftVSYSTransaction: newArray1
      }
    default:
      return state;
  }
};

import O from '../../assets/board/O_bg.png'
import I from '../../assets/board/I_bg.png'
import Z from '../../assets/board/Z_bg.png'
import J from '../../assets/board/J_bg.png'
import L from '../../assets/board/L_bg.png'
import T from '../../assets/board/T_bg.png'
import S from '../../assets/board/S_bg.png'
import LS_bg from '../../assets/board/LS_bg.png'
import IS_bg from '../../assets/board/IS_bg.png'
import SS_bg from '../../assets/board/SS_bg.png'
import TS_bg from '../../assets/board/TS_bg.png'
import JS_bg from '../../assets/board/JS_bg.png'
import OS_bg from '../../assets/board/OS_bg.png'
import ZS_bg from '../../assets/board/ZS_bg.png'

export const TETROMINOS = {
  0: { shape: [[0]], color: "0, 0, 0" },
  I: {
    shape: [
      ["I", "I", "I", "I"],
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0],
    ],
    color: "80, 227, 230,0.8",
    bg: I
  },
  J: {
    shape: [
      ["J", 0, 0],
      ["J", "J", "J"],
      [0, 0, 0],
    ],
    color: "36, 95, 223,0.8",
    bg: J
  },
  L: {
    shape: [
      [0, 0, "L"],
      ["L", "L", "L"],
      [0, 0, 0],
    ],
    color: "223, 173, 36,0.8",
    bg: L
  },
  O: {
    shape: [
      ["O", "O"],
      ["O", "O"],
    ],
    color: "154, 181, 207, 1",
    bg: O
  },
  S: {
    shape: [
      [0, "S", "S"],
      ["S", "S", 0],
      [0, 0, 0],
    ],
    color: "48, 211, 56,0.8",
    bg: S
  },
  T: {
    shape: [
      [0, "T", 0],
      ["T", "T", "T"],
      [0, 0, 0],
    ],
    color: "132, 61, 198,0.8",
    bg: T
  },
  Z: {
    shape: [
      ["Z", "Z", 0],
      [0, "Z", "Z"],
      [0, 0, 0],
    ],
    color: "227, 78, 78,0.8",
    bg: Z
  },
  IS: {
    color: "80, 227, 230,0.19",
    bg: IS_bg
  },
  JS: {
    color: "36, 95, 223,0.19",
    bg: JS_bg
  },
  LS: {
    color: "223, 173, 36,0.19",
    bg: LS_bg
  },
  OS: {
    color: "154, 181, 207, 1",
    bg: OS_bg
  },
  SS: {
    color: "48, 211, 56,0.19",
    bg: SS_bg
  },
  TS: {
    color: "132, 61, 198,0.19",
    bg: TS_bg
  },
  ZS: {
    color: "227, 78, 78,0.19",
    bg: ZS_bg
  },
  Wall: {
    color: "72, 72, 72,0.8",
    bg: OS_bg
  },
};

// Generate random tetrimino
// export const randomTetromino = () => {
//   const tetrominos = "IJLOSTZ";
//   const randTetromino = tetrominos[Math.floor(Math.random() * tetrominos.length)];
//   return TETROMINOS[randTetromino];
// };

import React, { useEffect, useState } from 'react'
import { Dropdown, Spin, Image } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { t } from 'i18next';

import { postNftDetailListQuality, postNftMarketplaceAction } from '../../../api';
import { viewCode } from '../../../tools/viewCode';
import { openNotification } from '../../../tools/notification';
import { IMAGE_BASEURL, TOKENNAME } from '../../../tools/gameConfig';

import CommonTable from '../../PublicComponents/Table';
import CommonPagination from '../../PublicComponents/Pagination';
import styles from '../../../scss/Hall/PersonalCenter/IncomeRecords.module.scss'

function MarketAction(props) {
    const [transactionType, setTransactionType] = useState("all")
    const [viewTransactionType, setViewTransactionType] = useState(t("tableData.all"))
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)

    const columns = [
        {
            title: t("tableData.type"),
            dataIndex: 'Type',
            key: 'Type',
            align: 'center',
            width: '14%',
            ellipsis: true,
            render: (type) => {
                let typeObj = items.find((item) => {
                    return item.key === type
                })
                return typeObj?.label || "其他"
            }
        },
        {
            title: t("market.info"),
            dataIndex: 'NftAttributes',
            key: 'NftAttributes',
            align: 'center',
            width: '25%',
            ellipsis: true,
            render: (NftAttributes) => {
                return (
                    <div className="nft_right">
                        <Image
                            className={"nft_img " + NftAttributes?.attributes?.quality}
                            preview={false}
                            src={NftAttributes?.content ? IMAGE_BASEURL + NftAttributes.content + ".png?imageView2/1/w/100/h/100" : ""}
                            placeholder={
                                <Image
                                    className={"nft_img " + NftAttributes?.attributes?.quality}
                                    preview={false}
                                    src={NftAttributes?.content ? IMAGE_BASEURL + NftAttributes.content + ".png?imageView2/1/w/50/h/50" : ""}
                                />
                            }
                        />
                        <div className='nft_series'>
                            <div>{t("market.tetrisSeries") + " #" + NftAttributes?.NftTokenIndex}</div>
                            <div className="series">
                                <div>{t("market.tetrisSeries")}</div>
                                <div className='series_ic'></div>
                            </div>
                        </div>
                    </div>)
            }
        },
        {
            title: t("market.price"),
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
            width: '16%',
            ellipsis: true,
            render: (price) => {
                if (price) {
                    return (
                        price + " " + TOKENNAME
                    )
                } else {
                    return "---"
                }
            }
        },
        {
            title: t("tableData.status"),
            dataIndex: 'Status',
            key: 'Status',
            align: 'center',
            width: '15%',
            ellipsis: true,
            render: (type) => {
                if (type === "Minting") {
                    return t("tableData.minting")
                } else if (type === "Pending") {
                    return t("tableData.pending")
                } else if (type === "Locked") {
                    return t("tableData.locked")
                } else if (type === "Success") {
                    return t("tableData.success")
                } else if (type === "Failed") {
                    return t("tableData.failed")
                } else {
                    return type
                }
            }
        },
        {
            title: t("tableData.time"),
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            align: 'center',
            width: '16%',
            ellipsis: true,
            render: (time) => {
                if (time.split("T").length) {
                    return time.split("T")[0] === "0001-01-01" ? t("tableData.permanent") : dayjs(time).format('YYYY-MM-DD HH:mm:ss')
                } else {
                    return time
                }
            }
        },
    ];
    const items = [
        { type: "All", label: t("tableData.all"), key: 'all' },
        { type: "All", label: t("tableData.list"), key: 'list' },
        { type: "All", label: t("tableData.remove"), key: 'remove' },
        { type: "All", label: t("tableData.sell"), key: 'sell' },
        { type: "All", label: t("tableData.buy"), key: 'buy' },

        { type: "AllMarket", label: t("tableData.list"), key: 'ListNFT' },
        { type: "AllMarket", label: t("tableData.remove"), key: 'RemoveNFT' },
        { type: "AllMarket", label: t("tableData.sell"), key: 'SellNFT' },
        { type: "AllMarket", label: t("tableData.buy"), key: 'BuyNFT' },
    ];



    useEffect(() => {
        if (page && !loading) {
            getDetailList()
        }
    }, [page, transactionType])

    // get Data
    const getDetailList = () => {
        setLoading(true)
        setDataSource([])
        setTotal(0)
        postNftMarketplaceAction({
            "userTransactionTypeFilter": transactionType,
            "pagination": {
                "page": page,
                "size": 8
            }
        }).then((res) => {
            if (!res.data.code) {
                filterDataList(res.data.data.userTransactions)
                setTotal(res.data.data.pagination.total)
            } else {
                setLoading(false)
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    const filterDataList = (list) => {
        let newlist = []
        if (!list || list.length <= 0) {
            setDataSource(newlist)
            setLoading(false)
            return
        }
        let UserNftIds = []
        list.map((item, index) => {
            let newItem = {}
            newItem.key = item.ID
            newItem.Recipient = item.Recipient
            newItem.Sender = item.Sender
            newItem.Status = item.Status
            newItem.CreatedAt = item.CreatedAt
            newItem.Type = item.Type
            newItem.amount =
                (item.Details.price) ? item.Details.price : ""
            newItem.nftUserID = item.Details.user_nft_id
            if (newItem.nftUserID && newItem.nftUserID !== -1) {
                UserNftIds.push(newItem.nftUserID)
            }
            newlist.push(newItem)
        })
        if (UserNftIds.length > 0) {
            postNftDetailListQuality({ userNFTIds: UserNftIds }).then((res) => {
                if (res.data.code) {
                    openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                } else {
                    newlist.map((item) => {
                        item.NftAttributes = res.data.data.find((it) => { return item.nftUserID == it.userNFTId })?.attributes || {}
                        item.NftAttributes.NftTokenIndex = res.data.data.find((it) => { return item.nftUserID == it.userNFTId })?.nftTokenIndex || 0
                    })
                    setDataSource(newlist)
                    setLoading(false)
                }

            }).catch(() => {
                setDataSource(newlist)
                setLoading(false)
            })
        } else {
            setDataSource(newlist)
            setLoading(false)
        }
    }

    const pageChange = (e) => {
        setPage(e)
    }

    const changeItem = ({ key }) => {
        let data = items.filter((item) => {
            return item.key === key
        })
        setViewTransactionType(data[0]?.label)
        setTransactionType(key)
        setPage(1)
    };

    return (
        <div className={styles.income_records_wrapper}>
            {loading ? <Spin /> :
                <div>
                    <div className={styles.filter}>
                        <div className={styles.filters}>
                            <Dropdown
                                menu={{
                                    items: items.filter((item) => item.type === "All"),
                                    onClick: changeItem,
                                }}
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <div className={styles.filter_label}>
                                        {viewTransactionType}
                                        <DownOutlined />
                                    </div>
                                </a>
                            </Dropdown>
                        </div>
                        <div className={styles.pagination_div}>
                            <CommonPagination onChange={pageChange} pageSize={8} current={page} total={total} />
                        </div>
                    </div>
                    <div className={styles.table_div}>
                        <CommonTable
                            dataSource={dataSource}
                            columns={columns}
                        />
                    </div>
                </div>
            }
        </div>

    )
}

export default MarketAction
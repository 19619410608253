import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Spin, Drawer, Image } from 'antd'

import Header from '../../Components/Hall/Header.js'
import Home from '../../Components/Hall/Home.js'
import SoloModeSelectLevel from './SoloModeSelectLevel/SoloModeSelectLevel.js'
import Ticker from './Ticker/Index'
import Mint from './Mint/Index'
import Rank from './Rank/Index'
import PersonalCenter from './PersonalCenter/Index'
import ConnectWalletDialog from '../../Components/PublicComponents/ConnectWalletDialog.js'
import ChooseGameType from '../../Components/ChooseGameType/index'
import BlockMovement from '../../Components/SpecialEffects/BlockMovement/Index.js'
import MintNFTMessage from '../../Components/PublicComponents/MintNFTMessage'
import Market from '../../Components/Market/Index'
import SoundSettings from './SoundSettings/SoundSettings.js'

import { socket } from '../../socket/socket'
import { Login } from '../../redux/actions/user/userActions'
import { MoveResult } from '../../redux/actions/sockets/socketsActions'
import Api from '../../socket/Api.js'
import { getInfo, postNftTetrisAttributesByUserNftId, postSetVsysAddress, postNftDetailList, getVSYS2USD, getSwapTokenTRate } from '../../api';

import { TOKEN_ADDRESS, VSWAP_CONTRACT_ADDRESS } from '../../tools/tokenConfig.js'
import { getMessage, signContent, getTokenAmount, getVSYSAmount } from '../../tools/wallet';
import { openNotification } from '../../tools/notification';
import { IMAGE_BASEURL, TOKENNAME } from '../../tools/gameConfig'
import { viewCode } from '../../tools/viewCode'

import './Index.scss'
import avatarDefault from "../../assets/hall/avatar_default.png";
import exit from "../../assets/hall/setting_exit.svg";
import copy from "../../assets/hall/setting_copy.svg";
import nft from "../../assets/hall/setting_nft.svg";
import icon from "../../assets/hall/setting_icon.svg";
import ticket from "../../assets/hall/setting_ticket.svg";
import security from "../../assets/hall/setting_security.svg";
import income from "../../assets/hall/setting_income.svg";
import dynamic from "../../assets/hall/setting_dynamic.svg";
import VSYSIcon from "../../assets/hall/vsys_icon.svg";
import TokenIcon from "../../assets/public_icon/token_icon.svg";
import Top from "../../assets/hall/setting_top.svg";

const Hall = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    let content;
    const [showSoloModeSelectLevel, setShowSoloModeSelectLevel] = useState(false)
    const [showTicker, setShowTicker] = useState(false)
    const [showRank, setShowRank] = useState(false)
    const [showMint, setShowMint] = useState(false)
    const [showPersonalCenter, setShowPersonalCenter] = useState(false)
    const [showConnectWalletDialog, setShowConnectWalletDialog] = useState(false)
    const [showChooseGameType, setShowChooseGameType] = useState(false)
    const [showSoundSettings, setShowSoundSettings] = useState(false)
    const [showMarket, setShowMarket] = useState(false)
    const [mostPowerfulNft, setMostPowerfulNft] = useState({})
    const [userId, setUserId] = useState('')
    const [navType, setNavType] = useState("")

    const [address, setAddress] = useState('')
    const [vsysAddress, setVsysAddress] = useState('')
    const [email, setEmail] = useState('')
    const [nickname, setNickname] = useState('')
    const [uuid, setUuid] = useState('')
    const [avatarUserNFTId, setAvatarUserNFTId] = useState(-1)
    const [userAvatarUrl, setUserAvatarUrl] = useState(null)
    const [freeNftMinted, setFreeNftMinted] = useState(false)
    const [secondPasswordSet, setSecondPasswordSet] = useState(false)
    const [model, setModel] = useState('')
    const [tetrisToken, setTetrisToken] = useState(0)
    const [tetrisTokenLocked, setTetrisTokenLocked] = useState(0)
    const [TETAmount, setTETAmount] = useState(0)
    const [VSYSAmount, setVSYSAmount] = useState(0)
    const [VSYS2USD, setVSYS2USD] = useState(0)
    const [TET2USD, setTET2USD] = useState(0)
    const token = useSelector((state) => state.user.token);
    const [loading, setLoading] = useState(false)
    const [loadingAddreess, setLoadingAddreess] = useState(false)
    const [briefAddr, setBriefAddr] = useState("")
    const [action, setAction] = useState("")
    const [isShowProfile, setIsShowProfile] = useState(false)
    const [isJoinGame, setIsJoinGame] = useState(false)
    const [isNoceOpen, setIsNoceOpen] = useState(true)

    const [atBattleGameTime, setAtBattleGameTime] = useState(null)
    const [battleStartTime, setBattleStartTime] = useState(null)
    const [battleEndTime, setBattleEndTime] = useState(null)


    const query = useQuery()
    const roommodel = query.get("roommodel")
    const type = query.get("type")
    // invite
    const inviteType = query.get("inviteType")
    const id = query.get("id")
    // continue game
    const continueType = query.get("continueType")

    // clear loading clear roomDetail
    useEffect(() => {
        battleGameTimeInit()
        setIsNoceOpen(false)
        socket.emit("leaveRoom", (res) => {
            if (res.code === 0) {
                props.MoveResult()
            }
        })
        if (localStorage.getItem("loading")) {
            localStorage.removeItem("loading")
        }
    }, [])

    useEffect(() => {
        if (isNoceOpen === false && props.roomDetail && !props.roomDetail.id) {
            setIsNoceOpen(true)
        }
    }, [isNoceOpen, props.roomDetail])

    // type === "invite"
    useEffect(() => {
        if ((roommodel === "battle" || roommodel === "props" || roommodel === "classic") &&
            id && type === "invite" && (inviteType === "friends" || inviteType === "room")) {
            onModeSelected(roommodel)
        }
    }, [roommodel, id, type, inviteType])

    // type === "continue"
    useEffect(() => {
        if ((roommodel === "battle" || roommodel === "props" || roommodel === "classic") && type === "continue" &&
            (continueType === "inviteFriends" || continueType === "making1v1"
                || continueType === "making3v3" || continueType === "making6v6"
                || continueType === "making8v8" || continueType === "roomList")) {
            onModeSelected(roommodel)
        }
    }, [roommodel, type, continueType])

    // init email address
    useEffect(() => {
        if (!token || !window) return
        updateUserInfo()
    }, [token, window])

    //  emit new_user
    useEffect(() => {
        if (!token || !nickname || !isNoceOpen || props.userName) return
        socket.emit("new_user", { username: nickname, token: token, url: userAvatarUrl }, (res) => {
            if (!res.code) {
                if (!isJoinGame) {
                    setIsJoinGame(true)
                }
            } else {
                openNotification(res.msg)
                if (isJoinGame) {
                    setIsJoinGame(false)
                }
            }
        })
    }, [token, nickname, userAvatarUrl, isNoceOpen, props.userName])

    // init user avatar
    useEffect(() => {
        if (avatarUserNFTId !== -1) {
            postNftTetrisAttributesByUserNftId({ userNFTId: avatarUserNFTId }).then(async (res) => {
                if (!res.data.code) {
                    let url = res.data.data.content ? IMAGE_BASEURL + res.data.data.content + ".png" : ""
                    setUserAvatarUrl(url)
                }
            })
        } else {
            setUserAvatarUrl("")
        }
    }, [avatarUserNFTId])

    useEffect(() => {
        if (vsysAddress) {
            getAddress()
            window?.vsys?.on('accountsChanged', handleAccountsChanged);
        }
        return () => {
            window?.vsys?.removeListener('accountsChanged', handleAccountsChanged);
        }
    }, [vsysAddress])

    useEffect(() => {
        if (address) {
            let addr = address
            let addrChars = addr.split("");
            addrChars.splice(3, 26, "...");
            setBriefAddr(addrChars.join(""))
        }
    }, [address])

    const handleAccountsChanged = (accounts) => {
        if (vsysAddress === accounts[0]) {
            setAddress(vsysAddress);
        } else {
            setAddress('')
            onHeaderItemClick()
        }
    }

    const getAddress = async () => {
        const address = await getVsysAddress()
        setLoadingAddreess(false)
        if (vsysAddress === address) {
            setAddress(vsysAddress);
        }
    }

    useEffect(() => {
        if (!isShowProfile) {
            setTETAmount(0)
            setVSYSAmount(0)
            setVSYS2USD(0)
            setTET2USD(0)
        }
    }, [isShowProfile])

    // update user info
    const updateUserInfo = () => {
        setLoadingAddreess(true)
        if (tetrisToken === "...") return
        setTetrisToken("...")
        getInfo().then(async (res) => {
            if (res.data.code !== 0) {
                return Login("")
            }
            if (res.data.data?.email !== email) setEmail(res.data.data?.email)
            setTetrisToken(res.data.data?.tetrisToken ? (Math.floor(res.data.data?.tetrisToken * 10000)) / 10000 : 0)
            setTetrisTokenLocked(res.data.data?.tetrisTokenLocked ? (Math.ceil(res.data.data?.tetrisTokenLocked * 100)) / 100 : 0)
            if (res.data.data?.avatarUserNFTId !== avatarUserNFTId) setAvatarUserNFTId(res.data.data?.avatarUserNFTId)
            if (res.data.data?.freeNftMinted !== freeNftMinted) setFreeNftMinted(res.data.data?.freeNftMinted)
            if (res.data.data?.secondPasswordSet !== secondPasswordSet) setSecondPasswordSet(res.data.data?.secondPasswordSet)
            if (res.data.data?.uuid !== uuid) setUuid(res.data.data?.uuid)
            if (res.data.data?.nickname !== nickname) setNickname(res.data.data?.nickname)
            if (res.data.data?.id !== userId) setUserId(res.data.data?.id)
            if (!res.data.data?.vsysAddress) setLoadingAddreess(false)
            if (res.data.data?.vsysAddress !== vsysAddress) setVsysAddress(res.data.data?.vsysAddress)
        }).catch((err) => {
            console.log(err)
            setTetrisToken("0")
            setTetrisTokenLocked("0")
        })
    }

    // get vsys address
    const getVsysAddress = async () => {
        const res = await getMessage()
        if (!res.result) {
            const { type, version } = res
            switch (type) {
                case "download":
                    setShowConnectWalletDialog(true)
                    break;
                case "version":
                    openNotification(t("wallet.versionError"))
                    break;
                case "success":
                    openNotification(t("wallet.authorizationError"))
                    break;
                case "switchNetwork":
                    openNotification(t("wallet.networkSettingError"))
                    break;
                case "login":
                    openNotification(t("wallet.walletLoginError"))
                    break;
                default:
                    break;
            }
        }
        // return address
        else {
            if (res.result) {
                return res.msg
            }
        }
    }

    // connect wallet
    const connectWallet = async () => {
        setLoadingAddreess(true)
        const address = await getVsysAddress()
        if (address) {
            signContent("You are verifying your account with address " + address + ". Please sign the transaction to confirm.").then((res) => {
                postSetVsysAddress({ vsysAddress: address, vsysPubKey: res.pubKey, signedMessage: res.signature }).then((res) => {
                    if (res.data.code) return setLoadingAddreess(false)
                    updateUserInfo()
                })
            })
        } else {
            setLoadingAddreess(false)
        }
    }

    useEffect(() => {
        if (showSoundSettings) {
            setNavType("settings")
        } else if (showRank) {
            setNavType("rank")
        } else if (showTicker) {
            setNavType("ticker")
        } else if (showMint) {
            setNavType("mint")
        } else if (showMarket) {
            setNavType("market")
        } else {
            setNavType("")
        }
    }, [showSoundSettings, showRank, showTicker, showMint, showMarket])

    const onHeaderItemClick = async (nav, type, menu) => {
        if (nav !== 'personalCenter' || type === 'modal') {
            onClose()
        }
        if (nav === 'settings') {
            setShowSoundSettings(true)
            setIsShowProfile(false)
        } else if (nav === 'rank') {
            setShowRank(true)
        } else if (nav === 'ticker') {
            setShowTicker(true)
        } else if (nav === 'mint') {
            setShowMint(true)
        } else if (nav === "market") {
            window._czc && window._czc.push(["_trackEvent", "市场", "点击", "市场按钮"]);
            setShowMarket(true)
        } else if (nav === "connectWallet") {
            connectWallet()
        } else if (nav === 'personalCenter') {
            if (type === 'modal') {
                setAction(menu)
                setShowPersonalCenter(true)
                setIsShowProfile(false)
            } else {
                setIsShowProfile(true)
                setTETAmount((await getTokenAmount(TOKEN_ADDRESS)).amount)
                setVSYSAmount((await getVSYSAmount()).amount)
                try {
                    setVSYS2USD((await getVSYS2USD()).data?.data?.vsysExchange?.usd)
                    setTET2USD((await getSwapTokenTRate({ vswapContract: VSWAP_CONTRACT_ADDRESS }))?.data?.data?.tetrisTokenExchange?.usd || 0)
                } catch (error) {
                    console.log(error)
                }
            }
        }
    }

    const goToTransferInNft = () => {
        onHeaderItemClick("personalCenter", "modal", "transferInNft")
    }
    const goToIncomeRecords = () => {
        onHeaderItemClick("personalCenter", "modal", "incomeRecords")
    }
    const goTomyNft = () => {
        onHeaderItemClick("personalCenter", "modal", "myNft")
    }
    const goToForgetPwd = () => {
        onHeaderItemClick("personalCenter", "modal", "settings")
    }

    const onModeSelected = async (mode) => {
        try {
            onClose()
            setLoading(true)
            const nftList = await getNftDetail("Valid")
            setLoading(false)
            if (nftList.length > 0) {
                setMostPowerfulNft(nftList[0])
                if (mode === 'mode1') {
                    window._czc && window._czc.push(["_trackEvent", "生存模式", "点击"]);
                    setShowSoloModeSelectLevel(true)
                } else if (mode == 'props') {
                    window._czc && window._czc.push(["_trackEvent", "道具站", "点击"]);
                    setModel('props')
                    setShowChooseGameType(true)
                } else if (mode == 'battle') {
                    setLoading(true)
                    const gameTime = await getBattleGameTime()
                    setLoading(false)
                    let nowTime = new Date().getTime()
                    if (gameTime?.start_time > nowTime || !gameTime?.start_time) {
                        navigate("/", { replace: true })
                        return openNotification(t("hall.gameEnded"))
                    }
                    window._czc && window._czc.push(["_trackEvent", "多人竞技", "点击"]);
                    setModel('battle')
                    setShowChooseGameType(true)
                } else if (mode === 'classic') {
                    window._czc && window._czc.push(["_trackEvent", "经典模式", "点击"]);
                    setModel('classic')
                    setShowChooseGameType(true)
                }
            } else {
                setLoading(true)
                const nftList = await getNftDetail("InActivated")
                setLoading(false)
                if (nftList.length > 0) {
                    openNotification(<div >{t("hall.inActivatedErr")}<br />{t("mint.onclick")}<a onClick={goTomyNft}>{t("mint.goToMyNft")}</a>{t("hall.activate")}</div>)
                } else {
                    openNotification(t("hall.requiredOneNFT"))
                }
            }
        } catch (e) {
            setLoading(false)
            openNotification(t("responseCode.networkFailed"))
        }
    }

    const getNftDetail = (nftValidStatusFilter) => {
        return new Promise((resolve, reject) => {
            postNftDetailList({
                "nftValidStatusFilter": nftValidStatusFilter,
                "nftOrder": "quality DESC",
                "pagination": {
                    "page": 1,
                    "size": 20
                }
            }).then((res) => {
                if (!res.data.code) {
                    resolve(res.data.data.userNFTs ? res.data.data.userNFTs : [])
                } else {
                    setLoading(false)
                    openNotification(t(`responseCode.${viewCode(res.data.code)}`))
                }
            }).catch((error) => {
                reject([])
            })
        })
    }

    const getBattleGameTime = () => {
        return new Promise((resolve, reject) => {
            Api().get("/getBattleTime").then((res) => {
                resolve(res.data)
            }).catch((err) => {
                resolve(null)
            })
        })
    }

    const battleGameTimeInit = async () => {
        const gameTime = await getBattleGameTime()
        let nowTime = new Date().getTime()
        setBattleStartTime(gameTime?.start_time || null)
        setBattleEndTime(gameTime?.end_time || null)
        if (gameTime?.start_time && gameTime?.start_time < nowTime) {
            setAtBattleGameTime(true)
        } else {
            setAtBattleGameTime(false)
        }
    }

    const battleStartTimeEnd = () => {
        let nowTime = new Date().getTime()
        if (battleEndTime > nowTime) {
            setAtBattleGameTime(true)
        } else {
            battleGameTimeInit()
        }
    }

    const onClose = () => {
        setShowSoloModeSelectLevel(false)
        setShowChooseGameType(false)
        setShowPersonalCenter(false)
        setShowConnectWalletDialog(false)
        setShowTicker(false)
        setShowRank(false)
        setShowMint(false)
        setShowSoundSettings(false)
        setShowMarket(false)
        setIsShowProfile(false)
    }


    if (showSoloModeSelectLevel) {
        content = <SoloModeSelectLevel onClose={onClose} />
    } else if (showChooseGameType) {
        content = <ChooseGameType onClose={onClose} isJoinGame={isJoinGame} mostPowerfulNft={mostPowerfulNft} token={token} uuid={nickname} inviteType={inviteType} type={type} id={id} model={model} continueType={continueType} />
    } else if (showTicker) {
        content = <Ticker onClose={onClose} updateUserInfo={updateUserInfo} />
    } else if (showMint) {
        content = <Mint show={showMint} onClose={onClose} freeNftMinted={freeNftMinted} updateUserInfo={updateUserInfo} />
    } else if (showRank) {
        content = <Rank onClose={onClose} />
    } else if (showSoundSettings) {
        content = <SoundSettings onClose={onClose} />
    } else if (showPersonalCenter) {
        content = <PersonalCenter TET2USD={TET2USD} userId={userId} action={action} updateUserInfo={updateUserInfo} secondPasswordSet={secondPasswordSet} userAvatarUrl={userAvatarUrl} avatarUserNFTId={avatarUserNFTId} uuid={uuid} nickname={nickname} tetrisToken={tetrisToken} tetrisTokenLocked={tetrisTokenLocked} email={email} addr={address} onClose={onClose} />
    } else if (showConnectWalletDialog) {
        content = <ConnectWalletDialog onClose={onClose} />
    } else if (showMarket) {
        content = <Market TET2USD={TET2USD} sell={goTomyNft} onClose={onClose} tetrisToken={tetrisToken} userId={userId} secondPasswordSet={secondPasswordSet} goToForgetPwd={goToForgetPwd} />
    } else {
        content = <Home cb={onModeSelected} getBattleGameTime={battleGameTimeInit} battleEndTime={battleEndTime} battleStartTime={battleStartTime} atBattleGameTime={atBattleGameTime} battleStartTimeEnd={battleStartTimeEnd} />
    }

    const copyAddr = () => {
        navigator.clipboard
            .writeText(address)
            .then((res) => {
                openNotification(t("commonTips.copySuccess"))
            })
            .catch((err) => {
                openNotification(t("commonTips.copyFail"))
            });
    }


    return (
        <div className="hall-wrapper">
            <BlockMovement />
            <div className="header">
                <Header navType={navType} userAvatarUrl={userAvatarUrl + "?imageView2/1/w/100/h/100"} avatarDefault={avatarDefault} onHeaderItemClick={onHeaderItemClick} address={address} loading={loadingAddreess} />
            </div>
            <div className="content">
                {loading ?
                    <div className="spin_div">
                        <Spin tip={t("hall.joinGame")} >{""}</Spin>
                    </div> : ""}
                {content}
            </div>
            <MintNFTMessage failed={goToIncomeRecords} success={goToTransferInNft} myNft={goTomyNft} />
            <Drawer
                placement='right'
                width={520}
                closable={false}
                onClose={() => setIsShowProfile(false)}
                open={isShowProfile}
            >
                <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'Adobe Heiti Std', }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '5px solid #7C29D433', paddingBottom: '20px' }}>
                        <div style={{ width: '55%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Image preview={false} fallback={avatarDefault} src={userAvatarUrl + "?imageView2/1/w/100/h/100"} width={80} height={80} style={{ borderRadius: '50%' }} />
                            <div style={{
                                fontSize: '21px',
                                fontWeight: '400',
                            }}>{briefAddr}</div>
                            <div onClick={() => copyAddr()} style={{ width: '35px', height: '35px', borderRadius: '50%', boxShadow: '0px 2px 2px 0px #CD9C1C', background: '#FFAE28', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                <Image preview={false} src={copy} width={20} height={20} />
                            </div>
                        </div>
                        <div onClick={() => { setIsShowProfile(false); Login("") }} style={{ width: '35px', height: '35px', borderRadius: '50%', boxShadow: '0px 2px 2px 0px #BA3D21', background: '#F16E30', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                            <Image preview={false} src={exit} width={20} height={20} />
                        </div>
                    </div>

                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', borderBottom: '5px solid #7C29D433', paddingBottom: '20px' }}>
                        <div style={{ width: '48%', }} onClick={() => onHeaderItemClick("personalCenter", 'modal', 'myNft')}>
                            <div style={{ width: '130px', height: '45px', background: "linear-gradient(0deg, #611DF0 -9900%, #7C61E8 100%)", boxShadow: '0px 2px 2.828000068664551px 0px #721AC9', borderRadius: '30px', display: 'flex', alignItems: 'center', justifyContent: 'space-around', margin: '16px 0 16px 0', cursor: 'pointer' }}>
                                <Image preview={false} src={nft} width={20} height={20} />
                                <div style={{ fontSize: '18px', fontWeight: 400, color: '#FFF' }}>{t("hall.myNft")}</div>
                            </div>
                        </div>
                        <div onClick={() => onHeaderItemClick("settings", 'modal')} style={{ width: '48%', display: 'flex', justifyContent: 'flex-end' }}>
                            <div style={{ width: '130px', height: '45px', background: "linear-gradient(0deg, #611DF0 -9900%, #7C61E8 100%)", boxShadow: '0px 2px 2.828000068664551px 0px #721AC9', borderRadius: '30px', display: 'flex', alignItems: 'center', justifyContent: 'space-around', margin: '16px 0 16px 0', cursor: 'pointer' }}>
                                <Image preview={false} src={icon} width={20} height={20} />
                                <div style={{ fontSize: '18px', fontWeight: 400, color: '#FFF' }}>{t("hall.settings")}</div>
                            </div>
                        </div>
                        <div onClick={() => onHeaderItemClick("personalCenter", 'modal', 'myTicker')} style={{ width: '48%', }}>
                            <div style={{ width: '130px', height: '45px', background: "linear-gradient(0deg, #611DF0 -9900%, #7C61E8 100%)", boxShadow: '0px 2px 2.828000068664551px 0px #721AC9', borderRadius: '30px', display: 'flex', alignItems: 'center', justifyContent: 'space-around', margin: '16px 0 16px 0', cursor: 'pointer' }}>
                                <Image preview={false} src={ticket} width={20} height={20} />
                                <div style={{ fontSize: '18px', fontWeight: 400, color: '#FFF' }}>{t("hall.tickets")}</div>
                            </div>
                        </div>
                        <div onClick={() => onHeaderItemClick("personalCenter", 'modal', 'settings')} style={{ width: '48%', display: 'flex', justifyContent: 'flex-end' }}>
                            <div style={{ width: '130px', height: '45px', background: "linear-gradient(0deg, #611DF0 -9900%, #7C61E8 100%)", boxShadow: '0px 2px 2.828000068664551px 0px #721AC9', borderRadius: '30px', display: 'flex', alignItems: 'center', justifyContent: 'space-around', margin: '16px 0 16px 0', cursor: 'pointer' }}>
                                <Image preview={false} src={security} width={20} height={20} />
                                <div style={{ fontSize: '18px', fontWeight: 400, color: '#FFF' }}>{t("hall.security")}</div>
                            </div>
                        </div>
                        <div onClick={() => onHeaderItemClick("personalCenter", 'modal', 'incomeRecords')} style={{ width: '48%', }}>
                            <div style={{ width: '130px', height: '45px', background: "linear-gradient(0deg, #611DF0 -9900%, #7C61E8 100%)", boxShadow: '0px 2px 2.828000068664551px 0px #721AC9', borderRadius: '30px', display: 'flex', alignItems: 'center', justifyContent: 'space-around', margin: '16px 0 16px 0', cursor: 'pointer' }}>
                                <Image preview={false} src={income} width={20} height={20} />
                                <div style={{ fontSize: '18px', fontWeight: 400, color: '#FFF' }}>{t("hall.income")}</div>
                            </div>
                        </div>
                        <div onClick={() => onHeaderItemClick("personalCenter", 'modal', 'marketAction')} style={{ width: '48%', display: 'flex', justifyContent: 'flex-end' }}>
                            <div style={{ width: '130px', height: '45px', background: "linear-gradient(0deg, #611DF0 -9900%, #7C61E8 100%)", boxShadow: '0px 2px 2.828000068664551px 0px #721AC9', borderRadius: '30px', display: 'flex', alignItems: 'center', justifyContent: 'space-around', margin: '16px 0 16px 0', cursor: 'pointer' }}>
                                <Image preview={false} src={dynamic} width={20} height={20} />
                                <div style={{ fontSize: '18px', fontWeight: 400, color: '#FFF' }}>{t("hall.dynamic")}</div>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', borderBottom: '5px solid #7C29D433', paddingBottom: '20px' }}>
                        <div style={{ width: '100%', fontSize: '20px', fontWeight: 400, display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
                            <span>{t("hall.walletAssets")}</span>
                            <span>${(((VSYSAmount || 0) * (VSYS2USD || 0)) + ((TETAmount || 0) * (TET2USD || 0))).toFixed(2)}</span>
                        </div>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '4px' }}>
                            <div style={{ fontSize: '17px', fontWeight: 400, display: 'flex', alignItems: 'center', }}>
                                <Image preview={false} src={VSYSIcon} width={50} height={50} />
                                <span style={{ marginLeft: '10px' }}>VSYS</span>
                            </div>
                            <div style={{ fontSize: '16px', fontWeight: 400, textAlign: 'right', lineHeight: '35px' }}>
                                <div>{VSYSAmount || 0}</div>
                                <div style={{ fontSize: '18px' }}>$ {((VSYSAmount || 0) * (VSYS2USD || 0)).toFixed(2)}</div>
                            </div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ fontSize: '17px', fontWeight: 400, display: 'flex', alignItems: 'center', }}>
                                <Image preview={false} src={TokenIcon} width={50} height={50} />
                                <span style={{ marginLeft: '10px' }}>{TOKENNAME}</span>
                            </div>
                            <div style={{ fontSize: '16px', fontWeight: 400, textAlign: 'right', lineHeight: '35px' }}>
                                <div>{TETAmount || 0}</div>
                                <div style={{ fontSize: '18px' }}>$ {((TETAmount || 0) * (TET2USD || 0)).toFixed(2)}</div>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ width: '100%', fontSize: '20px', fontWeight: 400, display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
                            <span>{t("hall.gameTokens")}</span>
                            <div onClick={() => onHeaderItemClick("personalCenter", "modal", "transferInToken")} style={{ fontSize: '18px', fontWeight: 400, color: '#FFF', cursor: 'pointer', background: 'linear-gradient(177.82deg, #1ACC21 5.68%, #33FD56 98.16%)', width: '128px', height: '40px', borderRadius: '25px', boxShadow: '0px 2px 2px 0px #1ACC21', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                <Image preview={false} src={Top} width={24} height={24} />
                                <span>{t("hall.topup")}</span>
                            </div>
                        </div>

                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                            <div style={{ fontSize: '17px', fontWeight: 400, display: 'flex', alignItems: 'center', }}>
                                <Image preview={false} src={TokenIcon} width={50} height={50} />
                                <span style={{ marginLeft: '10px' }}>{TOKENNAME} Tokens</span>
                            </div>
                            <div style={{ fontSize: '16px', fontWeight: 400, textAlign: 'right', lineHeight: '35px' }}>
                                <div>{tetrisToken || 0}</div>
                                <div style={{ fontSize: '18px' }}>${((tetrisToken || 0) * (TET2USD || 0)).toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer >
        </div >
    )
}

const mapStateToProps = (state) => ({
    roomDetail: state.sockets.roomDetail,
    userName: state.sockets.userName
});

const mapDispatchToProps = { MoveResult };

export default connect(mapStateToProps, mapDispatchToProps)(Hall);

// export default Hall
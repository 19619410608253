import Stage from "./Stage";
import { Statistic } from 'antd';
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'

const { Countdown } = Statistic;

function Board(props) {
  // console.log(props.data.stage)
  const [deadline, setDeadline] = useState(Date.now() + 1000 * 4)
  const { t } = useTranslation()

  useEffect(() => {
    if (props.data.isShowProps) {
      setDeadline(Date.now() + 1000 * 4)
    }
  }, [props.data.isShowProps])
  
  return (
    <div className="board">
      <Stage stage={props.data.stage} size={props.data.size} isShowEffect={props.data.isShowEffect} sweepExactRows={props.data.sweepExactRows}/>
      {props.data.isShowProps ? (
        <div className="overlay">
          {
            props.data.propMode === 'freeze' ? (
              <div className="freeze_bg">
                <div className="freeze_tips">
                {t("game.freezeCountdown")}
                </div>
                <div className="freeze_num"><Countdown valueStyle={{ color: '#fff', fontSize: '40px' }} value={deadline} format="s" />
                </div>
              </div>
            ) : (props.data.propMode === 'acc' ? (
              <div className="acc_bg"></div>
            ) : "")
          }
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Board;

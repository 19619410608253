import React from 'react'

import { Image } from 'antd';

import right_icon from '../../assets/icon/score_text_right_icon.png'
import left_icon from '../../assets/icon/score_text_left_icon.png'
import '../../scss/Game/OtherPlayerBg.scss'
import user_focus from '../../assets/hall/props_focus.svg'

function OtherPlayerBg(props) {
    return (
        <div className="player_bg">
            <div className="players_title">
                <Image
                    preview={false}
                    src={left_icon}
                    className='icon'
                />
                <span> #{props.userIndex + ' ' + props.title}</span>
                <Image
                    preview={false}
                    src={right_icon}
                    className='icon'
                />
            </div>
            {
                props.battleMode === 'props' && !props.isViewer && props.selectedIndex === props.userIndex ? <div className="focus">
                    <Image
                        preview={false}
                        src={user_focus}
                    />
                </div> : ''
            }
            <div className="content">
                {props.children}
            </div>

        </div>
    )
}

export default OtherPlayerBg
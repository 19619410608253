// 30s
const MATCHMAKING_TIME = 30 * 1000
// one day
const FREE_TICKET_TIME = 24 * 60 * 60 * 1000

// 60 day
const NFTEXPIRED = 24 * 60 * 60 * 1000 * 60

// mint about----------------
const MINTDISCOUNT = [
    { min: 1, max: 500, discount: 0.6 },
    { min: 501, max: 1000, discount: 0.7 },
    { min: 1001, max: 2000, discount: 0.8 },
    { min: 2001, max: 3000, discount: 0.9 }
]
const MINTPRICE = 15
// --------------------------

// ticket Price (usd)
const TICKETPRICE = 1

// survival mode threshold config
// Level, Power Requirement
const SURVIVAL_THRESHOLD = [
    { level: 1, powerRequirement: 1000, text: "freeLevels" },
    { level: 2, powerRequirement: 2500, text: "premiumLevels" },
    // { level: 3, powerRequirement: 4500 },
    // { level: 4, powerRequirement: 5000 },
    // { level: 5, powerRequirement: 5500 },
    // { level: 6, powerRequirement: 6000 },
    // { level: 7, powerRequirement: 7000 },
    // { level: 8, powerRequirement: 8000 },
    // { level: 9, powerRequirement: 9000 },
    // { level: 10, powerRequirement: 11000 },
]

const IMAGE_BASEURL = "https://qiniu.stylika.online/"


// market about ----------
const NFTEXPIRATIONDAY = 60
const ALLFEE = 0.895
const TRANSACTIONFEE = "0.5%"
const CREATORFEE = "10%"
const TOKENNAME = "TET"
const BLOCKCHAIN = "VSYS"
//---------------------


// Points Earned Table, Tickets Earned Table
const POINTSEARNEDTABLE = {
    oneVS1: ["+2", "-1"],
    oneVS1GameInvalid: ["+0", "-1"],
    oneVS8: ["+10", "+8", "+6", "+4", "-4", "-6", "-8", "-10"],
}
const TICKETSEARNEDTABLE = {
    oneVS3: ["+2", "+1", "+0"],
    oneVS6: ["+3", "+2", "+1", "+0", "+0", "+0"]
}
// -----------------------------

export {
    MATCHMAKING_TIME,
    SURVIVAL_THRESHOLD,
    NFTEXPIRED,
    MINTDISCOUNT,
    MINTPRICE,
    TICKETPRICE,
    FREE_TICKET_TIME,
    IMAGE_BASEURL,
    NFTEXPIRATIONDAY,
    ALLFEE,
    TRANSACTIONFEE,
    CREATORFEE,
    TOKENNAME,
    BLOCKCHAIN,
    POINTSEARNEDTABLE,
    TICKETSEARNEDTABLE
}
const initialState = {
  userexists: null,
  socket: null,
  userName: "",
  roomname: "",
  roomData: [],
  connected: false,
  tetriminos: [],
  rooms: [],
  roomDetail: {},
  gameAsyncTime: null,
  roomPlayers: [],
  roomViewers: [],
  chat: [],
  Stages: [],
  ClearStagesName: [],
  Scores: [],
  GameOver: false,
  GameFinished: false,
  wall: false,
  wallData: {},
  gameResult: [],
  gameinvalid: false,
  gameAsyncTime: null,
  propsData: [],
  currentUser: {},
  roomExists: false,
  roomFull: false,
  selectedHero: [],
};

export const sockets = (state = initialState, action) => {
  switch (action.type) {
    case "SOCKET_CONNECT":
      return {
        ...state,
        connected: true,
        socket: action.socket,
      };
    case "SOCKET_DISCONNECT":
      return {
        ...state,
        connected: false,
        socket: null,
      };
    case "NEW_TETRIMINOS":
      return {
        ...state,
        tetriminos: action.tetriminos,
      };
    case "USER_EXISTS":
      return { ...state, userexists: action.userexists };
    case "ROOM_EXISTS":
      return { ...state, roomExists: action.isExists }
    case "ROOM_FULL":
      return { ...state, roomFull: action.isFull }
    case "NEW_PLAYER":
      return { ...state, userName: action.userName };
    case "NEW_ROOM":
      return { ...state, roomname: action.roomname };
    case "UPDATE_ROOM_DATA":
      return {
        ...state,
        roomData: action.room,
      };
    case "UPDATE_ROOM_DETAIL":
      return {
        ...state,
        roomDetail: action.roomDetail,
      };
    case "START_GAME":
      return {
        ...state,
        GameOver: false,
        GameFinished: false,
        tetriminos: action.tetriminos,
      };
    case "GAME_OVER":
      return {
        ...state,
        GameOver: true,
      };
    case "SET_ROOMS":
      return {
        ...state,
        rooms: action.rooms,
      };
    case "ADD_STAGES": {
      return { ...state, Stages: action.Stages };
    }
    case "ADD_SCORES": {
      // console.log("ADD_SCORES", action.Scores)
      return { ...state, Scores: action.Scores };
    }
    case "ADD_PROPS": {
      // console.log("ADD_PROPS", action)
      return { ...state, propsData: [...state.propsData, action.PropsData] }
    }
    case "RESET_PROPS": {
      return { ...state, propsData: action.PropsData }
    }
    case "UPDATE_STAGES":
      return { ...state, Stages: action.Stages };
    case "ADD_CLEAR_STAGES":
      return { ...state, ClearStagesName: [...state.ClearStagesName, action.username] }
    case "ROOM_PLAYERS_LIST":
      let currentUserPlayer = action.roomPlayers.filter((item) => {
        return item.name === state.userName
      })[0]
      return {
        ...state,
        roomPlayers: action.roomPlayers,
        currentUser: currentUserPlayer || state.currentUser,
      };
    case "ROOM_VIEWERS_LIST":
      let currentUserViewer = action.roomViewers.filter((item) => {
        return item.name === state.userName
      })[0]
      return {
        ...state,
        roomViewers: action.roomViewers,
        currentUser: currentUserViewer || state.currentUser,
      }
    case "CHAT_MESSAGES":
      return {
        ...state,
        chat: [...state.chat, action.messages],
      };
    case "CLEAR_CHAT_MESSAGES":
      return {
        ...state,
        chat: [],
        roomData: [],
      };

    case "CLEAR_ALL_STATE":
      return {
        ...state,
        userName: "",
        tetriminos: [],
        rooms: [],
        roomViewers: [],
        roomPlayers: [],
        ClearStagesName: [],
        currentUser: {},
        chat: [],
        Stages: [],
        wall: false,
        wallData: {}
      };
    case "GAME_FINISHED":
      return {
        ...state,
        GameFinished: true,
        wall: false,
        wallData: {},
        tetriminos: [],
        roomViewers: [],
        roomPlayers: [],
        ClearStagesName: [],
        currentUser: {},
        gameinvalid: action.invalid,
        gameResult: action.rank
      };
    case "CLEAR_STATE_LEAVED_ROOM":
      return {
        ...state,
        roomPlayers: [],
        roomViewers: [],
        currentUser: {},
        roomDetail: {},
        ClearStagesName: [],
        gameAsyncTime: null,
        chat: [],
        Stages: [],
        tetriminos: [],
        GameFinished: false,
        roomname: "",
        selectedHero: [],
        gameResult: [],
        userexists: null,
        userName: "",
        connected: false,
        Scores: [],
        GameOver: false,
      };
    case "ADD_WALL":
      return {
        ...state,
        wall: action.wall,
        wallData: action.data
      };
    case "MOVE_GAME_RESULT":
      return {
        ...state,
        gameResult: [],
        userexists: null,
        GameFinished: false,
        userName: "",
        roomname: "",
        connected: false,
        tetriminos: [],
        roomDetail: {},
        gameAsyncTime: null,
        roomViewers: [],
        roomPlayers: [],
        ClearStagesName: [],
        currentUser: {},
        Stages: [],
        Scores: [],
        GameOver: false,
      }
    // set selectedHero
    case "SET_SELECTED_HERO":
      return {
        ...state,
        selectedHero: action.selectedHero
      }
    case "SET_SOLO_RESUME_GAME":
      return {
        ...state,
        GameFinished: false,
        roomname: "",
        tetriminos: [],
        roomDetail: {},
        gameAsyncTime: null,
        roomViewers: [],
        roomPlayers: [],
        ClearStagesName: [],
        currentUser: {},
        Stages: [],
        Scores: [],
        GameOver: false,
      }
    case "UPDATE_GAME_ASYNCTIME":
      return {
        ...state,
        gameAsyncTime: action.gameAsyncTime,
      }
    default:
      return state;
  }
};

import { notification } from 'antd'
import { t } from 'i18next';
const openNotification = (msg) => {
    let vH = document.documentElement.clientHeight || document.body.clientHeight;
    notification.config({
        placement: 'top',
        top: vH / 100 * 8,
    });
    notification.open({
        duration: 3,
        icon: "",
        closeIcon: "",
        message: msg,
        className: "notification"
    });
};
const copy = (value, msg) => {
    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(value).then(res => {
            openNotification(msg || t("commonTips.copySuccess"))
        }).catch(err => {
        })
    } else {
        let textArea = document.createElement("textarea");
        textArea.value = value;
        textArea.style.position = "absolute";
        textArea.style.opacity = "0";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            if (document.execCommand('copy')) {
                res()
                openNotification(msg || t("commonTips.copySuccess"))
            } else {
                rej();
            }
            textArea.remove();
        });
    }
}
export { openNotification, copy }
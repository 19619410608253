import React, { useEffect, useState } from 'react'
import { Dropdown, Spin } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';

import { postUserInfoNicknames, transactionListDetails } from '../../../api';

import { cutString } from '../../../tools/utils';
import { openNotification } from '../../../tools/notification';
import { viewCode } from '../../../tools/viewCode';
import { TOKENNAME } from '../../../tools/gameConfig';

import CommonTable from '../../PublicComponents/Table';
import CommonPagination from '../../PublicComponents/Pagination';
import styles from '../../../scss/Hall/PersonalCenter/IncomeRecords.module.scss'


function IncomeRecords(props) {
    const [transactionName, setTransactionName] = useState("All")
    const [viewTransactionName, setViewTransactionName] = useState(t("tableData.all"))
    const [transactionType, setTransactionType] = useState("")
    const [viewTransactionType, setViewTransactionType] = useState(t("tableData.all"))
    const [transactionStatus, setTransactionStatus] = useState("")
    const [viewTransactionStatus, setViewTransactionStatus] = useState(t("tableData.all"))
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)

    const columns = [
        {
            title: t("tableData.time"),
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            align: 'center',
            width: '20%',
            ellipsis: true,
            render: (time) => {
                if (time.split("T").length) {
                    return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
                } else {
                    return time
                }
            }
        },
        {
            title: t("tableData.amount"),
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
            width: '30%',
            ellipsis: true,
            // render: (str) => {
            //     return cutString(str, 12)
            // }
        },
        {
            title: t("tableData.type"),
            dataIndex: 'Type',
            key: 'Type',
            align: 'center',
            width: '22%',
            ellipsis: true,
            render: (type) => {
                let typeObj = items.find((item) => {
                    return item.key === type
                })
                return typeObj?.label || type
            }
        },
        {
            title: t("tableData.status"),
            dataIndex: 'Status',
            key: 'Status',
            align: 'center',
            width: '15%',
            ellipsis: true,
            render: (type) => {
                let typeObj = status_items.find((item) => {
                    return item.key === type
                })
                return typeObj?.label || type
            }
        },
        {
            title: t("tableData.recipient"),
            dataIndex: 'Recipient',
            key: 'Recipient',
            align: 'center',
            width: '22%',
            ellipsis: true,
            render: (str) => {
                if (str) {
                    return cutString(str, 4)
                } else {
                    return "-"
                }
            }
        },
    ];
    const items = [
        { type: "All", label: t("tableData.all"), key: '' },
        { type: "All", label: t("tableData.allTicket"), key: 'AllTicket' },
        { type: "All", label: 'Token', key: 'AllToken' },
        { type: "All", label: 'NFT', key: 'AllNFT' },
        { type: "All", label: t("tableData.reward"), key: 'Reward' },
        { type: "All", label: t("tableData.airdrop"), key: 'Airdrop' },
        { type: "All", label: t("tableData.SpinWheel"), key: 'SpinWheel' },
        { type: "All", label: t("tableData.InvitationReward"), key: 'InvitationReward' },

        { type: "AllTicket", label: t("tableData.freeTicket"), key: 'FreeTicket' },
        { type: "AllTicket", label: t("tableData.buyTicket"), key: 'BuyTicket' },
        { type: "AllTicket", label: t("tableData.refundTicket"), key: 'RefundTicket' },
        { type: "AllTicket", label: t("tableData.useTicket"), key: 'UseTicket' },
        { type: "AllTicket", label: t("tableData.earnedTicket"), key: 'EarnedTicket' },

        { type: "AllToken", label: t("tableData.depositToken"), key: 'DepositToken' },
        { type: "AllToken", label: t("tableData.withdrawToken"), key: 'WithdrawToken' },

        { type: "AllNFT", label: t("tableData.mintNFT"), key: 'MintNFT' },
        { type: "AllNFT", label: t("tableData.freeNFT"), key: 'FreeNFT' },
        { type: "AllNFT", label: t("tableData.depositNFT"), key: 'DepositNFT' },
        { type: "AllNFT", label: t("tableData.withdrawNFT"), key: 'WithdrawNFT' },

        { type: "AllMarket", label: t("tableData.list"), key: 'ListNFT' },
        { type: "AllMarket", label: t("tableData.remove"), key: 'RemoveNFT' },
        { type: "AllMarket", label: t("tableData.sell"), key: 'SellNFT' },
        { type: "AllMarket", label: t("tableData.buy"), key: 'BuyNFT' },
    ];
    // Minting  Pending Locked Success Failed
    const status_items = [
        // ALl
        { label: t("tableData.all"), key: '', },
        { label: t("tableData.success"), key: 'Success', },
        { label: t("tableData.failed"), key: 'Failed', },
        { label: t("tableData.locked"), key: 'Locked', },
        // NFT
        { label: t("tableData.minting"), key: 'Minting', type: "AllNFT" },
        { label: t("tableData.pending"), key: 'Pending', type: "AllNFT" },
        // TOKEN
        { label: t("tableData.review"), key: 'Review', type: "AllToken" },

    ];


    useEffect(() => {
        if (page && !loading) {
            getDetailList()
        }
    }, [page, transactionStatus, transactionType])

    // get Data
    const getDetailList = () => {
        setLoading(true)
        setDataSource([])
        setTotal(0)
        transactionListDetails({
            "transactionTypeFilter": transactionType,
            "transactionStatusFilter": transactionStatus,
            "pagination": {
                "page": page,
                "size": 12
            }
        }).then((res) => {
            if (!res.data.code) {
                filterDataList(res.data.data.transactions)
                setTotal(res.data.data.pagination.total)
            } else {
                setLoading(false)
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    const filterDataList = (list) => {
        let newlist = []
        if (!list || list.length <= 0) {
            setDataSource(newlist)
            setLoading(false)
            return
        }
        let userIds = []
        list.map((item, index) => {
            let newItem = {}
            newItem.key = item.ID
            newItem.Recipient = item.Recipient
            newItem.Sender = item.Sender
            newItem.Status = item.Status
            newItem.CreatedAt = item.CreatedAt
            newItem.Type = item.Type
            newItem.userID =
                newItem.nftUserID = newItem.Type == "SellNFT" ?
                    item.Details.buyer_user_id
                    : newItem.nftUserID = newItem.Type == "BuyNFT" ?
                        item.Details?.buyer_user_id : ""
            newItem.amount =
                // 抽奖
                (item.Type === "SpinWheel") ? t("tableData.SpinWheeled") + " " + (item.Details.reward_token) + " " + TOKENNAME :
                    // 空投
                    (item.Type === "Airdrop") ? ((item.Details.vsys_amount) ? (item.Details.vsys_amount) + " VSYS" : (item.Details.token_amount) ? (item.Details.token_amount) + " " + TOKENNAME : "") :
                        // 奖励
                        (item.Type === "Reward") ? "+" + (item.Details.token_amount) + TOKENNAME +
                            " (" + ((item.Details.game_type).startsWith("Solo") ? t("tableData.challenge") + item.Details.game_type.slice(11) + " " + t("tableData.rank") + (item.Details.rank + 1)
                                : t(`tableData.${item.Details.game_type}`)) + ")" :
                            // Token
                            (item.Details.token_amount) ? (item.Details.token_amount) + " " + TOKENNAME :
                                // 购买门票
                                (item.Details.ticket_number) ? "-" + (item.Details.ticket_number * item.Details.ticket_token_price).toFixed(2) + " " + TOKENNAME :
                                    // 免费门票
                                    (item.Details.user_ticket_id) ? 1 + " Ticket" :
                                        // 退票
                                        (item.Details.ticket_token_price) ? "+" + (item.Details.ticket_token_price - ((item.Details.refund_fee || 0) / 1)).toFixed(2) + " " + TOKENNAME :
                                            // mint nft
                                            (item.Details.token_used) ? 1 + " NFT " + (item.Details.token_used / 1).toFixed(2) + "" + TOKENNAME :
                                                // ListNFT SellNFT
                                                (item.Details.price) ? 1 + " NFT " + item.Details.price + "" + TOKENNAME :
                                                    // other
                                                    "1 NFT"
            if (newItem.userID) {
                userIds.push(newItem.userID)
            }
            newlist.push(newItem)
        })
        setDataSource(newlist)
        setLoading(false)
        if (userIds.length > 0) {
            postUserInfoNicknames({ userIds }).then((res) => {
                if (res.data.code) {
                    openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                } else {
                    newlist.map((item) => {
                        item.Recipient = res.data.data?.userNicknames.find((it) => { return item.userID == it.id })?.nickname || item.Recipient || "-"
                    })
                    setDataSource([...newlist])
                }
            })
        }
    }

    const pageChange = (e) => {
        setPage(e)
    }



    const changeItem = ({ key }) => {
        let data = items.filter((item) => {
            return item.key === key
        })
        setTransactionName(key || "All")
        setViewTransactionName(t("tableData.all"))
        setViewTransactionType(data[0]?.label)
        setTransactionType(key)
        setPage(1)
    };

    const changeItemName = ({ key }) => {
        let data = items.filter((item) => {
            return item.key === key
        })
        setViewTransactionName(data[0]?.label)
        setTransactionType(key)
        setPage(1)
    };

    const changeItemStatus = ({ key }) => {
        let data = status_items.filter((item) => {
            return item.key === key
        })
        setViewTransactionStatus(data[0]?.label)
        setTransactionStatus(key)
        setPage(1)
    };

    return (
        <div className={styles.income_records_wrapper}>
            {loading ? <Spin /> :
                <div>
                    <div className={styles.filter}>
                        <div className={styles.filters}>
                            <Dropdown
                                menu={{
                                    items: items.filter((item) => item.type === "All"),
                                    onClick: changeItem,
                                }}
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <div className={styles.filter_label}>
                                        {viewTransactionType}
                                        <div className='dropdown_ic'></div>
                                    </div>
                                </a>
                            </Dropdown>

                            {
                                transactionName && transactionName !== "All" ?
                                    <Dropdown
                                        menu={{
                                            items: items.filter((item) => item.type === transactionName),
                                            onClick: changeItemName,
                                        }}
                                    >
                                        <a onClick={(e) => e.preventDefault()}>
                                            <div className={styles.filter_label}>
                                                {viewTransactionName}
                                                <div className='dropdown_ic'></div>
                                            </div>
                                        </a>
                                    </Dropdown> : ""
                            }

                            <Dropdown
                                menu={{
                                    items: status_items.filter((item) => {
                                        if (transactionName === "All") {
                                            return item
                                        } else {
                                            if (!item.type || item.type === transactionName) {
                                                return item
                                            }
                                        }
                                    }),
                                    onClick: changeItemStatus,
                                }}
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <div className={styles.filter_label}>
                                        {viewTransactionStatus}
                                        <div className='dropdown_ic'></div>
                                    </div>
                                </a>
                            </Dropdown>
                        </div>
                        <div className={styles.pagination_div}>
                            <CommonPagination onChange={pageChange} pageSize={12} current={page} total={total} />
                        </div>
                    </div>
                    <div className={styles.table_div}>
                        <CommonTable
                            dataSource={dataSource}
                            columns={columns}
                        />
                    </div>
                </div>
            }
        </div>

    )
}

export default IncomeRecords
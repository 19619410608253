import React from 'react'
import { Button, Image } from 'antd';
import { useTranslation } from 'react-i18next';

import '../../scss/Hall/Header.scss'
import logo_img from '../../assets/icon/nav_tips.png'
import ic_rank from '../../assets/hall/ic_rank.png'
import ic_ticker from '../../assets/hall/ic_ticker.png'
import ic_mint from '../../assets/hall/ic_mint.png'
import ic_settings from '../../assets/hall/ic_settings.png'
import ic_market from '../../assets/hall/ic_market.png'

const Header = ({ onHeaderItemClick, address, avatarDefault, userAvatarUrl, loading, navType }) => {

    const navAction = (opt) => {
        onHeaderItemClick(opt)
    }
    const { t } = useTranslation()

    return (
        <div className='header-wrapper'>
            <div className='logo' onClick={() => navAction('logo')}>
                <img src={logo_img} />
            </div>
            <div className='header_left'>
                <div className={navType === "settings" ? "button button_active" : "button"} onClick={() => navAction('settings')}>
                    <img src={ic_settings} />
                    <div>
                        {t("hall.settings")}
                    </div>
                </div>
                <div className={navType === "rank" ? "button button_active" : "button"} onClick={() => navAction('rank')}>
                    <img src={ic_rank} />
                    <div>{t("hall.ranking")}</div>
                </div>
                <div className={navType === "ticker" ? "button button_active" : "button"} onClick={() => navAction('ticker')}>
                    <img src={ic_ticker} />
                    <div>
                        {t("hall.ticket")}
                    </div>
                </div>
                <div className={navType === "mint" ? "button button_active" : "button"} onClick={() => navAction('mint')}>
                    <img src={ic_mint} />
                    <div>{t("hall.minting")}</div>
                </div>
                <div className={navType === "market" ? "button button_active" : "button"} onClick={() => navAction('market')}>
                    <img src={ic_market} />
                    <div>
                        {t("hall.market")}
                    </div>
                </div>
            </div>
            <div className="header_left">
                {
                    address ?
                        <div className='wallet-container' onClick={() => navAction('personalCenter')}>
                            <Image preview={false} fallback={avatarDefault} src={userAvatarUrl} />
                            <div className='text'>{address}</div>
                        </div> :
                        <Button loading={loading} className='wallet-container' onClick={() => navAction('connectWallet')}>
                            <div>{t('header.connectWallet')}</div>
                        </Button>
                }
            </div>
        </div>
    )
}

export default Header
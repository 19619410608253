import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import PublicPopup from './PublicPopup'
import '../../scss/PublicComponentsStyle/ConnectWalletDialog.scss'

function ConnectWalletDialog(props) {

    const { t } = useTranslation()
    const [once, setOnce] = useState(false)

    const onClose = () => {
        props.onClose()
    }

    const onClick = (type) => {
        if (type === "ok") {
            setOnce(true)
            window.open("https://chromewebstore.google.com/detail/titan-wallet-extension/bplepbelihejfpcjoeialhjpamgpnfln", '_blank')
        } else if (type === "cancle") {
            window.location.reload();
        }
    }

    return (
        <PublicPopup isOverlay closefun={onClose}>
            <div className="connect_wallet_dialog">
                <div className="avatar"></div>
                <span className='msg'>
                    {t("wallet.pleaseInstallWallet")}
                </span>
                <div className='action'>
                    {
                        once ? <div className='button_common primary_button' onClick={() => onClick('cancle')}>{t("wallet.downloaded")}</div>
                            : <div className='button_common primary_button' onClick={() => onClick('ok')}>{t("wallet.goto")}</div>
                    }
                </div>
            </div>
        </PublicPopup>

    )
}

export default ConnectWalletDialog
import axios from 'axios'
import store from '../redux/store'
// request intercept for API
axios.interceptors.request.use(
    config => {
        const token = store.getState().user.token
        config.headers = {
            'Content-Type': 'application/json',
            "Authorization": token != '' ? "Bearer " + token : ''
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// response intercept for API
axios.interceptors.response.use(
    response => {
        if (response.data?.code === 2004) {
            store.dispatch({
                type: "LOGIN",
                token: "",
            });
        }
        //todo
        return response;
    },
    err => {
        return Promise.reject(err);
    }
);
const net = {
    get(url, params) {
        return axios.get(url, params)
    },
    post(url, data) {
        return axios.post(url, data)
    },
    delete(url, data) {
        return axios.delete(url, { data })
    },
    put(url, data) {
        return axios.put(url, data)
    },
    patch(url, data) {
        return axios.patch(url, data)
    }
}

export default net

import React, { useEffect, useState } from 'react'
import { Button, Image, Spin, Statistic } from 'antd'
import { t } from 'i18next'

import { getSwapTokenTRate, postMarketplaceInfo, postRemoveNft } from '../../api'

import { viewCode } from '../../tools/viewCode'
import { openNotification } from '../../tools/notification'
import { VSWAP_CONTRACT_ADDRESS } from '../../tools/tokenConfig'
import { processingTime } from '../../tools/utils'
import { IMAGE_BASEURL } from '../../tools/gameConfig'

import PublicPopup from '../PublicComponents/PublicPopup'
import "../../scss/Market/BuyDetail.scss"

const { Countdown } = Statistic

function RemoveNft({ onClose, onConfirm, removeNft, TET2USD }) {
    const [removeNftDetail, setRemoveNftDetail] = useState({})
    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [TET2USDCOPY, setTET2USDCOPY] = useState(0)

    useEffect(async () => {
        if (!TET2USD) {
            try {
                setTET2USDCOPY((await getSwapTokenTRate({ vswapContract: VSWAP_CONTRACT_ADDRESS }))?.data?.data?.tetrisTokenExchange?.usd || 0)
            } catch (error) {
                setTET2USDCOPY(0)
                console.log(error)
            }
        } else {
            setTET2USDCOPY(TET2USD)
        }
    }, [TET2USD])

    useEffect(() => {
        if (removeNft.ID) {
            if (!removeNft.NftPrice && removeNft.nftMarketplaceId) {
                getDetail()
            } else {
                setLoading(false)
                setRemoveNftDetail(removeNft)
            }
        }
    }, [removeNft])

    const getDetail = () => {
        setLoading(true)
        postMarketplaceInfo({ nftMarketplaceId: removeNft.nftMarketplaceId }).then((res) => {
            setLoading(false)
            if (res.data.code) {
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            } else {
                setRemoveNftDetail(res.data.data)
            }
        }).catch(() => {
            openNotification(t("responseCode.networkFailed"))
        }).finally(() => {
            setLoading(false)
        })
    }

    const close = () => {
        if (message == "success") {
            onConfirm()
        } else {
            onClose()
        }
    }

    const removeNFT = () => {
        setMsgLoading(true)
        window._czc && window._czc.push(["_trackEvent", "NFT下架页", "点击", "下架NFT"]);
        postRemoveNft({ "userNFTMarketplaceIds": [removeNftDetail.nftMarketplaceId || removeNftDetail.ID] }).then((res) => {
            if (res.data.code) {
                setMessage("filed")
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            } else {
                setMessage("success")
            }
        }).catch(() => {
            openNotification(t("responseCode.networkFailed"))
        }).finally(() => {
            setMsgLoading(false)
        })
    }


    return (
        <div className='buy_detail'>
            <PublicPopup title={t("market.removeNftTitle")} isOverlay closefun={close}>
                <div className='remove_nft'>
                    {loading ? <div className='nft_loading'><Spin /></div> :
                        <>
                            <div className='nft_detail'>
                                <div className="nft_right">
                                    <Image
                                        className={"nft_img"}
                                        preview={false}
                                        src={removeNftDetail.NftAttributes?.content ? IMAGE_BASEURL + removeNftDetail.NftAttributes.content + ".png?imageView2/1/w/500/h/500" : ""}
                                        placeholder={
                                            <Image
                                                className={"nft_img"}
                                                preview={false}
                                                src={removeNftDetail.NftAttributes?.content ? IMAGE_BASEURL + removeNftDetail.NftAttributes.content + ".png?imageView2/1/w/50/h/50" : ""}
                                            />
                                        }
                                    />
                                    <div className='nft_series'>
                                        <div className="series">
                                            <div className='series_ic'></div>
                                            <div>{t("market.tetrisSeries")}</div>
                                        </div>
                                        <div>{"#" + removeNftDetail.NftTokenIndex}</div>
                                    </div>
                                </div>
                                <div className='nft_left'>
                                    {/* price */}
                                    <div className="nft_price">
                                        <div className="tit">{t("market.price")}</div>
                                        <div className='price'>
                                            <div className='icon'>
                                                <div className='number'>
                                                    {removeNftDetail.NftPrice}
                                                </div>
                                                <div className='price_ic'></div>
                                            </div>
                                            <div className='price_usd'>
                                                {"$" + (removeNftDetail?.NftPrice * TET2USDCOPY).toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nft_price">
                                        <div className="tit">{t("market.nftValidityPeriod")}</div>
                                        <div className='price'>
                                            {
                                                removeNftDetail?.NftValidStatus === "InActivated" ?
                                                    t("personalCenter.InActivated")
                                                    : <Countdown title="" value={processingTime(removeNftDetail?.NftExpiredAt)} format="D 天 H 时 m 分 s 秒" />
                                            }
                                        </div>
                                    </div>
                                    <div className='nft_message'>
                                        {msgLoading ? <Spin />
                                            : message == "success" ?
                                                <>
                                                    <div className="success_ic"></div>
                                                    <div className='message_title message_success'>{t("market.removeNftSuccess")}</div>
                                                </>
                                                : message == "filed" ?
                                                    <>
                                                        <div className="filed_ic"></div>
                                                        <div className='message_title message_filed'>{t("market.removeNftFiled")}</div>
                                                    </>
                                                    : ""
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="nft_button">
                                {!msgLoading ? message ?
                                    <Button onClick={close} className="button_common primary_button">{t("market.confirm")}</Button> :
                                    <>
                                        <Button onClick={removeNFT} className='button_common primary_button'>{t("market.confirm")}</Button>
                                        <Button onClick={close} className='button_common secondary_button'>{t("market.cancel")}</Button>
                                    </>
                                    : ""
                                }
                            </div>
                        </>}
                </div>
            </PublicPopup>
        </div>
    )
}

export default RemoveNft
import React, { useEffect, useState } from 'react'
import { Input, Modal, Spin } from 'antd'
import { t } from 'i18next'

import { depositToken } from '../../../api/index'
import { getTokenAmount, sendToken } from '../../../tools/wallet'
import { openNotification } from '../../../tools/notification'
import { TOKENNAME } from '../../../tools/gameConfig'
import { TOKEN_ADDRESS } from '../../../tools/tokenConfig'

import CommonDialogSmall from '../../PublicComponents/CommonDialogSmall';
import styles from '../../../scss/Hall/PersonalCenter/TransferInToken.module.scss'

function TransferInToken({ updateUserInfo, TET2USD }) {

    const [tokenValue, setTokenValue] = useState('')
    const [select, setSelect] = useState(-1)
    const [myTokenAmount, setMyTokenAmount] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [balanceErr, setBalanceErr] = useState(false)

    const tokenData = [
        { sku: 0, price: 128 },
        { sku: 1, price: 298 },
        { sku: 2, price: 666 },
        { sku: 3, price: 888 },
        { sku: 4, price: 999 },
        { sku: 5, price: 0 },
    ]

    useEffect(async () => {
        gettokenAmount()
        return () => {
            setMyTokenAmount(0)
        }
    }, [])

    const onItemClick = (item, index) => {
        if (index === select) {
            setSelect(-1);
            setBalanceErr(false)
        } else {
            setSelect(index)
            if (tokenData[index].price > myTokenAmount) {
                setBalanceErr(true)
            }
        }
    }

    const gettokenAmount = async () => {
        let amount = (await getTokenAmount(TOKEN_ADDRESS)).amount
        // if (amount / 1 === 0) {
        //     openNotification(t("personalCenter.tokenInsufficientFunds"))
        // }
        setMyTokenAmount(Math.floor(amount * 10000) / 10000)
    }

    const [open, setopen] = useState(false)
    const gotoNext = async () => {
        // return
        if (myTokenAmount <= 0) { return }
        if (balanceErr) { return }
        if (select === 5 && tokenValue === "") { return }
        if (select !== -1) {
            let res = {}
            let tokenAmount = 1
            if (select === 5) {
                tokenAmount = ((tokenValue / 1).toFixed(4) / 1)
            } else if (select !== 5) {
                tokenAmount = tokenData[select].price
            }
            if (tokenAmount <= 0) { return setTokenValue("") }
            if (tokenAmount > myTokenAmount) {
                return openNotification(t("wallet.tokenInsufficientFunds"))
            }
            setopen(true)
            setIsModalOpen(true)
            res = await sendToken(tokenAmount)
            setIsModalOpen(false)
            setSelect(-1);
            setTokenValue('')
            if (res.result) {
                depositToken({
                    tokenAmount: tokenAmount,
                    transactionId: res.transactionId
                }).then(val => {
                    updateUserInfo()
                    gettokenAmount()
                }).catch(err => {
                    console.log(err)
                })
            }
            openNotification(res.result ? t("wallet.walletSuccess") : res.message)
        }
    }

    const onModalAction = (action) => {
        setopen(false)
    }

    const valueChange = (val, idx) => {
        if (val > 0) {
            setTokenValue(val);
            setSelect(val != '' ? 5 : -1)
            if (val > myTokenAmount) {
                setBalanceErr(true)
            } else {
                setBalanceErr(false)
            }
        } else {
            setTokenValue('');
        }

    }

    return (
        <div className={styles.transfer_in_token}>
            <div className={styles.tab_top}>
                <div className='price_ic'></div>
                <div>
                    <div className={styles.price_usd}>{"$" + (myTokenAmount / 1 * TET2USD).toFixed(2)}</div>
                    <div className={styles.price_tet}>{(myTokenAmount / 1).toFixed(4) + " " + TOKENNAME}</div>
                </div>
            </div>
            <div className={styles.token_list}>
                {
                    tokenData.map((item, index) => {
                        if (index === tokenData.length - 1) {
                            return (
                                <div className={select === index ? `${styles.token_item} ${styles.select}` : styles.token_item} key={index}>
                                    <Input className={styles.form_input} onFocus={() => setSelect(5)} onChange={(e) => valueChange(Number(e.target.value), index)} type='number' value={tokenValue} placeholder='Amount' />
                                    <div className={styles.form_input_info}>
                                        <span>{t("personalCenter.enterAmount")}</span>
                                        {select === index && balanceErr ?
                                            <span className={styles.info_error}>{t("personalCenter.tokenInsufficientError")}</span>
                                            : <span>≈${(tokenValue * TET2USD).toFixed(2)}</span>}
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className={select === index ? `${styles.token_item} ${styles.select}` : styles.token_item} key={index} onClick={() => onItemClick(item, index)}>
                                    <div className={styles.form_input}>{item.price}</div>
                                    <div className={styles.form_input_info}>
                                        <span>{t("personalCenter.select")}</span>
                                        {select === index && balanceErr ?
                                            <span className={styles.info_error}>{t("personalCenter.tokenInsufficientError")}</span>
                                            : <span>≈${(item.price * TET2USD).toFixed(2)}</span>}
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
            <div className={styles.bt_div}>
                <div className={`button_common primary_button ${myTokenAmount <= 0 || select === -1 || balanceErr || select === 5 && tokenValue === "" ? "button_common disabled_button" : ""}`} onClick={gotoNext}>{t("user.next")}</div>
            </div>
            <CommonDialogSmall
                open={open}
                msg={t("wallet.walletTokenAction")}
                cancel={false}
                ok={t("wallet.actionOK")}
                onModalAction={onModalAction}
            />
            <Modal wrapClassName={'spin_model'} centered open={isModalOpen} footer={null} closeIcon={null} style={{ textAlign: 'center' }}>
                <Spin />
            </Modal>
        </div>
    )
}

export default TransferInToken
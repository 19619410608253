import { React, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { Input, Button, Image } from 'antd';

import { postResetPassword, postSendVerificationCode } from '../../api';
import { openNotification } from '../../tools/notification';
import { viewCode } from '../../tools/viewCode';

import BlockMovement from '../../Components/SpecialEffects/BlockMovement/Index';
import "./Index.scss"
import OpenEye from "../../assets/web/open_eye.png"
import OffEye from "../../assets/web/off_eye.png"

let secondInterval = null;
const WebResetPassword = (props) => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [code, setCode] = useState('')
    const [email, setEmail] = useState('')
    const [seconds, setSeconds] = useState(0);
    const [sendingCode, setSendingCode] = useState(false)


    useEffect(() => {
        const Email = location.state
        if (!Email) {
            navigate('/webRecoverPassword', { replace: true })
        }
        setEmail(Email)
        setSeconds(60)
        return () => {
            setPassword("")
            setConfirmPassword("")
            setEmail("")
            setCode("")
            setSeconds(0)
            setSendingCode(false)
        }
    }, [])

    useEffect(() => {
        if (seconds <= 0) {
            secondInterval && clearInterval(secondInterval);
        } else if (seconds === 60) {
            secondInterval && clearInterval(secondInterval);
            secondInterval = setInterval(() => {
                setSeconds((n) => {
                    return n - 1;
                });
            }, 1000);
        }
    }, [seconds]);

    const sendVerificationCode = async () => {
        if (sendingCode) {
            return openNotification(t("user.sendingVerificationCode"))
        }
        setSendingCode(true)
        let emailG = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{1,7}$/;
        if (!emailG.test(email)) return openNotification(t("user.emailError"))
        postSendVerificationCode({ email: email, action: "reset password" }).then((res) => {
            if (!res.data.code) {
                setSeconds(60)
                openNotification(t("user.sendVerificationCodeSuccess"))
            } else {
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            }
        }).catch((err) => {
            openNotification(t("responseCode.networkFailed"))
        }).finally(() => {
            setSendingCode(false)
        })
    }

    const reset = () => {
        if (sendingCode) {
            return openNotification(t("user.resetingPassword"))
        }
        let passwordG = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
        if (password !== confirmPassword) return openNotification(t("user.passwordConfirmError"))
        if (!passwordG.test(password)) return openNotification(t("user.passwordError"))
        postResetPassword({ email: email, verificationCode: code, newPassword: password }).then((res) => {
            if (!res.data.code) {
                window._czc && window._czc.push(["_trackEvent", "密码找回完成", "点击"]);
                navigate('/webLogin', { replace: true })
                openNotification(t("user.resetPasswordSuccess"))
            } else {
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            }
        }).catch((err) => {
            openNotification(t("responseCode.networkFailed"))
        }).finally(() => {
            setSendingCode(false)
        })
    }


    return (
        <div className="web">
            <BlockMovement />
            <div className="logo"></div>
            <div className="form">
                <div className="form_flex_column">
                    <div className="web_title">{t("user.resetPassword")}</div>
                    <div className="form_subtitle">{t("user.resetPasswordSubtitle")}</div>
                </div>
                <Input className="form_input suffix_code_father" onChange={(e) => { setCode(e.target.value.trim()) }} value={code} placeholder={t('user.verificationCode')}
                    suffix={seconds === 0 ? <span onClick={() => { sendVerificationCode() }} className="suffix_code">{t('user.getVerificationCode')}</span> :
                        <span className="suffix_code disabled">{seconds + t("user.second")}</span>}
                />
                <Input.Password
                    className="form_input"
                    placeholder={t("user.newPassword")}
                    value={password}
                    onChange={(e) => { setPassword(e.target.value.trim()) }}
                    iconRender={(visible) => (visible ? <Image preview={false} src={OpenEye} /> : <Image preview={false} src={OffEye} />)}
                />
                <Input.Password
                    className="form_input"
                    value={confirmPassword}
                    onChange={(e) => { setConfirmPassword(e.target.value.trim()) }}
                    placeholder={t("user.confirmPassword")}
                    iconRender={(visible) => (visible ? <Image preview={false} src={OpenEye} /> : <Image preview={false} src={OffEye} />)}
                />
                <Button className="button_common primary_button" onClick={() => { reset() }}>{t("user.completed")}</Button>
                <div className="form_space_between">
                    <Link className="form_link" to="/webRecoverPassword" >{t("user.previous")}</Link>
                    <Link className="form_link" onClick={() => { window._czc && window._czc.push(["_trackEvent", "to_register", "click"]) }} to="/webRegister" >{t("user.register")}</Link>
                </div>
            </div>
        </div>
    )
}

export default WebResetPassword
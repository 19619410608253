import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next'
import { Spin, Dropdown } from 'antd';

import { socket } from '../../socket/socket'
import { postTicketList } from '../../api'
import { roomExists, roomFull } from '../../redux/actions/sockets/socketsActions'

import { openNotification } from '../../tools/notification'

import InviteFriendsToPlay from './InviteFriendsToPlay'
import Matchmaking from './Matchmaking'
import CreateRoom from './CreateRoom.js'
import RoomList from './RoomList.js'
import PublicPopup from '../PublicComponents/PublicPopup.js'
import '../../scss/ChooseGameType/Index.scss'

function ChooseGameType(props) {

    let content;
    const { roomdetail, roomExists, roomexists, roomfull, roomFull, username, isJoinGame } = props;
    const { t } = useTranslation()
    const navigate = useNavigate()
    const selectionList = {
        battle: { selection: [t("gameChooseModel.threePlayerBattle"), t("gameChooseModel.sixPlayerBattle")] },
        propsclassic: { selection: [t("gameChooseModel.oneVS1"), t("gameChooseModel.createRoom"), t("gameChooseModel.oneVS8"), t("gameChooseModel.roomList"), t("gameChooseModel.friendsVS")] },
    }
    const [type, setType] = useState("")
    const [title, setTitle] = useState("")
    const [selection, setSelection] = useState([])
    const [inviteJoinType, setInviteJoinType] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(async () => {
        if (props.model) {
            initModel(props.model)
        }
        if (!props.inviteType && props.model && !props.continueType) {
            setLoading(false)
        } else if ((props.inviteType && props.model === "battle") || (props.continueType && props.model === "battle")) {
            let res = await getTicketQuantity().catch((err) => {
                console.log(err)
            })
            if (!res) {
                navigate("/", { replace: true })
                setLoading(false)
                return openNotification(t("gameChooseModel.insufficientRemainingTickets"))
            } else {
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
        return () => {
            setLoading(true)
        }
    }, [props.model])

    // invite
    useEffect(() => {
        if (loading || props.type !== "invite") {
            return
        }
        if (props.id && props.inviteType && username && isJoinGame && !roomdetail.id) {
            roomExists(false)
            socket.emit('join_room', { "id": props.id, nftIds: props.mostPowerfulNft.ID })
            if (props.inviteType === "room") {
                setInviteJoinType("joinRoom")
                setLoading(true)
            } else if (props.inviteType === "friends") {
                setLoading(true)
                setInviteJoinType("inviteFriends_by")
            }
        }
    }, [props.id, props.inviteType, props.type, props.model, loading, username, isJoinGame])

    useEffect(() => {
        if (!inviteJoinType) {
            return
        }
        if (roomdetail.id) {
            setLoading(false)
            setType(inviteJoinType)
        }
        if (roomfull && !roomdetail.id) {
            openNotification(t("gameChooseModel.roomFullJoinFailed"))
            setLoading(false)
            roomFull(false)
            if (window.location.search) {
                navigate("/", { replace: true })
            }
        }
        if (roomexists && !roomdetail.id) {
            openNotification(t("gameChooseModel.invitationLinkInvalid"))
            setLoading(false)
            roomExists(false)
            if (window.location.search) {
                navigate("/", { replace: true })
            }
        }
        return () => {
            setInviteJoinType(false)
        }
    }, [inviteJoinType, roomdetail, roomexists, roomfull])

    // continue game
    useEffect(() => {
        if (loading || props.type !== "continue") {
            return
        }
        if (props.continueType && isJoinGame) {
            if (props.continueType === "making1v1") {
                setType('oneVS1')
            } else if (props.continueType === "making3v3") {
                setType('oneVS3')
            } else if (props.continueType === "making6v6") {
                setType('oneVS6')
            } else if (props.continueType === "making8v8") {
                setType('oneVS8')
            } else if (props.continueType === "roomList") {
                setType('roomList')
            } else if (props.continueType === "inviteFriends") {
                setType('inviteFriends')
            }
        }
    }, [props.continueType, props.type, props.model, loading, isJoinGame])

    const onClose = (setType) => {
        if (setType) {
            return initModel(setType)
        }
        //  close popup
        if (type === "props" || type === "classic" || type === "battle") {
            props.onClose('close')
        } else {
            initModel(props.model)
        }
    }

    // model==>
    // "props" "battle" "classic"
    const initModel = (model) => {
        setType(model)
        switch (model) {
            case "props":
                setTitle(t("gameChooseModel.itemModel"))
                setSelection(selectionList.propsclassic.selection)
                break
            case "battle":
                setTitle(t("gameChooseModel.competitiveModel"))
                setSelection(selectionList.battle.selection)
                break
            case "classic":
                setTitle(t("gameChooseModel.classicModel"))
                setSelection(selectionList.propsclassic.selection)
                break
            default:
                break;
        }
    }

    // type ==>
    // "props" "battle" "classic"
    // "oneVS1" "multiplayer" "inviteFriends" "oneVS8" "inviteFriends_by" 
    // "createdRoom" "viewRoom" "joinRoom" "roomList"
    const initType = async (index) => {
        if (type === "battle") {
            try {
                setLoading(true)
                let res = await getTicketQuantity()
                setLoading(false)
                if (!res) {
                    navigate("/", { replace: true })
                    return openNotification(t("gameChooseModel.insufficientRemainingTickets"))
                } else {
                    switch (index) {
                        case 0:
                            initModel("oneVS3")
                            window._czc && window._czc.push(["_trackEvent", "多人竞技", "点击", "三人战"]);
                            break;
                        case 1:
                            initModel("oneVS6")
                            window._czc && window._czc.push(["_trackEvent", "多人竞技", "点击", "六人战"]);
                            break;
                        default:
                            break;
                    }
                }
            } catch (e) {
                setLoading(false)
                openNotification(t("responseCode.networkFailed"))
            }
        } else if (type === "props" || type === "classic") {
            let category = ''
            type === "props" ? category = "道具战" : category = "经典模式"
            switch (index) {
                case 0:
                    initModel("oneVS1")
                    window._czc && window._czc.push(["_trackEvent", category, "点击", "1v1"]);
                    break;
                case 1:
                    initModel("createdRoom")
                    window._czc && window._czc.push(["_trackEvent", category, "点击", "创建房间"]);
                    break;
                case 2:
                    initModel("oneVS8")
                    window._czc && window._czc.push(["_trackEvent", category, "点击", "8人乱斗"]);
                    break;
                case 3:
                    initModel("roomList")
                    window._czc && window._czc.push(["_trackEvent", category, "点击", "房间列表"]);
                    break;
                case 4:
                    initModel("inviteFriends")
                    window._czc && window._czc.push(["_trackEvent", category, "点击", "邀请好友"]);
                    break;
                default:
                    break;
            }
        }
    }

    // retrieve the number of available tickets
    const getTicketQuantity = () => {
        return new Promise((resolve, reject) => {
            postTicketList({ "option": "valid" }).then((res) => {
                if (!res.data.code) {
                    if (res.data.data?.length > 0) {
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                } else {
                    reject(false)
                }
            }).catch((error) => {
                reject(false)
            })
        })
    }

    if (type === "inviteFriends" || type === "inviteFriends_by") {
        content =
            <InviteFriendsToPlay model={props.model} mostPowerfulNft={props.mostPowerfulNft} uuid={props.uuid} type={type} onClose={() => { onClose() }} />
    } else if (type === "oneVS8" || type === "oneVS1" || type === "oneVS3" || type === "oneVS6") {
        content =
            <Matchmaking model={props.model} mostPowerfulNft={props.mostPowerfulNft} type={type} uuid={props.uuid} onClose={() => { onClose() }} />
    } else if (type === "createdRoom" || type === "viewRoom" || type === "joinRoom") {
        content =
            <CreateRoom model={props.model} mostPowerfulNft={props.mostPowerfulNft} type={type} uuid={props.uuid} onClose={(setType) => { onClose(setType) }} />
    } else if (type === "roomList") {
        content =
            <RoomList model={props.model} mostPowerfulNft={props.mostPowerfulNft} onClose={(setType) => { onClose(setType) }} />
    } else {
        content = null
    }


    return (
        <div>
            {content ? content :
                <PublicPopup isOverlay
                    title={<Dropdown
                        dropdownRender={(menu) => (
                            <div style={{ background: "#40077e", whiteSpace: "pre-wrap", borderRadius: "1vw", width: "25vw", height: "auto", color: "#fff", fontFamily: "Noto Sans", padding: "1.5vw", boxSizing: "border-box", fontSize: "0.78vw", position: "relative" }}>
                                {t(`titleContent.${props.model}`)}
                                <a style={{ marginLeft: "0.5vw", color: "#f16e30", textDecoration: "underline", fontSize: "0.8vw" }} href={t(`titleContent.${props.model}Link`)} target="_blank">{t("titleContent.more")}</a>
                            </div>
                        )}
                        trigger={['click', 'hover']}
                        placement='bottom'>
                        <div style={{ display: "flex", gap: "0.5vw", alignItems: "center", justifyContent: "center" }}>{title}<div className="price_info_ic"></div></div>
                    </Dropdown>} closefun={() => onClose()} >
                    <div className="choose-game-type">
                        {loading || !isJoinGame ?
                            <div className="spin_div1">
                                <Spin tip={t("hall.joinGame")} >{""}</Spin>
                            </div> :
                            <div className="game_type" >
                                {
                                    selection?.length === 5 &&
                                    selection?.map((player, i) => {
                                        return <div onClick={() => { initType(i) }} className={"type5" + (i + 1)} key={i}>
                                            <div className='text'>{player}</div>
                                        </div>;
                                    })
                                }
                                {
                                    selection?.length === 2 &&
                                    selection?.map((player, i) => {
                                        return <div className="battle_div" key={i}>
                                            <div className={'top top' + (i + 1)}>
                                                <div className='ticker-type'>{player}</div>
                                            </div>
                                            <div className="button_common secondary_button" onClick={() => { initType(i) }}> {t("gameChooseModel.startGame")}</div>
                                        </div>;
                                    })
                                }
                            </div>}
                    </div>
                </PublicPopup>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    roomexists: state.sockets.roomExists,
    roomdetail: state.sockets.roomDetail,
    roomfull: state.sockets.roomFull,
    username: state.sockets.userName,
});

const mapDispatchToProps = { roomExists, roomFull };

export default connect(mapStateToProps, mapDispatchToProps)(ChooseGameType);
import React, { useEffect, useState } from 'react'
import { Button, Image, Spin, Statistic } from 'antd'
import { useTranslation } from 'react-i18next'

import { getSwapTokenTRate, getVSYS2USD, postMarketplaceInfo } from '../../api'

import { BLOCKCHAIN, IMAGE_BASEURL, NFTEXPIRED, TOKENNAME, TRANSACTIONFEE } from '../../tools/gameConfig'
import { callNetWork, processingTime } from '../../tools/utils'
import { viewCode } from '../../tools/viewCode'
import { openNotification } from '../../tools/notification'
import { VSWAP_CONTRACT_ADDRESS } from '../../tools/tokenConfig'

import ActivateNft from '../../Components/Market/ActivateNft'
import RemoveNft from './RemoveNft'
import PublicPopup from '../PublicComponents/PublicPopup'
import '../../scss/Market/MarketDetail.scss'

const { Countdown } = Statistic

function MarketDetail({ nftDetail, back, userId, buyNow, sellNow, nftMarketplaceId, loadIng, TET2USD, close, isOverlay }) {

    const { t } = useTranslation()
    const [marketNftDetail, setMarketNftDetail] = useState({})
    const [loading, setLoading] = useState(false)
    const [TET2USDCOPY, setTET2USDCOPY] = useState(0)
    const [activateNFTIndex, setActivateNFTIndex] = useState(false)
    const [removeNFTIndex, setRemoveNFTIndex] = useState(false)

    useEffect(async () => {
        if (!TET2USD) {
            try {
                setTET2USDCOPY((await getSwapTokenTRate({ vswapContract: VSWAP_CONTRACT_ADDRESS }))?.data?.data?.tetrisTokenExchange?.usd || 0)
            } catch (error) {
                setTET2USDCOPY(0)
                console.log(error)
            }
        } else {
            setTET2USDCOPY(TET2USD)
        }
    }, [TET2USD])

    useEffect(() => {
        setLoading(loadIng)
    }, [loadIng])

    useEffect(() => {
        if (nftDetail?.ID) {
            setMarketNftDetail(nftDetail)
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [nftDetail])

    useEffect(() => {
        if (nftMarketplaceId) {
            getDetail()
        }
    }, [nftMarketplaceId])

    // action
    const buyNowNFT = () => {
        buyNow()
    }
    const sellNFT = () => {
        sellNow(marketNftDetail)
    }
    const getDetail = () => {
        setLoading(true)
        postMarketplaceInfo({ nftMarketplaceId: nftMarketplaceId }).then((res) => {
            setLoading(false)
            if (res.data.code) {
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            } else {
                setMarketNftDetail(res.data.data)
            }
        }).catch(() => {
            openNotification(t("responseCode.networkFailed"))
        }).finally(() => {
            setLoading(false)
        })
    }

    // activate
    const activateNft = () => {
        window._czc && window._czc.push(["_trackEvent", "NFT详情页", "点击", "NFT激活页"]);
        setActivateNFTIndex(true)
    }
    const activateNftConfirm = () => {
        let newDetail = marketNftDetail
        newDetail.NftValidStatus = "Valid"
        newDetail.NftExpiredAt = new Date().getTime() + NFTEXPIRED
        setMarketNftDetail(newDetail)
        setActivateNFTIndex(false)
    }
    const activateNftClose = () => {
        setActivateNFTIndex(false)
    }

    // remove
    const removeNft = () => {
        window._czc && window._czc.push(["_trackEvent", "NFT详情页", "点击", "NFT下架页"]);
        setRemoveNFTIndex(true)
    }
    const removeNftConfirm = () => {
        let newDetail = marketNftDetail
        newDetail.NftNetworkStatus = "InGame"
        newDetail.NftSellStatus = ""
        setMarketNftDetail(newDetail)
        setRemoveNFTIndex(false)
    }
    const removeNftClose = () => {
        setRemoveNFTIndex(false)
    }


    return (
        <>
            <div className="market_detail">
                <PublicPopup isOverlay={isOverlay} title={marketNftDetail?.NftTokenIndex ? "#" + marketNftDetail?.NftTokenIndex : ""} closefun={close} backfun={back}>
                    {loading ? <div className='nft_detail'><Spin /></div>
                        : <div className='nft_detail'>
                            <Image
                                className={"nft_img"}
                                preview={false}
                                src={marketNftDetail?.NftAttributes?.content ? IMAGE_BASEURL + marketNftDetail.NftAttributes.content + ".png?imageView2/1/w/500/h/500" : ""}
                                placeholder={
                                    <Image
                                        preview={false}
                                        src={marketNftDetail?.NftAttributes?.content ? IMAGE_BASEURL + marketNftDetail.NftAttributes.content + ".png?imageView2/1/w/50/h/50" : ""}
                                        className={"nft_img"}
                                    />
                                }
                            />
                            <div className='nft_left'>
                                <div className='nft_title'>
                                    <div className='title_left'>
                                        <div className="series">
                                            <div className='series_ic'></div>
                                            <div>{t("market.tetrisSeries")}</div>
                                        </div>
                                        <div className={'nft_type ' + marketNftDetail?.NftType}>
                                            <div className="subheading"></div>
                                        </div>
                                    </div>
                                    <div className='title_right'>
                                        {t("market.nftValidityPeriod")}
                                        {
                                            marketNftDetail?.NftValidStatus === "InActivated" ?
                                                t("personalCenter.InActivated")
                                                : <Countdown title="" value={processingTime(marketNftDetail?.NftExpiredAt || marketNftDetail?.ExpiredAt)} format="D 天 H 时 m 分 s 秒" />
                                        }
                                    </div>
                                </div>
                                {/* other */}
                                {marketNftDetail?.SellerUserID && marketNftDetail?.SellerUserID !== userId ?
                                    <div className="nft_price">
                                        <div className="tit">{t("market.listingEndsAt")}
                                            <Countdown title="" value={processingTime(marketNftDetail?.ExpiredAt)} format="D 天 H 时 m 分 s 秒" />
                                        </div>
                                        <div className='price'>
                                            <div className='icon'>
                                                <div className="icon">
                                                    <div className='price_ic'></div>
                                                    <div className='number'>
                                                        <div className="usd">
                                                            {"$" + (marketNftDetail?.NftPrice * TET2USDCOPY).toFixed(2)}
                                                        </div>
                                                        <div>{marketNftDetail?.NftPrice} {TOKENNAME}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div onClick={buyNowNFT} className="tags_common buy_tag">{t("market.buyNow")}</div>
                                        </div>
                                    </div>
                                    : marketNftDetail?.NftNetworkStatus == "OnChain" ? ""
                                        // myself && Expired
                                        : marketNftDetail?.NftValidStatus == "Expired" ?
                                            <div className="nft_price">
                                                <div className="tit">{t("market.sellTitle")}</div>
                                                <div className='action'>
                                                    <div className="tags_common expired_tag">{t("market.expired")}</div>
                                                </div>
                                            </div>
                                            // myself && list 
                                            : marketNftDetail?.NftSellStatus == "OnSale" || marketNftDetail?.NftNetworkStatus == "InMarket" ?
                                                <div className="nft_price">
                                                    <div className="tit">{t("market.listingEndsAt")}
                                                        <Countdown title="" value={processingTime(marketNftDetail?.ExpiredAt)} format="D 天 H 时 m 分 s 秒" />
                                                    </div>
                                                    <div className='price'>
                                                        <div className='icon'>
                                                            <div className="icon">
                                                                <div className='price_ic'></div>
                                                                <div className='number'>
                                                                    <div className="usd">
                                                                        {"$" + (marketNftDetail?.NftPrice * TET2USDCOPY).toFixed(2)}
                                                                    </div>
                                                                    <div>{marketNftDetail?.NftPrice} {TOKENNAME}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div onClick={removeNft} className="tags_common remove_tag">{t("market.remove")}</div>
                                                    </div>
                                                </div>
                                                // myself && no list && activation
                                                : marketNftDetail?.NftValidStatus == "Valid" && marketNftDetail.NftType !== "M" ?
                                                    <div className="nft_price">
                                                        <div className="tit">{t("market.sellTitle")}</div>
                                                        <div className='action'>
                                                            <div onClick={sellNFT} className="tags_common sell_tag">{t("market.list")}</div>
                                                        </div>
                                                    </div>
                                                    // myself && no list && no activation
                                                    : marketNftDetail?.NftValidStatus == "InActivated" && marketNftDetail.NftType !== "M" ?
                                                        <div className="nft_price">
                                                            <div className="tit">{t("market.sellTitle")}</div>
                                                            <div className='action'>
                                                                <div onClick={activateNft} className="tags_common activate_tag">{t("market.activation")}</div>
                                                                <div onClick={sellNFT} className="tags_common sell_tag">{t("market.list")}</div>
                                                            </div>
                                                        </div>
                                                        : ""
                                }

                                <div className="nft_info">
                                    <div className='info_item tit'>{t("market.info")}</div>
                                    {/* contract */}
                                    <div className='info_item'>
                                        <div className='label'>{t("market.contract")}</div>
                                        <a target="_blank" href={callNetWork() + "nft/collection?id=" + marketNftDetail?.ContractID}>
                                            {marketNftDetail?.ContractID}
                                        </a>

                                    </div>
                                    {/* tokenId */}
                                    <div className='info_item'>
                                        <div className='label'>{t("market.tokenId")}</div>
                                        <a target="_blank" href={callNetWork() + "nft/nftInfo?id=" + marketNftDetail?.NftTokenID}>
                                            {marketNftDetail?.NftTokenID}
                                        </a>
                                    </div>
                                    {/* blockchain */}
                                    <div className='info_item'>
                                        <div className='label'>{t("market.blockchain")}</div>
                                        <div>{BLOCKCHAIN}</div>
                                    </div>
                                    {/* token */}
                                    <div className='info_item'>
                                        <div className='label'>
                                            {t("market.NFTpowerboost")}
                                            {" / "}
                                            {t("market.NFTitemdroprateincrease")}
                                        </div>
                                        <div>
                                            {marketNftDetail?.NftAttributes?.attributes?.power || 0}
                                            {" / "}
                                            {(marketNftDetail?.NftAttributes?.attributes?.skill?.rate || 0) * 100 + "%"}
                                        </div>
                                    </div>
                                    {/* rarity */}
                                    <div className='info_item'>
                                        <div className='label'>{t("market.rarity")}</div>
                                        <div className={"nft_type " + marketNftDetail?.NftType}>{marketNftDetail?.NftType}</div>
                                    </div>
                                    {/* Royalties */}
                                    <div className='info_item'>
                                        <div className='label'>{t("market.royalties")}</div>
                                        <div>{TRANSACTIONFEE}</div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </PublicPopup>
            </div>
            {activateNFTIndex ? <ActivateNft onConfirm={activateNftConfirm} onClose={activateNftClose} activateNFT={marketNftDetail} /> : ""}
            {removeNFTIndex ? <RemoveNft TET2USD={TET2USDCOPY} onConfirm={removeNftConfirm} onClose={removeNftClose} removeNft={marketNftDetail} /> : ""}
        </>
    )
}

export default MarketDetail
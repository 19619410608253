const viewCode = (code) => {
    switch (code) {
        // client errors
        case 1001:
            return "invalidArgument"
        case 1002:
            return "requestTimeout"
        case 1003:
            return "notFound"
        case 1004:
            return "apiError"
        case 1005:
            return "wrongAPIKey"
        // account errors
        case 2001:
            return "accountAlreadyExist"
        case 2002:
            return "accountNotExist"
        case 2003:
            return "verificationCodeError"
        case 2004:
            return "tokenError"
        case 2005:
            return "accountAddressNotSet"
        case 2006:
            return "accountSecondPasswordNotSet"
        case 2007:
            return "sameNFTAvatarSet"
        case 2008:
            return "emailAlreadyExist"
        case 2009:
            return "nicknameAlreadyExist"
        case 2010:
            return "invitationCodeUsed"
        case 2011:
            return "accountBanned"
        case 2012:
            return "inviterNotExist"
        // payment errors
        case 3001:
            return "balanceNotEnough"
        case 3002:
            return "notTicketOwner"
        case 3003:
            return "ticketInvalid"
        case 3004:
            return "freeTicketCannotRefund"
        case 3005:
            return "notNFTOwner"
        case 3006:
            return "NFTAlreadyOnChain"
        case 3007:
            return "NFTLocked"
        case 3008:
            return "freeTicketAlreadyExists"
        case 3009:
            return "userAlreadyHasFreeNFT"
        case 3010:
            return "NFTInMarketplace"
        case 3011:
            return "NFTNotOnSale"
        case 3012:
            return "cannotBuyOwnNFT"
        case 3013:
            return "NFTNetworkStatusError"
        case 3014:
            return "NFTValidStatusError"
        case 3015:
            return "NFTMintBalanceError"
        case 3016:
            return "invalidWithdrawFeeItem"
        case 3017:
            return "wrongSecondPassword"
        case 3018:
            return "invitationRewardNotFound"
        case 3019:
            return "userInvitationRewardNotFound"
        case 3020:
            return "userInvitationRewardFinished"
        case 3021:
            return "userInvitationRewardInvitationNotEnough"
        // transaction errors
        case 4001:
            return "transactionNotFound"
        case 4002:
            return "minimalTokenAmountReached"
        // game errors
        case 5001:
            return "userAlreadyHasLockedTicket"
        case 5002:
            return "userHasNoValidTicket"
        case 5003:
            return "userHasNoLockedTicket"
        // server errors
        case 10001:
            return "serverInternalError"
        case 10002:
            return "vsysChainServicesOnMaintenance"
        default:
            break;
    }
};
export { viewCode }
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { Button, Spin } from 'antd'
import { useTranslation } from 'react-i18next'

import { MoveResult } from '../../redux/actions/sockets/socketsActions'
import { socket } from '../../socket/socket'
import { copy, openNotification } from '../../tools/notification'

import PublicPopup from '../PublicComponents/PublicPopup'
import '../../scss/ChooseGameType/InviteFriendsToPlay.scss'

function InviteFriendsToPlay(props) {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const [inviteFrindsLink, setInviteFrindsLink] = useState('')
    const [isCopied, setIsCopied] = useState(false)
    const roomDetail = useSelector((state) => state.sockets.roomDetail);
    const [isLeaved, setISLeaved] = useState(false)

    useEffect(() => {
        if (props.model && props.type === "inviteFriends") {
            socket.emit("create_room", { name: "inviteFriend" + new Date().getTime(), mode: props.model, maxplayers: 2, private: 1, nftIds: props.mostPowerfulNft.ID })
        } else if (props.model && props.type === "inviteFriends_by") {
            startGame("gogo")
        }
    }, [props.model])


    useEffect(() => {
        if (isLeaved && !roomDetail.id) {
            props.onClose()
        }
        if (roomDetail.id) {
            let link = window.location.origin + `/?id=${roomDetail.id}&roommodel=${roomDetail.mode}&inviteType=friends&type=invite`
            setInviteFrindsLink(link)
            if (roomDetail.maxplayers === roomDetail.players) {
                startGame("gogo")
            }
        }
    }, [roomDetail])

    const copyFun = () => {
        if (inviteFrindsLink) {
            copy(inviteFrindsLink)
            setIsCopied(true)
        } else {
            openNotification(t("gameChooseModel.generatingLink"))
        }
    }

    const startGame = (type) => {
        if (isCopied || type) {
            const data = props.model === 'props' ? { nft: props.mostPowerfulNft } : {}
            navigate("/game#invite", { replace: true, state: data })
        } else {
            openNotification(t("gameChooseModel.pleaseCopyInvitationLinkFirst"))
        }
    }

    const onClose = () => {
        socket.emit("leaveRoom", (res) => {
            if (res.code === 0) {
                props.MoveResult()
            }
        })
        setISLeaved(true)
        if (window.location.search) {
            navigate("/", { replace: true })
        }

    }

    return (
        <PublicPopup isOverlay title={t("gameChooseModel.friendsVS")} closefun={() => onClose()} >
            {isLeaved || !roomDetail.id ? <div className="dialog-invite-friends"><Spin /></div>
                : <div className="dialog-invite-friends">
                    <div className="msg">{t("gameChooseModel.inviteFriendsContent")}</div>
                    <div className="item_content">
                        <span className='item_content_content'>{inviteFrindsLink}</span>
                        <div className='copy_ic' onClick={() => { copyFun() }}></div>
                    </div>
                    <Button onClick={() => { startGame() }} className={isCopied ? "button_common primary_button" : "button_common disabled_button"}>{t("commonText.start")}</Button>
                </div>}
        </PublicPopup>
    )
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = { MoveResult };

export default connect(mapStateToProps, mapDispatchToProps)(InviteFriendsToPlay);
import React from 'react'
import { Image } from 'antd';
import { t } from 'i18next';

import PublicPopup from './PublicPopup'
import styles from '../../scss/PublicComponentsStyle/BuyVsys.module.scss'
import xeggexLogo from "../../assets/buyVSYS/Xeggex.svg"
import coinExLogo from "../../assets/buyVSYS/CoinEx.svg"
import HitbtcLogo from '../../assets/buyVSYS/hitbtc.svg';
import HuobiLogo from '../../assets/buyVSYS/huobi.svg';
import oceanExLogo from "../../assets/buyVSYS/OceanEx.svg"
import KucoinLogo from '../../assets/buyVSYS/kucoin.svg';
import XTLogo from '../../assets/buyVSYS/xt.svg';
import CoinwLogo from '../../assets/buyVSYS/coinw.svg';
import mEXCLogo from "../../assets/buyVSYS/MEXC.svg";

const BuyVsys = ({ closefun }) => {
    const logoList = [
        { name: "Kucoin", img: KucoinLogo, link: "https://www.kucoin.com/how-to-buy/v-systems" },
        { name: "Huobi", img: HuobiLogo, link: "https://www.htx.com/en-in/asset-introduction/details/?currency=vsys" },
        { name: "mEXC", img: mEXCLogo, link: "https://www.mexc.com/exchange/VSYS_USDT?_from=search" },
        { name: "Coinw", img: CoinwLogo, link: "https://www.coinw.com/frontSpot/spottrade?symbol=998" },
        { name: "coinEx", img: coinExLogo, link: "https://www.coinex.com/en/info/VSYS" },
        { name: "oceanEx", img: oceanExLogo, link: "https://oceanex.pro/en/trades/vsysbtc" },
        { name: "Hitbtc", img: HitbtcLogo, link: "https://hitbtc.com/vsys-to-btc" },
        { name: "xeggex", img: xeggexLogo, link: "https://xeggex.com/pool/VSYS_USDT" },
        { name: "XT", img: XTLogo, link: "https://www.xt.com/en/trade/vsys_usdt" },
    ];
    return (
        <PublicPopup isOverlay title={t("mint.buyVsys")} closefun={closefun}>
            <div className={styles.buy_vsys}>
                {logoList.map((logo, index) => (
                    <a key={index} href={logo.link} rel="noreferrer" target='_blank'>
                        <div className={styles.buy_vsys_item}>
                            <Image src={logo.img} preview={false} alt={logo.name} className={styles.logo_img} />
                        </div>
                    </a>
                ))}
            </div>
        </PublicPopup>
    )
}

export default BuyVsys
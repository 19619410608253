import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Spin, Image, Button, Dropdown } from 'antd'
import { useTranslation } from 'react-i18next'

import { postNftDetailList } from '../../../api'
import { SetselectedHero } from '../../../redux/actions/sockets/socketsActions'
import { IMAGE_BASEURL, SURVIVAL_THRESHOLD } from '../../../tools/gameConfig'

import PublicPopup from '../../../Components/PublicComponents/PublicPopup'
import CommonPagination from '../../../Components/PublicComponents/Pagination'
import './SoloModeSelectLevel.scss'
import avatar from '../../../assets/hall/hero_item1_avatar.png'
import levelAvtar from '../../../assets/hall/level_avtar.png'

function SoloModeSelectLevel(props) {
    const [selectedLevel, setSelectedLevel] = useState({})
    const [selectedPower, setSelectedPower] = useState(0)
    const [allHero, setAllHero] = useState([])
    const [selectedHero, setSelectedHero] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [nftDetailList, setNftDetailList] = useState([])
    const [showSelectHero, setShowSelectHero] = useState(false)
    const [whiteHeroSelected, setWhiteHeroSelected] = useState(false)

    const navigate = useNavigate()
    const { t } = useTranslation()

    const handleClick = (action) => {
        props.onClose(action)
    }
    const allHeroData = [
        {
            NftTokenID: 10000,
            power: 5000,
            url: `${avatar}`,
            NftTokenIndex: 1,
        }, {
            NftTokenID: 10001,
            power: 3000,
            url: `${avatar}`,
            NftTokenIndex: 2,
        }, {
            NftTokenID: 10002,
            power: 2000,
            url: `${avatar}`,
            NftTokenIndex: 3,
        }, {
            NftTokenID: 10003,
            power: 1500,
            url: `${avatar}`,
            NftTokenIndex: 4,
        }, {
            NftTokenID: 10004,
            power: 1000,
            url: `${avatar}`,
            NftTokenIndex: 5,
        },
    ]
    useEffect(() => {
        return () => {
            setAllHero([])
            setNftDetailList([])
        }
    }, [])

    useEffect(() => {
        if (page && !loading) {
            getNftDetailList()
        }
    }, [page])

    useEffect(() => {
        if (nftDetailList.length > 0 && loading) {
            getNftDataList()
        }
    }, [nftDetailList])

    useEffect(() => {
        if (allHero.length > 0 && loading) {
            setLoading(false)
        }
    }, [allHero])

    useEffect(() => {
        // update selectedPower
        let powerTotal = 0
        selectedHero.map((item) => {
            powerTotal += item.power
        })
        setSelectedPower(powerTotal)
    }, [selectedHero])

    const getNftDetailList = () => {
        // setAllHero(allHeroData)
        // return
        setLoading(true)
        postNftDetailList({
            "nftValidStatusFilter": "Valid",
            "nftOrder": "quality DESC",
            "pagination": {
                "page": page,
                "size": 20
            }
        }).then((res) => {
            if (!res.data.code) {
                if (res.data.data.userNFTs) {
                    setNftDetailList(res.data.data.userNFTs)
                    setTotal(res.data.data.pagination?.total)
                } else {
                    setLoading(false)
                }
            } else {
                setLoading(false)
            }
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    const getNftDataList = async () => {
        let nftDataList = []
        for (let index = 0; index < nftDetailList.length; index++) {
            const item = nftDetailList[index];
            let nftObj = {}
            nftObj.nftId = item.ID
            nftObj.TetrisNftID = item.TetrisNftID
            nftObj.NftTokenID = item.NftTokenID
            nftObj.NftTokenIndex = item.NftTokenIndex
            nftObj.power = item.NftAttributes?.attributes?.power
            nftObj.nft_type = item.NftAttributes?.attributes?.quality || ""
            nftObj.url = item.NftAttributes?.content ? IMAGE_BASEURL + item.NftAttributes?.content + ".png" : ""
            nftDataList.push(nftObj)
        }
        setAllHero([...nftDataList])
    }


    const clickLevel = (item) => {
        setSelectedLevel(item)
    }

    const goNext = () => {
        setShowSelectHero(true)
    }

    const startGame = () => {
        window._czc && window._czc.push(["_trackEvent", "生存模式", "点击", selectedLevel.level]);
        let ids = []
        selectedHero.forEach((item) => {
            ids.push({
                id: item.nftId,
                url: item.url
            })
        })
        props.SetselectedHero(ids)
        navigate(`/game?gameLevel=${selectedLevel.level}&gamePower=${selectedPower}`, { replace: true })
    }

    const clearHero = () => {
        setWhiteHeroSelected(false)
        setSelectedHero([])
    }

    const autoSelect = () => {
        let newSeletedHero = []
        let whiteHeroSelected = false
        for (let i = 0; i < allHero.length; i++) {
            let hero = allHero[i]
            if (newSeletedHero.length >= 3) { break }
            if (hero.nft_type === "M" && whiteHeroSelected) {
                continue
            }
            if (hero.nft_type === "M") { whiteHeroSelected = true; setWhiteHeroSelected(true) }
            newSeletedHero.push(hero)
        }
        setSelectedHero(newSeletedHero.slice(0, 3))
    }

    const selectHero = (hero) => {
        let isSelect = selectedHero.filter((item) => hero.NftTokenIndex === item.NftTokenIndex)
        if (isSelect.length > 0) { return }
        if (hero.nft_type === "M" && whiteHeroSelected) { return }
        if (hero.nft_type === "M") { setWhiteHeroSelected(true) }
        let selected = [hero, ...selectedHero]
        setSelectedHero(selected.slice(0, 3))
    }

    const pageChange = (e) => {
        setPage(e)
    }


    return (
        <div>
            {
                showSelectHero ? (
                    <PublicPopup isOverlay title={t("game.chooseHero")} closefun={(action) => handleClick(action)}>
                        <div className='hero-select-wrapper'>
                            <div className="hero_left">
                                <Image className='avatar' preview={false} src={levelAvtar} />
                                <Button className='tags_common activate_tag'>{t("game.level")}{selectedLevel.level}</Button>
                                <Button className='tags_common activate_tag'>
                                    <span className="red">{selectedPower}</span>
                                    <span>/{selectedLevel.powerRequirement}</span>
                                </Button>
                            </div>
                            <div className='hero_right'>
                                <div className="page_div">
                                    <CommonPagination onChange={pageChange} pageSize={20} current={page} total={total} />
                                </div>
                                <div className="top">
                                    <div className="middle">
                                        {selectedHero.map((item, index) => {
                                            return (
                                                <div className="item1" key={index}>
                                                    <Image className='img' src={item.url ? item.url + "?imageView2/1/w/300/h/300" : ""} fallback={item.url} preview={false} alt={item.ID} placeholder={<Image className='img' src={item.url ? item.url + "?imageView2/1/w/50/h/50" : ""} fallback={item.url} preview={false} alt={item.ID} />} />
                                                    <span className="nftid">nft:{item.NftTokenIndex}</span>
                                                    <div className={"line " + item.nft_type}></div>
                                                    <span className={"nftpoint " + item.nft_type}>{t("game.ability")}:{item.power}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="right">
                                        <div className='button' onClick={() => autoSelect()}>{t("game.quickSetup")}</div>
                                        <div className='button' onClick={() => clearHero()}>{t("game.initialize")}</div>
                                        <div className={selectedPower >= selectedLevel.powerRequirement ? "button" : "button disabled"} onClick={() => selectedPower >= selectedLevel.powerRequirement ? startGame() : {}}>{t("game.play")}</div>
                                    </div>
                                </div>
                                <div className='buttom'>
                                    {loading ? <Spin /> : allHero.map((item, index) => {

                                        return (
                                            <div className="item2" key={index} onClick={() => selectHero(item)} >
                                                <Image className='img' src={item.url ? item.url + "?imageView2/1/w/200/h/200" : ""} fallback={item.url} preview={false} alt={item.ID}
                                                    placeholder={<Image className='img' src={item.url ? item.url + "?imageView2/1/w/50/h/50" : ""} fallback={item.url} preview={false} alt={item.ID} />} />
                                                <span className="nftid">nft:{item.NftTokenIndex}</span>
                                                <div className={"line " + item.nft_type}></div>
                                                <span className={"nftpoint " + item.nft_type}>{t("game.ability")}:{item.power}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                        </div>

                    </PublicPopup>
                ) : (
                    <PublicPopup
                        title={<Dropdown
                            dropdownRender={(menu) => (
                                <div style={{ background: "#40077e", whiteSpace: "pre-wrap", borderRadius: "1vw", width: "25vw", height: "auto", color: "#fff", fontFamily: "Noto Sans", padding: "1.5vw", boxSizing: "border-box", fontSize: "0.78vw", position: "relative" }}>
                                    {t("titleContent.solo")}
                                    <a style={{ marginLeft: "0.5vw", color: "#f16e30", textDecoration: "underline", fontSize: "0.8vw" }} href={t("titleContent.soloLink")} target="_blank">{t("titleContent.more")}</a>
                                </div>
                            )}
                            trigger={['click', 'hover']}
                            placement='bottom'>
                            <div style={{ display: "flex", gap: "0.5vw", alignItems: "center", justifyContent: "center" }}>{t("personalCenter.survivalMode")}<div className="price_info_ic"></div></div>
                        </Dropdown>}
                        isOverlay closefun={(action) => handleClick(action)}>
                        <div className='level-select-wrapper'>
                            <div className="level_select_bg">
                                <div className="top">
                                    {SURVIVAL_THRESHOLD.map((item, index) => {
                                        return (
                                            <div className={'item-bg item' + (index + 1) + " item-bg" + (index + 1)} key={item.level} onClick={() => clickLevel(item)}>
                                                <div className='text'>{t(`game.${item.text}`)}</div>
                                            </div>
                                        )
                                    })}
                                    {new Array(12 - (SURVIVAL_THRESHOLD.length)).fill(false).map((item, index) => {
                                        return <div key={index + "" + item} className={'item-lock-bg item' + (index + 1 + SURVIVAL_THRESHOLD.length)}>{
                                            !index ? <div className='text'>{t("game.comingSoon")}</div> : ""
                                        }</div>
                                    })}
                                </div>
                                <div className='bbb'>
                                    <Button className={selectedLevel.level ? 'button_common secondary_button' : 'button_common disabled_button'} onClick={() => selectedLevel.level ? goNext() : {}}>{t("user.next")}</Button>
                                    <div className='point'>{t("game.abilityDemand")}：{selectedLevel.powerRequirement}</div>
                                </div>
                            </div>
                        </div>

                    </PublicPopup>
                )
            }



        </div>
    )
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = { SetselectedHero };

// export default SoloModeSelectLevel
export default connect(mapStateToProps, mapDispatchToProps)(SoloModeSelectLevel);
import React from 'react';
import { Image } from 'antd';
import { t } from 'i18next';

import styles from '../../scss/PublicComponentsStyle/NoData.module.scss'
import NoDataIcon from '../../assets/public_icon/table_no_data_icon.png'

const NoData = ({ title }) => {
    return (
        <div className={styles.table_no_data}>
            <Image preview={false} src={NoDataIcon} />
            <div className={styles.title}>{title || t("commonText.noData")}</div>
        </div>
    )
}

export default NoData
import React from 'react'
import { t } from 'i18next'
import { Button } from 'antd'

import PublicPopup from './PublicPopup'
import styles from '../../scss/PublicComponentsStyle/CommonDialogSmall.module.scss'

const CommonDialogSmall = ({ open, onModalAction, title, msg, cancel, ok }) => {

    const onClick = (action) => {
        onModalAction(action)
    }
    return (
        <>{open ?
            <PublicPopup title={title} isOverlay >
                <div className={styles.dialog_small_wrapper}>
                    <span className={styles.msg}>
                        {msg}
                    </span>
                    <div className={styles.action}>
                        {cancel ? <Button className='button_common secondary_button' onClick={() => { onClick('cancle') }}>{t("market.cancel")}</Button> : ""}
                        <Button className='button_common primary_button' onClick={() => { onClick('ok') }}>{ok || t("market.confirm")}</Button>
                    </div>
                </div>
            </PublicPopup>
            : ""}</>
    )
}

export default CommonDialogSmall

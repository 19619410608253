import Api from "../../../socket/Api";
import { socket } from "../../../socket/socket";

/*---------------------------------- Sockets Actions ----------------------------------------------------*/

/*
 ** Set new  player
 */
export const newPLayer = (username) => {
  return async (dispatch) => {
    dispatch({
      type: "NEW_PLAYER",
      userName: username,
    });
  };
};
/*
 ** USER_ EXISTS
 */
export const userExists = (userexists) => {
  return async (dispatch) => {
    dispatch({
      type: "USER_EXISTS",
      userexists,
    });
  };
};

export const roomExists = (isExists) => {
  return async (dispatch) => {
    dispatch({ type: "ROOM_EXISTS", isExists })
  }
}

export const roomFull = (isFull) => {
  return async (dispatch) => {
    dispatch({ type: "ROOM_FULL", isFull })
  }
}
/*
 ** Set new  player
 */
export const newRoom = (roomname) => {
  return async (dispatch) => {
    dispatch({
      type: "NEW_ROOM",
      roomname,
    });
  };
};

/*
 ** Get room List
 */

export const getRooms = () => {
  return async (dispatch) => {
    Api()
      .get("/rooms")
      .then((res) => {
        dispatch({
          type: "SET_ROOMS",
          rooms: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

/*
 ** Update the room list
 */

export const updateRoomList = (rooms) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_ROOMS",
      rooms,
    });
  };
};

export const updateRoomDetail = (roomDetail) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_ROOM_DETAIL",
      roomDetail,
    });
  };
};
/*
 ** Get room players List
 */
export const getRoomPlayerslist = (players) => {
  return async (dispatch) => {
    dispatch({
      type: "ROOM_PLAYERS_LIST",
      roomPlayers: players,
    });
  };
};

/*
 ** Get room players List
 */
export const getRoomViewerslist = (Viewers) => {
  return async (dispatch) => {
    dispatch({
      type: "ROOM_VIEWERS_LIST",
      roomViewers: Viewers,
    });
  };
};

/*
 ** Update room Data
 */
export const updateRoomData = (room) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_ROOM_DATA",
      room,
    });
  };
};

/*
 ** start the game
 */
export const StartGame = (tetriminos) => {
  return async (dispatch) => {
    dispatch({
      type: "START_GAME",
      tetriminos,
    });
  };
};

/*
** Game Over
*/
export const gameOverAction = () => {
  return async (dispatch) => {
    dispatch({
      type: "GAME_OVER",
    });
  }

}

/*
 ** Get new Tetriminos in startred game
 */
export const newTetriminos = (tetris, tetriminos) => {
  return async (dispatch) => {
    let newTetris = [];
    dispatch({
      type: "NEW_TETRIMINOS",
      tetriminos: newTetris.concat(tetriminos, tetris),
    });
  };
};

/*
 ** Set Stages in multiple game
 */

export const setStages = (Stages, stage, roomname, clearStagesName) => {
  return async (dispatch) => {
    if (Stages.length === 0) {
      Stages.push(stage);
      dispatch({
        type: "ADD_STAGES",
        Stages: Stages,
      });
    } else {
      let Stg = Stages.filter((stg) => stg.username === stage.username);
      if (Stg[0]?.username) {
        Stg[0].stage = stage.stage
        Stg[0].nextStage = stage.nextStage
      }
      else {
        Stages.push(stage);
        socket.emit("checkStages", { Stages, stage, room: roomname });
      }
      if (clearStagesName.length) {
        Stages = Stages.filter((sta) => {
          return sta.username !== clearStagesName.find((name) => name == sta.username)
        })
      }
      dispatch({
        type: "ADD_STAGES",
        Stages: Stages,
      });
    }
  };
};

/*
 ** Set Scores in multiple game
 */

export const setScores = (Scores, score, roomname) => {
  return async (dispatch) => {
    if (Scores.length === 0) {
      Scores.push(score);
      dispatch({
        type: "ADD_SCORES",
        Scores: Scores,
      });
    } else {
      let Sco = Scores.filter((sco) => sco.username === score.username);
      if (Sco[0]?.username) Sco[0].score = score.score;
      else {
        Scores.push(score);
        // socket.emit("checkStages", { Stages, stage, room: roomname });
      }
      dispatch({
        type: "ADD_SCORES",
        Scores: Scores,
      });
    }
  };
};

export const resetProps = (prop) => {
  return async (dispatch) => {
    dispatch({
      type: "RESET_PROPS",
      PropsData: [],
    });
  };
};

// set props data into redux
export const setProps = (PropsData, prop, roomname) => {
  return async (dispatch) => {
    // if (PropsData.length === 0) {
    // PropsData.push(prop);
    dispatch({
      type: "ADD_PROPS",
      PropsData: prop,
    });
    // } else {
    // let Sco = Props.filter((sco) => sco.username === score.username);
    // if (Sco[0]?.username) Sco[0].score = score.score;
    // else {
    //   Scores.push(score);
    //   // socket.emit("checkStages", { Stages, stage, room: roomname });
    // }
    // dispatch({
    //   type: "ADD_PROPS",
    //   Scores: Scores,
    // });
    // }
  };
};

/*
 ** Update stages state on redux
 */
export const updateStages = (Stages) => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_STAGES", Stages: Stages.Stages });
  };
};

/*
 ** Add a wall to the other players stages
 */
export const addWall = (wall) => {
  console.log(wall)
  return async (dispatch) => {
    dispatch({
      type: "ADD_WALL",
      wall: wall.wall,
      data: wall.data
    });
  };
};

/*
 ** Delete user from Stages
 */
export const deleteUserfromStages = (username, stages) => {
  return (dispatch) => {
    dispatch({ type: "ADD_CLEAR_STAGES", username: username });
    const newStages = stages.filter((stage) => stage.username !== username);
    dispatch({ type: "UPDATE_STAGES", Stages: newStages });
  };
};
/*
 ** Get Chat Messages
 */
export const getChatMessages = (messages) => {
  return async (dispatch) => {
    dispatch({
      type: "CHAT_MESSAGES",
      messages,
    });
  };
};

/*
 ** Clear Chat Messages
 */
export const clearChatMessages = () => {
  return async (dispatch) => {
    dispatch({
      type: "CLEAR_CHAT_MESSAGES",
    });
  };
};

/*
 ** Clear All State
 */
export const clearAllState = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_ALL_STATE",
    });
  };
};
/*
 ** Declare Game finished
 */
export const GameFinished = (rank, invalid) => {
  return async (dispatch) => {
    dispatch({ type: "GAME_FINISHED", rank: rank || [], invalid: invalid });
  };
};


export const MoveResult = () => {
  return async (dispatch) => {
    dispatch({ type: "MOVE_GAME_RESULT" })
  }
}

/*
 ** Clear state after player Leaved
 */
export const ClearStateAfterLeavedRoom = () => {
  return async (dispatch) => {
    dispatch({ type: "CLEAR_STATE_LEAVED_ROOM" });
  };
};

export const SetselectedHero = (selectedHero) => {
  return async (dispatch) => {
    dispatch({ type: "SET_SELECTED_HERO", selectedHero })
  }
}

export const SoloResumeGame = () => {
  return async (dispatch) => {
    dispatch({ type: "SET_SOLO_RESUME_GAME" })
  }
}
export const UpdateGameAsyncTime = (gameAsyncTime) => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_GAME_ASYNCTIME", gameAsyncTime })
  }
}

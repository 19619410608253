const network = process.env.REACT_APP_DEPLOY_NET
const TEST_TOKEN_ADDRESS = 'TWudFkwZV5h7stgTao8Wiyzq44iJWz2RTmgxwk3cz'
const TEST_NFT_CONTRACT_ADDRESS = 'CF3VomjBDupSR396uScakUyuCVuf6qtg8Dc'
const TEST_VSWAP_CONTRACT_ADDRESS = 'CF1KpW6n5H286jHhoJFxveksH1iebR1UKho'
const TEST_TOKEN_SYMBOL = ''
const TEST_TOKEN_UNITY = ''
const TEST_VSYS_ADMIN_ACCOUNT = 'AU5QSztVDZ7yGLZPrDbSk1Q4P4GqcZaJZiX'
const TEST_VSWAP_SITE = 'http://158.69.246.226:49160/'

const MAIN_TOKEN_ADDRESS = 'TWbTUFLjM6hi1Ci1qv9e3mrcEsewfMedMSG7pYipt'
const MAIN_NFT_CONTRACT_ADDRESS = 'CC9zcipWrvuMdgdRDaQoSZs9XpL95DGeg1r'
const MAIN_VSWAP_CONTRACT_ADDRESS = 'CC4LzkKg3gLKjKGEa3ankhx88ho3pY2T6Yy'
const MAIN_TOKEN_SYMBOL = ''
const MAIN_TOKEN_UNITY = ''
const MAIN_VSYS_ADMIN_ACCOUNT = 'ARNoUymFzbgEZN7BBCms781yCLmerEMLD6c'
const MAIN_VSWAP_SITE = ''

const TOKEN_ADDRESS = network === "M" ? MAIN_TOKEN_ADDRESS : TEST_TOKEN_ADDRESS
const NFT_CONTRACT_ADDRESS = network === "M" ? MAIN_NFT_CONTRACT_ADDRESS : TEST_NFT_CONTRACT_ADDRESS
const VSWAP_CONTRACT_ADDRESS = network === "M" ? MAIN_VSWAP_CONTRACT_ADDRESS : TEST_VSWAP_CONTRACT_ADDRESS
const TOKEN_SYMBOL = network === "M" ? MAIN_TOKEN_SYMBOL : TEST_TOKEN_SYMBOL
const TOKEN_UNITY = network === "M" ? MAIN_TOKEN_UNITY : TEST_TOKEN_UNITY
const VSYS_ADMIN_ACCOUNT = network === "M" ? MAIN_VSYS_ADMIN_ACCOUNT : TEST_VSYS_ADMIN_ACCOUNT
const VSWAP_SITE = network === "M" ? MAIN_VSWAP_SITE : TEST_VSWAP_SITE

export {
    TOKEN_ADDRESS,
    NFT_CONTRACT_ADDRESS,
    TOKEN_SYMBOL,
    TOKEN_UNITY,
    VSYS_ADMIN_ACCOUNT,
    VSWAP_SITE,
    VSWAP_CONTRACT_ADDRESS,
}
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Carousel, Image, Spin, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import { AddTransaction } from '../../../redux/actions/user/userActions';
import { freeNft, getSwapTokenTRate, getVSYS2USD, mintNft, postNftDiscountInfo, postNftTetrisAttributes, transactionDetails, transactionStatus } from '../../../api';

import { openNotification } from '../../../tools/notification';
import { viewCode } from '../../../tools/viewCode';
import { IMAGE_BASEURL, MINTPRICE, TOKENNAME } from '../../../tools/gameConfig';
import { VSWAP_CONTRACT_ADDRESS } from '../../../tools/tokenConfig'

import PublicPopup from '../../../Components/PublicComponents/PublicPopup';
import './Index.scss'
import avatarDefault1 from '../../../assets/hall/avatar_default.png'
import AddIcon from '../../../assets/public_icon/add_icon.svg'
import RemoveIcon from '../../../assets/public_icon/remove_icon.svg'
import BuyVsys from '../../../Components/PublicComponents/buyVsys';

let time = null
let time1 = null
function Index(props) {
    const { t } = useTranslation()
    const handleClick = () => {
        props.onClose()
    }
    const updateUserInfo = () => {
        props.updateUserInfo()
    }
    const [count, setCount] = useState(1)
    const [discount, setDiscount] = useState(0)

    const [totalVSYS, setTotalVSYS] = useState(0)
    const [totalTET, setTotalTET] = useState(0)
    const [priceVSYS, setPriceVSYS] = useState(0)
    const [priceTET, setPriceTET] = useState(0)
    const [discountTotalVSYS, setDiscountTotalVSYS] = useState(0)
    const [discountTotalTET, setDiscountTotalTET] = useState(0)

    const [minting, setMinting] = useState(false)
    const [transactionIds, setTransactionIds] = useState([])
    const [mintingStatusIsSuccess, setMintingStatusIsSuccess] = useState(false)

    const [showFreeNft, setShowFreeNft] = useState(false)
    const [showBuyNft, setShowBuyNft] = useState(false)
    const [nftData, setNftData] = useState([])

    const [buyVsys, setBuyVsys] = useState(false)

    useEffect(() => {
        getPrice()
        getDiscountInfo()
    }, [])

    useEffect(() => {
        if (priceTET > 0 && priceVSYS > 0) {
            clearInterval(time1)
            time1 = setInterval(() => {
                getPrice()
            }, 10000);
        } else {
            clearInterval(time1)
            time1 = setInterval(() => {
                getPrice()
            }, 5000);
        }
        return () => {
            clearInterval(time1)
        }
    }, [priceTET, priceVSYS])

    useEffect(() => {
        if (priceTET) {
            setTotalTET(Math.ceil(priceTET * count))
            setDiscountTotalTET(Math.ceil(priceTET * count * (1 - discount)))
        } else {
            setTotalTET(0)
            setDiscountTotalTET(0)
        }
        return () => {
        }
    }, [count, priceTET, discount])

    useEffect(() => {
        if (priceVSYS) {
            setTotalVSYS(Math.ceil(priceVSYS * count * 1.02))
            setDiscountTotalVSYS(Math.ceil(priceVSYS * count * (1 - discount) * 1.02))
        } else {
            setTotalTET(0)
            setDiscountTotalTET(0)
        }
        return () => {
        }
    }, [count, priceVSYS, discount])

    // get Transaction Detail
    useEffect(() => {
        if (transactionIds.length > 0 && mintingStatusIsSuccess) {
            getTransactionDetail()
        }
    }, [mintingStatusIsSuccess, transactionIds])

    // clear transaction Ids
    useEffect(() => {
        if (nftData.length === transactionIds.length) {
            setMinting(false)
            setTransactionIds([])
        }
    }, [nftData])
    // clear minting Status
    useEffect(() => {
        if (transactionIds.length === 0) {
            setMintingStatusIsSuccess(false)
        }
    }, [transactionIds])

    useEffect(() => {
        if (transactionIds.length > 0 && props.mintNftTransaction && props.mintNftTransaction.length > 0) {
            if (props.mintNftTransaction.find((item) => {
                return item.key === time + "success"
            })) { setMintingStatusIsSuccess(true) }
        }
    }, [props.mintNftTransaction, transactionIds])

    // get price
    const getPrice = async () => {
        try {
            let VSYS2USD = ((await getVSYS2USD()).data?.data?.vsysExchange?.usd || 0)
            setPriceVSYS(VSYS2USD ? MINTPRICE * 1 / VSYS2USD : 0)
            let TET2USD = ((await getSwapTokenTRate({ vswapContract: VSWAP_CONTRACT_ADDRESS }))?.data?.data?.tetrisTokenExchange?.usd || 0)
            setPriceTET(TET2USD ? MINTPRICE * 1 / TET2USD : 0)
        } catch (error) {
            console.log(error)
        }
    }

    // get discount
    const getDiscountInfo = () => {
        postNftDiscountInfo().then((res) => {
            if (res.data.code) { } else {
                if (new Date(res.data.data?.discountStartingTime).getTime() < new Date().getTime()) {
                    if (new Date(res.data.data?.discountEndingTime).getTime() > new Date().getTime()) {
                        setDiscount(res.data.data?.discountRatio)
                    }
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    // update count
    const updateCount = (type) => {
        if (type === "+") {
            if (count >= 10) {
                setCount(10)
            } else {
                setCount(p => p + 1)
            }
        } else if (type === "-") {
            if (count > 2) {
                setCount(p => p - 1)
            } else {
                setCount(1)
            }
        }
    }


    // mint NFT
    const mintNFT = (type, number) => {
        setMinting(true)
        if (type == "buyNftVSYS") {
            window.open('/home?homePage=casting', '_blank')
        } else if (type == "buyNftTET") {
            buyNFT(number || count)
            setShowBuyNft(true)
        } else if (type === "freeNft") {
            freeNFT()
            setShowFreeNft(true)
        }
    }

    // buy NFT
    const buyNFT = (number) => {
        mintNft({ "mintNFTNumber": number }).then((res) => {
            if (!res.data.code) {
                window._czc && window._czc.push(["_trackEvent", "铸造NFT", "点击"]);
                if (res.data.data.userTransactionIds) {
                    setTransactionIds(res.data.data.userTransactionIds)
                    time = new Date().getTime()
                    props.AddTransaction({ key: time, transaction: res.data.data.userTransactionIds, type: "pending" })
                    updateUserInfo()
                }
            } else {
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                setMinting(false)
                setShowBuyNft(false)
            }
        }).catch((err) => {
            console.log(err)
            openNotification(t("responseCode.networkFailed"))
            setShowBuyNft(false)
            setMinting(false)
        })
    }

    // free NFT
    const freeNFT = () => {
        if (props.freeNftMinted) {
            return
        }
        setCount(1)
        freeNft().then((res) => {
            if (!res.data.code) {
                window._czc && window._czc.push(["_trackEvent", "领取免费NFT", "点击"]);
                setTransactionIds([res.data.data.userTransactionId])
                time = new Date().getTime()
                props.AddTransaction({ key: time, transaction: [res.data.data.userTransactionId], type: "pending", isFree: true })
                updateUserInfo()
            } else {
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                setMinting(false)
                setShowFreeNft(false)
            }
        }).catch((err) => {
            openNotification(t("responseCode.networkFailed"))
            setMinting(false)
            setShowFreeNft(false)
        })
    }

    // get Transaction Detail to NftData
    const getTransactionDetail = () => {
        let nftDataList = []
        for (let index = 0; index < transactionIds.length; index++) {
            const id = transactionIds[index];
            transactionDetails({ "id": id }).then(async (res) => {
                let nftObj = {}
                if (!res.data.code) {
                    nftObj.nftId = res.data.data.Details.user_nft_id
                    nftObj.nft_type = res.data.data.Details.nft_type
                    let respond = await postNftTetrisAttributes({ dbputTxIds: [res.data.data.Details?.nft_attributes_dbput_tx_id] }).catch((err) => {
                        console.log(err)
                    })
                    if (respond?.data?.code === 0) {
                        nftObj.url = respond.data.data[0]?.attributes?.content ? IMAGE_BASEURL + respond.data.data[0]?.attributes?.content + ".png" : ""
                    }
                }
                nftDataList.push(nftObj)
                if (nftDataList.length === transactionIds.length) {
                    setNftData(nftDataList)
                }
            }).catch((error) => {
                console.log(error)
                let nftObj = {}
                nftDataList.push(nftObj)
                if (index === transactionIds.length - 1) {
                    setNftData(nftDataList)
                }
            })
        }
    }

    let content = <div className='mint-home'>
        <div className='left-left'>
            <div className='banner'>
                <Carousel autoplay>
                    <div><Image width={"100%"} preview={false} fallback={avatarDefault1} src={`${IMAGE_BASEURL}Qmeh3qHqcrvP3SVNWRZRNFMcapssxECMWdfR8C7wBqXtDw.png?imageView2/1/w/500/h/500`} placeholder={<Image width={"100%"} preview={false} fallback={avatarDefault1} src={`${IMAGE_BASEURL}Qmeh3qHqcrvP3SVNWRZRNFMcapssxECMWdfR8C7wBqXtDw.png?imageView2/1/w/50/h/50`} />} /></div>
                    <div><Image width={"100%"} preview={false} fallback={avatarDefault1} src={`${IMAGE_BASEURL}QmUHPkeR54vZXmtG7Yhc1THqbjH9E5wMVkZvCkc7t5V6N1.png?imageView2/1/w/500/h/500`} placeholder={<Image width={"100%"} preview={false} fallback={avatarDefault1} src={`${IMAGE_BASEURL}QmUHPkeR54vZXmtG7Yhc1THqbjH9E5wMVkZvCkc7t5V6N1.png?imageView2/1/w/50/h/50`} />} /></div>
                    <div><Image width={"100%"} preview={false} fallback={avatarDefault1} src={`${IMAGE_BASEURL}QmckrdKVfxpk1F1uZH6d6KfMTRYGCJQPKvRxXktUs2i88h.png?imageView2/1/w/500/h/500`} placeholder={<Image width={"100%"} preview={false} fallback={avatarDefault1} src={`${IMAGE_BASEURL}QmckrdKVfxpk1F1uZH6d6KfMTRYGCJQPKvRxXktUs2i88h.png?imageView2/1/w/50/h/50`} />} /></div>
                    <div><Image width={"100%"} preview={false} fallback={avatarDefault1} src={`${IMAGE_BASEURL}QmUkUk7U3foajz9rQEapmQi4SrUxiLrBFZytd9MoNAFy6J.png?imageView2/1/w/500/h/500`} placeholder={<Image width={"100%"} preview={false} fallback={avatarDefault1} src={`${IMAGE_BASEURL}QmUkUk7U3foajz9rQEapmQi4SrUxiLrBFZytd9MoNAFy6J.png?imageView2/1/w/50/h/50`} />} /></div>
                    <div><Image width={"100%"} preview={false} fallback={avatarDefault1} src={`${IMAGE_BASEURL}QmcSjQnsnzECzUmS2uGDxo3N9WnsCZYHuSPtiqfs6VNpfh.png?imageView2/1/w/500/h/500`} placeholder={<Image width={"100%"} preview={false} fallback={avatarDefault1} src={`${IMAGE_BASEURL}QmcSjQnsnzECzUmS2uGDxo3N9WnsCZYHuSPtiqfs6VNpfh.png?imageView2/1/w/50/h/50`} />} /></div>
                </Carousel>
            </div>
        </div>
        <div className='left' >
            <div className='top'>
                <div className='ticker-type'>{t("mint.NFTBlindBox")}</div>
                <div className='ticker-img'></div>
                <div className='total-div'>
                    <div className='price_div'><div className='discount_total'>{TOKENNAME} : {discountTotalTET <= 0 ? "..." : discountTotalTET}</div>
                        {discountTotalTET !== totalTET ? <div className='total'>{TOKENNAME} : {totalTET <= 0 ? "..." : totalTET}</div> : ""}</div>
                    <div className='option'>
                        <Button className='arrow_button' onClick={() => { updateCount("-") }}>
                            <Image preview={false} src={RemoveIcon} />
                        </Button>
                        <div className='count'>{count}</div>
                        <Button className='arrow_button' onClick={() => { updateCount("+") }}>
                            <Image preview={false} src={AddIcon} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className='bottom-option'>
                <Button className='button_common primary_button' onClick={() => { mintNFT("buyNftVSYS") }}>
                    {t("mint.forging")} VSYS
                    <div className='span'>
                        <span className="button_span_discount">{discountTotalVSYS}VSYS</span>
                        <span className='button_span'>{totalVSYS}VSYS</span>
                    </div>
                </Button>
                <Button className='button_common secondary_button' onClick={() => { mintNFT("buyNftTET") }}>
                    {t("mint.forging")} TET
                </Button>
            </div>
        </div>
        <div className='left'>
            <div className='top'>
                <div className='ticker-type'>{t("mint.freeGiftBox")}</div>
                <div className='ticker-img-free'></div>
            </div>
            <div className={props.freeNftMinted ? "button_common disabled_button" : "button_common secondary_button"} onClick={() => props.freeNftMinted ? {} : mintNFT("freeNft")}>
                {props.freeNftMinted ? t("mint.haveReceived") : t("mint.claimNow")}
            </div>
        </div>
        <Button className='button_common button_shadow primary_button buy_vsys' onClick={() => { setBuyVsys(true) }}>{t("mint.buyVsys")}</Button>
    </div>
    // free Nft
    if (showFreeNft) {
        content =
            <div className='mint-free-nft'>
                {minting ? <div className="spindiv"><Spin tip="minting..." >{""}</Spin></div> :
                    <>
                        <div className='nft-list'>
                            {
                                nftData.map((item, index) => {
                                    return (
                                        <div className='nft-item' key={item.nftId + index}>
                                            <div className='nft-img'>
                                                <Image
                                                    width={"100%"}
                                                    preview={false}
                                                    fallback={avatarDefault1}
                                                    src={item.url + "?imageView2/1/w/200/h/200"}
                                                    placeholder={
                                                        <Image preview={false}
                                                            width={"100%"}
                                                            src={item.url + "?imageView2/1/w/50/h/50"}
                                                        />
                                                    }
                                                />
                                            </div>
                                            <div className='nft-id'>NFTID:{item.nftId}</div>
                                            <div className='nft-color'>{t("mint.rarity")}:{item.nft_type}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='bottom-option'>
                            <div className='button_common primary_button' onClick={handleClick}>
                                {t("mint.confirm")}</div>
                        </div>
                    </>}
            </div>
    } else if (showBuyNft) {
        content =
            <div className='mint-free-nft'>
                {minting ? <div className="spindiv"><Spin tip="minting..." >{""}</Spin></div> :
                    <>
                        <div className='nft-list'>
                            {
                                nftData.map((item, index) => {
                                    return (
                                        <div className='nft-item' key={item.nftId}>
                                            <div className='nft-img'>
                                                <Image
                                                    width={"100%"}
                                                    preview={false}
                                                    fallback={avatarDefault1}
                                                    src={item.url + "?imageView2/1/w/200/h/200"}
                                                    placeholder={
                                                        <Image preview={false}
                                                            width={"100%"}
                                                            src={item.url + "?imageView2/1/w/50/h/50"}
                                                        />
                                                    }
                                                />
                                            </div>
                                            <div className='nft-id'>NFTID:{item.nftId}</div>
                                            <div className='nft-color'>{t("mint.rarity")}:{item.nft_type}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='bottom-option'>
                            <div className='button_common primary_button' onClick={() => { mintNFT("buyNftTET", 1) }}>
                                {t("mint.tryAgain")} </div>
                            <div className='button_common secondary_button' onClick={() => { mintNFT("buyNftTET", 10) }}>
                                {t("mint.tenMoreTimes")} </div>
                        </div>
                    </>}
            </div>
    }

    return (
        <><PublicPopup
            title={<Dropdown
                dropdownRender={(menu) => (
                    <div style={{ background: "#40077e", whiteSpace: "pre-wrap", borderRadius: "1vw", width: "40vw", height: "auto", color: "#fff", fontFamily: "Noto Sans", padding: "1.5vw", boxSizing: "border-box", fontSize: "0.78vw", position: "relative" }}>
                        {t("titleContent.mint")}
                        <a style={{ marginLeft: "0.5vw", color: "#f16e30", textDecoration: "underline", fontSize: "0.8vw" }} href={t("titleContent.mintLink")} target="_blank">{t("titleContent.more")}</a>
                    </div>
                )}
                trigger={['click', 'hover']}
                placement='bottom'>
                <div style={{ display: "flex", gap: "0.5vw", alignItems: "center", justifyContent: "center" }}>{t("mint.mintingNFT")}<div className="price_info_ic"></div></div>
            </Dropdown>}
            closefun={handleClick}>
            <div className='mint-home-wrapper'>
                {content}
            </div>
        </PublicPopup>
            {buyVsys ? <BuyVsys closefun={() => { setBuyVsys(false) }} /> : ""}
        </>
    )
}

const mapStateToProps = (state) => ({
    mintNftTransaction: state.user.mintNftTransaction,
});
const mapDispatchToProps = { AddTransaction };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
import { postNftImage } from "../api";

// 1, 2, 3, 4, 5 ===> 一，二，三，四，五
const toChineseNum = (num) => {
    let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //changeNum[0] = "零"
    let unit = ["", "十", "百", "千", "万"];
    num = parseInt(num);
    let getWan = (temp) => {
        let strArr = temp.toString().split("").reverse();
        let newNum = "";
        for (var i = 0; i < strArr.length; i++) {
            newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
        }
        return newNum;
    }
    let overWan = Math.floor(num / 10000);
    let noWan = num % 10000;
    if (noWan.toString().length < 4) noWan = "0" + noWan;
    return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
}
// butter ===> Base64
const arrayBufferToBase64 = (buffer) => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

//probability fun

function getRand(obj) {
    this.obj = obj;
    return this.init();
}

getRand.prototype.sum = function (key) {
    var self = this;
    var obj = this.obj;
    var sum = 0;
    for (var i in obj) {
        sum += obj[i][key];
    }
    return sum;
};

getRand.prototype.init = function () {
    var result = null;
    var self = this;
    var obj = this.obj;
    var sum = this.sum('prob');
    for (var i in obj) {
        var rand = parseInt(Math.random() * sum);
        if (rand <= obj[i].prob) {
            result = obj[i];
            break;
        } else {
            sum -= obj[i].prob;
        }
    }
    return result;
};

// Testing prob data per 1000 times
// const probObj = [{
//     name: 'freeze',
//     prob: 10
// },
// {
//     name: 'acc',
//     prob: 90
// }
// ];
// var record = [];
// for (var i = 0; i < 1000; i++) {
//     var result = new getRand(probObj);
//     var index = false;
//     for (var j in record) {
//         if (record[j].name == result['name']) {
//             index = j;
//             break;
//         }
//     }
//     if (index !== false) {
//         record[index].num += 1;
//     } else {
//         record.push({ name: result['name'], num: 1 });
//     }
// }
// console.log(record);

// get img
const getImg = (id) => {
    return new Promise((resolve) => {
        postNftImage({ "tetrisNftId": id }).then((res) => {
            let url = ""
            if (res.data.byteLength > 100) {
                url = 'data:image/jpeg;base64,' + arrayBufferToBase64(res.data);
            }
            resolve(url)
        }).catch((err) => {
            console.log(err)
            resolve("")
        })
    })

}
function shuffleArray(arr) {
    // for (var i = arr.length - 1; i > 0; i--) {
    //     var j = Math.floor(Math.random() * (i + 1));
    //     [arr[i], arr[j]] = [arr[j], arr[i]];
    // }
    return arr
}
const cutString = (string, len) => {
    let str = string + ""
    if (str.length <= len * 2) {
        return str;
    }
    return str.substring(0, len) + '...'
        + str.substring(str.length - len, str.length)

}

const updateTickets = (time) => {
    var now = new Date();

    var lastClaimedTime = new Date(time);

    var dailyUpdateTime = new Date();
    dailyUpdateTime.setUTCHours(20, 0, 0, 0);
    dailyUpdateTime.setDate(now.getDate())

    if (now > dailyUpdateTime && lastClaimedTime < dailyUpdateTime) {
        return true
    } else {
        return false
    }

}
const checkWalletVersion = (str) => {
    let number = 0
    let array = []
    array = str.split(".")
    array.map((item, index) => {
        number = number + item * Math.pow(10, array.length - (index + 1))
    })
    if (number <= 100) {
        return false
    } else {
        return true
    }
}
// Time processing function
const processingTime = (str) => {
    try {
        const timeObj = new Date(str);
        return timeObj.getTime()
    } catch (error) {
        const timeObj = new Date()
        return timeObj.getTime()
    }
}


const keyCodeList = [{
    code: 81,
    key: "Q"
}, {
    code: 87,
    key: "W"
}, {
    code: 69,
    key: "E"
}, {
    code: 82,
    key: "R"
}, {
    code: 84,
    key: "T"
}, {
    code: 89,
    key: "Y"
}, {
    code: 85,
    key: "U"
}, {
    code: 73,
    key: "I"
}, {
    code: 79,
    key: "O"
}, {
    code: 80,
    key: "P"
}, {
    code: 65,
    key: "A"
}, {
    code: 83,
    key: "S"
}, {
    code: 68,
    key: "D"
}, {
    code: 70,
    key: "F"
}, {
    code: 71,
    key: "G"
}, {
    code: 72,
    key: "H"
}, {
    code: 74,
    key: "J"
}, {
    code: 75,
    key: "K"
}, {
    code: 76,
    key: "L"
}, {
    code: 90,
    key: "Z"
}, {
    code: 88,
    key: "X"
}, {
    code: 67,
    key: "C"
}, {
    code: 86,
    key: "V"
}, {
    code: 66,
    key: "B"
}, {
    code: 78,
    key: "N"
}, {
    code: 77,
    key: "M"
}, {
    code: 32,
    key: "SPACE"
}, {
    code: 37,
    key: "←"
}, {
    code: 38,
    key: "↑"
}, {
    code: 39,
    key: "→"
}, {
    code: 40,
    key: "↓"
},
]

const displayKey = (code) => {
    return keyCodeList[keyCodeList.findIndex((e) => e.code === code)].key || 'UNKNOW'
}

const callNetWork = () => {
    const MAIN_NET = "https://mainnetexplorer.v.systems/"
    const TEST_NET = "https://testnetexplorer.v.systems/"
    const network = process.env.REACT_APP_DEPLOY_NET
    if (network === "T") {
        return TEST_NET
    } else {
        return MAIN_NET
    }

}
export { toChineseNum, getRand, arrayBufferToBase64, getImg, shuffleArray, cutString, updateTickets, checkWalletVersion, keyCodeList, displayKey, processingTime, callNetWork }

import React, { useEffect, useState } from 'react';
import { StyledPlayersStage } from './styles/StyledPlayersStage';
import anime from 'animejs/lib/anime.es.js';

import Cell from './Cell';
import '../../scss/Game/Effects.scss'

const Stage = ({ stage, size, name }) => {
  const [rowsCleared, setRowsCleared] = useState(0);
  const [sweepExactRows, setSweepExactRows] = useState([]);
  const [isShowEffect, setIsShowEffect] = useState(false);

  const heightArr = [
    85, 16, 8.9, 6.1, 4.7, 3.8, 3.2, 2.75, 2.42, 2.16, 1.95, 1.77, 1.63, 1.51, 1.4, 1.31, 1.23, 1.16, 1.097, 1.04
  ]

  const shadowBlocks = ['IS', 'JS', 'LS', 'OS', 'SS', 'TS', 'ZS']



  const sweepRows = (newStage) => {
    let currentSweepExactRows = []
    let tmp = newStage.reduce((ack, row, idx) => {
      if (row.findIndex((cell) => cell[0] === 0) === -1) {
        setRowsCleared((prev) => prev + 1);
        ack.unshift(new Array(newStage[0].length).fill([0, "clear"]));
        currentSweepExactRows.push(idx)
        return ack;
      }
      ack.push(row);
      return ack;
    }, []);
    setSweepExactRows(currentSweepExactRows)
    return tmp
  }

  useEffect(() => {
    if (rowsCleared > 0) {
      setIsShowEffect(true)
      setTimeout(() => {
        setIsShowEffect(false)
      }, 400);
    }
  }, [rowsCleared])

  useEffect(() => {
    if (sweepExactRows.length > 0) {
    }
  }, [sweepExactRows])

  useEffect(() => {
    const newStage = stage.map((row) => row.map((cell) => ((shadowBlocks.findIndex(item => cell[0] === item) != -1) ? [0, "clear"] : cell)));
    setRowsCleared(0);
    // setSweepExactRows([])
    sweepRows(newStage)
  }, [stage])

  return (
    <StyledPlayersStage width={stage[0].length} height={stage.length} size={size}>
      {
        isShowEffect ? (<div className="pyro" style={{ width: `calc( ${size} / 2)`, height: `calc( ${size} /1)`, position: 'absolute', top: '50%' }}>
          <div className="before"></div>
          <div className="after"></div>
        </div>) : ''
      }
      {
        sweepExactRows.map((item, idx) => {
          const viewAnimation = anime({
            targets: `.light_player_${name + idx}`,
            easing: 'easeInOutQuad',
            // direction: 'alternate',
            duration: 400,
            opacity: .0,
            // loop: true
            autoplay: true
          });
          return (
            isShowEffect ? (<div className={`light_player_${name + idx}`} key={idx} style={{ width: `calc( ${size} /1.95)`, height: `calc( ${size} /20)`, background: '#97ccf6', position: 'absolute', bottom: `calc( (${size} / ${heightArr[19 - item]}  )  )`, left: '2%', }}></div>) : ''
          )
        })
      }
      {stage.map(row => row.map((cell, x) => <Cell key={x} type={cell[0]} isOtherPlayer={true} />))}
    </StyledPlayersStage>
  )
}

export default Stage;

import styles from './Index.module.scss'

const twinklingStars = () => {
    return <div className={styles.twinklingStars}>
        {/* {
            new Array(6).fill(false).map((item, index) => {
                return <div className={styles.star} key={index}></div>
            })
        } */}
        <div className={styles.stars1}></div>
        <div className={styles.stars2}></div>
        <div className={styles.stars3}></div>
    </div>
}

export default twinklingStars;

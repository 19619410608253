import styled from 'styled-components';
import panelBG from '../../../assets/icon/game_panel.png';

export const StyledStage = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.width}, 1fr);
  grid-template-rows: repeat(
    ${props => props.height},
    calc( ${props => props.size} / 2 / ${props => props.width})
  );
//   grid-gap: 1px ;
  height: calc( ${props => props.size} );
  width: calc( ${props => props.size} / 2 );
  position: relative;
  background: url(${panelBG});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 2% 2.3%;
  border-radius: 1.5%;
  -webkit-animation: ani 2s linear infinite;
  
  @keyframes ani {
    0%{
         box-shadow: 0 0 0px #B08AB1
    }
    25%{
         box-shadow: 0 0 10px #B08AB1
    }
     50%{
         box-shadow: 0 0 20px #B08AB1
    }
    75%{
         box-shadow: 0 0 10px #B08AB1
    }
    100%{
     box-shadow: 0 0 0px #B08AB1
    }
 }
`;

import { useEffect, useState } from 'react';
import { Button, Image } from 'antd';
import { t } from 'i18next';

import FlipClock from '../FlipClock/FlipClock';
import styles from '../../scss/Home/EventPromotionPopup.module.scss'
import closeBlackIcon from "../../assets/public_icon/close_black_icon.svg"

const EventPromotionPopup = ({ discountStartingTime, getDiscountInfo, discountEndingTime, setDiscountEnd, discountEnd, discountCopy, gotoMint }) => {
    const [show, setShow] = useState(true)

    useEffect(() => {
        // let EventPromotionTime = localStorage.getItem("EventPromotion")
        // if (EventPromotionTime && (EventPromotionTime / 1 + 24 * 60 * 60 * 1000 > new Date().getTime())) {
        //     setShow(false)
        // } else {
        //     setShow(true)
        // }
    }, [])

    const onclose = () => {
        // localStorage.setItem("EventPromotion", new Date().getTime())
        setShow(false)
    }

    return (
        <>
            {show && !discountEnd && discountCopy ?
                <div className={styles.event_promotion_popup}>
                    <div className={styles.event_promotion_popup_bg}>
                        <div className={styles.logo}></div>
                        <Button onClick={onclose} className={[styles.close_button]}>
                            <Image preview={false} src={closeBlackIcon} />
                        </Button>
                        <div className={styles.right}>
                            <div className={styles.right_bg}></div>
                            <div className={styles.right_content}>
                                {
                                    discountEndingTime ?
                                        <>
                                            <div className={styles.title}>{t("mint.genesisNFT")}</div>
                                            <div className={styles.subtitle}>{t("mint.expiresIn")}</div>
                                            <div className={styles.content}>{discountCopy * 100 + t("mint.limitedEdition")}</div>
                                            <div className={styles.content_limited}>
                                                <FlipClock endTime={new Date(discountEndingTime).getTime()} ended={() => { setDiscountEnd(true) }} />
                                            </div>
                                        </> : ""}
                                {discountStartingTime && !discountEndingTime ?
                                    <>
                                        <div className={styles.title}>{t("mint.genesisNFT")}</div>
                                        <div className={styles.subtitle}>{t("mint.comingSoon")}</div>
                                        <div className={styles.content}>{discountCopy * 100 + t("mint.limitedEdition")}</div>
                                        <div className={styles.content_limited}>
                                            <FlipClock endTime={new Date(discountStartingTime).getTime()} ended={() => { getDiscountInfo() }} />
                                        </div>
                                    </> : ""
                                }
                                <Button className='button_common primary_button' onClick={() => { gotoMint(); onclose() }} >{t("mint.link")}</Button>
                            </div>
                        </div>
                    </div>
                </div> : ""
            }
        </>
    )
}

export default EventPromotionPopup
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Switch, Image, Button } from 'antd';

import { SetSound } from '../../../redux/actions/localSettings/localActions';
import { openNotification } from '../../../tools/notification';
import { keyCodeList, displayKey } from '../../../tools/utils'

import PublicPopup from '../../../Components/PublicComponents/PublicPopup';
import './SoundSettings.scss';
import arrow from "../../../assets/public_icon/right_icon.svg";

function SoundSettings(props) {

    const { t, i18n } = useTranslation()

    const localSoundSettings = useSelector((state) => state.localSetting.soundSettings)
    const [page, setPage] = useState(0)
    const [music, setMusic] = useState(false)
    const [soundEffects, setSoundEffects] = useState(false)
    const [specialEffects, setSpecialEffects] = useState(false)
    const [disposalReminder, setDisposalReminder] = useState(false)
    const [selectedKey, setSelectedKey] = useState('')
    const [currentRotateLKey, setCurrentRotateLKey] = useState('')
    const [currentReverseKey, setCurrentReverseKey] = useState('')
    const [currentLeftKey, setCurrentLeftKey] = useState('')
    const [currentRightKey, setCurrentRightKey] = useState('')
    const [currentRotateKey, setCurrentRotateKey] = useState('')
    const [currentPushKey, setCurrentPushKey] = useState('')
    const [currentMomentumKey, setCurrentMomentumKey] = useState('')
    const [currentFreezeKey, setCurrentFreezeKey] = useState('')
    const [currentAddKey, setCurrentAddKey] = useState('')
    const [currentAccKey, setCurrentACcKey] = useState('')
    const [pageType, setPageType] = useState('lang')
    const [pageLang, setPageLang] = useState('en')


    useEffect(() => {
        if (page === 0) {
            setMusic(localSoundSettings.music || false)
            setSoundEffects(localSoundSettings.soundEffects || false)
            setSpecialEffects(localSoundSettings.specialEffects || false)
            setDisposalReminder(localSoundSettings.disposalReminder || false)
            setCurrentRotateLKey(localSoundSettings.customKeyCode ? (localSoundSettings.customKeyCode.rotateL ? localSoundSettings.customKeyCode.rotateL : 90) : 90)
            setCurrentReverseKey(localSoundSettings.customKeyCode ? (localSoundSettings.customKeyCode.reverse ? localSoundSettings.customKeyCode.reverse : 67) : 67)
            setCurrentLeftKey(localSoundSettings.customKeyCode ? (localSoundSettings.customKeyCode.left ? localSoundSettings.customKeyCode.left : 37) : 37)
            setCurrentRightKey(localSoundSettings.customKeyCode ? (localSoundSettings.customKeyCode.right ? localSoundSettings.customKeyCode.right : 39) : 39)
            setCurrentRotateKey(localSoundSettings.customKeyCode ? (localSoundSettings.customKeyCode.rotate ? localSoundSettings.customKeyCode.rotate : 38) : 38)
            setCurrentPushKey(localSoundSettings.customKeyCode ? (localSoundSettings.customKeyCode.push ? localSoundSettings.customKeyCode.push : 40) : 40)
            setCurrentMomentumKey(localSoundSettings.customKeyCode ? (localSoundSettings.customKeyCode.momentum ? localSoundSettings.customKeyCode.momentum : 32) : 32)
            setCurrentFreezeKey(localSoundSettings.customKeyCode ? (localSoundSettings.customKeyCode.freeze ? localSoundSettings.customKeyCode.freeze : 81) : 81)
            setCurrentAddKey(localSoundSettings.customKeyCode ? (localSoundSettings.customKeyCode.add ? localSoundSettings.customKeyCode.add : 87) : 87)
            setCurrentACcKey(localSoundSettings.customKeyCode ? (localSoundSettings.customKeyCode.acc ? localSoundSettings.customKeyCode.acc : 69) : 69)

            setPageLang(localStorage.getItem("language"))
        }
    }, [page])


    const onClose = (type) => {
        props.onClose()
    }

    const onBack = () => {
        setSelectedKey('')
        setPage(page - 1)
    }

    const onClick = (type, pageType) => {
        if (type === "ok") {
            const customKeyCode = {
                left: currentLeftKey,
                right: currentRightKey,
                rotate: currentRotateKey,
                push: currentPushKey,
                rotateL: currentRotateLKey,
                reverse: currentReverseKey,
                momentum: currentMomentumKey,
                freeze: currentFreezeKey,
                add: currentAddKey,
                acc: currentAccKey
            }
            SetSound({ music, soundEffects, specialEffects, disposalReminder, customKeyCode })
            openNotification(t("commonTips.saveSuccess"))
            onClose()

        } else if (type === "cancle") {
            setPage(page + 1)
            setPageType(pageType)
        }
    }

    const setLanguage = () => {
        i18n.changeLanguage(pageLang)
        localStorage.setItem("language", pageLang)
        openNotification(t("commonTips.saveSuccess"))
        onClose()
    }

    const checkKeyCode = (code) => {
        return keyCodeList.some((e) => e.code === code)
    }


    const listeningKeyBoard = (event) => {
        setSelectedKey((e) => {
            if (e && checkKeyCode(event.keyCode)) {
                if (e === 'left') {
                    setCurrentLeftKey(event.keyCode)
                } else if (e === 'right') {
                    setCurrentRightKey(event.keyCode)
                } else if (e === 'rotate') {
                    setCurrentRotateKey(event.keyCode)
                } else if (e === 'rotateL') {
                    setCurrentRotateLKey(event.keyCode)
                } else if (e === 'reverse') {
                    setCurrentReverseKey(event.keyCode)
                } else if (e === 'push') {
                    setCurrentPushKey(event.keyCode)
                } else if (e === 'momentum') {
                    setCurrentMomentumKey(event.keyCode)
                } else if (e === 'freeze') {
                    setCurrentFreezeKey(event.keyCode)
                } else if (e === 'add') {
                    setCurrentAddKey(event.keyCode)
                } else if (e === 'acc') {
                    setCurrentACcKey(event.keyCode)
                }
            }
            return e
        })
    }

    const saveCustomKey = () => {
        const keyList = [currentLeftKey, currentRightKey, currentRotateKey, currentRotateLKey, currentReverseKey, currentPushKey, currentMomentumKey, currentFreezeKey, currentAddKey, currentAccKey]
        if (Array.from(new Set(keyList)).length === keyList.length) {
            onClick('ok')
        } else {
            openNotification(t("commonTips.keyConflict"))
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", listeningKeyBoard);

        return () => {
            document.removeEventListener("keydown", listeningKeyBoard)
        }
    }, [])

    return (
        <div>
            {page === 0 ?
                <PublicPopup title={t("soundSettings.setting")} closefun={onClose}>
                    <div className="settings_content">
                        <div className="setting_list">
                            <div className="setting">
                                <div>{t("soundSettings.music")}</div>
                                <div className="setting_switch">
                                    <Switch className="switch_st" checked={music} onChange={(e) => { setMusic(e) }} />
                                </div>
                            </div>
                            <div className="setting">
                                <div>{t("soundSettings.soundEffects")}</div>
                                <div className="setting_switch">
                                    <Switch checked={soundEffects} onChange={(e) => { setSoundEffects(e) }} />
                                </div>
                            </div>
                            <div className="setting">
                                <div>{t("soundSettings.specialEffects")}</div>
                                <div className="setting_switch">
                                    <Switch checked={specialEffects} onChange={(e) => { setSpecialEffects(e) }} />
                                </div>
                            </div>
                            <div className="setting">
                                <div>{t("soundSettings.diaposalReminder")}</div>
                                <div className="setting_switch">
                                    <Switch checked={disposalReminder} onChange={(e) => { setDisposalReminder(e) }} />
                                </div>
                            </div>
                            <div className="setting">
                                <div>{t("soundSettings.language")}</div>
                                <div className="setting_switch">
                                    <div className="language">{i18n.language === 'cn' ? '中文' : 'English'}</div>
                                    <Button onClick={() => onClick('cancle', 'lang')} className='arrow_button arrow_right'>
                                        <Image preview={false} height={"80%"} src={arrow} />
                                    </Button>
                                </div>
                            </div>
                            <div className="setting">
                                <div>{t("soundSettings.keyPosition")}</div>
                                <div className="setting_switch">
                                    <Button onClick={() => onClick('cancle', 'key')} className='arrow_button arrow_right'>
                                        <Image preview={false} height={"80%"} src={arrow} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className='opt_btn'>
                            <div className='button_common primary_button' onClick={() => onClick('ok')}>{t("soundSettings.save")}</div>
                            <div className='button_common secondary_button' onClick={() => onClose()}>{t("soundSettings.cancel")}</div>
                        </div>
                    </div>
                </PublicPopup> :
                <PublicPopup isOverlay title={pageType === "lang" ? t("soundSettings.language") : t("soundSettings.keyPosition")} closefun={onClose} backfun={onBack}>
                    {
                        pageType === 'lang' ? (
                            <div className="settings_content">
                                <div className="setting_list">
                                    <div onClick={() => setPageLang('cn')} className={i18n.language === 'cn' ? "setting setting_active" : "setting"}>
                                        <div>中文</div>
                                        <Button className={pageLang == 'cn' ? "selected_button" : "unselected_button"}></Button>
                                    </div>
                                    <div onClick={() => setPageLang('en')} className={i18n.language === 'en' ? "setting setting_active" : "setting"}>
                                        <div>English</div>
                                        <Button className={pageLang !== 'cn' ? "selected_button" : "unselected_button"}></Button>
                                    </div>
                                </div>
                                <div className="opt_btn opt_btn_center">
                                    <div className="button_common primary_button" onClick={() => setLanguage()}>{t('soundSettings.save')}</div>
                                </div>
                            </div>
                        ) : (
                            <div className="settings_content">
                                <div className="user_custom_key">
                                    <div className="keys_box">
                                        <div className="keys_container" onClick={() => setSelectedKey('rotateL')}>
                                            <div className="all_key">{displayKey(currentRotateLKey)}</div>
                                            <div className="keys_text">{t('soundSettings.rotateL')}</div>
                                        </div>
                                        <div className="keys_container" onClick={() => setSelectedKey('rotate')}>
                                            <div className="all_key">{displayKey(currentRotateKey)}</div>
                                            <div className="keys_text">{t('soundSettings.rotateR')}</div>
                                        </div>
                                        <div className="keys_container" onClick={() => setSelectedKey('reverse')}>
                                            <div className="all_key">{displayKey(currentReverseKey)}</div>
                                            <div className="keys_text">180°</div>
                                        </div>
                                    </div>
                                    <div className="keys_box">
                                        <div className="keys_container" onClick={() => setSelectedKey('left')}>
                                            <div className="all_key">{displayKey(currentLeftKey)}</div>
                                            <div className="keys_text">{t('soundSettings.left')}</div>
                                        </div>
                                        <div className="keys_container" onClick={() => setSelectedKey('push')}>
                                            <div className="all_key">{displayKey(currentPushKey)}</div>
                                            <div className="keys_text">{t('soundSettings.push')}</div>
                                        </div>
                                        <div className="keys_container" onClick={() => setSelectedKey('right')}>
                                            <div className="all_key">{displayKey(currentRightKey)}</div>
                                            <div className="keys_text">{t('soundSettings.right')}</div>
                                        </div>
                                    </div>
                                    <div className="keys_box">
                                        <div className="keys_container" onClick={() => setSelectedKey('momentum')}>
                                            <div className="all_key space_key">{displayKey(currentMomentumKey)}</div>
                                            <div className="keys_text">{t('soundSettings.momentum')}</div>
                                        </div>
                                    </div>
                                    <div className="keys_box">
                                        <div className="keys_container" onClick={() => setSelectedKey('freeze')}>
                                            <div className="all_key">{displayKey(currentFreezeKey)}</div>
                                            <div className="keys_text">{t('soundSettings.freeze')}</div>
                                        </div>
                                        <div className="keys_container" onClick={() => setSelectedKey('add')}>
                                            <div className="all_key">{displayKey(currentAddKey)}</div>
                                            <div className="keys_text">{t('soundSettings.add')}</div>
                                        </div>
                                        <div className="keys_container" onClick={() => setSelectedKey('acc')}>
                                            <div className="all_key">{displayKey(currentAccKey)}</div>
                                            <div className="keys_text">{t('soundSettings.acc')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="opt_btn opt_btn_center">
                                    <div className="button_common primary_button" onClick={() => saveCustomKey()}>{t('soundSettings.save')}</div>
                                </div>
                            </div>
                        )
                    }
                </PublicPopup>
            }
        </div>

    )
}

export default SoundSettings
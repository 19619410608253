import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { t } from 'i18next';

import { AddTransaction, MoveTransaction, MoveVSYSTransaction, AddVSYSTransaction } from '../../redux/actions/user/userActions';
import { postGuestNftMintStatus, transactionStatus } from '../../api/index'
import { openNotification } from '../../tools/notification'
import { viewCode } from '../../tools/viewCode'

import "../../scss/PublicComponentsStyle/MintNFTMessage.scss"

let time = null
const MintNFTMessage = (props) => {

    const [api, contextHolder] = notification.useNotification({
        getContainer: () => document.getElementById("Message"),
        stack: false
    });

    useEffect(() => {
        return () => {
            clearTimeout(time)
        }
    }, [])

    const failedGoto = () => {
        props.failed()
    }

    const successGoto = () => {
        props.success()
    }

    const myNftGoto = () => {
        props.myNft()
    }

    useEffect(() => {
        if (!props.type && props.mintNftTransaction && props.mintNftTransaction.length > 0) {
            let transactionAll = []
            props.mintNftTransaction.map((item) => {
                if (item.type === "pending") {
                    mintingMessage(item.key, item.transaction.length, item.transaction.length, item.isFree || false)
                    transactionAll = [...transactionAll, ...item.transaction]
                } else if (item.type === "success") {
                    successMessage(item.key, item.number, item.isFree || false)
                } else if (item.type === "failed") {
                    failedMessage(item.key, item.number)
                }
            })
            if (transactionAll.length > 0) {
                updateAllNftState(transactionAll)
            }
        }
    }, [props.mintNftTransaction])

    const mintingMessage = (key, total, wait, isFree) => {
        api.open({
            key,
            message: <div><b>{t("mint.minting")}</b>{" " + total - wait + " / " + total + " "}Nfts</div>,
            description: isFree ? <div>{t("mint.passable")}<a onClick={myNftGoto}>{t("mint.goToMyNft")}</a> {t("mint.viewTheMintedNFT")}</div>
                : <div>{t("mint.passable")}<a onClick={successGoto}>{t("mint.goToTransferInNft")}</a> {t("mint.viewTheMintedNFT")}</div>,
            duration: 0,
            bottom: 200,
            placement: "bottomRight",
            className: "mint_message",
            icon: <div className='mintingIcon'></div>,
            closeIcon: <div className='closeIcon' onClick={() => { notificationClose(key, "mint") }}></div>,
        });
    }
    const successMessage = (key, success, isFree) => {
        api.open({
            key,
            message: <div><b>{t("mint.success")}</b>{" " + success + " "}Nfts</div>,
            description: isFree ? <div>{t("mint.passable")}<a onClick={myNftGoto}>{t("mint.goToMyNft")}</a> {t("mint.viewTheMintedNFT")}</div>
                : <div>{t("mint.onclick")} <a onClick={successGoto}>{t("mint.goToTransferInNft")}</a> {t("mint.transferNFT")}</div>,
            duration: 0,
            bottom: 200,
            placement: "bottomRight",
            className: "mint_message",
            icon: <div className='successIcon'></div>,
            closeIcon: <div className='closeIcon' onClick={() => { notificationClose(key) }}></div>,
        });
    }
    const failedMessage = (key, failed) => {
        api.open({
            key,
            message: <div><b>{t("mint.failed")}</b>{" " + failed + " "}Nfts</div>,
            description: <div>{t("mint.onclick")} <a onClick={failedGoto}>{t("mint.goToIncomeRecords")}</a> {t("mint.checkFailure")}</div>,
            duration: 0,
            bottom: 200,
            placement: "bottomRight",
            className: "mint_message",
            icon: <div className='failedIcon'></div>,
            closeIcon: <div className='closeIcon' onClick={() => { notificationClose(key) }}></div>,
        });
    }
    const notificationClose = (key, type) => {
        if (type === "mint") {
            clearTimeout(time)
        }
        props.MoveTransaction(key)
    }

    const updateAllNftState = (ids) => {
        clearTimeout(time)
        time = setTimeout(() => {
            transactionStatus({ ids }).then((res) => {
                if (res.data.code) {
                    return openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                } else {
                    let transactionArray = res.data.data.transactionStatusDetails
                    // 分发到每个任务上单独展示
                    props.mintNftTransaction.map((item) => {
                        if (item.type === "pending") {
                            let transactionResult = []
                            item.transaction.map((ite) => {
                                transactionResult.push(transactionArray.find((tem) => ite === tem.transactionId))
                            })
                            viewNftState(transactionResult, item.key, item.isFree || false)
                        }
                    })
                    // 存在没完成的任务，继续执行请求
                    let waiting = transactionArray.filter((item) => {
                        return (item.status === "Minting" || item.status === "Pending")
                    })
                    if (waiting.length > 0) {
                        return updateAllNftState(ids)
                    }
                }
            }).catch((error) => {
                return openNotification(t("responseCode.networkFailed"))
            })
        }, 6000);
    }

    const viewNftState = (idsResult, key, isFree) => {
        let success = idsResult.filter((item) => {
            return (item.status === "Locked" || item.status === "Success")
        })
        let failed = idsResult.filter((item) => {
            return item.status === "Failed"
        })
        let waiting = idsResult.filter((item) => {
            return (item.status === "Minting" || item.status === "Pending")
        })
        if (waiting.length > 0) {
            mintingMessage(key, idsResult.length, waiting.length, isFree)
        }
        if (failed.length + success.length === idsResult.length) {
            api.destroy(key)
            props.MoveTransaction(key)
            if (success.length > 0) {
                props.AddTransaction({ type: "success", key: key + "success", number: success.length, isFree })
            }
            if (failed.length > 0) {
                props.AddTransaction({ type: "failed", key: key + "failed", number: failed.length, isFree })
            }
        }
    }

    useEffect(() => {
        if (props.mintNftVSYSTransaction && props.mintNftVSYSTransaction.length > 0) {
            let transactionAll = []
            props.mintNftVSYSTransaction.map((item) => {
                if (item.type === "pending" || item.type === "refunding") {
                    if (item.type === "pending") {
                        mintingVSYSMessage(item.key, item.transaction.length, item.transaction.length)
                    } else {
                        refundingVSYSMessage(item.key, item.transaction.length, item.transaction.length)
                    }
                    transactionAll = [...transactionAll, ...item.transaction]
                } else if (item.type === "success") {
                    successVSYSMessage(item.key, item.number)
                } else if (item.type === "failed") {
                    failedVSYSMessage(item.key, item.number)
                } else if (item.type === "refunded") {
                    refundedVSYSMessage(item.key, item.number)
                }
            })
            if (transactionAll.length > 0) {
                updateAllNftVSYSState(transactionAll)
            }
        }
    }, [props.mintNftVSYSTransaction])

    const mintingVSYSMessage = (key, total, wait) => {
        api.open({
            key,
            message: props.type === "VSYS" ?
                <div style={{ textAlign: "left" }}><b>{t("mint.minting")}</b>{" " + total - wait + " / " + total + " "}Nfts</div>
                : <div><b>{t("mint.minting")}</b>{" " + total - wait + " / " + total + " "}Nfts</div>,
            description: props.type === "VSYS" ?
                <div>{t("mint.mintingVSYSInfo")}</div>
                : <div>{t("mint.passable")}<a onClick={successGoto}>{t("mint.goToTransferInNft")}</a> {t("mint.viewTheMintedNFT")}</div>,
            duration: 0,
            bottom: 200,
            placement: "bottomRight",
            className: "mint_message",
            icon: <div className='mintingIcon'></div>,
            closeIcon: <div className='closeIcon' onClick={() => { notificationVSYSClose(key, "mint") }}></div>,
        });
    }
    const successVSYSMessage = (key, success) => {
        api.open({
            key,
            message: props.type === "VSYS" ?
                <div style={{ textAlign: "left" }}><b>{t("mint.success")}</b>{" " + success + " "}Nfts</div>
                : <div><b>{t("mint.success")}</b>{" " + success + " "}Nfts</div>,
            description: props.type === "VSYS" ?
                <div>{t("mint.mintingVSYSSuccess")}</div>
                : <div>{t("mint.onclick")} <a onClick={successGoto}>{t("mint.goToTransferInNft")}</a> {t("mint.transferNFT")}</div>,
            duration: 0,
            bottom: 200,
            placement: "bottomRight",
            className: "mint_message",
            icon: <div className='successIcon'></div>,
            closeIcon: <div className='closeIcon' onClick={() => { notificationVSYSClose(key) }}></div>,
        });
    }
    const failedVSYSMessage = (key, failed) => {
        api.open({
            key,
            message: <div style={{ textAlign: "left" }}><b>{t("mint.failed")}</b>{" " + failed + " "}Nfts</div>,
            description: <div>{t("mint.mintingVSYSFailed")}</div>,
            duration: 0,
            bottom: 200,
            placement: "bottomRight",
            className: "mint_message",
            icon: <div className='failedIcon'></div>,
            closeIcon: <div className='closeIcon' onClick={() => { notificationVSYSClose(key) }}></div>,
        });
    }
    const refundingVSYSMessage = (key, total, wait) => {
        api.open({
            key,
            message: <div style={{ textAlign: "left" }}><b>{t("mint.refunding")}</b>{" " + total - wait + " / " + total + " "}Nfts</div>,
            description: <div>{t("mint.mintingVSYSRefunding")}</div>,
            duration: 0,
            bottom: 200,
            placement: "bottomRight",
            className: "mint_message",
            icon: <div className='failedIcon'></div>,
            closeIcon: <div className='closeIcon' onClick={() => { notificationVSYSClose(key, "mint") }}></div>,
        });
    }
    const refundedVSYSMessage = (key, failed) => {
        api.open({
            key,
            message: <div style={{ textAlign: "left" }}><b>{t("mint.failed")}</b>{" " + failed + " "}Nfts</div>,
            description: <div>{t("mint.mintingVSYSRefunded")}</div>,
            duration: 0,
            bottom: 200,
            placement: "bottomRight",
            className: "mint_message",
            icon: <div className='failedIcon'></div>,
            closeIcon: <div className='closeIcon' onClick={() => { notificationVSYSClose(key) }}></div>,
        });
    }
    const updateAllNftVSYSState = (ids) => {
        clearTimeout(time)
        time = setTimeout(() => {
            postGuestNftMintStatus({ guestNFTMintingIds: ids }).then((res) => {
                if (res.data.code) {
                    return openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                } else {
                    let transactionArray = [...res.data.data.guestNFTMintingStatusDetails]
                    // 分发到每个任务上单独展示
                    props.mintNftVSYSTransaction.map((item) => {
                        if (item.type === "pending" || item.type === "refunding") {
                            let transactionResult = []
                            item.transaction.map((ite) => {
                                transactionResult.push(transactionArray.find((tem) => ite === tem.guestNFTMintingId))
                            })
                            viewNftVSYSState(transactionResult, item.key, item.type)
                        }
                    })
                    // 存在没完成的任务，继续执行请求
                    let waiting = transactionArray.filter((item) => {
                        return (item.guestNFTMintingStatus === "Minting" || item.guestNFTMintingStatus === "Refunding" ||
                            item.guestNFTMintingStatus === "TryRefund" || item.guestNFTMintingStatus === "Transferring" ||
                            item.guestNFTMintingStatus === "Paying")
                    })
                    if (waiting.length > 0) {
                        return updateAllNftVSYSState(ids)
                    }
                }
            }).catch((error) => {
                return openNotification(t("responseCode.networkFailed"))
            })
        }, 6000);
    }
    const viewNftVSYSState = (idsResult, key, type) => {
        let success = idsResult.filter((item) => {
            return (item.guestNFTMintingStatus === "Success")
        })
        let failed = idsResult.filter((item) => {
            return item.guestNFTMintingStatus === "Failed"
        })
        let refunded = idsResult.filter((item) => {
            return item.guestNFTMintingStatus === "Refunded"
        })
        let waiting = idsResult.filter((item) => {
            return (item.guestNFTMintingStatus === "Minting" || item.guestNFTMintingStatus === "Refunding" ||
                item.guestNFTMintingStatus === "TryRefund" || item.guestNFTMintingStatus === "Transferring" ||
                item.guestNFTMintingStatus === "Paying")
        })
        if (waiting.length > 0) {
            if (type !== "refunding") {
                mintingVSYSMessage(key, idsResult.length, waiting.length)
            }
        }
        if (failed.length + success.length + refunded.length === idsResult.length) {
            api.destroy(key)
            props.MoveVSYSTransaction(key)
            if (success.length > 0) {
                props.AddVSYSTransaction({ type: "success", key: key + "success", number: success.length })
            }
            if (failed.length > 0) {
                props.AddVSYSTransaction({ type: "failed", key: key + "failed", number: failed.length })
            }
            if (refunded.length > 0) {
                props.AddVSYSTransaction({ type: "refunded", key: key + "refunded", number: refunded.length })
            }
        }
    }

    const notificationVSYSClose = (key, type) => {
        if (type === "mint") {
            clearTimeout(time)
        }
        props.MoveVSYSTransaction(key)
    }

    return <div id="Message">{contextHolder}</div>

};
const mapStateToProps = (state) => ({
    mintNftTransaction: state.user.mintNftTransaction,
    mintNftVSYSTransaction: state.user.mintNftVSYSTransaction,
});
const mapDispatchToProps = { AddTransaction, MoveTransaction, AddVSYSTransaction, MoveVSYSTransaction };

export default connect(mapStateToProps, mapDispatchToProps)(MintNFTMessage);
import { React, useEffect, useRef, useState } from 'react'
import { Button, Dropdown, Image, Input, Modal, Spin, Divider, List, Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import { t } from 'i18next'


import { postItemValid, postWheelRecords, postWheelUserRecords, withdrawToken } from '../../../api/index'
import { openNotification } from '../../../tools/notification'
import { viewCode } from '../../../tools/viewCode'
import { TOKENNAME } from '../../../tools/gameConfig'

import CommonDialogSmall from '../../PublicComponents/CommonDialogSmall'
import PublicPopup from '../../PublicComponents/PublicPopup'
import SuperPrizeGrid from './SuperPrizeGrid'
import NoData from '../../PublicComponents/NoData';
import styles from '../../../scss/Hall/PersonalCenter/TransferOutToken.module.scss'
import BackIcon from '../../../assets/public_icon/left_icon.svg'
import settingsIcon from "../../../assets/hall/ic_settings.png"
import cashIcon from "../../../assets/hall/cash_icon.png"
import gifIcon from '../../../assets/hall/gif_icon.png'
import OpenEye from "../../../assets/web/open_eye.png"
import OffEye from "../../../assets/web/off_eye.png"

export const TransferOutToken = ({ obtainCoupons, TET2USD, tetrisToken, tetrisTokenLocked, email, forgetPwd, updateUserInfo }) => {

    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)
    const [withdrawAmount, setWithdrawAmount] = useState(0)
    const [idList, setIdList] = useState([])

    const gotoStep2 = (amount, IdList) => {
        setIdList(IdList)
        if (amount > 0) {
            setWithdrawAmount(((amount / 1).toFixed(4)) / 1)
            setStep2(true)
            setStep1(false)
        }
    }

    const backStep1 = () => {
        setStep1(true)
        setStep2(false)
        setWithdrawAmount(0)
    }

    let content = ""
    if (step1) {
        content = <TransferOutTokenStep1 TET2USD={TET2USD} tetrisToken={tetrisToken} tetrisTokenLocked={tetrisTokenLocked} gotoStep2={gotoStep2} obtainCoupons={obtainCoupons} />
    } else if (step2) {
        content = <TransferOutTokenStep2 tokenAmount={withdrawAmount} idList={idList} email={email} forgetPwd={forgetPwd} updateUserInfo={updateUserInfo} backStep1={backStep1} />
    }

    return (
        <div className={styles.transfer_out_token}>
            <div className={styles.transfer_out_token_step}>
                {content}
            </div>
            <LuckyDraw updateUserInfo={updateUserInfo} tetrisToken={tetrisToken} />
        </div>
    )
}


const TransferOutTokenStep1 = ({ TET2USD, tetrisToken, tetrisTokenLocked, gotoStep2, obtainCoupons }) => {
    const total = tetrisToken !== "..." && (tetrisToken - tetrisTokenLocked) > 0 ? Math.floor((tetrisToken - tetrisTokenLocked) * 10000) / 10000 : 0
    const [value, setValue] = useState('')
    const [balanceErr, setBalanceErr] = useState(false)
    const amount = useRef(null)
    const [cashSelect, setCashSelect] = useState(false)
    const [cashSelected, setCashSelected] = useState(0)
    const [cashSelectedIdList, setCashSelectedIdList] = useState([])
    const [isOpenCashSetting, setIsOpenCashSetting] = useState(false)
    const [fee, setFee] = useState(30)
    const [disFee, setDisFee] = useState(30)

    const [cashPage, setCashPage] = useState(0)
    const [cashTotal, setCashTotal] = useState(0)
    const [cashLoading, setCashLoading] = useState(false)
    const [cashList, setCashList] = useState([])


    const transferAll = () => {
        amount.current.value = total
        setValue(total)
    }

    const valueChange = (val) => {
        if (val > 0) {
            setValue(val);
            if (val > total) {
                setBalanceErr(true)
            } else {
                setBalanceErr(false)
            }
        } else {
            setValue('');
        }
    }

    const openCash = () => {
        setCashSelect(!cashSelect)
    }

    const openCashSetting = () => {
        setIsOpenCashSetting(!isOpenCashSetting)
        if (!isOpenCashSetting) { setCashSelect(true) }
    }

    const cheakCash = (index) => {
        let newCashList = cashList
        newCashList[index].selected = !(newCashList[index]?.selected || false)
        setCashList([...newCashList])
    }

    const saveCash = () => {
        let idList = []
        cashList.map((item) => {
            if (item.selected) idList.push(item.ID)
        })
        setCashSelectedIdList(idList)
        setCashSelected(idList.length)
        setIsOpenCashSetting(false)
    }

    const goto = () => {
        if (total <= 0 || balanceErr || value <= 0) { return }
        gotoStep2(value, cashSelectedIdList)
    }

    useEffect(() => {
        if (cashSelect) {
            setIsOpenCashSetting(true)
        }
    }, [cashSelect])

    useEffect(() => {
        if (!isOpenCashSetting) { return }
        setCashSelected(0)
        loadMoreCashList(1)
        setCashSelectedIdList([])
    }, [isOpenCashSetting])

    const loadMoreCashList = (page) => {
        if (cashLoading) { return }
        setCashLoading(true)
        let newPage = page || cashPage + 1
        setCashPage(newPage)
        postItemValid({
            "userItemType": "WithdrawFeeDiscount",
            "pagination": {
                "page": newPage,
                "size": 10
            }
        }).then((res) => {
            setCashLoading(false)
            if (res.data.data) {
                if (newPage === 1) {
                    setCashList([...res.data.data?.userItems])
                } else {
                    setCashList([...cashList, ...res.data.data?.userItems])
                }
                setCashTotal(res.data.data?.pagination?.total)
            }
        }).catch((err) => {
            console.log(err)
            setCashLoading(false)
        })
    }

    // update fee 
    useEffect(() => {
        let newFee = 30
        if (TET2USD) {
            if (value / 1 >= (10 / TET2USD).toFixed(2)) { newFee = 1 }
            else if (value / 1 >= (3 / TET2USD).toFixed(2)) { newFee = 10 }
        }
        setFee(newFee)

        if (cashSelect && cashSelected) {
            newFee = newFee - cashSelected > 15 ? newFee - cashSelected : 16
        }
        setDisFee(newFee)

    }, [value, cashSelect, cashSelected, TET2USD])

    return (
        <div className={styles.transfer_out_token_step1}>
            <div className={styles.price}>
                <div className={styles.price_div}>
                    <div className='price_ic'></div>
                    <div>
                        <div className={styles.price_usd}>{total ? "$" + (total * TET2USD).toFixed(2) : "$ 0.00"}</div>
                        <div className={styles.price_tet}>{total ? total + " " + TOKENNAME : "0.00 TET"}</div>
                    </div></div>
                <div className={styles.all} onClick={transferAll}>{t("personalCenter.withdrawalAll")}</div>
            </div>
            <div className={styles.price_input}>
                <div className={styles.input_div}>
                    <Input className={styles.form_input} onChange={(e) => { valueChange(Number(e.target.value)) }} value={value} ref={amount} type='number' placeholder='Amount' />
                    <div className={styles.form_input_info}>
                        <span>{t("personalCenter.pleaseEnterValue")}</span>
                        {balanceErr ?
                            <span className={styles.info_error}>{t("personalCenter.tokenInsufficientError")}</span>
                            : <span>≈${(value * TET2USD).toFixed(2)}</span>}
                    </div>
                </div>
                <div className={styles.cash}>
                    <div className={styles.cash_left} onClick={() => { openCash() }}>
                        <Button className={cashSelect ? "selected_button" : "unselected_button"}></Button>
                        {cashSelect ? <span>{t("personalCenter.cashWithdrawCoupon")}</span> : t("personalCenter.cashWithdrawCoupon")}
                    </div>
                    <div className={styles.cash_right} onClick={() => { openCashSetting() }} >
                        <span>{cashSelect ? cashSelected + t("personalCenter.cashbackVouchersSelected") + (fee - disFee) + t("personalCenter.feeWaived") : t("personalCenter.noCashbackVoucherSelected")}</span>
                        <Image src={settingsIcon} preview={false} className={styles.setting_icon} />
                    </div>
                </div>
                <div className={styles.locked}>{t("personalCenter.lockedAsset") + (tetrisTokenLocked || 0).toFixed(2)} {TOKENNAME}</div>
                <div className={styles.line}></div>
                <div className={styles.fee}><span>{t("personalCenter.withdrawFee") + ": " + disFee}%</span>
                    <Dropdown
                        dropdownRender={(menu) => (
                            <div style={{ background: "#40077e", whiteSpace: "pre-line", borderRadius: "1vw", width: "10vw", height: "auto", color: "#fff", fontFamily: "Noto Sans", padding: "1.5vw", boxSizing: "border-box", fontSize: "0.78vw" }}>
                                {t("personalCenter.withdrawFee")}
                                <div style={{ fontSize: "0.7vw", height: "2vw", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <span>{t("personalCenter.threshold")}</span>
                                    <span>{t("personalCenter.fee")}</span>
                                </div>
                                <div style={{ width: "100%", height: "0.05vw", background: "#fff" }}></div>
                                <div style={{ fontSize: "0.7vw", height: "1.5vw", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <span>{TET2USD ? (10 / TET2USD).toFixed(2) + TOKENNAME : "10 U"}</span>
                                    <span>1%</span>
                                </div>
                                <div style={{ fontSize: "0.7vw", height: "1.5vw", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <span>{TET2USD ? (3 / TET2USD).toFixed(2) + TOKENNAME : "3 U"}</span>
                                    <span>10%</span>
                                </div>
                                <div style={{ fontSize: "0.7vw", height: "1.5vw", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <span>{TET2USD ? (0 / TET2USD).toFixed(2) + TOKENNAME : "0 U"}</span>
                                    <span>30%</span>
                                </div>
                            </div>
                        )}
                        trigger={['click', 'hover']}
                        placement="bottom"
                    >
                        <div className='price_info_ic'></div>
                    </Dropdown>
                </div>
            </div>
            <div className={styles.bt_div}>
                <div className={total <= 0 || balanceErr || value <= 0 ? "button_common disabled_button" : "button_common primary_button"} onClick={goto}>{t("user.next")}</div>
            </div>
            {isOpenCashSetting ?
                <PublicPopup isOverlay title={t("personalCenter.cashWithdrawCoupon")} closefun={openCashSetting} >
                    <div className={styles.cash_setting}>
                        <div
                            id="scrollableDiv"
                            style={{
                                overflow: 'auto',
                            }}
                            className={styles.cash_setting_items}
                        >
                            <InfiniteScroll
                                dataLength={cashList.length}
                                next={loadMoreCashList}
                                hasMore={cashList.length < cashTotal}
                                loader={
                                    <Skeleton
                                        avatar
                                        paragraph={{
                                            rows: 1,
                                        }}
                                        active
                                    />
                                }
                                endMessage={<Divider plain><div className={styles.list_end}>{cashTotal ? t("personalCenter.listEnd") : ""}</div></Divider>}
                                scrollableTarget="scrollableDiv"
                            >
                                <List
                                    dataSource={cashList}
                                    locale={{ emptyText: <NoData /> }}
                                    loading={cashLoading}
                                    split={false}
                                    renderItem={(item, index) => (
                                        <List.Item key={index} className={styles.cash_setting_item}>
                                            <div className={styles.cash_item_left}>
                                                <Image src={cashIcon} preview={false} className={styles.cash_icon} />
                                                <div className={styles.cash_item_discount}>
                                                    <span>{t("personalCenter.cash")}</span>
                                                    1% {t("personalCenter.discount")}
                                                </div>
                                            </div>
                                            <div onClick={() => { cheakCash(index) }} className={item.selected ? "checked_button" : "unchecked_button"}></div>
                                        </List.Item>
                                    )}
                                />
                            </InfiniteScroll>
                        </div>
                        <div className={styles.button_div}>
                            <Button className='button_common secondary_button' onClick={() => { obtainCoupons() }}>{t("personalCenter.obtainCoupons")}<Image src={gifIcon} preview={false} /> </Button>
                            <Button className='button_common primary_button' onClick={() => { saveCash() }}>{t("soundSettings.save")}</Button>
                        </div>
                    </div>
                </PublicPopup> : ""}
        </div>
    )
}

const TransferOutTokenStep2 = ({ forgetPwd, tokenAmount, updateUserInfo, backStep1, idList }) => {

    const [pwd, setPwd] = useState("")
    const [open, setopen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [passwordErr, setPasswordErr] = useState(false)


    const doStep2 = () => {
        if (!pwd || passwordErr) {
            return
        }
        setIsModalOpen(true)
        withdrawToken({
            tokenAmount: tokenAmount / 1,
            secondPassword: pwd,
            withdrawFeeDiscountItemIds: idList
        }).then(res => {
            if (!res.data.code) {
                if (res.data.data.Status === "Review") {
                    backStep1()
                    return openNotification(t("personalCenter.reviewing"))
                }
                setopen(true)
                updateUserInfo()
            } else if (res.data.code === 1001) {
                openNotification(t("user.login2Failed1001"))
            } else {
                backStep1()
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            }
        }).catch(err => {
            console.log(err)
            openNotification(t("responseCode.networkFailed"))

        }).finally(fin => {
            setIsModalOpen(false)
        })
    }

    const onModalAction = (action) => {
        setopen(false)
        backStep1()
    }

    const passwordChange = (val) => {
        setPwd(val)
        if (val.length === 6 && !isNaN(Number(val))) {
            setPasswordErr(false)
        } else {
            setPasswordErr(true)
        }
    }

    return (
        <div className={styles.transfer_out_token_step2}>
            <div className={styles.top}>
                <Button className="close_button" onClick={backStep1}><Image preview={false} src={BackIcon} /></Button>
            </div>
            <div className={styles.price_input}>
                <div className={styles.input_div}>
                    <Input.Password
                        id='pwd'
                        className="form_input"
                        placeholder={t("personalCenter.pleaseEnterSecondaryPassword")}
                        value={pwd}
                        onChange={(e) => { passwordChange(e.target.value) }}
                        iconRender={(visible) => (visible ? <Image preview={false} src={OpenEye} /> : <Image preview={false} src={OffEye} />)}
                    />
                    <div className={styles.form_input_info}>
                        <span onClick={forgetPwd} className={styles.info_link}>{t("user.forgotPassword")}</span>
                        {passwordErr ?
                            <span className={styles.info_error}>{t("personalCenter.pleaseEnterSixNumerical")}</span>
                            : <span></span>}
                    </div>
                </div>
            </div>
            <div className={styles.bt_div}>
                <div className={passwordErr || !pwd ? "button_common disabled_button" : "button_common primary_button"} onClick={doStep2}>{t("user.next")}</div>
            </div>

            <Modal wrapClassName={'spin_model'} centered open={isModalOpen} footer={null} closeIcon={null} style={{ textAlign: 'center' }}>
                <Spin />
            </Modal>
            <CommonDialogSmall
                open={open}
                msg={t("wallet.walletActionUpdate")}
                cancel={false}
                onModalAction={onModalAction}
            />
        </div>
    )
}

const LuckyDraw = ({ updateUserInfo, tetrisToken }) => {
    const [congratulations, setCongratulations] = useState(false)
    const [congratulationsResult, setCongratulationsResult] = useState("")

    const [winningRecordsMyself, setWinningRecordsMyself] = useState(false)
    const [winningRecordsListMyself, setWinningRecordsListMyself] = useState([])
    const [winningRecordsListMyselfTotal, setWinningRecordsListMyselfTotal] = useState(0)
    const [winningRecordsListMyselfPage, setWinningRecordsListMyselfPage] = useState(0)
    const [winningRecordsMyselfLoading, setWInningRecordsMyselfLoading] = useState(false)

    const [winningRecordsList, setWinningRecordsList] = useState(null)

    const openCongratulations = (result) => {
        setCongratulationsResult(result)
        setCongratulations(true)
    }
    const closeCongratulations = () => {
        updateUserInfo()
        setCongratulations(false)
    }

    useEffect(() => {
        postWheelRecords().then((res) => {
            if (res.data.data) {
                setWinningRecordsList(res.data.data.userWheelRecords)
            }
        }).catch((err) => {
        })
    }, [])

    useEffect(() => {
        if (winningRecordsMyself) {
            loadMoreMyselfRecords(1)
        }
    }, [winningRecordsMyself])

    const loadMoreMyselfRecords = (page) => {
        if (winningRecordsMyselfLoading) { return }
        setWInningRecordsMyselfLoading(true)
        let newPage = page || winningRecordsListMyselfPage + 1
        setWinningRecordsListMyselfPage(newPage)
        postWheelUserRecords({
            "pagination": {
                "page": newPage,
                "size": 10
            }
        }).then((res) => {
            setWInningRecordsMyselfLoading(false)
            if (res.data.data) {
                if (newPage === 1) {
                    setWinningRecordsListMyself([...res.data.data?.userWheelRecords])
                } else {
                    setWinningRecordsListMyself([...winningRecordsListMyself, ...res.data.data?.userWheelRecords])
                }
                setWinningRecordsListMyselfTotal(res.data.data?.pagination?.total)
            }
        }).catch(() => {
            setWInningRecordsMyselfLoading(false)
        })
    }
    return (
        <div className={styles.lucky_draw}>
            <div className={styles.lucky_draw_title}>{t("personalCenter.luckyDraw")}</div>
            <div className={styles.lucky_dray_content}>
                <SuperPrizeGrid back={openCongratulations} tetrisToken={tetrisToken} />
                <div className={styles.notice}>
                    <div>{t("tableData.Winners")}</div>
                    <div className={styles.notice_inner}>
                        <div className={styles.notice_box}>
                            {winningRecordsList?.map((item, index) => {
                                return (
                                    <div className={styles.notice_item} key={index + "0"}>
                                        {item.userNicknameMasked} {t("tableData.SpinWheeled")} {item.rewardToken + TOKENNAME}
                                    </div>
                                )
                            })}
                        </div>
                        <div className={styles.notice_box}>
                            {winningRecordsList?.map((item, index) => {
                                return (
                                    <div className={styles.notice_item} key={index + "1"}>
                                        {item.userNicknameMasked} {t("tableData.SpinWheeled")} {item.rewardToken + TOKENNAME}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.lucky_dray_bottom}>
                <Dropdown
                    dropdownRender={(menu) => (
                        <div style={{ background: "#40077e", whiteSpace: "pre-line", borderRadius: "1vw", width: "20vw", height: "auto", color: "#fff", fontFamily: "Noto Sans", padding: "1.5vw", boxSizing: "border-box", fontSize: "0.78vw" }}>
                            {t("personalCenter.rules")}
                            <div style={{ fontSize: "0.7vw" }}>
                                {t("personalCenter.rulesContent")}
                            </div>
                        </div>
                    )}
                    trigger={['click', 'hover']}
                    placement="bottomLeft"
                >
                    <div>{t("personalCenter.activityRules")}</div>
                </Dropdown>
                <div onClick={() => { setWinningRecordsMyself(true) }}>{t("personalCenter.winingRecords")}</div>
            </div>
            <div>
                {winningRecordsMyself ?
                    <PublicPopup isOverlay title={t("personalCenter.winingRecords")} closefun={() => { setWinningRecordsMyself(false) }}>
                        <div
                            id="scrollableDiv1"
                            style={{
                                overflow: 'auto',
                            }}
                            className={styles.winning_records}
                        >
                            <InfiniteScroll
                                dataLength={winningRecordsListMyself.length}
                                next={loadMoreMyselfRecords}
                                hasMore={(winningRecordsListMyself.length) < winningRecordsListMyselfTotal}
                                loader={
                                    <Skeleton
                                        avatar
                                        paragraph={{
                                            rows: 1,
                                        }}
                                        active
                                    />
                                }
                                endMessage={<Divider plain><div className={styles.list_end}>{winningRecordsListMyselfTotal ? t("personalCenter.listEnd") : ""}</div></Divider>}
                                scrollableTarget="scrollableDiv1"
                            >
                                <List
                                    dataSource={winningRecordsListMyself}
                                    locale={{ emptyText: <NoData /> }}
                                    loading={winningRecordsMyselfLoading}
                                    split={false}
                                    renderItem={(item, index) => (
                                        <List.Item key={index} className={styles.winning_records_item}>
                                            <div className='gif_img'></div>
                                            <div className={styles.winning_records_item_content}>
                                                <div className={styles.winning_records_title}>{item.RewardToken + TOKENNAME}</div>
                                                <div className={styles.winning_records_subtitle}>{dayjs(item.CreatedAt).format('YYYY-MM-DD HH:mm')}</div>
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </InfiniteScroll>
                        </div>

                    </PublicPopup> : ""}
                {congratulations ?
                    <PublicPopup isOverlay title={t("personalCenter.congratulations")} closefun={closeCongratulations} >
                        <div className={styles.congratulations}>
                            <div className='gif_img'></div>
                            <div className={styles.congratulations_title}>{congratulationsResult}</div>
                            <div className={styles.congratulations_subtitle}>{t("personalCenter.congratulationsSubtitle")}</div>
                            <div className='button_common primary_button' onClick={closeCongratulations}>{t("personalCenter.checkBalance")}</div>
                        </div>
                    </PublicPopup> : ""}
            </div>
        </div >
    )
}

import React, { useEffect, useState } from 'react'
import { Button, Form, Image, Input, Spin, Statistic } from 'antd'
import { t } from 'i18next'

import { getSwapTokenTRate, postBuyNft } from '../../api'

import { CREATORFEE, IMAGE_BASEURL, TOKENNAME, TRANSACTIONFEE } from '../../tools/gameConfig'
import { openNotification } from '../../tools/notification'
import { viewCode } from '../../tools/viewCode'
import { processingTime } from '../../tools/utils'
import { VSWAP_CONTRACT_ADDRESS } from '../../tools/tokenConfig'

import PublicPopup from '../PublicComponents/PublicPopup'
import "../../scss/Market/BuyDetail.scss"
import OpenEye from "../../assets/web/open_eye.png"
import OffEye from "../../assets/web/off_eye.png"

const { Countdown } = Statistic

function BuyDetail({ nftDetail, onClose, tetrisToken, userId, onConfirm, secondPasswordSet, goToForgetPwd, TET2USD }) {

    const [showInter, setShowInter] = useState(false)
    const [password, setPassword] = useState('')
    const [errorType, setErrorType] = useState('')
    const [loading, setLoading] = useState(false)
    const [TET2USDCOPY, setTET2USDCOPY] = useState(0)

    useEffect(async () => {
        if (!TET2USD) {
            try {
                setTET2USDCOPY((await getSwapTokenTRate({ vswapContract: VSWAP_CONTRACT_ADDRESS }))?.data?.data?.tetrisTokenExchange?.usd || 0)
            } catch (error) {
                setTET2USDCOPY(0)
                console.log(error)
            }
        } else {
            setTET2USDCOPY(TET2USD)
        }
    }, [TET2USD])

    const buyNFT = () => {
        if (!showInter) {
            return setShowInter(true)
        }
        if (!password) {
            return setErrorType("interNone")
        }
        window._czc && window._czc.push(["_trackEvent", "NFT购买页", "点击", "购买NFT"]);
        setLoading(true)
        postBuyNft({
            userNFTMarketplaceId: nftDetail.ID / 1,
            secondPassword: password
        }).then((res) => {
            if (res.data.code) {
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                setErrorType("passwordError")
            } else {
                setErrorType("success")
            }
        }).catch(() => {
            openNotification(t("responseCode.networkFailed"))
        }).finally(() => {
            setLoading(false)
        })
    }
    const close = () => {
        if (errorType === "success") {
            onConfirm()
        } else {
            onClose()
        }
    }

    return (
        <div className='buy_detail'>
            <PublicPopup title={t("market.buy")} closefun={close} isOverlay>
                <div className='nft_detail'>
                    <div className="nft_right">
                        <Image
                            className={"nft_img"}
                            preview={false}
                            src={nftDetail.NftAttributes?.content ? IMAGE_BASEURL + nftDetail.NftAttributes.content + ".png?imageView2/1/w/500/h/500" : ""}
                            placeholder={
                                <Image
                                    preview={false}
                                    src={nftDetail.NftAttributes?.content ? IMAGE_BASEURL + nftDetail.NftAttributes.content + ".png?imageView2/1/w/50/h/50" : ""}
                                    className={"nft_img"}
                                />
                            }
                        />
                        <div className='nft_series'>
                            <div className="series">
                                <div className='series_ic'></div>
                                <div>{t("market.tetrisSeries")}</div>
                            </div>
                            <div>{"#" + nftDetail.NftTokenIndex}</div>
                        </div>
                    </div>
                    <div className='nft_left'>
                        {/* price */}
                        <div className="nft_price">
                            <div className="tit">{t("market.price")}</div>
                            <div className='price'>
                                <div className='icon'>
                                    <div className='number'>
                                        {nftDetail.NftPrice}
                                    </div>
                                    <div className='price_ic'></div>
                                </div>
                                <div className='price_usd'>
                                    {"$" + (nftDetail?.NftPrice * TET2USDCOPY).toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div className="nft_price">
                            <div className="tit">{t("market.nftValidityPeriod")}</div>
                            <div className='price'>
                                {
                                    nftDetail?.NftValidStatus === "InActivated" ?
                                        t("personalCenter.InActivated")
                                        : <Countdown title="" value={processingTime(nftDetail?.NftExpiredAt)} format="D 天 H 时 m 分 s 秒" />
                                }
                            </div>
                        </div>
                        {nftDetail.SellerUserID === userId ?
                            <div className="buy_fee">
                                <div className="tit">{t("market.handlingFee")}</div>
                                <div className="tit_content">{t("market.handlingFeeContent")}</div>
                                <div className='fee'>
                                    <div>{t("market.transactionFee")} {TRANSACTIONFEE}</div>
                                    <div>{t("market.creatorFee")} {CREATORFEE}</div>
                                </div>
                            </div>
                            : !showInter ?
                                <div className="buy_info">
                                    <div className="tit">
                                        <div>{t("market.paymentCurency")}</div>
                                        <div>{t("market.balance")}</div>
                                    </div>
                                    <div className="info">
                                        <div className='icon_info'>
                                            {TOKENNAME}
                                            <div className='price_ic'></div>
                                        </div>
                                        <div>{tetrisToken}</div>
                                    </div>
                                </div> : <div className="buy_info">
                                    <div className="tit">
                                        <div>{t("personalCenter.secondaryPassword")}</div>
                                    </div>
                                    <div className="info">
                                        <Form>
                                            {secondPasswordSet ?
                                                <>
                                                    <Input.Password
                                                        className="form_input"
                                                        placeholder={t("personalCenter.pleaseEnterSecondaryPassword")}
                                                        onChange={(e) => { setPassword(e.target.value) }}
                                                        iconRender={(visible) => (visible ? <Image preview={false} src={OpenEye} /> : <Image preview={false} src={OffEye} />)}
                                                    />
                                                    <div className="button_common secondary_button" onClick={goToForgetPwd}>{t("user.forgotPassword")}</div>
                                                </>
                                                : <div className="button_common secondary_button" onClick={goToForgetPwd}>{t("personalCenter.setSecondaryPassword")}</div>}
                                        </Form>
                                    </div>
                                </div>}
                        <div className='nft_message'>
                            {loading ? <Spin />
                                : errorType == "success" ?
                                    <>
                                        <div className="success_ic"></div>
                                        <div className='message_title message_success'>{t("market.buySuccess")}</div>
                                    </>
                                    : errorType == "passwordError" ?
                                        <>
                                            <div className="filed_ic"></div>
                                            <div className='message_title message_filed'>{t("market.buyFiled")}</div>
                                        </>
                                        : errorType == "interNone" ?
                                            <>
                                                <div className="filed_ic"></div>
                                                <div className='message_title message_filed'>{t("personalCenter.secondaryPasswordNone")}</div>
                                            </>
                                            : ""
                            }
                        </div>
                    </div>
                </div>
                <div className="nft_button">
                    {tetrisToken < nftDetail.NftPrice ?
                        <Button className="button_common disabled_button" onClick={close}>{t("market.insufficientFunds")}</Button>
                        : errorType !== "success" ?
                            <Button className="button_common primary_button" onClick={buyNFT}>{t("market.buy")}</Button>
                            : ""}
                    {errorType !== "success" ?
                        <Button className="button_common secondary_button" onClick={close}>{t("market.cancel")}</Button>
                        : <Button className="button_common secondary_button" onClick={close}>{t("market.confirm")}</Button>}

                </div>
            </PublicPopup>
        </div>
    )
}

export default BuyDetail
import React from 'react'
import { Statistic } from 'antd';
import { useTranslation } from 'react-i18next';

import Slider from './Slider'
import '../../scss/Hall/Home.scss'
const { Countdown } = Statistic;

function Home({ cb, atBattleGameTime, battleStartTime, battleEndTime, getBattleGameTime, battleStartTimeEnd }) {

    const handleClick = (mode) => {
        cb(mode)
    }
    const { t } = useTranslation()

    return (
        <div className="home-wrapper">
            <div className="sideshow">
                <div className="box_container">
                    <Slider />
                </div>
            </div>
            <div className="mode-container">

                <div className='mode' onClick={() => handleClick("mode1")}>
                    <span className="text">{t("hall.survivalMode")}</span>
                </div>

                <div className='mode' onClick={() => handleClick("props")} >
                    <span className="text">{t("hall.itemBattle")}</span>
                </div>

                {atBattleGameTime ?
                    <div className='mode' onClick={() => handleClick("battle")}>
                        <span className='wait'><div className="wait_ic"></div> {t("hall.endingAfterwards")}<Countdown value={battleEndTime} onFinish={getBattleGameTime} format="HH:mm:ss" /></span>
                        <span className="text">{t("hall.multiplayerCompetition")}</span>
                    </div>
                    :
                    <div className='mode mode_dis'>
                        <span className='wait'><div className="wait_ic"></div> {t("hall.startingAfterwards")}<Countdown value={battleStartTime} onFinish={battleStartTimeEnd} format="HH:mm:ss" /> </span>
                        <span className="text">{t("hall.multiplayerCompetition")}</span>
                    </div>
                }

                <div className='mode' onClick={() => handleClick("classic")}>
                    <span className="text" data-title={t("hall.classicMode")}>{t("hall.classicMode")}</span>
                </div>

            </div>
        </div>
    )
}

export default Home
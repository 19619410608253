import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en'
import cn from './locales/cn'

const resources = { en, cn };

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language") || "en",
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

import React, { useEffect } from 'react';
import { StyledStage } from './styles/StyledStage';
import { StyledLiner } from './styles/StyledLiner'
import anime from 'animejs/lib/anime.es.js';

import '../../scss/Game/Effects.scss'

import Cell from './Cell';
const Stage = ({ stage, size, isShowEffect, sweepExactRows }) => {

  const heightArr = [
    85, 16, 8.9, 6.1, 4.7, 3.8, 3.2, 2.75, 2.42, 2.16, 1.95, 1.77, 1.63, 1.51, 1.4, 1.31, 1.23, 1.16, 1.097, 1.04
  ]

  const viewAnimation = anime({
    targets: '.light',
    easing: 'easeInOutQuad',
    // direction: 'alternate',
    duration: 400,
    opacity: .0,
    // loop: true
    autoplay: true
  });

  return (
    <StyledStage width={stage[0].length} height={stage.length} size={size}>
      {/* <StyledLiner>
      </StyledLiner> */}
      {
        isShowEffect ? (<div className="pyro" style={{ width: `calc( ${size} / 2)`, height: `calc( ${size} /1)`, position: 'absolute', top: '50%' }}>
          <div className="before"></div>
          <div className="after"></div>
        </div>) : ''
      }
      {
        sweepExactRows.map((item, idx) => {
          return (
            isShowEffect ? (<div className="light" key={idx} style={{ width: `calc( ${size} /1.95)`, height: `calc( ${size} /20)`, background: '#97ccf6', position: 'absolute', bottom: `calc( (${size} / ${heightArr[19 - item]}  )  )`, left: '2%', }}></div>) : ''
          )
        })
      }
      {stage.map(row => row.map((cell, x) => (<Cell key={x} type={cell[0]} />)))}
    </StyledStage>
  )
};

export default Stage;

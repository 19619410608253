import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import dayjs from 'dayjs';
import en from "dayjs/locale/en"
import zhCn from 'dayjs/locale/zh-cn';
import { Button, Dropdown, Image } from 'antd';

import Header from '../../Components/Home/Header';
import ConnectWalletDialog from '../../Components/PublicComponents/ConnectWalletDialog';

import '../OfficialSite.scss'

import twitter from "../../assets/icon/twitter.png";
import fb from "../../assets/icon/fb.png";
import discord from '../../assets/icon/discord.svg'
import telegram from "../../assets/icon/telegram.svg"

import avatarDefault from "../../assets/hall/avatar_default.png"
import invitesImg from '../../assets/web/invites.svg'
import invitesValidImg from '../../assets/web/invite_valid.svg'
import stepImg from '../../assets/web/step_line.svg'
import inviteCompleted from '../../assets/web/invite_completed.png'
import inviting from '../../assets/web/inviting.png'
import claimNftImg from '../../assets/web/claim_nft_img.png'

import { getMessage, signContent } from '../../tools/wallet';
import { copy, openNotification } from '../../tools/notification';
import { Login } from '../../redux/actions/user/userActions';
import { getInfo, postInvitationRewardCheck, postInvitationRewardClaim, postInvitationRewardLatest, postSetVsysAddress } from '../../api';
import { viewCode } from '../../tools/viewCode';

const Invite = (props) => {
    const navigate = useNavigate()

    const { t } = useTranslation()

    const [connectWalletLoading, setConnectWalletLoading] = useState(false)
    const [downloadWallet, setDownloadWallet] = useState(false)
    const [address, setAddress] = useState("")
    const [vsysAddress, setVsysAddress] = useState("")
    const [briefAddr, setBriefAddr] = useState("")
    const [uuid, setUuid] = useState('')

    const [levels, setLevels] = useState(0)
    const [requires, setRequires] = useState([])
    const [rewards, setRewards] = useState([])
    const [endAt, setEndAt] = useState(0)
    const [startAt, setStartAt] = useState(0)
    const [maxLevel, setMaxLeval] = useState(0)
    const [allInvitations, setAllInvitations] = useState(0)
    const [validInvitations, setValidInvitations] = useState(0)
    const [claiming, setClaiming] = useState(true)

    // connect wallet
    const connectWallet = async () => {
        setConnectWalletLoading(true)
        const address = await getVsysAddress()
        if (address) {
            signContent("You are verifying your account with address " + address + ". Please sign the transaction to confirm.").then((res) => {
                postSetVsysAddress({ vsysAddress: address, vsysPubKey: res.pubKey, signedMessage: res.signature }).then((res) => {
                    if (res.data.code) return setConnectWalletLoading(false)
                    updateUserInfo()
                })
            })
        } else {
            setConnectWalletLoading(false)
        }
    }
    // update user info
    const updateUserInfo = () => {
        setConnectWalletLoading(true)
        getInfo().then(async (res) => {
            if (res.data.code !== 0) {
                return props.Login("")
            }
            if (res.data.data?.uuid !== uuid) setUuid(res.data.data?.uuid)
            if (!res.data.data?.vsysAddress) setConnectWalletLoading(false)
            if (res.data.data?.vsysAddress !== vsysAddress) setVsysAddress(res.data.data?.vsysAddress)
        }).catch((err) => {
            console.log(err)
        })
    }
    const getAddress = async () => {
        const address = await getVsysAddress()
        setConnectWalletLoading(false)
        if (vsysAddress === address) {
            setAddress(vsysAddress);
        }
    }
    const getVsysAddress = async () => {
        const res = await getMessage()
        if (!res.result) {
            const { type, version } = res
            switch (type) {
                case "download":
                    setDownloadWallet(true)
                    break;
                case "version":
                    openNotification(t("wallet.versionError"))
                    break;
                case "success":
                    openNotification(t("wallet.authorizationError"))
                    break;
                case "switchNetwork":
                    openNotification(t("wallet.networkSettingError"))
                    break;
                case "login":
                    openNotification(t("wallet.walletLoginError"))
                    break;
                default:
                    break;
            }
        }
        // return address
        else {
            if (res.result) {
                return res.msg
            }
        }
    }
    useEffect(() => {
        if (vsysAddress) {
            getAddress()
            window?.vsys?.on('accountsChanged', handleAccountsChanged);
        }
        return () => {
            window?.vsys?.removeListener('accountsChanged', handleAccountsChanged);
        }
    }, [vsysAddress])

    useEffect(() => {
        if (address) {
            let addr = address
            let addrChars = addr.split("");
            addrChars.splice(7, 20, "...");
            setBriefAddr(addrChars.join(""))
        } else {
            setBriefAddr("")
        }
    }, [address])
    // init email address
    useEffect(() => {
        if (!props.token || !window) return
        updateUserInfo()
    }, [props.token, window])
    const handleAccountsChanged = (accounts) => {
        if (vsysAddress === accounts[0]) {
            setAddress(vsysAddress)
        } else {
            setAddress('')
        }
    }
    const onClose = () => {
        setDownloadWallet(false)
    }
    const closeAddress = () => {
        setAddress("")
        setVsysAddress("")
    }
    const selectPage = (page) => {
        navigate('/home?homePage=' + page)
    }
    useEffect(() => {
        if (!props.token) return
        getLatest()
        getCheck()
    }, [props.token])
    const getLatest = () => {
        postInvitationRewardLatest().then((res) => {
            if (!res.data.code) {
                setLevels(res.data.data.levels)
                setRequires(res.data.data.requires.split(","))
                setRewards(res.data.data.rewards.split(","))
                setStartAt(res.data.data.startAt / 1000000)
                setEndAt(res.data.data.endAt / 1000000)
            } else {
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            }
        }).catch((err) => {
            openNotification(t("responseCode.networkFailed"))
        })
    }
    const getCheck = () => {
        postInvitationRewardCheck().then((res) => {
            if (!res.data.code) {
                setMaxLeval(res.data.data.maxLevel)
                setAllInvitations(res.data.data.allInvitations)
                setValidInvitations(res.data.data.validInvitations)
                setClaiming(false)
            } else {
                setClaiming(false)
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            }
        }).catch((err) => {
            setClaiming(false)
            openNotification(t("responseCode.networkFailed"))
        })
    }
    const Claim = (index) => {
        if (validInvitations >= requires[index] && maxLevel < index + 1 && !claiming) {
            setClaiming(true)
            postInvitationRewardClaim().then((res) => {
                if (!res.data.code) {
                    if (res.data.data.invitationLevel === 4) {
                        openNotification("invite.nftRewards")
                    } else {
                        openNotification(t("invite.claimSuccessful") + res.data.data.rewardTokenAmount + "Token")
                    }
                    setLevels(0)
                    getLatest()
                    getCheck()
                } else {
                    setClaiming(false)
                    openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                }
            }).catch((err) => {
                setClaiming(false)
                openNotification(t("responseCode.networkFailed"))
            })
        } else {
            return
        }
    }
    return (
        <div className="offical_site">
            <Header selectPage={selectPage} connectWalletLoading={connectWalletLoading} address={briefAddr} connectWallet={connectWallet} setAddress={closeAddress} avatarDefault={avatarDefault} />
            {downloadWallet ? <ConnectWalletDialog onClose={onClose} /> : ""}
            <div className='invite_page'>
                <div className="invite_page_bg">
                    <div className="invite_page_bg1">
                        <div className="invite_page_bg1_bg"></div>
                    </div>
                </div>
                <div className="invite_page_content">
                    <div className="invite_page_title_div">
                        <div className="invite_page_title_title">{t("invite.tetriverse")}</div>
                        <div className="invite_page_title_content">{t("invite.invitationEvent")}</div>
                    </div>
                    <div className="invite_page_time">{startAt && endAt ?
                        dayjs(startAt).locale(t("commonText.language") == "en" ? en : zhCn).format(t("commonText.language") == "en" ? "D MMM" : "MMM D") + " - " +
                        dayjs(endAt).locale(t("commonText.language") == "en" ? en : zhCn).format(t("commonText.language") == "en" ? "D MMM" : "MMM D") : ""}
                    </div>
                    <div className="invite_page_code">
                        <div className="invite_page_code_input">
                            <span className='invite_page_code_content'>{uuid}</span>
                            <div className='copy_ic' onClick={() => { copy(window.location.origin + "/webRegister?inviteId=" + uuid) }}></div>
                        </div>
                        <div className="invite_page_code_title">
                            {t("invite.copyLink")}
                        </div>
                    </div>
                    <div className="invite_page_step">
                        <div className="invite_page_step_content">
                            <div className="invite_page_step_content_title">  {t("invite.step1")} </div>
                            <div className="invite_page_step_content_content"> {t("invite.step1Content")}</div>
                        </div>
                        <div className="invite_page_step_space">
                            <Image
                                preview={false}
                                src={stepImg}
                                className='invite_page_step_space_img'
                            />
                        </div>
                        <div className="invite_page_step_content">
                            <div className="invite_page_step_content_title"> {t("invite.step2")}</div>
                            <div className="invite_page_step_content_content"> {t("invite.step2Content")}</div>
                        </div>
                        <div className="invite_page_step_space">
                            <Image
                                preview={false}
                                src={stepImg}
                                className='invite_page_step_space_img'
                            />
                        </div>
                        <div className="invite_page_step_content">
                            <div className="invite_page_step_content_title"> {t("invite.step3")}</div>
                            <div className="invite_page_step_content_content"> {t("invite.step3Content")}</div>
                        </div>
                    </div>
                    <div className="invite_page_rest">
                        <div className="invite_page_rest_invites">
                            <Image
                                preview={false}
                                src={invitesImg}
                                className='invite_page_rest_invites_img1'
                                onClick={() => window.open('https://twitter.com/tetriverseNFT', "_blank")}
                            />
                            <div className="invite_page_rest_invites_title">{t("invite.invites")}</div>
                            <div className='invite_page_rest_invites_number'>{allInvitations}</div>
                        </div>
                        <div className="invite_page_rest_invites">
                            <Image
                                preview={false}
                                src={invitesValidImg}
                                className='invite_page_rest_invites_img2'
                                onClick={() => window.open('https://twitter.com/tetriverseNFT', "_blank")}
                            />
                            <div className="invite_page_rest_invites_title">
                                {t("invite.validInvites")}
                                <Dropdown
                                    dropdownRender={(menu) => (
                                        <div style={{
                                            background: "rgba(5, 5, 5, 0.50)", borderRadius: "1.4vw", width: "20vw",
                                            whiteSpace: "pre-wrap", height: "auto", color: "#fff", fontFamily: "Noto Sans",
                                            padding: "0.9vw 1.5vw", boxSizing: "border-box", fontSize: "0.8vw", backdropFilter: "blur(25px)"
                                        }}>
                                            {t("invite.validInvitesContent")}
                                        </div>
                                    )}
                                    trigger={['click', 'hover']}
                                    placement="right"
                                >
                                    <div className='price_info_ic'></div>
                                </Dropdown></div>
                            <div className='invite_page_rest_invites_number'>{validInvitations}</div>
                        </div>
                    </div>
                    <div className='invite_page_collect'>
                        {new Array(levels).fill("false").map((item, index) => {
                            return <div className='invite_page_collect_item' key={index}>
                                <Dropdown
                                    dropdownRender={(menu) => (
                                        <div style={{
                                            background: "rgba(5, 5, 5, 0.50)",
                                            borderRadius: "1.4vw", width: "auto",
                                            whiteSpace: "pre-wrap", height: "auto",
                                            color: "#fff", fontFamily: "Noto Sans",
                                            padding: "0.9vw",
                                            boxSizing: "border-box", fontSize: "0.9vw",
                                            backdropFilter: "blur(25px)",
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "1vw",
                                            position: "relative",
                                            left: "3vw",
                                            bottom: "-5vw"
                                        }}>
                                            <div style={{ color: "#DBC8F4" }}>{t("invite.currentValidInvitations")}{validInvitations}</div>
                                            <div style={{ fontWeight: 700 }}>{t("invite.inviteContent1")} {requires[index]} {t("invite.inviteContent2")} {rewards[index] !== "0.0" ? rewards[index] + "TET" : "1 NFT (N)"} </div>
                                            <div style={{ color: validInvitations >= requires[index] ? "#65F563" : "#F01313" }}>{t("invite.currentProgress")}{validInvitations}/{requires[index]}</div>
                                        </div>
                                    )}
                                    trigger={['click', 'hover']}
                                    placement="topLeft"
                                >
                                    <Image
                                        preview={false}
                                        src={validInvitations >= requires[index] ? inviteCompleted : inviting}
                                        className='invite_page_collect_item_img'
                                    />
                                </Dropdown>
                                <Button
                                    className={validInvitations >= requires[index] && maxLevel < index + 1 ?
                                        "invite_page_collect_item_button"
                                        : "invite_page_collect_item_button invite_page_collect_item_button_dis"}
                                    onClick={() => { Claim(index) }}
                                    loading={validInvitations >= requires[index] && maxLevel < index + 1 && claiming}
                                >
                                    {maxLevel < index + 1 ? t("invite.collect") : t("invite.collected")}
                                </Button>
                            </div>
                        })}
                    </div>
                </div>
                {levels ? <div
                    className={levels && validInvitations >= requires[levels]
                        && maxLevel < levels && !claiming ?
                        'claim_nft' : "claim_nft claim_nft_dis"}
                    onClick={() => { Claim(levels) }}
                >
                    <Dropdown
                        dropdownRender={(menu) => (
                            <div style={{ color: "#fff", fontFamily: "Turret Road", fontSize: "1.4vw", fontWeight: 800, textAlign: "center" }}>
                                {t("invite.claimReward")}
                            </div>
                        )}
                        trigger={['hover']}
                        placement="top"
                    >
                        <div className='claim_nft_bg'>
                            <Image
                                preview={false}
                                src={claimNftImg}
                                className='claim_nft_img'
                            />
                        </div>
                    </Dropdown>
                </div> : ""}
            </div >
            <div className="footer_ow">
                <div className="container">
                    <div className="left">
                        <div className="slogan_des">{t("home.fontContent")}</div>
                        <div className="reserved">{t("home.reserved")}</div>
                    </div>
                    <div className="right">
                        <div className="join">{t("home.socialMediaLinks")}</div>
                        <div className="social">
                            <Image
                                preview={false}
                                src={twitter}
                                className=' btn tw'
                                onClick={() => window.open('https://twitter.com/tetriverseNFT', "_blank")}
                            />
                            <Image
                                preview={false}
                                src={fb}
                                onClick={() => { window.open("https://www.facebook.com/people/Tetriverse-NFT-Game/61555663441495/", "_blank") }}
                                className=' btn tw'
                            />
                            <Image
                                preview={false}
                                src={discord}
                                onClick={() => { window.open("https://discord.gg/TrnXN5kaEy", "_blank") }}
                                className=' btn fb'
                            />
                            <Image
                                preview={false}
                                src={telegram}
                                onClick={() => { if (t("commonText.language") === "en") { window.open("https://t.me/tetriverseNFT", "_blank") } else { window.open("https://t.me/tetriversechinese", "_blank") } }}
                                className=' btn fb'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
const mapStateToProps = (state) => ({
    token: state.user.token
});
const mapDispatchToProps = { Login };

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
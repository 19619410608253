import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { Button, Image, Spin } from 'antd';

import Api from '../../socket/Api';
import { socket } from '../../socket/socket';
import { roomExists, roomFull } from '../../redux/actions/sockets/socketsActions';

import { openNotification } from '../../tools/notification';

import PublicPopup from '../PublicComponents/PublicPopup';
import '../../scss/ChooseGameType/RoomList.scss'
import avatarDefault from "../../assets/hall/avatar_default.png";


function RoomList({ onClose, model, roomdetail, roomExists, roomexists, roomFull, roomfull, mostPowerfulNft }) {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [roomList, setRoomList] = useState([])
    const [pageNo, setPageNo] = useState(1)
    const [loading, setLoading] = useState(false)
    const [joinType, setJoinType] = useState(false)


    useEffect(() => {
        roomExists(false)
        if (window.location.search) {
            navigate("/", { replace: true })
        }
    }, [])

    useEffect(() => {
        if (!joinType) {
            return
        }
        if (roomdetail.id && !roomexists) {
            onClose(joinType)
        }
        if (roomfull && !roomdetail.id) {
            openNotification(t("gameChooseModel.roomFullJoinFailed"))
            roomFull(false)
        }
        if (roomexists && !roomdetail.id) {
            openNotification(t("gameChooseModel.joinRoomError"))
            getRoomList()
            roomExists(false)
        }
        return () => {
            setJoinType(false)
        }

    }, [joinType, roomdetail, roomexists, roomfull])

    useEffect(() => {
        getRoomList()
    }, [model])

    //get room list
    const getRoomList = (next) => {
        if (loading) { return }
        setLoading(true)
        let page = pageNo
        if (next) page = page + next
        setPageNo(page)
        Api().get(`/rooms?mode=${model}&pageNo=${page}`).then((res) => {
            setLoading(false)
            if (res.status === 200) {
                setRoomList(res.data)
            }
        }).catch(() => {
            setLoading(false)
        })
    }

    // join room
    const join_room = (id) => {
        if (joinType) { return }
        socket.emit("join_room", { "id": id, nftIds: mostPowerfulNft.ID })
        setJoinType("joinRoom")
        // props.onClose("joinRoom")
    }

    // viewer join room
    const viewer_join_room = (id) => {
        if (joinType) { return }
        socket.emit("viewer_join_room", { "id": id })
        setJoinType("viewRoom")
        // props.onClose("viewRoom")
    }


    // quick join room
    const quick_join = () => {
        socket.emit("quick_join", { name: "room" + new Date().getTime(), mode: model, maxplayers: 8 }, (res) => {
            if (res.id) {
                join_room(res.id)
            } else {
                openNotification(t("gameChooseModel.quickJoinError"))
            }
        })
    }

    return (
        <PublicPopup isOverlay >
            <div className="room_list">
                <div className="room_header">
                    <button onClick={() => { onClose() }}>{t("gameChooseModel.back")}</button>
                    <div className="right">
                        <button onClick={() => { quick_join() }}>{t("gameChooseModel.quickJoin")}</button>
                        <button onClick={() => { getRoomList(1 - pageNo) }}>{t("gameChooseModel.refresh")}</button>
                    </div>
                </div>
                <div className="room_list_content">
                    {loading ? <div className='spin'><Spin /> </div> :
                        roomList.map((item, index) => {
                            return (
                                <div className="item" key={index}>
                                    <div className="user_msg">
                                        <Image className="avatar" preview={false} fallback={avatarDefault} src={""} />
                                        <div className="username">{item.name}</div>
                                    </div>
                                    <div className="capacity">{item.players}\{item.maxplayers}</div>
                                    <div className="button_list">
                                        <Button loading={joinType} onClick={() => { viewer_join_room(item.id) }} className="button_spectator">{t("gameChooseModel.spectating")}</Button>
                                        <Button loading={joinType} onClick={() => { join_room(item.id) }} className="button_join">{t("gameChooseModel.join")}</Button>
                                    </div>
                                </div>
                            )
                        })}
                    <div className="page_button">
                        <button disabled={pageNo <= 1 || loading} onClick={() => { getRoomList(-1) }} className={pageNo > 1 && !loading ? "pre" : "pre disabled"}></button>
                        <div className="page">{pageNo}</div>
                        <button disabled={roomList.length < 3 || loading} onClick={() => { getRoomList(1) }} className={roomList.length < 3 || loading ? "next disabled" : "next"}></button>
                    </div>
                </div>
            </div>
        </PublicPopup>
    )
}
const mapStateToProps = (state) => ({
    roomexists: state.sockets.roomExists,
    roomfull: state.sockets.roomFull,
    roomdetail: state.sockets.roomDetail,
});

const mapDispatchToProps = { roomExists, roomFull };
export default connect(mapStateToProps, mapDispatchToProps)(RoomList);
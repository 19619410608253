import React, { useState } from 'react'
import { Button, Dropdown, Image, InputNumber } from 'antd'
import { t } from 'i18next'

import { ALLFEE, CREATORFEE, IMAGE_BASEURL, TOKENNAME, TRANSACTIONFEE } from '../../tools/gameConfig'

import PublicPopup from '../PublicComponents/PublicPopup'
import "../../scss/Market/BuyDetail.scss"

function ListSingleNft({ nftDetail, onClose, Confirm }) {

    const sortTypeItems = [
        { key: "vsys", label: TOKENNAME }
    ]

    const expirationItems = [
        { key: 1, label: t("market.hour1") },
        { key: 3, label: t("market.hour3") },
        { key: 6, label: t("market.hour6") },
        { key: 7 * 24, label: t("market.hour168") },
        { key: 14 * 24, label: t("market.hour336") },
        { key: 30 * 24, label: t("market.hour720") },
    ]
    const [expirationType, setExpiractionType] = useState(expirationItems[0].key)
    const [price, setPrice] = useState(null)
    const [error, setError] = useState(false)

    const expirationChange = ({ key }) => {
        setExpiractionType(key)
    }

    const confirm = () => {
        if (price && expirationType) {
            let newSelectNft = nftDetail
            newSelectNft.price = ((price / 1).toFixed(2)) / 1
            newSelectNft.expirationTime = expirationType
            Confirm([newSelectNft])
        } else {
            setError(true)
        }
    }

    return (
        <div className='buy_detail'>
            <PublicPopup title={t("market.list")} isOverlay closefun={onClose}>
                <div className='nft_detail'>
                    <div className="nft_right">
                        <Image
                            className={"nft_img"}
                            preview={false}
                            src={nftDetail?.NftAttributes?.content ? IMAGE_BASEURL + nftDetail?.NftAttributes.content + ".png?imageView2/1/w/500/h/500" : ""}
                            placeholder={
                                <Image
                                    preview={false}
                                    className={"nft_img"}
                                    src={nftDetail?.NftAttributes?.content ? IMAGE_BASEURL + nftDetail?.NftAttributes.content + ".png?imageView2/1/w/50/h/50" : ""}
                                />
                            }
                        />
                        <div className='nft_series'>
                            <div className="series">
                                <div className='series_ic'></div>
                                <div>{t("market.tetrisSeries")}</div>
                            </div>
                            <div>{"#" + nftDetail?.NftTokenIndex}</div>
                        </div>
                        <div className='nft_ear'>
                            {t("market.productAtPrice") + (price || 0).toFixed(3) + TOKENNAME}
                            {" "}
                            {t("market.willEar") + (price * ALLFEE || 0).toFixed(3) + TOKENNAME}
                        </div>
                    </div>
                    <div className='nft_left'>
                        {/* price */}
                        <div className="nft_input">
                            <div className="input_title">{t("market.price")}</div>
                            <div className='input_content'>
                                <InputNumber value={price} min={0} className='button' onChange={(value) => { setPrice(value) }} />
                                <Dropdown
                                    className='button dropdown'
                                    menu={{
                                        items: sortTypeItems,
                                    }}
                                >
                                    <a onClick={(e) => e.preventDefault()}>
                                        {TOKENNAME}
                                        <div className='dropdown_ic'></div>
                                    </a>

                                </Dropdown>
                            </div>
                        </div>
                        <div className="nft_input">
                            <div className="input_title">{t("market.expiration")}</div>
                            <Dropdown
                                className='button dropdown'
                                menu={{
                                    items: expirationItems,
                                    onClick: expirationChange
                                }}
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    {t(`market.hour${expirationType}`)}
                                    <div className='dropdown_ic'></div>
                                </a>

                            </Dropdown>
                        </div>
                        <div className="buy_fee">
                            <div className="tit">{t("market.handlingFee")}</div>
                            <div className="tit_content">{t("market.handlingFeeContent")}</div>
                            <div className='fee'>
                                <div>{t("market.transactionFee")} {TRANSACTIONFEE}</div>
                                <div>{t("market.creatorFee")} {CREATORFEE}</div>
                            </div>
                        </div>
                        <div className='nft_message'>
                            {error ?
                                <>
                                    <div className="filed_ic"></div>
                                    <div className='message_title message_filed'>{t("market.pleaseEnterPrice")}</div>
                                </>
                                : ""}
                        </div>
                    </div>
                </div>
                <div className="nft_button">
                    <Button onClick={confirm} className='button_common primary_button'>{t("market.listButton")}</Button>
                </div>
            </PublicPopup>
        </div>
    )
}

export default ListSingleNft
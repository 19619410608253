const initialState = {
  soundSettings: {
    music: false, soundEffects: false, specialEffects: true, disposalReminder: false, customKeyCode: {
      left: 37,
      right: 39,
      rotate: 38,
      rotateL: 90,
      reverse: 67,
      push: 40,
      momentum: 32,
      freeze: 81,
      add: 87,
      acc: 69
    }
  },
  haveFreeTicket: { time: new Date().getTime(), type: true },
};

export const localSetting = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SOUND":
      return {
        ...state,
        soundSettings: action.settings
      }

    case "HAVE_FREE_TICKET":
      return {
        ...state,
        haveFreeTicket: {
          type: action.freeTicket,
          time: new Date().getTime()
        }
      }
    default:
      return state;
  }
};

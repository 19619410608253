import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { Image, Spin } from 'antd';

import { MoveResult } from '../../redux/actions/sockets/socketsActions';
import { socket } from '../../socket/socket';

import AdSlod from '../AdSlot/Index'

import avatarDefault from "../../assets/hall/avatar_default.png";
import '../../scss/ChooseGameType/Matchmaking.scss'

let timeInterval = null;
function Matchmaking(props) {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const slotList = useSelector((state) => state.sockets.roomPlayers)
    const roomDetail = useSelector((state) => state.sockets.roomDetail)
    const currentUser = useSelector((state) => state.sockets.currentUser);
    const [close, setClose] = useState(false)

    const [matchTime, setMatchTime] = useState(0);
    const [showMaking, setShowMaking] = useState(true)
    const [isLeaved, setISLeaved] = useState(false)

    // clear hash
    useEffect(() => {
        if (window.location.search) {
            navigate("/", { replace: true })
        }
    }, [])

    // open matching
    useEffect(() => {
        if (props.type === "oneVS1" && props.model) {
            matching(2)
        } else if (props.type === "oneVS3" && props.model) {
            matching(3)
        } else if (props.type === "oneVS6" && props.model) {
            matching(6)
        } else if (props.type === "oneVS8" && props.model) {
            matching(8)
        }
    }, [props.type, props.model])


    // room Detail
    useEffect(() => {
        if (isLeaved && !roomDetail.id) {
            props.onClose()
        }
        if (roomDetail.maxplayers && roomDetail.maxplayers !== roomDetail.players) {
            setClose(true)
        }
    }, [roomDetail])

    // start Game
    useEffect(() => {
        if (currentUser.admin && roomDetail.state === 0 && roomDetail.maxplayers && roomDetail.maxplayers === roomDetail.players) {
            startGame()
        }
    }, [roomDetail])


    // // mactched ===> link game
    useEffect(() => {
        if (!showMaking) {
            setTimeout(() => {
                gotoGame()
            }, 1000);
        }
    }, [showMaking])

    useEffect(() => {
        if (roomDetail.state == 1) {
            setShowMaking(false)
        } else {
            setShowMaking(true)
        }
    }, [roomDetail.state])

    const matching = (number) => {
        socket.emit("match", { name: new Date(), mode: props.model, maxplayers: number, nftIds: props.mostPowerfulNft.ID })
        timeInterval && clearInterval(timeInterval);
        timeInterval = setInterval(() => {
            setMatchTime((n) => n + 1000)
        }, 1000);
    }

    const gotoGame = () => {
        const data = props.model === 'props' ? { nft: props.mostPowerfulNft } : {}
        navigate("/game#making", { replace: true, state: data })
    }

    const closeMatching = () => {
        if (!close) {
            return
        }
        socket.emit("leaveRoom", (res) => {
            if (res.code === 0) {
                props.MoveResult()
            }
        })
        setISLeaved(true)
    }

    const startGame = () => {
        setClose(false)
        socket.emit("startgame", { room: roomDetail.id });
    }

    return (
        <div className='choose_game_div'>{isLeaved ? <div className="room_content"><Spin /></div> :
            showMaking ?
                <AdSlod>
                    <div className="matching">
                        <div className='matching-item'>
                            <div className="matching_bg">
                            </div>
                            <div className="matching_content">
                                <div className="content_text">
                                    {t("gameChooseModel.matching")}
                                </div>
                                <div className="content_text">{
                                    (new Date(matchTime).getMinutes() < 10 ? '0' + new Date(matchTime).getMinutes() : new Date(matchTime).getMinutes())
                                    + ':' +
                                    (new Date(matchTime).getSeconds() < 10 ? '0' + new Date(matchTime).getSeconds() : new Date(matchTime).getSeconds())}
                                </div>
                            </div>
                        </div>
                        <button className={close ? "button_common primary_button" : "button_common disabled_button"} onClick={() => { closeMatching() }}>{t("gameChooseModel.close")}</button>
                    </div>
                </AdSlod> 
                :
                <div className="matchmaking">
                    <div className="vs"></div>
                    <div className="left">
                        {slotList.map((item, index) => {
                            if (index % 2 === 0) {
                                return (<div className="item" key={index}>
                                    <Image preview={false} fallback={avatarDefault} src={item.url} />
                                    <div className="username">{t("gameChooseModel.username")}：{item.name}</div>
                                    <div className="points">
                                        {/* {t("gameChooseModel.points")}：{100} */}
                                    </div>
                                </div>)
                            }
                        })}
                    </div>
                    <div className="right">
                        {slotList.map((item, index) => {
                            if (index % 2 === 1) {
                                return <div className="item" key={index}>
                                    <Image preview={false} fallback={avatarDefault} src={item.url} />
                                    <div className="username">{t("gameChooseModel.username")}：{item.name}</div>
                                    <div className="points">
                                        {/* {t("gameChooseModel.points")}：{200} */}
                                    </div>
                                </div>
                            }
                        })}
                    </div>
                </div>
        }
        </div>
    )
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = { MoveResult };

export default connect(mapStateToProps, mapDispatchToProps)(Matchmaking);
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { t } from 'i18next';

import { postWheelSpin } from '../../../api';
import { viewCode } from '../../../tools/viewCode';
import { openNotification } from '../../../tools/notification';
import { TOKENNAME } from '../../../tools/gameConfig';

import styles from "../../../scss/Hall/PersonalCenter/SuperPrizeGrid.module.scss"

const clockwiseOrder = [0, 1, 2, 5, 8, 7, 6, 3]; // 顺时针的顺序

const SuperPrizeGrid = ({ back, tetrisToken }) => {

    const [prizeIdx, setPrizeIdx] = useState(null);
    const [isRolling, setIsRolling] = useState(false);
    const [stopIdx, setStopIdx] = useState(null);
    const timer = useRef(null);
    const data = [
        { seat: 0, content: 50 },
        { seat: 1, content: 66 },
        { seat: 2, content: 88 },
        { seat: 3, content: 100 },
        { seat: 4, content: t("personalCenter.startDraw") }, // 中间的按钮
        { seat: 5, content: 128 },
        { seat: 6, content: 888 },
        { seat: 7, content: 999 },
        { seat: 8, content: 9999 },
    ];

    const move = useCallback(() => {
        setPrizeIdx(prevPrizeIdx => (prevPrizeIdx + 1) % clockwiseOrder.length);
    }, []);

    const startRolling = () => {
        if (isRolling || tetrisToken < 100) return;
        setIsRolling(true);
        setPrizeIdx(0);
        setStopIdx(null);
        timer.current = setInterval(move, 100);
        getStopIdx()
    };

    const getStopIdx = () => {
        postWheelSpin().then((res) => {
            if (!res.data.code) {
                if (res.data.data.rewardToken) {
                    setStopIdx(clockwiseOrder.findIndex((item) => item === data.findIndex((item) => item.content === res.data.data.rewardToken)))
                } else {
                    setIsRolling(false);
                    setPrizeIdx(null);
                    setStopIdx(null);
                    clearInterval(timer.current);
                    openNotification(t("responseCode.otherCode"))
                }
            } else {
                setIsRolling(false);
                setPrizeIdx(null);
                setStopIdx(null);
                clearInterval(timer.current);
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            }
        }).catch((err) => {
            setIsRolling(false);
            setPrizeIdx(null);
            setStopIdx(null);
            clearInterval(timer.current);
            openNotification(t("responseCode.otherCode"))
        })
    }

    useEffect(() => {
        if (stopIdx !== null && isRolling) {
            clearInterval(timer.current);
            timer.current = setInterval(move, 200);
            // 设置两圈内转到中奖位置后停止,计算步数
            let stpe = (stopIdx + 16 - prizeIdx)
            setTimeout(() => {
                clearInterval(timer.current);
                setIsRolling(false);
                setTimeout(() => {
                    back(data[clockwiseOrder[stopIdx]].content)
                    setPrizeIdx(null);
                }, 200);
            }, 200 * stpe);
        }
    }, [stopIdx, isRolling, move]);

    return (
        <div className={styles.super_prize_grid}>
            {data.map((item, index) => (
                <div
                    key={index}
                    className={index === 4 && tetrisToken < 100 ? styles.item + " " + styles.disabled :
                        (prizeIdx !== null && prizeIdx === clockwiseOrder.indexOf(index)) || index === 4 ? styles.item + " " + styles.current :
                            styles.item}
                    onClick={() => index === 4 && !isRolling && tetrisToken >= 100 && startRolling()}
                >
                    <div className={index === 4 ? "gif_open_img" : "gif_img"} ></div>
                    {index === 4 ? item.content : item.content + TOKENNAME}
                </div>
            ))}
        </div>
    );
};

export default SuperPrizeGrid;

export let table_header1 = [
    {
        title: '',
        type: '',
        dataIndex: 'key',
        key: 'index',
        align: 'center',
        width: '0px',
        render: (item) => {
            return
        }
    },
    {
        title: '',
        type: 'ordinal',
        dataIndex: 'rank',
        key: 'rank',
        align: 'center',
        width: '100px',
    },
    {
        type: "UID",
        dataIndex: 'email',
        key: 'email',
        align: 'center',
        width: '150px',
        ellipsis: true
    },
    {
        title: '',
        type: 'time',
        dataIndex: 'time',
        key: 'time',
        align: 'center',
        width: '200px',
    },
    {
        title: '',
        type: 'challenge',
        dataIndex: 'level',
        key: 'level',
        align: 'center',
        width: '120px'
    },
    {
        title: '',
        type: 'ability',
        dataIndex: 'power',
        key: 'power',
        align: 'center',
        width: '120px'
    },
    {
        title: '',
        type: 'score',
        dataIndex: 'points',
        key: 'points',
        align: 'center',
        width: '120px'
    },
];

export let table_header2 = [
    {
        title: '',
        type: '',
        dataIndex: 'key',
        key: 'index',
        align: 'center',
        width: '0px',
        render: (item) => {
            return
        }
    },
    {
        title: '',
        type: 'ordinal',
        dataIndex: 'rank',
        key: 'rank',
        align: 'center',
        width: '100px',
    },
    {
        type: "UID",
        dataIndex: 'email',
        key: 'email',
        align: 'center',
        width: '150px',
        ellipsis: true
    },
    {
        title: '',
        type: 'time',
        dataIndex: 'time',
        key: 'time',
        align: 'center',
        width: '150px',
    },
    {
        title: '',
        type: 'highestWinStreak',
        dataIndex: 'maxWinStreak',
        key: 'maxWinStreak',
        align: 'center',
        width: '150px'
    },
    {
        title: '',
        type: 'wins',
        dataIndex: 'winCount',
        key: 'winCount',
        align: 'center',
        width: '120px'
    },
    {
        title: '',
        type: 'losses',
        dataIndex: 'loseCount',
        key: 'loseCount',
        align: 'center',
        width: '120px'
    },
    {
        title: '',
        type: 'points',
        dataIndex: 'score',
        key: 'score',
        align: 'center',
        width: '120px'
    },
]

export let table_header3 = [
    {
        title: '',
        type: '',
        dataIndex: 'key',
        key: 'index',
        align: 'center',
        width: '0px',
        render: (item) => {
            return
        }
    },
    {
        title: '',
        type: 'ordinal',
        dataIndex: 'rank',
        key: 'rank',
        align: 'center',
        width: '100px',
    },
    {
        type: "UID",
        dataIndex: 'email',
        key: 'email',
        align: 'center',
        width: '150px',
        ellipsis: true
    },
    {
        title: '',
        type: 'time',
        dataIndex: 'time',
        key: 'time',
        align: 'center',
        width: '150px',
    },
    {
        title: '',
        type: 'highestScore',
        dataIndex: 'points',
        key: 'points',
        align: 'center',
        width: '150px'
    },
    {
        title: '',
        type: 'MVPs',
        dataIndex: 'mvpCount',
        key: 'mvpCount',
        align: 'center',
        width: '120px'
    },
    {
        title: '',
        type: 'points',
        dataIndex: 'score',
        key: 'score',
        align: 'center',
        width: '120px'
    },
]
import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import { Button, Image, Input } from 'antd';
import { t } from 'i18next';

import { postResetSecondPassword, postSecondPassword, postSendVerificationCode } from '../../../api';
import { openNotification } from '../../../tools/notification';
import { viewCode } from '../../../tools/viewCode';

import styles from '../../../scss/Hall/PersonalCenter/SafeSettings.module.scss'
import safeImg from '../../../assets/hall/security_setting_icon.png'
import BackIcon from '../../../assets/public_icon/left_icon.svg'
import OpenEye from "../../../assets/web/open_eye.png"
import OffEye from "../../../assets/web/off_eye.png"

let secondInterval = null;
function SafeSettings(props) {

    const [pwd, setPwd] = useState('');
    const [otp, setOtp] = useState('');
    const [step1, setstep1] = useState(true)
    const [step2, setstep2] = useState(false)
    const [step3, setstep3] = useState(false)
    const [pwdDiff, setPwdDiff] = useState(false)
    const [pwdError, setPwdError] = useState(false)
    const [email, setEmail] = useState(props.email)
    const [seconds, setSeconds] = useState(0);

    const [pwdRef1, setPwdRef1] = useState("");
    const [pwdRef2, setPwdRef2] = useState("");

    useEffect(() => {
        return () => {
            setPwd("")
            setOtp("")
            setstep1(true)
            setstep2(false)
            setstep3(false)
            setPwdDiff(false)
            setPwdError(false)
            setSeconds(0)
        }
    }, [])


    useEffect(() => {
        if (seconds <= 0) {
            secondInterval && clearInterval(secondInterval);
        } else if (seconds === 60) {
            secondInterval && clearInterval(secondInterval);
            secondInterval = setInterval(() => {
                setSeconds((n) => {
                    return n - 1;
                });
            }, 1000);
        }
    }, [seconds]);

    const handleClick = () => {
        setPwd("")
        setOtp("")
        setstep1(true)
        setstep2(false)
        setstep3(false)
        setPwdDiff(false)
        setSeconds(0)
    }

    const gotoStep2 = () => {
        setstep1(false)
        setstep2(true)
        setstep3(false)
    }

    const sendVerCode = () => {
        if (seconds > 0) {
            return
        }
        let action = ""
        if (props.secondPasswordSet) {
            action = "reset second password"
        } else {
            action = "set second password"
        }
        setSeconds(60)
        postSendVerificationCode({ email: email, action: action }).then((res) => {
            if (!res.data.code) {
                openNotification(t("user.sendVerificationCodeSuccess"))
            } else {
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            }
        }).catch((err) => {
            console.log(err)
            openNotification(t("responseCode.networkFailed"))
        })
    }

    const gotoStep5 = () => {
        var params = {}
        if (props.secondPasswordSet) {
            params = { verificationCode: otp.toUpperCase(), newSecondPassword: pwd, email: email }
            postResetSecondPassword(params).then((res) => {
                if (!res.data.code) {
                    openNotification(t("personalCenter.setSuccess"))
                    handleClick()
                } else {
                    openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                }
            }).catch((err) => {
                console.log(err)
                openNotification(t("responseCode.networkFailed"))
            })
        } else {
            params = { verificationCode: otp.toUpperCase(), secondPassword: pwd }
            postSecondPassword(params).then((res) => {
                if (!res.data.code) {
                    openNotification(t("personalCenter.setSuccess"))
                    handleClick()
                    props.updateUserInfo()
                } else {
                    openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                }
            }).catch((err) => {
                console.log(err)
                openNotification(t("responseCode.networkFailed"))
            })
        }
    }
    const gotoStep3 = () => {
        let pwdG = /^\d{6}$/
        if (!pwdG.test(pwdRef1)) {
            setPwdError(true)
        } else if (pwdRef1 !== pwdRef2) {
            setPwdError(false)
            setPwdDiff(true)
        } else {
            setPwd(pwdRef1)
            sendVerCode()
            setstep1(false)
            setstep2(true)
            setstep3(true)
        }

    }
    const gotoHome = () => {
        setstep1(true)
        setstep2(false)
        setstep3(false)
    }


    return (
        <div className={styles.safe_settings_wrapper}>
            {step1 ? (
                <div className={styles.step1}>
                    <div className={styles.top}>
                        <div className="web_title">{t("personalCenter.secondaryPassword")}</div>
                        <div className={styles.center}>{t("personalCenter.requiredSetPassword")}</div>
                        <div className={styles.button} onClick={gotoStep2}>{
                            props.secondPasswordSet ? t("personalCenter.reset") : t("personalCenter.set")}</div>
                    </div>
                    <div className={styles.safe_img}>
                        <Image preview={false} src={safeImg} />
                    </div>
                </div>
            ) : ("")
            }

            {step2 ? (
                <div className={styles.step2}>
                    <div className={styles.top}>
                        <Button className="close_button" onClick={gotoHome}><Image preview={false} src={BackIcon} /></Button>
                        <div className={styles.title}>{props.secondPasswordSet ? t("personalCenter.resetSecondaryPassword") : t("personalCenter.setSecondaryPassword")}</div>
                    </div>

                    <div className={styles.content}>
                        {step3 ? (
                            <>
                                <div className={styles.code}>{t("user.interCode")}</div>
                                <div className={styles.code_send}>{t("user.sendCode")} {" " + email}</div>

                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    inputStyle={styles.inputStyle}
                                    renderSeparator={<span></span>}
                                    renderInput={(props) => <input {...props} />}
                                    shouldAutoFocus
                                />

                                <div className={styles.option}>
                                    <div className="button_common secondary_button" onClick={sendVerCode}>
                                        {seconds <= 0 ? t("user.requestNew") : seconds + t("user.second")}
                                    </div>
                                    <div className="button_common primary_button" onClick={gotoStep5}>{t("user.next")}</div>
                                </div>
                            </>
                        ) : (
                            <>
                                <Input.Password
                                    className="form_input"
                                    placeholder={t("personalCenter.pleaseEnterSecondaryPassword")}
                                    value={pwdRef1}
                                    onChange={(e) => { setPwdRef1(e.target.value.trim()) }}
                                    iconRender={(visible) => (visible ? <Image preview={false} src={OpenEye} /> : <Image preview={false} src={OffEye} />)}
                                />
                                <div className={pwdError ? styles.msg_info_err : styles.msg_info}>
                                    {t("personalCenter.pleaseEnterSixNumerical")}
                                </div>
                                <Input.Password
                                    className="form_input"
                                    placeholder={t("personalCenter.pleaseEnterSecondaryPassword")}
                                    value={pwdRef2}
                                    onChange={(e) => { setPwdRef2(e.target.value.trim()) }}
                                    iconRender={(visible) => (visible ? <Image preview={false} src={OpenEye} /> : <Image preview={false} src={OffEye} />)}
                                />
                                {
                                    pwdDiff ? (
                                        <div className={styles.err_info}>{t("user.passwordConfirmError")}</div>
                                    ) : <div className={styles.err_info}> </div>}
                                <div className={styles.option1}>
                                    <div className="button_common primary_button" onClick={gotoStep3}>{t("user.next")}</div>
                                </div>
                            </>
                        )
                        }
                    </div>
                </div>
            ) : ("")
            }
        </div>
    )
}

export default SafeSettings
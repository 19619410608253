import React, { useEffect, useState } from 'react'
import { Tag, Dropdown, DatePicker } from 'antd'
import dayjs from 'dayjs';
import zhCn from "dayjs/locale/zh-cn"
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';

import Api from '../../../../src/socket/Api'
import { table_header1, table_header2, table_header3 } from './tableData'

import PublicPopup from '../../../Components/PublicComponents/PublicPopup';
import CommonTable from '../../../Components/PublicComponents/Table';
import CommonPagination from '../../../Components/PublicComponents/Pagination';
import './Index.scss'
import ic_next from '../../../assets/hall/ic_next_page.png'
import ic_pre from '../../../assets/hall/ic_pre_page.png'

dayjs.extend(customParseFormat);
dayjs.locale({
    ...zhCn,
    weekStart: 1,
})

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

function Index(props) {
    const { t } = useTranslation()

    const handleClick = (action) => {
        props.onClose(action)
    }

    // 关卡下拉选项
    const level_items = new Array(2).fill(0).map((_, index) => {
        var index = index + 1
        var xx = {
            label: t("tableData.challenge") + index,
            key: index,
        }
        return xx
    })

    const tagsData = [
        t("personalCenter.survivalMode"),
        t("personalCenter.classicBattle1v1"),
        t("personalCenter.classiceBattleMulti"),
        t("personalCenter.itemMode1v1"),
        t("personalCenter.itemModeMulti"),
    ]

    const { CheckableTag } = Tag;

    const [selectedTags, setSelectedTags] = useState(tagsData[0]);

    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState([0])

    const getDefaultStartTime = () => {
        const currentDate = dayjs();
        // 获取上一周的周一
        // const lastMondayDate = currentDate.subtract(1, 'week').startOf('week');
        // 本周
        const lastMondayDate = currentDate.startOf('week');
        const formattedLastMondayDate = lastMondayDate.format(dateFormat);
        return formattedLastMondayDate
    }

    const getDefaultEndTime = () => {
        const currentDate = dayjs();
        // 获取上一周的周日
        // const lastMondayDate = currentDate.subtract(1, 'week').endOf('week');
        // 本周
        const lastMondayDate = currentDate.endOf('week');
        const formattedLastMondayDate = lastMondayDate.format(dateFormat);
        return formattedLastMondayDate
    }

    const [soloTime, setSoloTime] = useState(dayjs().format(dateFormat));
    const [startTime, setStartTime] = useState(getDefaultStartTime())
    const [endTime, setEndTime] = useState(getDefaultEndTime())
    const [soloLevel, setSoloLevel] = useState(1)
    const [soloLevelName, setSoloLevelName] = useState(level_items[0].label)

    const handleChange = (tag, checked) => {
        setPage(1)
        setSelectedTags(tag)
    };

    useEffect(() => {
        if (selectedTags === tagsData[0]) {
            getSoloRank()
        } else if (selectedTags === tagsData[1]) {
            getClassic1v1Rank()
        } else if (selectedTags === tagsData[2]) {
            getClassic8v8Rank()
        } else if (selectedTags === tagsData[3]) {
            getProps1v1Rank()
        } else if (selectedTags === tagsData[4]) {
            getProps8v8Rank()
        }
    }, [selectedTags])


    function formatDateTime(isoDateString) {
        // const date = new Date(isoDateString);

        // const year = date.getFullYear();
        // const month = String(date.getMonth() + 1).padStart(2, '0');
        // const day = String(date.getDate()).padStart(2, '0');
        // const hours = String(date.getHours()).padStart(2, '0');
        // const minutes = String(date.getMinutes()).padStart(2, '0');

        // const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
        // return formattedDateTime;

        return dayjs(isoDateString).format('YYYY-MM-DD HH:mm:ss')
    }

    const convertSoloData = (it) => {
        const tmp = {
            key: it.id,
            rank: it.rank,
            email: it.nick_name,
            time: formatDateTime(it.created_at),
            level: it.level,
            points: it.points,
            power: it.power
        }
        return tmp;
    }

    const convertClassic1v1Data = (it) => {
        const tmp = {
            // key: new Date(),
            // rank: it.rank,
            // email: it.email,
            // time: formatDateTime(it.create_at),
            // maxWinStreak: it.maxWinStreak,
            // winCount: it.winCount,
            // loseCount: it.loseCount,
            // points: it.points,
            ...it,
            email: it.email,
            key: it.id,
            // time: formatDateTime(it.created_at),
            time: dayjs(it.season.started_at).format('MM/DD') + "-" + dayjs(it.season.end_at).format('MM/DD'),

        }
        return tmp;
    }

    const convertClassic8v8Data = (it) => {
        const tmp = {
            // key: new Date(),
            // rank: it.rank,
            // email: it.email,
            // time: formatDateTime(it.create_at),
            // mvpCount: it.mvpCount,
            // score: it.score,
            // points: it.points,
            ...it,
            email: it.email,
            key: it.id,
            // time: formatDateTime(it.created_at),
            time: dayjs(it.season.started_at).format('MM/DD') + "-" + dayjs(it.season.end_at).format('MM/DD'),


        }
        return tmp;
    }


    const getSoloRank = () => {
        setLoading(true)
        Api()
            .get(`/soloRank?level=${soloLevel}&time=${soloTime}&pageNo=${page}&pageSize=10`)
            .then((res) => {
                const { total, list } = res.data
                const arr = list.map(it => convertSoloData(it))
                if (selectedTags === tagsData[0]) {
                    setLoading(false)
                    setDataSource([...arr])
                    setTotal(total)
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)
            })
    }

    const getClassic1v1Rank = () => {
        setLoading(true)
        Api()
            .get(`/classic1v1Rank?startTime=${startTime}&endTime=${endTime}&pageNo=${page}&pageSize=10`)
            .then((res) => {
                const { total, list } = res.data
                const arr = list.map(it => convertClassic1v1Data(it))
                if (selectedTags === tagsData[1]) {
                    setLoading(false)
                    setDataSource([...arr])
                    setTotal(total)
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)
            })
    }

    const getClassic8v8Rank = () => {
        setLoading(true)
        Api()
            .get(`/classic8v8Rank?startTime=${startTime}&endTime=${endTime}&pageNo=${page}&pageSize=10`)
            .then((res) => {
                const { total, list } = res.data
                const arr = list.map(it => convertClassic8v8Data(it))
                if (selectedTags === tagsData[2]) {
                    setLoading(false)
                    setDataSource([...arr])
                    setTotal(total)
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)
            })
    }

    const getProps1v1Rank = () => {
        setLoading(true)
        Api()
            .get(`/props1v1Rank?startTime=${startTime}&endTime=${endTime}&pageNo=${page}&pageSize=10`)
            .then((res) => {
                const { total, list } = res.data
                const arr = list.map(it => convertClassic1v1Data(it))
                if (selectedTags === tagsData[3]) {
                    setLoading(false)
                    setDataSource([...arr])
                    setTotal(total)
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)
            })
    }


    const getProps8v8Rank = () => {
        setLoading(true)
        Api()
            .get(`/props8v8Rank?startTime=${startTime}&endTime=${endTime}&pageNo=${page}&pageSize=10`)
            .then((res) => {
                const { total, list } = res.data
                const arr = list.map(it => convertClassic8v8Data(it))
                if (selectedTags === tagsData[4]) {
                    setLoading(false)
                    setDataSource([...arr])
                    setTotal(total)
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)
            })
    }

    // useEffect(() => {
    //     handleChange(tagsData[0], true)
    // }, [])

    useEffect(() => {
        const tag = selectedTags
        if (tag === tagsData[0]) {
            getSoloRank()
        } else if (tag === tagsData[1]) {
            getClassic1v1Rank()
        } else if (tag === tagsData[2]) {
            getClassic8v8Rank()
        } else if (tag === tagsData[3]) {
            getProps1v1Rank()
        } else if (tag === tagsData[4]) {
            getProps8v8Rank()
        }
    }, [soloTime, soloLevel, page, startTime, endTime])
    table_header1.map((item) => {
        if (!item.title && item.type) {
            item.title = t(`tableData.${item.type}`)
        }
    })
    table_header2.map((item) => {
        if (!item.title && item.type) {
            item.title = t(`tableData.${item.type}`)
        }
    })
    table_header3.map((item) => {
        if (!item.title && item.type) {
            item.title = t(`tableData.${item.type}`)
        }
    })

    let columns = table_header1

    if (selectedTags === tagsData[1] || selectedTags === tagsData[3]) {
        columns = table_header2
    } else if (selectedTags === tagsData[2] || selectedTags === tagsData[4]) {
        columns = table_header3
    }

    const pageChange = (e) => {
        setPage(e)
    }
    const pageItemRender = (current, type, originalElement) => {

        if (type === 'prev') {
            return (
                <a>
                    <img src={ic_pre} />
                </a>
            )
        } else if (type === 'next') {
            return (
                <a>
                    <img src={ic_next} />
                </a>
            )
        }
        return originalElement
    }

    const onSoloLevelClick = ({ key }) => {
        setSoloLevel(key);
        setSoloLevelName(level_items[key - 1].label);
        setPage(1)
    };

    const handleSelectTime = (value, dateString) => {
        setStartTime(dateString[0])
        setEndTime(dateString[1])
    }

    const onChange = (date, dateString) => {
        setSoloTime(dateString)
    };

    return (
        <PublicPopup
            title={<Dropdown
                dropdownRender={(menu) => (
                    <div style={{ background: "#40077e", whiteSpace: "pre-wrap", borderRadius: "1vw", width: "30vw", height: "auto", color: "#fff", fontFamily: "Noto Sans", padding: "1.5vw", boxSizing: "border-box", fontSize: "0.78vw" }}>
                        {t("titleContent.rank")}
                        <a style={{ marginLeft: "0.5vw", color: "#f16e30", textDecoration: "underline", fontSize: "0.8vw" }} href={t("titleContent.rankLink")} target="_blank">{t("titleContent.more")}</a>
                    </div>
                )}
                trigger={['click', 'hover']}
                placement='bottom' >
                <div style={{ display: "flex", gap: "0.5vw", alignItems: "center", justifyContent: "center" }}>{t("hall.ranking")}<div className="price_info_ic"></div></div>
            </Dropdown>}
            closefun={(action) => handleClick(action)}>
            <div className="rank-wrapper">

                <div className="tab-div">
                    {tagsData.map((tag) => (
                        <CheckableTag
                            key={tag}
                            checked={selectedTags.includes(tag)}
                            onChange={(checked) => handleChange(tag, checked)}
                            className={selectedTags.includes(tag) ? 'tag check' : 'tag'}
                        >
                            {tag}
                        </CheckableTag>
                    ))}
                </div>
                <div className="table_bg">
                    <div className="filter_div">
                        <div className="filters">
                            {selectedTags === tagsData[0] ?
                                (
                                    <>
                                        <Dropdown
                                            menu={{
                                                items: level_items,
                                                onClick: onSoloLevelClick,
                                            }}
                                        >
                                            <a onClick={(e) => e.preventDefault()}>
                                                <div className="filter_label">
                                                    {soloLevelName}
                                                    <div className='dropdown_ic'></div>
                                                </div>
                                            </a>
                                        </Dropdown>

                                        {/* <DatePicker onChange={onChange} defaultValue={dayjs(soloTime, dateFormat)} /> */}
                                    </>) : ""


                                // <RangePicker
                                //     defaultValue={[dayjs(startTime, dateFormat), dayjs(endTime, dateFormat)]}
                                //     format={dateFormat}
                                //     onChange={handleSelectTime}
                                // />
                            }

                        </div>
                        <div className="pagination_div">
                            <CommonPagination onChange={pageChange} pageSize={10} current={page} total={total} />
                        </div>
                    </div>
                    <div className="table_div">
                        <CommonTable
                            dataSource={dataSource}
                            columns={columns}
                            loading={loading}
                        />
                    </div>
                </div>

            </div>

        </PublicPopup>
    )
}

export default Index
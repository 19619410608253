import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import adImg1 from '../../assets/web/ad_img1.png'
import adImg2 from '../../assets/web/ad_img2.png'
import closeIcon from '../../assets/public_icon/close_icon.svg'

import '../../scss/AdSlod/Index.scss'
import { Button, Image } from 'antd'

function AdSlod(props) {

    const { t } = useTranslation()
    const [slodHeader, setSlodHeader] = useState(false)
    const [slodLeft, setSlodLeft] = useState(true)
    const [slodRight, setSlodRight] = useState(true)


    return (
        <div className='ad_slod'>
            <div className="ad_slod_children">{props.children}</div>
            {slodHeader ?
                <div className='ad_slod_header ad_slod_item'
                    onClick={() => { window.open("https://hao.360.cn/?src=lm&ls=n34a90e18a7", "_blank") }}
                >
                    <div className='ad_slod_close_button' onClick={() => { setSlodHeader(!slodHeader) }}>
                        <Image className='ad_slod_close_button_img' preview={false} src={closeIcon} />
                    </div>
                    <div
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                        <Image className='ad_img2' preview={false} src={adImg2} />
                    </div>
                    <div
                        style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "10px" }}
                    >
                        <div className='ad_slod_content'>{t("gameChooseModel.adTitle")}</div>
                        <Button className='button_common button_shadow primary_button'
                            onClick={(e) => { e.stopPropagation(); window.open('https://urlqh.cn/sSlfm', "_blank") }}
                        >{t("gameChooseModel.adDownload")}</Button>
                    </div>
                    <div
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                        <Image className='ad_img1' preview={false} src={adImg1} />
                    </div>
                </div> : ""}
            {slodLeft ?
                <div className='ad_slod_left ad_slod_center ad_slod_item'
                    onClick={() => { window.open("https://hao.360.cn/?src=lm&ls=n34a90e18a7", "_blank") }} >
                    <div className='ad_slod_close_button' onClick={() => { setSlodLeft(!slodLeft) }}>
                        <Image className='ad_slod_close_button_img' preview={false} src={closeIcon} />
                    </div>
                    <div className='ad_slod_content'>{t("gameChooseModel.adTitle")}</div>
                    <div
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                        <Image className='ad_img1' preview={false} src={adImg1} />
                    </div>
                    <div
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                        <Image className='ad_img2' preview={false} src={adImg2} />
                    </div>
                    <Button className='button_common button_shadow primary_button'
                        onClick={(e) => { e.stopPropagation(); window.open('https://urlqh.cn/sSlfm', "_blank") }}
                    >{t("gameChooseModel.adDownload")}</Button>
                </div> : ""}
            {slodRight ?
                <div className='ad_slod_right ad_slod_center ad_slod_item'
                    onClick={() => { window.open("https://hao.360.cn/?src=lm&ls=n34a90e18a7", "_blank") }} >
                    <div className='ad_slod_close_button' onClick={() => { setSlodRight(!slodLeft) }}>
                        <Image className='ad_slod_close_button_img' preview={false} src={closeIcon} />
                    </div>
                    <div className='ad_slod_content'>{t("gameChooseModel.adTitle")}</div>
                    <div
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                        <Image className='ad_img1' preview={false} src={adImg1} />
                    </div>
                    <div
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                        <Image className='ad_img2' preview={false} src={adImg2} />
                    </div>
                    <Button className='button_common button_shadow primary_button'
                    >{t("gameChooseModel.adLoadMore")}</Button>
                </div> : ""}
        </div>
    )
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdSlod);
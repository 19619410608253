import { useEffect } from "react";
import { socket } from "../../socket/socket";
import { useSelector } from "react-redux";

function Room({ loading, interGameType, gameLevel }) {

  const roomDetail = useSelector((state) => state.sockets.roomDetail);
  const token = useSelector((state) => state.user.token)
  const nftIds = useSelector((state) => state.sockets.selectedHero)

  useEffect(() => {
    // console.log(roomDetail.name, interGameType, loading, gameLevel, token, nftIds)
    if (!roomDetail.name && !interGameType && !loading && nftIds.length > 0 && gameLevel > 0 && token) {
      // console.log("created solo Room")
      const roomName = "soloroom" + new Date().getTime()
      let nftIdList = []
      nftIds.map(item => nftIdList.push(item.id))
      socket.emit("create_room", { name: roomName, mode: 'solo', maxplayers: 1, token, gameLevel, nftIds: nftIdList });
    } else if (interGameType && !loading && !roomDetail.name) {
      // other mode create room error
      console.log("other mode create room error")
    }
  }, [roomDetail, interGameType, loading, gameLevel, token, nftIds]);

  return ""

}

export default Room;

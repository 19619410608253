import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import anime from 'animejs/lib/anime.es.js';

import Board from "./board";
import "../../scss/Game/home.scss";
import "../../scss/Game/room.scss";
import { useStage } from "../../hooks/useStage";
import { usePlayer } from "../../hooks/usePlayer";
import { createStage, checkCollision } from "./gameHelpers";
import { useInterval } from "../../hooks/useInterval";
import { useGameStatus } from "../../hooks/useGameStatus";
import NextPiece from "./NextPiece";
import PlayersStage from "./PlayersStage";
import { socket } from "../../socket/socket";
import { connect, useSelector } from "react-redux";
import hard from "../../assets/hardDrop.mp3";
import moove from "../../assets/move.mp3";
import logo_img from '../../assets/icon/nav_tips.png'
import level_cube from '../../assets/icon/level_cube.png'
import right_icon from '../../assets/icon/score_text_right_icon.png'
import left_icon from '../../assets/icon/score_text_left_icon.png'
import pause from '../../assets/icon/pause.png'
import fakeTar from '../../assets/hall/avatar_default.png'
import freeze_card from '../../assets/board/freeze_card.png'
import add_card from '../../assets/board/add_card.png'
import acc_card from '../../assets/board/acc_card.png'

import { addWall, gameOverAction, MoveResult, resetProps, SoloResumeGame } from "../../redux/actions/sockets/socketsActions";
import { Image, Statistic } from 'antd';
import { PauseOutlined } from '@ant-design/icons';
import OtherPlayerBg from "./OtherPlayerBg";

import { getRand, displayKey } from '../../tools/utils'
import zlib from "zlib";
import { t } from "i18next";
import { openNotification } from "../../tools/notification";
import GameResultModal from "./GameResultModel";

const probObj = [{
  name: 'freeze',
  prob: 100
},
{
  name: 'acc',
  prob: 100
},
{
  name: 'add',
  prob: 100
}
];

function Game(props) {
  const navigate = useNavigate()
  const params = useLocation().state
  const { tetriminos, stages, scores, propsData, roomDetail, userName, gameFinished, wall, wallData, addWall, resetProps, GameOver, gameOverAction, gameResult, currentUser, SoloResumeGame, gameAsyncTime, MoveResult } = props;

  const soundEffects = useSelector((state) => state.localSetting.soundSettings.soundEffects)
  const soundSetting = useSelector((state) => state.localSetting.soundSettings.music)
  const specialEffect = useSelector((state) => state.localSetting.soundSettings.specialEffects)
  const disposalReminder = useSelector((state) => state.localSetting.soundSettings.disposalReminder)
  const leftKey = useSelector((state) => state.localSetting.soundSettings.customKeyCode ? (state.localSetting.soundSettings.customKeyCode.left ? state.localSetting.soundSettings.customKeyCode.left : 37) : 37)
  const rightKey = useSelector((state) => state.localSetting.soundSettings.customKeyCode ? (state.localSetting.soundSettings.customKeyCode.right ? state.localSetting.soundSettings.customKeyCode.right : 39) : 39)
  const rotateKey = useSelector((state) => state.localSetting.soundSettings.customKeyCode ? (state.localSetting.soundSettings.customKeyCode.rotate ? state.localSetting.soundSettings.customKeyCode.rotate : 38) : 38)
  const pushKey = useSelector((state) => state.localSetting.soundSettings.customKeyCode ? (state.localSetting.soundSettings.customKeyCode.push ? state.localSetting.soundSettings.customKeyCode.push : 40) : 40)
  const momentumKey = useSelector((state) => state.localSetting.soundSettings.customKeyCode ? (state.localSetting.soundSettings.customKeyCode.momentum ? state.localSetting.soundSettings.customKeyCode.momentum : 32) : 32)
  const rotateLKey = useSelector((state) => state.localSetting.soundSettings.customKeyCode ? (state.localSetting.soundSettings.customKeyCode.rotateL ? state.localSetting.soundSettings.customKeyCode.rotateL : 90) : 90)
  const reverseKey = useSelector((state) => state.localSetting.soundSettings.customKeyCode ? (state.localSetting.soundSettings.customKeyCode.reverse ? state.localSetting.soundSettings.customKeyCode.reverse : 67) : 67)
  const freezeKey = useSelector((state) => state.localSetting.soundSettings.customKeyCode ? (state.localSetting.soundSettings.customKeyCode.freeze ? state.localSetting.soundSettings.customKeyCode.freeze : 81) : 81)
  const addKey = useSelector((state) => state.localSetting.soundSettings.customKeyCode ? (state.localSetting.soundSettings.customKeyCode.add ? state.localSetting.soundSettings.customKeyCode.add : 87) : 87)
  const accKey = useSelector((state) => state.localSetting.soundSettings.customKeyCode ? (state.localSetting.soundSettings.customKeyCode.acc ? state.localSetting.soundSettings.customKeyCode.acc : 69) : 69)
  const nftIds = useSelector((state) => state.sockets.selectedHero)
  const competitiveList = useSelector((state) => state.sockets.roomPlayers);

  const [currentLeftKey, setCurrentLeftKey] = useState(leftKey);
  const [currentRotateLKey, setCurrentRotateLKey] = useState(rotateLKey)
  const [currentReverseKey, setCurrentReverseKey] = useState(reverseKey)
  const [currentRightKey, setCurrentRightKey] = useState(rightKey);
  const [currentRotateKey, setCurrentRotateKey] = useState(rotateKey);
  const [currentPushKey, setCurrentPushKey] = useState(pushKey);
  const [currentMomentumKey, setCurrentMomentumKey] = useState(momentumKey);
  const [currentFreezeKey, setCurrentFreezeKey] = useState(freezeKey);
  const [currentAddKey, setCurrentAddKey] = useState(addKey);
  const [currentAccKey, setCurrentAccKey] = useState(accKey);
  const [username, setusername] = useState(userName);
  const [currentuser, setCurrentuser] = useState(currentUser);
  const [isViewer, setIsViewer] = useState(currentUser.viewer);
  const [myNftUrl, setMyNftUrl] = useState(currentUser.url);
  const [viewUserName, setViewUserName] = useState("");
  const [roomName, setroomName] = useState(roomDetail.id);
  const [mode, setmode] = useState(roomDetail.mode)
  const [maxplayers, setmaxplayers] = useState(roomDetail.maxplayers)
  const [alivePlayer, setalivePlayer] = useState(roomDetail.alivePlayer || roomDetail.players)
  const [localGameTime, setLocalGameTime] = useState(null)
  const start = props.data.start;
  const setstart = props.data.setstart;
  const interGameType = props.data.interGameType
  const gameLevel = props.data.gameLevel
  const gamePower = props.data.gamePower
  const [gameOver, setGameOver] = useState(false);
  const [dropTime, setDropTime] = useState(null);
  const [submited, setsubmited] = useState(true);
  const gameRef = useRef(null);
  const [getTetrimino, setgetTetrimino] = useState(false);
  const [gameStart, setGameStart] = useState(false);
  const [firstDrop, setfirstDrop] = useState(1);
  const [audio] = useState(new Audio(hard));
  const [moved] = useState(new Audio(moove));
  const [deadLineTime, setDeadLineTime] = useState(Date.now() + 3 * 60 * 1000);
  const [remainTime, setRemainTime] = useState(3 * 60 * 1000);
  const [playStatus, setPlaystatus] = useState(true);
  const [prevDropTime, setPrevDropTime] = useState(1000);
  const [win, setIsWin] = useState(0);
  const [copyScore, setCopyScore] = useState(0);
  const [timeoutOver, setTimeoutOver] = useState(false);
  const [openModal, setIsopenModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [odds, setOdds] = useState(roomDetail.mode === 'props' ? (params.nft.NftAttributes.attributes.skill.rate * 100) + 10 : 10)
  const [isFreezeGame, setIsFreezeGame] = useState(false);
  const [difficultyTime, setDifficultyTime] = useState(1000);
  const [preRow, setPreRow] = useState(0);
  const [isShowProps, setIsShowProps] = useState(false);
  const [propMode, setPropMode] = useState('freeze');
  const [currentToUsrArr, setCurrentToUsrArr] = useState([]);
  const [currentToOtherUsrArr, setCurrentToOtherUsrArr] = useState([]);
  const [propsUsageInterval, setPropsUsageInterval] = useState({});
  const [isShowEffect, setIsShowEffect] = useState(false);
  const [isReleaseKey, setIsReleaseKey] = useState(true);
  const [freezePropsNum, setFreezePropsNum] = useState(0);
  const [accPropsNum, setAccPropsNum] = useState(0);
  const [addPropsNum, setAddPropsNum] = useState(0);
  const [propsSeletedUser, setPropsSeletedUser] = useState('');
  const [propsSeletedUserIndex, setPropsSeletedUserIndex] = useState(1);
  const [viewerNextStage, setViewerNextStage] = useState(createStage(4, 4))
  const [viewerScore, setViewerScore] = useState(0)

  const { Countdown } = Statistic;

  const boardAnimation = anime({
    targets: '.game',
    easing: 'easeInOutQuad',
    direction: 'alternate',
    translateY: '1vh',
    duration: 100,
    autoplay: false
  });

  // Custom Hooks
  const [player, nextPiece, updatePlayerPos, resetPlayer, playerRotate, concatTetriminos, setConcatTetriminos,] =
    usePlayer(setGameOver, setstart, setDropTime, tetriminos, setgetTetrimino, userName,
      roomName, gameOverAction, roomDetail.mode, win, gameLevel, gamePower, copyScore, timeoutOver,
      maxplayers, isViewer, nftIds);
  const [stage, nextStage, isAddUsrWall, sweepExactRows, rowsCleared, setStage, setNextStage, setIsAddUsrWal, setSweepExactRows] =
    useStage(player, nextPiece, resetPlayer, gameOver, start,
      userName, roomName, wall, wallData, addWall, isViewer, timeoutOver);

  const [score, setScore, rows, setRows, level, setLevel] = useGameStatus(rowsCleared, isViewer, soundEffects);

  // Check if the Game finished (Battle mode)
  useEffect(() => {
    setstart(true);
    setgetTetrimino(false);
    setGameOver(false);
    if (!gameFinished && tetriminos.length > 0) {
      setStage(createStage());
      setNextStage(createStage(4, 4));
      resetPlayer();
      setGameOver(false);
      setScore(0);
      setLevel(0);
      setRows(0);
    }
  }, [gameFinished]);

  //select user to attack randomly
  useEffect(() => {
    if (competitiveList.length > 0) {
      let userObj = [];
      competitiveList.map(item => {
        if (item.id != currentUser.id) {
          userObj.push({
            name: item.socketId,
            prob: 100
          })
        }
      })

      const currentUsrObj = new getRand(userObj)
      const arr = competitiveList.filter(item => item.socketId === currentUsrObj.name)

      setPropsSeletedUser(currentUsrObj.name)
      setPropsSeletedUserIndex(arr.length > 0 ? (arr[0].index || "") : 1)
    }
  }, [competitiveList])

  useEffect(() => {
    if (start && gameResult.length) {
      setIsopenModal(true)
      setGameOver(true)
      if (gameResult.length <= 2) {
        setModalType("1v1Over")
      } else if (gameResult.length <= 8) {
        setModalType("8v8Over")
      }
    }
  }, [gameResult, start])

  //  currentuser
  useEffect(() => {
    console.log("当前玩家更新", currentUser)
    setCurrentuser(currentUser)
    if (!isViewer) {
      setIsViewer(currentUser.viewer)
    }
    setMyNftUrl(currentUser.url)
  }, [currentUser])

  //  close window
  useEffect(() => {
    if (window) {
      window.addEventListener("beforeunload", onbeforeunload);
      return () => {
        window.removeEventListener("beforeunload", onbeforeunload);
      }
    }
  }, [window])

  const onbeforeunload = (event) => {
    // event.preventDefault();
    event.returnValue = false;
    if (event.clientX > document.body.clientWidth && event.clientY < 0 || event.altKey) {
      // 关闭浏览器
    } else {
      // 刷新页面 设置标记
      localStorage.setItem('loading', true)
    }
  }

  //Emit the stage
  useEffect(() => {
    // console.log(stage, roomName, username, isViewer)
    if (roomName && username && !isViewer && !gameOver) {
      zlib.gzip(JSON.stringify(stage), (err, buffer) => {
        socket.emit("Stage", {
          stage: buffer.toString('base64'),
          roomName: roomName, username, nextStage: nextStage
        });
      })
    }
  }, [stage]);

  // Emit the score
  useEffect(() => {
    // console.log(score, roomName, username, roomDetail)
    if (roomName && username && !isViewer && !gameOver) {
      socket.emit("Score", { score, roomName: roomName, username, url: myNftUrl, mode });
    }
  }, [score]);

  useEffect(() => {
    setCopyScore(score)
  }, [score])

  useEffect(() => {
    if (gameOver && !gameResult.length) {
      setPlaystatus(false)
      // clearInterval(setGameDifficulty)
      if (maxplayers === 1) {
        setIsopenModal(true)
        if (win === 1) {
          score >= 400 ?
            setModalType("soloSuccess")
            : setModalType("soloScoreFail")
        } else {
          setModalType("soloFail")
        }
      } else if (maxplayers <= 8 && maxplayers > 2) {
        setIsopenModal(true)
        setModalType("8v8Fail")
      }
    }
  }, [gameOver])

  // making, created room
  useEffect(() => {
    if ((interGameType !== "invite" && currentuser.admin) || (!interGameType && currentuser.admin)) {
      startgame()
    }
  }, [interGameType, currentuser])

  // friend invited room
  useEffect(() => {
    if (roomDetail.name && interGameType === "invite" && roomDetail.maxplayers === roomDetail.players && currentuser.admin) {
      startgame()
    }
  }, [interGameType, roomDetail, currentuser])

  // update RoomDetail
  useEffect(() => {
    setroomName(roomDetail.id)
    setmaxplayers(roomDetail.maxplayers)
    setalivePlayer(roomDetail.alivePlayer || roomDetail.players)
    setmode(roomDetail.mode)
  }, [roomDetail])

  // init viewer data
  useEffect(() => {
    let sta = stages.filter((item) => item.username === viewUserName)[0]
    if (isViewer && stages.length > 0 && !sta) {
      setViewUserName(stages[0].username)
    }
  }, [isViewer, stages])

  // update viewer data
  const updateViewUserName = (username) => {
    if (!isViewer) { return }
    setViewUserName(username)
  }

  // update viewerData
  useEffect(() => {
    if (viewUserName) {
      let sta = stages.filter((item) => item.username === viewUserName)[0]
      setViewerNextStage(sta?.nextStage || createStage(4, 4))
      let sta1 = scores.filter((item) => item.username === viewUserName)[0]
      setViewerScore(sta1?.score)
    }
  }, [viewUserName, stages])

  // Start Game effect
  useEffect(() => {
    if (gameStart && !isViewer) {
      if (gameOver || (gameFinished && tetriminos.length > 0)) {
        setStage(createStage());
        setNextStage(createStage(4, 4));
        resetPlayer();
        setGameOver(false);
        setScore(0);
        setLevel(0);
        setRows(0);
        setDropTime(1000);
      }
      if (firstDrop === 1) {
        resetPlayer();
        setfirstDrop(2);
        setScore(0);
        setLevel(0);
        setRows(0);
        props.data.setSound(true);
        if (soundSetting) {
          props.data.audio.play();
        }
      }
      setstart(false);
      setGameOver(false);
      setGameStart(false);
      if (mode === 'solo') {
        setDropTime(1000 / (level + 1) + 200);
      }
    }
  }, [gameStart]);

  // Start Game (Drop the tetrimino down)
  useInterval(() => {
    // console.log(playStatus, isViewer, new Date())
    if (playStatus && !isViewer && mode == 'solo') {
      drop();
    }
  }, dropTime);

  //Increase the drop speed per 10 seconds
  // useInterval(() => {
  //   if (mode != 'solo' && dropTime > 100) {
  //     setDropTime(dropTime * 0.93);
  //   }

  // }, 10000);

  //Handle start game
  function startgame(e) {
    // if (e.key === "Enter" && submited) {
    if (!getTetrimino) {
      socket.emit("startgame", { room: roomName });
    }
    // }
  }

  useEffect(() => {
    if (isViewer) { return }
    pauseDrop()
    if (playStatus) {
      setDeadLineTime(Date.now() + remainTime)
    }

  }, [playStatus])

  //Get Tetriminos effect
  useEffect(() => {
    // console.log("tetriminos", tetriminos)
    if (tetriminos.length > 0 && !GameOver && !isViewer) {
      setGameStart(true);
      setgetTetrimino(true);
    }
    return () => { };
  }, [tetriminos]);

  // Get Tetriminos for the second time
  useEffect(() => {
    if (concatTetriminos && !isViewer) {
      socket.emit("newTetriminos", { room: roomName });
      setConcatTetriminos(false);
    }
  }, [concatTetriminos]);

  // Set focus on the game
  useEffect(() => {
    gameRef.current.focus();
  }, []);

  // Move the tetrimino on (x) axis
  const movePlayer = (dir) => {
    if (isViewer) { return }
    if (!checkCollision(player, stage, { x: dir, y: 0 })) {
      updatePlayerPos({ x: dir, y: 0 });
    }
  };

  // Stop move the tetrimino down

  const pauseDrop = () => {
    if (isViewer) { return }
    if (!playStatus) {
      setPrevDropTime(dropTime)

    }
    if (playStatus) {
      if (dropTime === 1000) {
        setDropTime(1200)
      } else {
        setDropTime(prevDropTime)
      }
    } else {
      setDropTime(9999999999999999999999999999999999999999);
    }
  }

  // Move the tetrimino down
  const drop = () => {
    if (isViewer) { return }
    // randomly generate prop when player has cleared 3 rows
    if (mode === 'props' && rows > 0 && rows > preRow && (rows - preRow >= 3 || rows % 3 === 0)) {
      const rateList = [{
        name: 'Y',
        prob: odds
      },
      {
        name: 'N',
        prob: 100 - odds
      }
      ];
      const result = new getRand(rateList)
      if (result.name === 'Y') {
        const randomProp = new getRand(probObj)
        if (randomProp.name === 'freeze') {
          setFreezePropsNum(freezePropsNum + 1)
        } else if (randomProp.name === 'acc') {
          setAccPropsNum(accPropsNum + 1)
        } else if (randomProp.name === 'add') {
          setAddPropsNum(addPropsNum + 1)
        }
      }
      setPreRow(rows)
    }

    // Increase level when player has cleared 4 rows
    if (mode === 'solo' && rows > (level + 1) * 3) {
      setLevel((prev) => prev + 1);
      // Also increase speed
      setDropTime(1000 / (level + 1) + 200);
    }
    if (!checkCollision(player, stage, { x: 0, y: 1 })) {
      updatePlayerPos({ x: 0, y: 1, collided: false });
    } else {
      // the player has landed, so we can stop moving the tetrimino down and drop the next one
      updatePlayerPos({ x: 0, y: 0, collided: true });
    }
  };

  useEffect(() => {
    if (rows > 0 && specialEffect) {
      setIsShowEffect(true)
      setTimeout(() => {
        setIsShowEffect(false)
      }, 400);
    }
  }, [rows])

  // Hard Drop the tetrimino
  const hardDrop = () => {
    if (isViewer) { return }
    if (soundEffects) {
      audio.play();
    }
    let tmp = 0;
    while (!checkCollision(player, stage, { x: 0, y: tmp })) tmp += 1;
    updatePlayerPos({ x: 0, y: tmp - 1, collided: false });
    if (specialEffect) {
      boardAnimation.play()
    }
  };

  // Move the tetrimino
  const move = ({ keyCode }) => {
    if (isViewer) { return }
    if (!gameOver && submited && playStatus && !isFreezeGame) {
      if (keyCode === currentLeftKey) {
        if (soundEffects) {
          moved.play();
        }
        movePlayer(-1);
      } else if (keyCode === currentRightKey) {
        if (soundEffects) {
          moved.play();
        }
        movePlayer(1);
      } else if (keyCode === currentPushKey) {
        if (soundEffects) {
          moved.play();
        }
        drop();
      } else if (keyCode === currentRotateKey && isReleaseKey) {
        setIsReleaseKey(false)
        if (soundEffects) {
          moved.play();
        }
        // Rotate the tetrimino(usePlayer Hook)
        playerRotate(stage, 1);
      } else if (keyCode === currentRotateLKey && isReleaseKey) {
        setIsReleaseKey(false)
        if (soundEffects) {
          moved.play();
        }
        // Rotate the tetrimino(usePlayer Hook)
        playerRotate(stage, 2);
      } else if (keyCode === currentReverseKey && isReleaseKey) {
        setIsReleaseKey(false)
        if (soundEffects) {
          moved.play();
        }
        // Rotate the tetrimino(usePlayer Hook)
        playerRotate(stage, 3);
      } else if (keyCode === currentMomentumKey) {
        hardDrop();
      } else if (keyCode === currentFreezeKey) {
        if (freezePropsNum > 0) {
          userUseProps('freeze')
        }
      } else if (keyCode === currentAddKey) {
        if (addPropsNum > 0) {
          userUseProps('add')
        }

      } else if (keyCode === currentAccKey) {
        if (accPropsNum > 0) {
          userUseProps('acc')
        }
      } else if (keyCode === 49 && competitiveList.length > 0) {
        if (competitiveList.filter(item => item.id === currentUser.id)[0].index != 1) {
          const arr = competitiveList.filter(item => item.index === 1)
          setPropsSeletedUser(arr.length > 0 ? (arr[0].socketId || "") : "")
          setPropsSeletedUserIndex(arr.length > 0 ? (arr[0].index || "") : 1)
        }
      } else if (keyCode === 50 && competitiveList.length > 0) {
        if (competitiveList.filter(item => item.id === currentUser.id)[0].index != 2) {
          const arr = competitiveList.filter(item => item.index === 2)
          setPropsSeletedUser(arr.length > 0 ? (arr[0].socketId || "") : "")
          setPropsSeletedUserIndex(arr.length > 0 ? (arr[0].index || "") : 1)
        }
      } else if (keyCode === 51 && competitiveList.length > 0) {
        if (competitiveList.filter(item => item.id === currentUser.id)[0].index != 3) {
          const arr = competitiveList.filter(item => item.index === 3)
          setPropsSeletedUser(arr.length > 0 ? (arr[0].socketId || "") : "")
          setPropsSeletedUserIndex(arr.length > 0 ? (arr[0].index || "") : 1)
        }
      } else if (keyCode === 52 && competitiveList.length > 0) {
        if (competitiveList.filter(item => item.id === currentUser.id)[0].index != 4) {
          const arr = competitiveList.filter(item => item.index === 4)
          setPropsSeletedUser(arr.length > 0 ? (arr[0].socketId || "") : "")
          setPropsSeletedUserIndex(arr.length > 0 ? (arr[0].index || "") : 1)
        }
      } else if (keyCode === 53 && competitiveList.length > 0) {
        if (competitiveList.filter(item => item.id === currentUser.id)[0].index != 5) {
          const arr = competitiveList.filter(item => item.index === 5)
          setPropsSeletedUser(arr.length > 0 ? (arr[0].socketId || "") : "")
          setPropsSeletedUserIndex(arr.length > 0 ? (arr[0].index || "") : 1)
        }
      } else if (keyCode === 54 && competitiveList.length > 0) {
        if (competitiveList.filter(item => item.id === currentUser.id)[0].index != 6) {
          const arr = competitiveList.filter(item => item.index === 6)
          setPropsSeletedUser(arr.length > 0 ? (arr[0].socketId || "") : "")
          setPropsSeletedUserIndex(arr.length > 0 ? (arr[0].index || "") : 1)
        }
      } else if (keyCode === 55 && competitiveList.length > 0) {
        if (competitiveList.filter(item => item.id === currentUser.id)[0].index != 7) {
          const arr = competitiveList.filter(item => item.index === 7)
          setPropsSeletedUser(arr.length > 0 ? (arr[0].socketId || "") : "")
          setPropsSeletedUserIndex(arr.length > 0 ? (arr[0].index || "") : 1)
        }
      } else if (keyCode === 56 && competitiveList.length > 0) {
        if (competitiveList.filter(item => item.id === currentUser.id)[0].index != 8) {
          const arr = competitiveList.filter(item => item.index === 8)
          setPropsSeletedUser(arr.length > 0 ? (arr[0].socketId || "") : "")
          setPropsSeletedUserIndex(arr.length > 0 ? (arr[0].index || "") : 1)
        }
      }
    }
  };

  const timeFinsh = () => {
    // setGameOver(true)
    setIsWin(1)
    setTimeoutOver(true)
    setRemainTime(0)
  }
  useInterval(() => {
    if (mode === 'props') {
      setCurrentToUsrArr((prev) => {
        let arr = prev
        if (arr.length > 0 && !isShowProps) {
          setIsShowProps(true)
          setPropMode(arr[0].props)
          let time = 3000
          if (arr[0].props === 'freeze') {
            freezeGame(true)
          } else if (arr[0].props === 'acc') {
            accelerateDropTime()
            time = 1000
          } else if (arr[0].props === 'add') {
            addTetrimions()
          }
          arr.splice(0, 1)
          setTimeout(() => {
            setIsShowProps(false)
          }, time);
        }
        return [...arr]
      })
      setCurrentToOtherUsrArr((prev) => {
        // console.log(prev)
        let arr = prev
        arr.map(item => {
          if (!item.isShowProps && item.data.length > 0) {
            let time = item.propMode === 'acc' ? 1000 : 3000
            item.isShowProps = true
            item.propMode = item.data[0].props
            item.data.splice(0, 1)
            item.deadlines = Date.now() + 1000 * 4
            setTimeout(() => {
              item.isShowProps = false
            }, time);
          }
        })
        return [...arr]
      })
    }
  }, 1000);

  // props mode function

  const propsUsage = (val) => {
    let currentToUsrArr1 = [], currentToOtherUsrArr1 = [], nameArr = [], reconfigArr = [];
    try {
      val.map((item, index) => {
        if (item.to.name === userName) {
          currentToUsrArr1.push(item)
        } else {
          nameArr.push(item.to.name)
          currentToOtherUsrArr1.push(item)
        }
      })
      nameArr = Array.from(new Set(nameArr))

      nameArr.map(item => {
        const tempArr = currentToOtherUsrArr1.filter(res => res.to.name === item)
        reconfigArr.push({
          isShowProps: false,
          name: item,
          propMode: tempArr[0].props,
          deadlines: Date.now() + 1000 * 4,
          data: tempArr
        })
      })

      setCurrentToUsrArr([...currentToUsrArr, ...currentToUsrArr1])
      setCurrentToOtherUsrArr([...currentToOtherUsrArr, ...reconfigArr])
      resetProps()
    } catch (e) {
      console.log(e)
    }
  }

  // user use props manually
  const userUseProps = (propName) => {
    // console.log(propName, propsSeletedUser)
    socket.emit("useProps", { room: roomName, props: propName, to: propsSeletedUser });
    if (propName === 'freeze') {
      setFreezePropsNum(freezePropsNum - 1)
    } else if (propName === 'acc') {
      setAccPropsNum(accPropsNum - 1)
    } else if (propName === 'add') {
      setAddPropsNum(addPropsNum - 1)
    }
  }

  //freeze the game

  const freezeGame = (val) => {
    setIsFreezeGame(val)
    setTimeout(() => {
      setIsFreezeGame(false)
    }, 3000);
  }

  // accelerate drop time
  const accelerateDropTime = () => {
    hardDrop()
    setIsFreezeGame(true)
    setTimeout(() => {
      setIsFreezeGame(false)
    }, 1000);
  }

  useEffect(() => {
    if (gameOver) {
      return
    }
    if (gameAsyncTime && localGameTime) {
      if (gameAsyncTime - localGameTime > 10000) {
        socket.emit("leaveRoom")
        openNotification(t("game.networkDisconnectedGameOver"))
        navigate("/", { replace: true })
      }
    }
    if (gameAsyncTime && !localGameTime) {
      // console.log("开始计时")
      UpdateGameTime()
    }
    // console.log(gameAsyncTime - localGameTime)
    drop()

  }, [gameAsyncTime])

  const UpdateGameTime = () => {
    // console.log("计时")
    setLocalGameTime(gameAsyncTime)
  }

  useInterval(() => {
    if (localGameTime) {
      setLocalGameTime((time) => {
        return time + 1000;
      })
    }
  }, 1000);

  //add tetriminos 
  const addTetrimions = () => {
    setIsAddUsrWal(true)
  }

  useEffect(() => {
    // console.log(propsData)
    if (mode === 'props' && propsData.length > 0) {
      propsUsage(propsData)
    }
  }, [propsData])


  const confirm = (type) => {
    setIsopenModal(false)
    // move game 
    // solo mode
    if (mode === "solo") {
      if (type === "gohome") {
        MoveResult()
        // gohome
        navigate("/", { replace: true })
      } else if (type === "resume") {
        // For resuming the game after pausing
        SoloResumeGame()
      } else if (type === "replay") {
        // replay this level
        SoloReplayGame()
      } else if (type === "continue") {
        // For continuing the game after it has ended
        SoloContinueGame()
      }
    } else {
      // other mode
      if (type === "continue") {

        // invite friends
        if (interGameType === "invite") {
          navigate(`/?type=continue&continueType=inviteFriends&roommodel=${mode}`, { replace: true })
        }

        // making
        if (interGameType === "making") {
          // 1v1 making
          if (maxplayers === 2) {
            navigate(`/?type=continue&continueType=making1v1&roommodel=${mode}`, { replace: true })
          }
          if (maxplayers === 3) {
            navigate(`/?type=continue&continueType=making3v3&roommodel=${mode}`, { replace: true })
          }
          if (maxplayers === 6) {
            navigate(`/?type=continue&continueType=making6v6&roommodel=${mode}`, { replace: true })
          }
          // 8v8 making
          else if (maxplayers === 8) {
            navigate(`/?type=continue&continueType=making8v8&roommodel=${mode}`, { replace: true })
          }
        }

        // created room
        if (interGameType === "room" && maxplayers === 8) {
          navigate(`/?type=continue&continueType=roomList&roommodel=${mode}`, { replace: true })
        }

      } else if (type === "gohome") {
        navigate("/", { replace: true })
      } else if (type === "continue_view") {
        setIsViewer(true)
        socket.emit("viewer_join_room", { id: roomName })
      }
    }
  }
  // For continuing the game after it has ended
  const SoloContinueGame = () => {
    if (gameOver) {
      return
    }
    // 游戏中暂停
    if (playStatus) {
      setModalType("soloPause")
      setIsopenModal(true)
      setPlaystatus(false)
    }
    // 暂停中开始
    else {
      setModalType("")
      setIsopenModal(false)
      setPlaystatus(true)
    }
  }
  // Solo Replay Game
  const SoloReplayGame = () => {
    setModalType("")
    setDeadLineTime(Date.now() + 3 * 60 * 1000)
    setRemainTime(3 * 60 * 1000)
    setPlaystatus(true)
    setDropTime(1000);
    setStage(createStage());
    setNextStage(createStage(4, 4));
    resetPlayer();
    setScore(0);
    setLevel(0);
    setRows(0);
  }

  const gotoRoomList = () => {
    navigate(`/?type=continue&continueType=roomList&roommodel=${mode}`, { replace: true })
  }

  return (
    <div className="room-field" tabIndex="0" ref={gameRef} onKeyDown={(e) => move(e)} onKeyUp={e => { if (e.keyCode === currentRotateKey || e.keyCode === currentRotateLKey || e.keyCode === currentReverseKey) setIsReleaseKey(true) }}>
      <GameResultModal isOpen={openModal} confirmFun={confirm} gameResult={gameResult} gameinvalid={props.gameinvalid} userName={userName} type={modalType} roomDetail={roomDetail} />
      <div className="right-field" >

        <Image
          preview={false}
          src={logo_img}
          className='game_title'
        />
        {
          // solo
          maxplayers === 1 ?
            <>
              <div className="level_panel">
                <Image
                  preview={false}
                  src={level_cube}
                  className='cube'
                />
                <div className="level_text">{t("game.level")}：{gameLevel}</div>
                <Image
                  preview={false}
                  src={level_cube}
                  className='cube'
                />
              </div>
              <div className="left_panel">
                {
                  nftIds.map(item => {
                    return (
                      <div className="avatar_box" key={item.id}>
                        <Image
                          preview={false}
                          src={item.url ? item.url + "?imageView2/1/w/300/h/300" : ""}
                          fallback={item.url}
                          className='fake'
                        />
                      </div>
                    )
                  })
                }
              </div>
            </> :
            // 1v1
            maxplayers === 2 ?
              <div className="other_player">
                {stages.filter((stg) => {
                  if (isViewer) {
                    return stg.username !== viewUserName
                  } else {
                    return stg.username !== username
                  }
                }).map((stage, i) => {
                  if (competitiveList.length > 0) {
                    if (competitiveList.filter(item => stage.username === item.name).length > 0) {
                      stage.index = competitiveList.filter(item => stage.username === item.name)[0].index
                    }
                  }
                  return (
                    stage.username !== username && <div className="item" key={i}>
                      <OtherPlayerBg title={stage.username} userIndex={stage.index} isViewer={isViewer} selectedIndex={propsSeletedUserIndex} battleMode={mode}>
                        <PlayersStage stage={stage.stage} size="50vh" name={stage.username} />
                        {currentToOtherUsrArr.map((item, idx) => {
                          if (item.name === stage.username && item.isShowProps) {
                            return (
                              <div className="overlay_play" key={idx}>
                                {
                                  item.propMode === 'freeze' ? (
                                    <div className="freeze_bg">
                                      <div className="freeze_tips">
                                        {t("game.freezeCountdown")}
                                      </div>
                                      <div className="freeze_num"><Countdown valueStyle={{ color: '#fff', fontSize: '30px' }} value={item.deadlines} format="s" />
                                      </div>
                                    </div>
                                  ) : (item.propMode === 'acc' ? (
                                    <div className="acc_bg"></div>
                                  ) : "")
                                }
                              </div>
                            )
                          }
                        })}
                      </OtherPlayerBg>
                    </div>
                  );
                })}
              </div> :
              // 8v8
              scores.length <= 8 ?
                <div className="other_player">
                  <div className="other_players">
                    {stages.filter((stg) => {
                      if (isViewer) {
                        return stg.username !== viewUserName
                      } else {
                        return stg.username !== username
                      }
                    }).map((stage, i) => {
                      if (competitiveList.length > 0) {
                        if (competitiveList.filter(item => stage.username === item.name).length > 0) {
                          stage.index = competitiveList.filter(item => stage.username === item.name)[0].index
                        }
                      }
                      return (
                        i < 4 && (
                          <div className="item" key={stage.username} onClick={() => { updateViewUserName(stage.username) }}>
                            <OtherPlayerBg title={stage.username} userIndex={stage.index} selectedIndex={propsSeletedUserIndex} isViewer={isViewer} battleMode={mode}>
                              <PlayersStage stage={stage.stage} size="25vh" name={stage.username} />
                              {currentToOtherUsrArr.map((item, idx) => {
                                if (item.name === stage.username && item.isShowProps) {
                                  return (
                                    <div className="overlay_play_mulity" key={idx}>
                                      {
                                        item.propMode === 'freeze' ? (
                                          <div className="freeze_bg">
                                            <div className="freeze_tips">
                                              {t("game.freezeCountdown")}
                                            </div>
                                            <div className="freeze_num"><Countdown valueStyle={{ color: '#fff', fontSize: '20px' }} value={item.deadlines} format="s" />
                                            </div>
                                          </div>
                                        ) : (item.propMode === 'acc' ? (
                                          <div className="acc_bg"></div>
                                        ) : "")
                                      }
                                    </div>
                                  )
                                }
                              })}
                            </OtherPlayerBg>
                          </div>
                        )
                      );
                    })}
                  </div>
                </div> : <div>error maxplayers number : {maxplayers} scores length : {scores.length}</div>
        }

        {
          mode === 'props' ? (
            <div className="card_container">
              <div className="card_box">
                <div className="card_img">
                  <div className='props_icon'>
                    <Image
                      preview={false}
                      src={freeze_card}
                      className='dj'
                    />
                  </div>
                  {
                    freezePropsNum > 0 ?
                      <div className='red_point'>
                        {freezePropsNum}
                      </div> : <div className='blank_area'></div>
                  }
                </div>
                {/* <div className="card_name">{t("game.frozenCard")}</div> */}
                <div className="card_name">{displayKey(currentFreezeKey)}</div>
              </div>
              <div className="card_box">
                <div className="card_img">
                  <div className='props_icon'>
                    <Image
                      preview={false}
                      src={add_card}
                      className='dj'
                    />
                  </div>
                  {
                    addPropsNum > 0 ?
                      <div className='red_point'>
                        {addPropsNum}
                      </div> : <div className='blank_area'></div>
                  }
                </div>
                {/* <div className="card_name">{t("game.brickingCard")}</div> */}
                <div className="card_name">{displayKey(currentAddKey)}</div>
              </div>
              <div className="card_box">
                <div className="card_img">
                  <div className='props_icon'>
                    <Image
                      preview={false}
                      src={acc_card}
                      className='dj'
                    />
                  </div>
                  {
                    accPropsNum > 0 ?
                      <div className='red_point'>
                        {accPropsNum}
                      </div> : <div className='blank_area'></div>
                  }
                </div>
                {/* <div className="card_name">{t("game.accelerationCard")}</div> */}
                <div className="card_name">{displayKey(currentAccKey)}</div>
              </div>
            </div>
          ) : ''
        }
      </div>

      <div className="left-field game">
        {isViewer ?
          stages.filter((stg) => stg.username === viewUserName).map((stage, i) => {
            return (
              <div className="board" key={stage.username}>
                <PlayersStage stage={stage.stage} size="86vh" name={stage.username} />
                {currentToOtherUsrArr.map((item, idx) => {
                  if (item.name === stage.username && item.isShowProps) {
                    return (
                      <div className="overlay" key={idx}>
                        {
                          item.propMode === 'freeze' ? (
                            <div className="freeze_bg">
                              <div className="freeze_tips">
                                {t("game.freezeCountdown")}
                              </div>
                              <div className="freeze_num"><Countdown valueStyle={{ color: '#fff', fontSize: '20px' }} value={item.deadlines} format="s" />
                              </div>
                            </div>
                          ) : (item.propMode === 'acc' ? (
                            <div className="acc_bg"></div>
                          ) : "")
                        }
                      </div>
                    )
                  }
                })}
                <div className="spectator_top">{t("game.spectating")}：{viewUserName}</div>
              </div>
            );
          }) :
          <Board data={{ start, setstart, stage, gameOver, gameFinished, size: "86vh", isShowProps, propMode, isShowEffect, sweepExactRows }} />}
        {
          mode === 'props' ? (
            <div className="odds"><span>{t("game.itemDropRate")}</span>
              <span className="num">{odds}%</span>
            </div>
          ) : ''
        }
      </div>
      <div className="right_panel">
        {
          // solo
          maxplayers === 1 ?
            <div className="opt_panel">
              <div className="score">
                <div className="next" style={{ color: '#fff' }}>{!start ? <NextPiece size="18vh" stage={nextStage} /> : "Game Over!"}</div>
              </div>
              <div className="tips_box">
                <div className="tips_title">
                  <Image
                    preview={false}
                    src={left_icon}
                    className='icon'
                  />
                  <span>{t("game.points")}</span>
                  <Image
                    preview={false}
                    src={right_icon}
                    className='icon'
                  />
                </div>
                <div className="data">
                  {score}
                </div>
              </div>
              <div className="tips_box">
                <div className="tips_title">
                  <Image
                    preview={false}
                    src={left_icon}
                    className='icon'
                  />
                  <span>{t("game.countdownTimer")}</span>
                  <Image
                    preview={false}
                    src={right_icon}
                    className='icon'
                  />
                </div>
                <div className="data">
                  {
                    playStatus ?
                      <Countdown valueStyle={{ color: '#fff' }} value={deadLineTime} format="mm:ss" onChange={(e) => setRemainTime(e)} onFinish={() => timeFinsh()} />
                      :
                      '0' + new Date(remainTime).getMinutes() + ':' + (new Date(remainTime).getSeconds() < 10 ? '0' + new Date(remainTime).getSeconds() : new Date(remainTime).getSeconds())
                  }
                </div>
              </div>
              <div className="tips_box">
                <div className="tips_title">
                  <Image
                    preview={false}
                    src={left_icon}
                    className='icon'
                  />
                  <span>{t("game.currentState")}</span>
                  <Image
                    preview={false}
                    src={right_icon}
                    className='icon'
                  />
                </div>
                <div className="status" onClick={SoloContinueGame}>
                  {
                    playStatus ?
                      <PauseOutlined style={{ fontSize: '30px' }} />
                      :
                      <Image
                        preview={false}
                        src={pause}
                        className='pause'
                      />
                  }
                </div>
              </div>
            </div> :
            maxplayers === 2 ?
              // 1v1
              <div className="opt_panel">
                <div className="score">
                  <div className="next" style={{ color: '#fff' }}>{!start ? <NextPiece stage={nextStage} size="18vh" /> : "Game Over!"}</div>
                </div>
                <div className="panel_vs">
                  <div className="vs">
                    <div className="img">
                      <div className="item"></div>
                    </div>
                  </div>
                  {
                    scores.map((item) => {
                      return (
                        <div className="vs_opponent" key={item.username}>
                          <div className="avatar">
                            <div className="avatar_box">
                              <Image
                                preview={false}
                                src={item.url || fakeTar}
                                fallback={fakeTar}
                                className='fake'
                              />
                            </div>
                            <div className="username">{item.username}</div>
                          </div>
                          <div className="tips_box">
                            <div className="tips_title">
                              <Image
                                preview={false}
                                src={left_icon}
                                className='icon'
                              />
                              <span>{t("game.points")}</span>
                              <Image
                                preview={false}
                                src={right_icon}
                                className='icon'
                              />
                            </div>
                            <div className="status">
                              {item.score}
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div> :
              scores.length <= 8 ?
                // 8v8
                <div className="other_player">
                  <div className="opt_panel">
                    <div className="score">
                      <div className="next" style={{ color: '#fff' }}>{!start ? <NextPiece size="9vh" stage={nextStage} />
                        : isViewer ? <NextPiece size="9vh" stage={viewerNextStage} /> : "Game Over!"}</div>
                    </div>
                    <div className="tips_box">
                      <div className="tips_title">
                        <Image
                          preview={false}
                          src={left_icon}
                          className='icon'
                        />
                        <span>{t("game.points")}</span>
                        <Image
                          preview={false}
                          src={right_icon}
                          className='icon'
                        />
                      </div>
                      <div className="data">
                        {isViewer ? viewerScore : score}
                      </div>
                    </div>
                    <div className="tips_box">
                      <div className="tips_title">
                        <Image
                          preview={false}
                          src={left_icon}
                          className='icon'
                        />
                        <span>{t("game.alive")}</span>
                        <Image
                          preview={false}
                          src={right_icon}
                          className='icon'
                        />
                      </div>
                      <div className="data">
                        {alivePlayer + "/" + maxplayers}
                      </div>
                    </div>
                  </div>
                  {stages.filter((stg) => {
                    if (isViewer) {
                      return stg.username !== viewUserName
                    } else {
                      return stg.username !== username
                    }
                  }).map((stage, i) => {
                    if (competitiveList.length > 0) {
                      if (competitiveList.filter(item => stage.username === item.name).length > 0) {
                        stage.index = competitiveList.filter(item => stage.username === item.name)[0].index
                      }
                    }
                    return (
                      i > 3 && (
                        <div className="item" key={stage.username} onClick={() => { updateViewUserName(stage.username) }}>
                          <OtherPlayerBg title={stage.username} userIndex={stage.index} selectedIndex={propsSeletedUserIndex} isViewer={isViewer} battleMode={mode} >
                            <PlayersStage stage={stage.stage} size="25vh" name={stage.username} />
                            {currentToOtherUsrArr.map((item, idx) => {
                              if (item.name === stage.username && item.isShowProps) {
                                return (
                                  <div className="overlay_play_mulity" key={idx}>
                                    {
                                      item.propMode === 'freeze' ? (
                                        <div className="freeze_bg">
                                          <div className="freeze_tips">
                                            {t("game.freezeCountdown")}
                                          </div>
                                          <div className="freeze_num"><Countdown valueStyle={{ color: '#fff', fontSize: '20px' }} value={item.deadlines} format="s" />
                                          </div>
                                        </div>
                                      ) : (item.propMode === 'acc' ? (
                                        <div className="acc_bg"></div>
                                      ) : "")
                                    }
                                  </div>
                                )
                              }
                            })}
                          </OtherPlayerBg>
                        </div>
                      )
                    );
                  })}
                </div> : <div>error maxplayers number : {maxplayers} scores length : {scores.length}</div>
        }

        {
          isViewer ?
            <div className="viewer_button_list">
              <button className="button_common secondary_button" onClick={gotoRoomList}>{t("game.roomList")}</button>
              <button className="button_common primary_button" onClick={() => { confirm("gohome") }}>{t("game.title")}</button>
            </div> :
            modalType === "soloPause" ?
              <div className="viewer_button_list">
                <button className="button_common secondary_button" onClick={() => confirm("gohome")}>{t("game.title")}</button>
                <button className="button_common primary_button" onClick={() => confirm("replay")}>{t("game.restart")}</button>
                <button className="button_common secondary_button" onClick={() => confirm("continue")}>{t("game.backGame")}</button>
              </div> :
              modalType === "soloSuccess" ?
                <div className="viewer_button_list">
                  <button className="button_common secondary_button" onClick={() => confirm("gohome")}>{t("game.title")}</button>
                </div> :
                modalType === "soloFail" ?
                  <div className="viewer_button_list">
                    <button className="button_common secondary_button" onClick={() => confirm("gohome")}>{t("game.title")}</button>
                    <button className="button_common primary_button" onClick={() => confirm("resume")}>{t("game.restart")}</button>
                  </div> :
                  modalType === "1v1Over" ?
                    <div className="viewer_button_list">
                      <button className="button_common secondary_button" onClick={() => confirm("gohome")}>{t("game.title")}</button>
                      <button className="button_common primary_button" onClick={() => confirm("continue")}>{t("game.backGame")}</button>
                    </div> :
                    modalType === "8v8Fail" ?
                      <div className="viewer_button_list">
                        <button className="button_common secondary_button" onClick={() => confirm("gohome")}>{t("game.title")}</button>
                        <button className="button_common primary_button" onClick={() => confirm("continue")}>{t("game.restart")}</button>
                        <button className="button_common primary_button" onClick={() => confirm("continue_view")}>{t("game.keepWatching")}</button>
                      </div> :
                      modalType === "8v8Over" ?
                        <div className="viewer_button_list">
                          <button className="button_common secondary_button" onClick={() => confirm("gohome")}>{t("game.title")}</button>
                          <button className="button_common primary_button" onClick={() => confirm("continue")}>{t("game.backGame")}</button>
                        </div> :
                        <div className="arrow_box">

                          <div className="user_custom_key">
                            <div className="keys_box">
                              <div className="keys_container">
                                <div className="all_key">{displayKey(currentRotateLKey)}</div>
                                <div className="keys_text">{t('soundSettings.rotateL')}</div>
                              </div>
                              <div className="keys_container">
                                <div className="all_key">{displayKey(currentRotateKey)}</div>
                                <div className="keys_text">{t('soundSettings.rotateR')}</div>
                              </div>
                              <div className="keys_container">
                                <div className="all_key">{displayKey(currentReverseKey)}</div>
                                <div className="keys_text">180°</div>
                              </div>
                            </div>
                            <div className="keys_box">
                              <div className="keys_container">
                                <div className="all_key">{displayKey(currentLeftKey)}</div>
                                <div className="keys_text">{t('soundSettings.left')}</div>
                              </div>
                              <div className="keys_container">
                                <div className="all_key">{displayKey(currentPushKey)}</div>
                                <div className="keys_text">{t('soundSettings.push')}</div>
                              </div>
                              <div className="keys_container">
                                <div className="all_key">{displayKey(currentRightKey)}</div>
                                <div className="keys_text">{t('soundSettings.right')}</div>
                              </div>
                            </div>
                            <div className="keys_box">
                              <div className="keys_container">
                                <div className="all_key space_key">{displayKey(currentMomentumKey)}</div>
                                <div className="keys_text">{t('soundSettings.momentum')}</div>
                              </div>
                            </div>
                          </div>
                        </div>
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  tetriminos: state.sockets.tetriminos,
  stages: [...state.sockets.Stages],
  scores: [...state.sockets.Scores],
  propsData: state.sockets.propsData,
  userName: state.sockets.userName,
  gameFinished: state.sockets.GameFinished,
  wall: state.sockets.wall,
  wallData: state.sockets.wallData,
  GameOver: state.sockets.GameOver,
  roomDetail: state.sockets.roomDetail,
  gameResult: state.sockets.gameResult,
  currentUser: state.sockets.currentUser,
  gameinvalid: state.sockets.gameinvalid,
  gameAsyncTime: state.sockets.gameAsyncTime,
});
const mapDispatchToProps = { addWall, gameOverAction, resetProps, SoloResumeGame, MoveResult };

export default connect(mapStateToProps, mapDispatchToProps)(Game);

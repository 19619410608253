import { useEffect, useState } from 'react';
import { Button, Image } from 'antd';
import { t } from 'i18next';

import { FREE_TICKET_TIME, POINTSEARNEDTABLE, TICKETSEARNEDTABLE } from '../../tools/gameConfig.js';

import PublicPopup from '../PublicComponents/PublicPopup.js';
import '../../scss/Game/GameResultModal.scss'
import avatar_victory from '../../assets/hall/avatar_default.png'
import shareIcon from '../../assets/public_icon/share_icon.png'
import twitterIcon from '../../assets/public_icon/twitter_icon.png'
import telegramIcon from '../../assets/public_icon/telegram_icon.png'
import discordIcon from '../../assets/public_icon/discord_icon.png'
import facebookIcon from '../../assets/public_icon/facebook_icon.png'
import copyIcon from '../../assets/public_icon/copy_icon.png'
import { copy } from '../../tools/notification.js';


const GameResultModal = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(props.isOpen || false)
    const [title, setTitle] = useState(t("game.challengeCleared"))
    const [gameResult, setGameResult] = useState(props.gameResult || [])
    const [userName, setUserName] = useState(props.userName)
    const [pointList, setPointList] = useState(null)
    const [ticketList, setTicketList] = useState(null)
    const [nowTime, setNowTime] = useState(new Date().getTime() + FREE_TICKET_TIME)
    const [showShareGame, setShowShareGame] = useState(false)

    const confirm = (type) => {
        props.confirmFun(type)
    }

    useEffect(() => {
        setIsModalOpen(props.isOpen)
        setGameResult(props.gameResult)
        setUserName(props.userName)
        if (props.type) {
            init()
        }
    }, [props.isOpen, props.type])

    const init = () => {
        if (props.type === "soloPause") {
            setTitle(t("game.gamePaused"))
        } else if (props.type === "soloSuccess") {
            setTitle(t("game.challengeCleared"))
        } else if (props.type === "soloScoreFail") {
            setTitle(t("game.challengeFailed"))
        } else if (props.type === "soloFail") {
            setTitle(t("game.challengeFailed"))
        } else if (props.type === "1v1Over") {
            setTitle(t("game.competitionCompleted"))
        } else if (props.type === "8v8Fail") {
            setTitle(t("game.challengeFailed"))
        } else if (props.type === "8v8Over") {
            setTitle(t("game.competitionCompleted"))
        }
        if (props.roomDetail.private_mode) {
            // 需要积分
            if (props.type === "1v1Over") {
                if (!props.gameinvalid) {
                    setPointList(POINTSEARNEDTABLE.oneVS1)
                } else {
                    setPointList(POINTSEARNEDTABLE.oneVS1GameInvalid)
                }
            } else if (props.type === "8v8Over") {
                setPointList(POINTSEARNEDTABLE.oneVS8)
            }
        } else {
            setPointList(null)
        }
        if (props.roomDetail.mode === "battle") {
            // 需要门票
            if (props.roomDetail.maxplayers === 3) {
                setTicketList(TICKETSEARNEDTABLE.oneVS3)
            } else if (props.roomDetail.maxplayers === 6) {
                setTicketList(TICKETSEARNEDTABLE.oneVS6)
            }
            setPointList(null)
        } else {
            setTicketList(null)
        }
    }

    const shareGame = () => {
        setShowShareGame(true)
    }

    const shareGameClose = () => {
        setShowShareGame(false)
    }


    return (
        <>
            {isModalOpen ? <PublicPopup title={title} isAd isOverlay closefun={() => { confirm("close") }} >
                <div className='tips_modal'>
                    {
                        props.type === "soloPause" ?
                            <>
                                <div className="tips_des"> {t("game.gamePausedContent")} </div>
                                <div className="btn_container">
                                    <div className="button_common primary_button" onClick={() => confirm("gohome")}>{t("game.backTitle")}</div>
                                    <div className="button_common secondary_button" onClick={() => confirm("replay")}>{t("game.restart")}</div>
                                    <div className="button_common secondary_button" onClick={() => confirm("continue")}>{t("game.backGame")}</div>
                                </div>
                            </>
                            : props.type === "soloSuccess" ?
                                <>
                                    <div className="tips_des"> {t("game.challengeClearedContent1")}{new Date(nowTime).getFullYear() + '.' + (new Date(nowTime).getMonth() + 1) + '.' + (new Date(nowTime).getDate())}(12:00 PM CST){t("game.challengeClearedContent2")} </div>
                                    <div className="btn_container">
                                        <div className="button_common secondary_button" onClick={() => confirm("gohome")}>{t("game.confirm")}</div>
                                    </div>
                                </>
                                : props.type === "soloFail" ?
                                    <>
                                        <div className="tips_des">
                                            {t("game.challengeFailedContent")}
                                        </div>
                                        <div className="btn_container">
                                            <div className="button_common primary_button" onClick={() => confirm("gohome")}>{t("game.backTitle")}</div>
                                            <div className="button_common secondary_button" onClick={() => confirm("resume")}>{t("game.backGame")}</div>
                                        </div>
                                    </>
                                    : props.type === "soloScoreFail" ?
                                        <>
                                            <div className="tips_des">
                                                {t("game.soloScoreFail")}
                                            </div>
                                            <div className="btn_container">
                                                <div className="button_common primary_button" onClick={() => confirm("gohome")}>{t("game.backTitle")}</div>
                                                <div className="button_common secondary_button" onClick={() => confirm("resume")}>{t("game.backGame")}</div>
                                            </div>
                                        </>
                                        : props.type === "1v1Over" || props.type === "8v8Over" ?
                                            <div className='game_over'>
                                                <div className='game_over_list'>
                                                    {gameResult?.map((item, index) => {
                                                        return (
                                                            <div className={userName === item.nick_name ? "item myself" : "item"} key={index}>
                                                                <span>{t(`game.tip${index + 1}`)}</span>
                                                                <div className="avatar">
                                                                    <Image
                                                                        preview={false}
                                                                        src={item.avatar || avatar_victory}
                                                                        fallback={avatar_victory}
                                                                    />
                                                                    <span className='nick_name'>{item.nick_name}</span>
                                                                </div>
                                                                <div className='point'>
                                                                    <div>{index === 0 ? "MVP" : item.time}</div>
                                                                    <div>{item.points + t("game.pointss")}</div>
                                                                </div>
                                                                {
                                                                    pointList || ticketList ? <div className='point'>
                                                                        <div>{pointList ? pointList[index] + t("game.points") : ""}</div>
                                                                        <div>{ticketList ? ticketList[index] + t("game.ticket") : ""}</div>
                                                                    </div> : ""
                                                                }
                                                                {userName === item.nick_name ?
                                                                    <Button className='close_button' onClick={shareGame}>
                                                                        <Image src={shareIcon} preview={false} />
                                                                    </Button> : ""}
                                                            </div>
                                                        )
                                                    })}
                                                    {
                                                        props.gameinvalid ?
                                                            <div className='gameinvalid'>
                                                                {t("game.gameinvalid")}
                                                            </div> : ""
                                                    }
                                                </div>
                                                <div className="game_btn_container">
                                                    <div className="button_common secondary_button" onClick={() => confirm("continue")}>{t("game.backGame")}</div>
                                                    <div className="button_common primary_button" onClick={() => confirm("gohome")}>{t("game.backTitle")}</div>
                                                </div>
                                            </div>
                                            : props.type === "8v8Fail" ?
                                                <>
                                                    <div className="tips_des">
                                                        {t("game.challengeFailedContent")}
                                                    </div>
                                                    <div className="btn_container">
                                                        <div className="button_common secondary_button" onClick={() => confirm("continue_view")}>{t("game.keepWatching")}</div>
                                                        <div className="button_common secondary_button" onClick={() => confirm("continue")}>{t("game.restart")}</div>
                                                        <div className="button_common primary_button" onClick={() => confirm("gohome")}>{t("game.title")}</div>
                                                    </div>
                                                </> : ''
                    }
                </div>
                {showShareGame ? <ShareGame back={shareGameClose}></ShareGame> : ""}
            </PublicPopup> : ""}</>
    )
}
export default GameResultModal


const ShareGame = ({ back }) => {
    const shareList = [
        { name: "TWITTER", img: twitterIcon, link: "" },
        { name: "TELEGRAM", img: telegramIcon, link: "" },
        { name: "DISCORD", img: discordIcon, link: "" },
        { name: "FACEBOOK", img: facebookIcon, link: "" },
        { name: "COPY LINK", img: copyIcon, link: "" },
    ]

    const share = (index) => {
        const url = "https://tetriverse.com"
        const title = t("home.stroyContent")
        let intentUrl = ''
        switch (index) {
            case 0:
                window._czc && window._czc.push(["_trackEvent", "分享游戏", "点击", "TWITTER"]);
                intentUrl = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(title) + "&url=" + encodeURIComponent(url);
                return window.open(intentUrl, "_blank");
            case 1:
                window._czc && window._czc.push(["_trackEvent", "分享游戏", "点击", "TELEGRAM"]);
                intentUrl = "https://telegram.me/share/url?url=" + encodeURIComponent(url);
                return window.open(intentUrl, "_blank");
            case 2:
                window._czc && window._czc.push(["_trackEvent", "分享游戏", "点击", "DISCORD"]);
                return copy(url, t("game.copySuccess"))
            case 3:
                window._czc && window._czc.push(["_trackEvent", "分享游戏", "点击", "FACEBOOK"]);
                intentUrl = "http://www.facebook.com/share.php?u=" + url;
                return window.open(intentUrl, "_blank");
            case 4:
                window._czc && window._czc.push(["_trackEvent", "分享游戏", "点击", "COPY LINK"]);
                return copy(url)
        }
    }

    return (
        <PublicPopup isOverlay title={"Share"} backfun={back} >
            <div className='share_div'>
                {shareList.map((item, index) => {
                    return <div className='share_item' key={item.name} onClick={() => { share(index) }}>
                        <Image src={item.img} preview={false} />
                        <div className='name'>{item.name}</div>
                    </div>
                })}
            </div>
        </PublicPopup>
    )

}
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Image, InputNumber, Spin } from 'antd'
import { useTranslation } from 'react-i18next'

import { getSwapTokenTRate, postNftMarketplace } from '../../api';
import { IMAGE_BASEURL, NFTEXPIRATIONDAY, TOKENNAME } from '../../tools/gameConfig';
import { VSWAP_CONTRACT_ADDRESS } from '../../tools/tokenConfig';

import PublicPopup from "../PublicComponents/PublicPopup"
import MarketDetail from './MarketDetail';
import BuyDetail from './BuyDetail';
import ListNftConfirm from './ListNftConfirm';
import ListSingleNft from './ListSingleNft';
import RemoveNft from './RemoveNft'
import NoData from '../PublicComponents/NoData'
import '../../scss/Market/Index.scss'

function Index(props) {
    const { t } = useTranslation()
    const onClose = () => {
        props.onClose()
    }

    const sortTypeItems = [
        { key: "price ASC", label: t("market.price ASC"), },
        { key: "price DESC", label: t("market.price DESC"), },
        { key: "quality ASC", label: t("market.quality ASC"), },
        { key: "quality DESC", label: t("market.quality DESC"), },
        { key: "time ASC", label: t("market.time ASC"), },
        { key: "time DESC", label: t("market.time DESC"), },
    ]

    const validTypeItems = [
        { key: "InActivated", label: t("personalCenter.InActivated") },
        { key: "Valid", label: t("personalCenter.Valid") },
    ]

    const rarityTypeItems = [
        { key: "All", label: "ALL" },
        { key: "SSR", label: "SSR" },
        { key: "SR", label: "SR" },
        { key: "R", label: "R" },
        { key: "N", label: "N" },
        { key: "M", label: "M" },
    ]

    const [sortType, setSortType] = useState(sortTypeItems[0].key)
    const [validType, setValidType] = useState("InActivated")
    const [rarityType, setRarityType] = useState("All")

    const [validityMinimum, setValidityMinimum] = useState(null)
    const [validityMaximum, setValidityMaximum] = useState(null)
    const [validityType, setValidityType] = useState("0-60")
    const [validityErr, setValidityErr] = useState(false)

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [nftList, setNftList] = useState(null)
    const [selectNft, setSelectNft] = useState({})
    const [selectBuyNft, setSelectBuyNft] = useState({})
    const [singleNftSelected, setSingleNftSelected] = useState({})
    const [nftSelectedListConfirm, setNftSelectedListConfirm] = useState([])
    const [removeNFTIndex, setRemoveNFTIndex] = useState(null)
    const [TET2USD, setTET2USD] = useState(0)

    useEffect(async () => {
        if (!props.TET2USD) {
            try {
                setTET2USD((await getSwapTokenTRate({ vswapContract: VSWAP_CONTRACT_ADDRESS }))?.data?.data?.tetrisTokenExchange?.usd || 0)
            } catch (error) {
                setTET2USD(0)
                console.log(error)
            }
        } else {
            setTET2USD(props.TET2USD)
        }
    }, [props.TET2USD])

    useEffect(() => {
        if (!validityType) { return }
        refresh()
    }, [rarityType, validityType, sortType, validType])

    // validity period 
    const restartPeriod = () => {
        if (loading) { return }
        let Minimum = validityMinimum
        let Maximum = validityMaximum
        if (validityMaximum > NFTEXPIRATIONDAY) {
            setValidityMaximum(NFTEXPIRATIONDAY)
            Maximum = NFTEXPIRATIONDAY
        }
        if (validityMinimum < 0) {
            setValidityMinimum(0)
            Minimum = 0
        }
        if ((validityMinimum || 0) > (validityMaximum || NFTEXPIRATIONDAY)) {
            return setValidityErr(true)
        } else {
            setValidityErr(false)
        }
        setValidityType((Minimum || 0) + "-" + (Maximum || NFTEXPIRATIONDAY))
    }

    const getList = (page, type) => {
        if (loading) { return }
        setLoading(true)
        let newNftLowestLife = validityMinimum || 0
        let newNftHighestLife = validityMaximum || 60
        if (validType == "InActivated") {
            newNftLowestLife = 0
            newNftHighestLife = 0
        }
        postNftMarketplace({
            "nftLowestLife": newNftLowestLife / 1,
            "nftHighestLife": newNftHighestLife / 1,
            "nftQualityFilter": rarityType,
            "nftValidStatusFilter": validType,
            "nftOrder": sortType,
            "pagination": {
                "size": 14,
                "page": page
            }
        }).then((res) => {
            if (selectNft.ID && !type) {
                let newSelectNft = res.data.data?.userNFTMarketPlaces.find((item) => item.NftTokenIndex == selectNft.NftTokenIndex) || {}
                setSelectNft(newSelectNft)
            }
            if (page === 1) {
                setNftList([...(res.data.data?.userNFTMarketPlaces || [])])
            } else {
                setNftList([...nftList, ...(res.data.data?.userNFTMarketPlaces || [])])
            }
            if ((page - 1) * 14 + (res.data.data?.userNFTMarketPlaces?.length || 0) >= res.data.data.pagination?.total) {
                setLoadMore(false)
            } else {
                setLoadMore(true)
            }
            setTotal(res.data.data?.pagination?.total)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    const refresh = (type) => {
        setNftList([])
        setPage(1)
        getList(1, type)
    }
    const loadMoreData = () => {
        if (loading) { return }
        setPage(page + 1)
        getList(page + 1)
    }

    // sort type 
    const sortTypeChange = ({ key }) => {
        if (loading) { return }
        setSortType(key)
    }
    const validTypeChange = (key) => {
        setValidType(key)
    }


    const actionSelectNft = (index) => {
        window._czc && window._czc.push(["_trackEvent", "市场首页", "点击", "NFT详情"]);
        setSelectNft(nftList[index])
    }
    const backMarket = () => {
        setSelectNft({})
        refresh("off")
    }
    const closeMarketDetail = () => {
        setSelectNft({})
        onClose()
    }

    const actionSelectBuyNft = (e, index) => {
        window._czc && window._czc.push(["_trackEvent", "市场首页", "点击", "NFT详情页"]);
        e.stopPropagation();
        setSelectBuyNft(nftList[index])
    }
    const closeBuyDetail = () => {
        setSelectBuyNft({})
    }
    const buyConfirm = () => {
        refresh()
        closeBuyDetail()
    }

    const buyNow = () => {
        window._czc && window._czc.push(["_trackEvent", "市场详情页", "点击", "购买NFT页"]);
        setSelectBuyNft(selectNft)
    }
    const closeSingleNft = () => {
        setSingleNftSelected({})
    }

    const sellNowNFT = (nft) => {
        window._czc && window._czc.push(["_trackEvent", "NFT详情页", "点击", "单个NFT上架页"]);
        setSingleNftSelected(nft)
    }
    const listNFTConfirm = (list) => {
        window._czc && window._czc.push(["_trackEvent", "NFT详情页", "点击", "NFT上架确定页"]);
        setNftSelectedListConfirm(list)
    }
    const closeListNFTConfirm = () => {
        closeSingleNft()
        setNftSelectedListConfirm([])
        refresh()
    }
    const backListNFTConfirm = () => {
        setNftSelectedListConfirm([])
    }

    // remove
    const removeNft = (e, index) => {
        window._czc && window._czc.push(["_trackEvent", "市场首页", "点击", "NFT下架页"]);
        e.stopPropagation();
        setRemoveNFTIndex(index)
    }
    const removeNftConfirm = () => {
        let newList = nftList
        nftList.splice(removeNFTIndex, 1)
        setNftList([...newList])
        setRemoveNFTIndex(null)
    }
    const removeNftClose = () => {
        setRemoveNFTIndex(null)
    }

    return (
        <div>
            {selectNft.ID ?
                <MarketDetail TET2USD={TET2USD} loadIng={loading} nftDetail={selectNft} back={backMarket} close={closeMarketDetail} userId={props.userId} buyNow={buyNow} sellNow={(nft) => { sellNowNFT(nft) }} />
                : <PublicPopup
                    title={<Dropdown
                        dropdownRender={(menu) => (
                            <div style={{ background: "#40077e", whiteSpace: "pre-wrap", borderRadius: "1vw", width: "25vw", height: "auto", color: "#fff", fontFamily: "Noto Sans", padding: "1.5vw", boxSizing: "border-box", fontSize: "0.78vw", position: "relative" }}>
                                {t("titleContent.market")}
                                <a style={{ marginLeft: "0.5vw", color: "#f16e30", textDecoration: "underline", fontSize: "0.8vw" }} href={t("titleContent.marketLink")} target="_blank">{t("titleContent.more")}</a>
                            </div>
                        )}
                        trigger={['click', 'hover']}
                        placement='bottom'>
                        <div style={{ display: "flex", gap: "0.5vw", alignItems: "center", justifyContent: "center" }}>{t("hall.market")}<div className="price_info_ic"></div></div>
                    </Dropdown>} closefun={onClose}>
                    <div id="market">
                        <div className="filter_header">
                            <div className="filter_right">
                                <Button loading={loading} className='button' onClick={() => { refresh() }}>
                                    <div className='refresh_ic'></div>
                                    <span>{t("market.refresh")}</span>
                                </Button>
                                {!validityType || validType === "InActivated" ? "" :
                                    <Button className='button'>{t("market.validityPeriod") + " " + validityType}</Button>
                                }
                            </div>
                            <div className="filter_left">
                                <Dropdown
                                    className='button dropdown'
                                    menu={{
                                        items: sortTypeItems,
                                        onClick: sortTypeChange,
                                    }}
                                >
                                    <a style={loading ? { opacity: "0.8", cursor: "no-drop" } : {}} onClick={(e) => e.preventDefault()}>
                                        {t(`market.${sortType}`)}
                                        <div className='dropdown_ic'></div>
                                    </a>

                                </Dropdown>
                                <Button onClick={() => { props.sell() }} className='button'>{t("market.sell")}</Button>
                            </div>
                        </div>
                        <div className="content">
                            <div className="filter_label">
                                <div className='label_item'>
                                    <div className='results'>{total} {t("market.results")}</div>
                                </div>
                                <div className='label_item'>
                                    <div className="filter_title">{t("market.rarity")}</div>
                                    <div className='filter_rarity'>
                                        {rarityTypeItems.map((item) => {
                                            return <div className='rarity_item' onClick={() => { if (loading) { return } setRarityType(item.key) }} key={item.key}>
                                                {item.label}
                                                <Button style={loading ? { opacity: "0.8", cursor: "no-drop" } : {}} className={item.key === rarityType ? "selected_button" : "unselected_button"}></Button>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div className="label_item">
                                    <div className="filter_title">NFT<br />{t("market.activateStatus")}</div>
                                    <div className='filter_rarity'>
                                        {validTypeItems.map((item) => {
                                            return <div className='rarity_item' onClick={() => { if (loading) { return } validTypeChange(item.key) }} key={item.key}>
                                                {item.label}
                                                <Button style={loading ? { opacity: "0.8", cursor: "no-drop" } : {}} className={item.key === validType ? "selected_button" : "unselected_button"}></Button>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                {validType !== "InActivated" ?
                                    <div className='label_item' style={{ border: "none" }}>
                                        <div className="filter_title">{t("market.validityPeriod")}</div>
                                        <div className='filter_validity'>
                                            <InputNumber onBlur={(e) => { setValidityMinimum(e.target.value) }} value={validityMinimum} min={0} max={NFTEXPIRATIONDAY} placeholder={0} />
                                            to
                                            <InputNumber onBlur={(e) => { setValidityMaximum(e.target.value) }} value={validityMaximum} min={0} max={NFTEXPIRATIONDAY} placeholder={NFTEXPIRATIONDAY} />
                                        </div>
                                        <div className='validity_err'>{
                                            validityErr ? t("market.validityPeriodError") : ""
                                        }</div>
                                        <Button className='button' loading={loading} onClick={restartPeriod}>{t("market.restartPeriod")}</Button>
                                    </div> : ""}
                            </div>
                            <div className="content_list">
                                <div className="nft_list">
                                    {nftList?.map((item, index) => {
                                        return (
                                            <div onClick={() => { actionSelectNft(index) }} className='nft' key={item.ID}>
                                                <Image
                                                    className={"nft_img"}
                                                    preview={false}
                                                    src={item.NftAttributes?.content ? IMAGE_BASEURL + item.NftAttributes.content + ".png?imageView2/1/w/300/h/300" : ""}
                                                    placeholder={
                                                        <Image
                                                            preview={false}
                                                            src={item.NftAttributes?.content ? IMAGE_BASEURL + item.NftAttributes.content + ".png?imageView2/1/w/50/h/50" : ""}
                                                            className={"nft_img"}
                                                        />
                                                    }
                                                />
                                                <div className='nft_detail'>
                                                    <div className='nft_title'>
                                                        <div>{t("market.tetrisSeries")}</div>
                                                        <div className='series_ic'></div>
                                                    </div>
                                                    <div className='nft_index'>#{item.NftTokenIndex}</div>
                                                    <div className={"nft_type " + item.NftType}></div>
                                                    <div className='nft_price'>
                                                        <div className='price_ic'></div>
                                                        <div>
                                                            <div className='price_usd'>${(item?.NftPrice * TET2USD).toFixed(2)}</div>
                                                            <div>{item.NftPrice} {TOKENNAME}</div>
                                                        </div>
                                                    </div>
                                                    <div className='nft_buy'>
                                                        {item.SellerUserID === props.userId ?
                                                            <Button className='remove_tag tags_common' onClick={(e) => { removeNft(e, index) }}>{t("market.remove")}</Button>
                                                            : <Button className='buy_tag tags_common' onClick={(e) => { actionSelectBuyNft(e, index) }}>{t("market.buy")}</Button>}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {loading || !nftList ? <Spin className='loading' /> : nftList.length === 0 ? <NoData /> : ""}
                            </div>
                        </div>
                        <div className="footer">
                            {loadMore ? <Button loading={loading} className='button_common primary_button' onClick={() => { loadMoreData() }}>{t("market.loadMore")}</Button> : ""}
                        </div>
                    </div>
                </PublicPopup>
            }
            {
                nftSelectedListConfirm.length > 0 ?
                    <ListNftConfirm TET2USD={TET2USD} onBack={backListNFTConfirm} onConfirm={closeListNFTConfirm} nftDetailList={nftSelectedListConfirm} onClose={closeListNFTConfirm} />
                    : singleNftSelected.ID ?
                        <ListSingleNft onClose={closeSingleNft} nftDetail={singleNftSelected} Confirm={(list) => { listNFTConfirm(list) }} />
                        : ""}
            {selectBuyNft.ID ? <BuyDetail TET2USD={TET2USD} secondPasswordSet={props.secondPasswordSet} goToForgetPwd={props.goToForgetPwd} onConfirm={buyConfirm} userId={props.userId} tetrisToken={props.tetrisToken} onClose={closeBuyDetail} nftDetail={selectBuyNft} /> : ""}
            {removeNFTIndex !== null ? <RemoveNft TET2USD={TET2USD} onConfirm={removeNftConfirm} onClose={removeNftClose} removeNft={nftList[removeNFTIndex]} /> : ""}
        </div>
    )
}

export default Index
import Home from "../views/Game/home";
import OfficialSite from "../views/OfficialSite";
import Hall from "../views/Hall/Index";
import WebLogin from '../views/Web/WebLogin';
import WebRegister from '../views/Web/WebRegister';
import WebRecoverPassword from '../views/Web/WebRecoverPassword';
import WebResetPassword from '../views/Web/WebResetPassword';
import Author from '../views/Auth/Author'
import Page404 from "../views/404/Page404";
import Invite from "../views/Invite";

const routeList = [
    // {
    //     path: "/",
    //     name: "首页",
    //     element: <OfficialSite />
    // },
    {
        path: "/",
        element: <Author>{['/home', '/webRegister', '/webRecoverPassword', '/webResetPassword']}</Author>,
        children: [
            {
                path: "/",
                name: "大厅",
                element: <Hall></Hall>,
                meta: {
                    title: "大厅",
                    noLogin: true,
                }
            },
            {
                path: "/game",
                element: <Home />
            },
            {
                path: "/invite",
                element: <Invite />
            }
        ]
    },
    {
        path: "/home",
        name: "首页",
        element: <OfficialSite />
    },
    {
        path: "/webLogin",
        element: <WebLogin />,
        meta: {
            title: "登录",
            needLogin: false,
        }
    },
    {
        path: "/webRegister",
        element: <WebRegister />
    }, ,
    {
        path: "/webRecoverPassword",
        element: <WebRecoverPassword />
    },
    {
        path: "/webResetPassword",
        element: <WebResetPassword />
    },
    {
        path: "*",
        element: <Page404 />,
        meta: {
            title: "404",
            noLogin: true,
        },
    },
];

export default routeList;
import React, { useEffect, useState } from 'react'
import { Image } from 'antd';
import { t } from 'i18next';

import { TOKENNAME } from '../../../tools/gameConfig';
import { VSWAP_CONTRACT_ADDRESS } from '../../../tools/tokenConfig'
import { getSwapTokenTRate } from '../../../api';

import InviteCashback from '../../../Components/Hall/PersonalCenter/InviteCashback';
import { TransferNft } from '../../../Components/Hall/PersonalCenter/TransferNft';
import TransferInToken from '../../../Components/Hall/PersonalCenter/TransferInToken';
import { TransferOutToken } from '../../../Components/Hall/PersonalCenter/TransferOutToken';
import MyNft from '../../../Components/Hall/PersonalCenter/MyNft';
import MyTicker from '../../../Components/Hall/PersonalCenter/MyTicker';
import SafeSettings from '../../../Components/Hall/PersonalCenter/SafeSettings';
import IncomeRecords from '../../../Components/Hall/PersonalCenter/IncomeRecords';
import MarketAction from '../../../Components/Hall/PersonalCenter/MarketAction';
import PublicPopup from '../../../Components/PublicComponents/PublicPopup';

import './Index.scss'

import avatarDefault from "../../../assets/hall/avatar_default.png";
import refreshWhiteIcon from '../../../assets/public_icon/refresh_white_icon.svg'
import gifIcon from "../../../assets/hall/gif_icon.png"


function Index(props) {
    const assetsOptionTabs = [
        {
            key: "transferInToken",
            label: t("personalCenter.tokenRecharge"),
        },
        {
            key: "transferInNft",
            label: t("personalCenter.transferNFT"),
        },
        {
            key: "transferOutToken",
            label: t("personalCenter.tokenWithdrawal"),
        }, {
            key: "transferOutNft",
            label: t("personalCenter.withDrawNFT"),
        },
    ];

    const updateUserInfo = () => {
        props.updateUserInfo()
    }

    const handleClick = (action) => {
        props.onClose(action)
    }

    const [action, setAction] = useState(props.action)
    const [uuid, setUuid] = useState(props.uuid)
    const [nickname, setNickname] = useState(props.nickname)
    const [email, setEmail] = useState(props.email)
    const [TET2USD, setTET2USD] = useState(0)
    const [showInviteCashback, setShowInviteCashback] = useState(false)

    useEffect(async () => {
        updateUserInfo()
    }, [])

    useEffect(async () => {
        if (!props.TET2USD) {
            try {
                setTET2USD((await getSwapTokenTRate({ vswapContract: VSWAP_CONTRACT_ADDRESS }))?.data?.data?.tetrisTokenExchange?.usd || 0)
            } catch (error) {
                setTET2USD(0)
                console.log(error)
            }
        } else {
            setTET2USD(props.TET2USD)
        }
    }, [props.TET2USD])

    useEffect(() => {
        setUuid(props.uuid)
        setNickname(props.nickname)
        setEmail(props.email)
    }, [props])

    useEffect(() => {
        setAction(props.action)
    }, [props.action,])

    const gotoNext = (tab) => {
        if (tab === "transferInNft") {
            window._czc && window._czc.push(["_trackEvent", "转入NFT", "点击"]);
        }
        setAction(tab)
    }

    const onLeftClick = (action) => {
        setAction(action)
    }

    const forgetPwd = () => {
        onLeftClick("settings");
    }

    const goToTransferInNft = () => {
        onLeftClick("transferInNft");
    }

    const goToTransferOutnNft = () => {
        onLeftClick("transferOutNft");
    }

    const obtainCoupons = () => {
        setShowInviteCashback(true)
    }

    let rightContent = ""
    if (action === 'myNft') {
        rightContent = <MyNft TET2USD={TET2USD} userId={props.userId} avatarUserNFTId={props.avatarUserNFTId} updateUserInfo={updateUserInfo} goToTransferInNft={goToTransferInNft} goToTransferOutnNft={goToTransferOutnNft} />
    } else if (action === 'myTicker') {
        rightContent = <MyTicker updateUserInfo={updateUserInfo} />
    } else if (action === 'settings') {
        rightContent = <SafeSettings email={email} secondPasswordSet={props.secondPasswordSet} updateUserInfo={updateUserInfo} />
    } else if (action === 'incomeRecords') {
        rightContent = <IncomeRecords />
    } else if (action === "marketAction") {
        rightContent = <MarketAction />
    } else if (action === "transferInToken") {
        rightContent = <TransferInToken TET2USD={TET2USD} updateUserInfo={updateUserInfo} />
    } else if (action === "transferInNft") {
        rightContent = <TransferNft type={"deposit"} addr={props.addr} />
    } else if (action === "transferOutToken") {
        rightContent = <TransferOutToken TET2USD={TET2USD} tetrisToken={props.tetrisToken} tetrisTokenLocked={props.tetrisTokenLocked} email={props.email} forgetPwd={forgetPwd} obtainCoupons={obtainCoupons} updateUserInfo={updateUserInfo} />
    } else if (action === "transferOutNft") {
        rightContent = <TransferNft type={"withdraw"} addr={props.addr} forgetPwd={forgetPwd} />
    } else {
        rightContent = <></>
    }


    return (
        <PublicPopup isOverlay title={t("personalCenter.personalCenter")} closefun={(action) => handleClick(action)}>
            <div className='personal-center-wrapper'>
                <div className='left-side'>
                    <div className="avatar_div">
                        <Image className='avatar' fallback={avatarDefault} preview={false} src={props.userAvatarUrl + "?imageView2/1/w/300/h/300"} placeholder={<Image className='avatar' fallback={avatarDefault} preview={false} src={props.userAvatarUrl + "?imageView2/1/w/50/h/50"} />} />
                        <div className="invite" onClick={obtainCoupons}>
                            <Image className='gif_icon' preview={false} src={gifIcon} />
                        </div>
                    </div>
                    <div className='uid activate_tag tags_common'>{nickname}</div>
                    <div className={action === "myNft" ? "left_bt_hover left_bt" : "left_bt"} onClick={() => onLeftClick("myNft")}>{t("personalCenter.myNFT")}</div>
                    <div className={action === "myTicker" ? "left_bt_hover left_bt" : "left_bt"} onClick={() => onLeftClick("myTicker")}>{t("personalCenter.myTickets")}</div>
                    <div className={action === "incomeRecords" ? "left_bt_hover left_bt" : "left_bt"} onClick={() => onLeftClick("incomeRecords")}>{t("personalCenter.incomeRecords")}</div>
                    <div className={action === "marketAction" ? "left_bt_hover left_bt" : "left_bt"} onClick={() => onLeftClick("marketAction")}>{t("personalCenter.marketAction")}</div>
                    <div className={action === "settings" ? "left_bt_hover left_bt" : "left_bt"} onClick={() => onLeftClick("settings")}>{t("personalCenter.safeSettings")}</div>
                </div>

                <div className='right-side'>
                    <div className='my-nft'>
                        <div className='top-tab'>
                            <div className="tab_right">
                                {assetsOptionTabs.map((item, index) => {
                                    return <div key={item.key} onClick={() => gotoNext(item.key)} className={action === item.key ? "button button_hover" : "button"}>{item.label}</div>
                                })}
                            </div>
                            <div className="tab_left">
                                <div className='price_ic'></div>
                                <div>
                                    <div className='price_usd'>{props.tetrisToken === "..." ? "..." : "$" + (props.tetrisToken * TET2USD).toFixed(2)}</div>
                                    <div className='price_tet'>{props.tetrisToken === "..." ? "..." : (props.tetrisToken / 1).toFixed(4) + " " + TOKENNAME}</div>
                                </div>
                                <div className='close_button' onClick={updateUserInfo}>
                                    <Image preview={false} src={refreshWhiteIcon} />
                                </div>
                            </div>
                        </div>
                        <div className='right_content'>
                            {rightContent}
                        </div>
                    </div>
                </div>
            </div>
            {showInviteCashback ? <InviteCashback uuid={uuid} onClose={() => { setShowInviteCashback(false) }} /> : ""}
        </PublicPopup>
    )
}

export default Index
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import AOS from "aos";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";
import Socketscapsule from "./socket/capsule";
import routes from './routers/index'
import "./scss/Common.scss";

AOS.init();

function App() {
  const location = useLocation()
  const navigator = useNavigate()
  // const token = useSelector((state) => state.user.token, shallowEqual);
  let token = null

  // //Set system language
  // const lan = (window.navigator.browserLanguage || window.navigator.language).toLowerCase()
  // if (lan.indexOf('zh') > -1) {
  //   console.log('中文')
  // } else if (lan.indexOf('en') > -1) {
  //   console.log('英文')
  // } else {
  //   console.log('其他语言')
  // }

  // if (location.state) {
  //   token = location.state.token
  // }

  if (JSON.parse(sessionStorage.getItem('persist:user'))) {
    token = JSON.parse(JSON.parse(sessionStorage.getItem('persist:user')).user).token
  } else {
    token = null
  }
  useEffect(() => {
    if (token) {
      if (location.pathname === '/webLogin') {
        if (location.state !== null) {
          navigator(-1)
        } else {
          navigator("/", { replace: true })
        }
      }
    } else {
      if (location.pathname !== '/home') {
        // navigator('/webLogin')
      }
    }
  }, [location.pathname])

  return (
    <div className="App">
      <Socketscapsule>
        {useRoutes(routes)}
      </Socketscapsule>
    </div>
  );
}

export default App;
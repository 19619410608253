import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


import { Image, Dropdown, Button } from 'antd';

import '../../views/OfficialSite.scss'
import topLogo from "../../assets/icon/nav_tips.png";


const menuStyle = {
    borderRadius: '1.1vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    fontFamily: "Noto Sans",
    padding: "0px",
    top: "-2.3vw",
    fontSize: "0.86vw",
    boxShadow: "3px 3px 3px 0px rgba(73, 75, 134, 0.30)"
}

const menuItemStyle = {
    display: 'flex',
    alignItems: 'center',
    width: "100%",
    fontWeight: 'bold',
    cursor: 'pointer',
    fontWeight: "700",
    height: "2.2vw",
}

const Header = (props) => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const [locale, setLocale] = useState('cn')


    useEffect(() => {
        setLocale(i18n.language)
    }, [i18n.language])

    const setLanguage = (language) => {
        i18n.changeLanguage(language)
        localStorage.setItem("language", language)
    }

    const selectMenu = (homePage, e) => {
        props.selectPage(homePage)
        setTimeout(() => {
            const anchor = document.getElementById(e) || { offsetTop: 0 };
            // chrome
            document.body.scrollTop = anchor.offsetTop;
            // firefox
            document.documentElement.scrollTop = anchor.offsetTop;
            // safari
            window.pageYOffset = anchor.offsetTop;
        }, 0);
    }

    const openWhitepaper = () => {
        if (locale === "cn") {
            window.open("https://tetriverse-white-paper.gitbook.io/untitled/", "_blank")
        } else if (locale === "en") {
            window.open("https://tetriverse-white-paper.gitbook.io/tetriverse-white-paper/", "_blank")
        }
    }

    return (
        <div className="header">
            <div className="opt_section_logo">
                <div className="top_logo" onClick={() => selectMenu('Home')} >
                    <Image
                        preview={false}
                        src={topLogo}
                        className='top_img'
                    />
                </div>
            </div>
            <div className="menu">
                <div className="item" onClick={() => selectMenu('Home', 'Story')}>{t('header.story')}</div>
                <div className="item" onClick={() => selectMenu('Home', 'Model')}>{t('header.mode')}</div>
                <div className="item" onClick={() => selectMenu('Home', 'Nft')}>{t('header.nft')}</div>
                <div className="item" onClick={() => { selectMenu('casting', 'casting') }}>{t('header.casting')}</div>
                <div className="item" onClick={() => { openWhitepaper() }}>{t('header.intro')}</div>
            </div>
            <div className="opt_section">
                <Dropdown
                    dropdownRender={(menu) => (
                        <div style={{ ...menuStyle, background: 'linear-gradient(180deg, #EEE 0%, #B073EC 149%)', height: '6.6vw', }}>
                            <div style={{ ...menuItemStyle, letterSpacing: "0.11vw", gap: "0.22vw", color: '#721ac9', justifyContent: "center", }}>
                                <div className="lng"></div>
                                <div>{i18n.language == 'en' ? 'ENG' : '中文'}</div>
                                <div className="arrow"></div>
                            </div>
                            <div style={{ borderBottom: "1px solid #721ac9", width: "60%" }}></div>
                            <div style={menuItemStyle} onClick={() => { setLanguage("cn") }}>
                                <div style={{ color: i18n.language == 'cn' ? '#721ac9' : '#747474', padding: "1vw" }}>中文</div>
                            </div>
                            <div style={menuItemStyle} onClick={() => { setLanguage("en") }}>
                                <div style={{ color: i18n.language == 'en' ? '#721ac9' : '#747474', padding: "1vw" }}>English</div>
                            </div>
                        </div>
                    )}
                >
                    <div className="lng_container">
                        <div className="lng"></div>
                        <div>{i18n.language == 'en' ? 'ENG' : '中文'}</div>
                        <div className="arrow"></div>
                    </div>
                </Dropdown>
                <Button className="btn start_game" onClick={() => navigate('/webLogin')}>
                    {t('commonText.start')}
                </Button>
                {!props.address ?
                    <Button loading={props.connectWalletLoading} className="btn connect_wallet" onClick={() => props.connectWallet()}>
                        {t('header.connectWallet')}
                    </Button> :
                    <Dropdown
                        dropdownRender={(menu) => (
                            <div style={{
                                ...menuStyle,
                                background: "linear-gradient(180deg, #503666 0%, #7C1DC9 149%)",
                                boxShadow: "3px 3px 3px 0px rgba(73, 75, 134, 0.30)"
                            }}>
                                <div style={{ ...menuItemStyle, gap: "0.5vw", color: '#fff', justifyContent: "center" }}>
                                    <Image width={"1.5vw"} height={"1.5vw"} style={{ overflow: "hidden", borderRadius: "50%" }} preview={false} fallback={props.avatarDefault} src={props.avatarDefault} />
                                    <div>{props.address}</div>
                                </div>
                                <div style={{ borderBottom: "1px solid #fff", width: "80%" }}></div>
                                <div style={{ ...menuItemStyle, color: '#fff', paddingLeft: "2vw", gap: "0.5vw" }} onClick={() => { props.setAddress("") }}>
                                    <div className="log_out"></div>
                                    <div>{t("header.logOut")}</div>
                                </div>
                            </div>
                        )}
                        trigger={['click', 'hover']}
                    >
                        <Button loading={props.connectWalletLoading} className="btn connect_wallet">
                            <Image preview={false} fallback={props.avatarDefault} src={props.avatarDefault} />
                            <div className='text'>{props.address}</div>
                        </Button>
                    </Dropdown>
                }
            </div>
        </div>
    )
}

export default Header
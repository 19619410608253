import { combineReducers } from "redux";
import { sockets } from "./sockets";
import { user } from "./user";
import { localSetting } from "./localSettings";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const localSettingPersistConfig = {
	key: 'localSetting',
	storage: storage,
	blacklist: ['user', 'sockets']
}

// import reducers
export default combineReducers({
	// reducers
	sockets,
	user,
	localSetting: persistReducer(localSettingPersistConfig, localSetting),
});
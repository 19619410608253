import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist'
import sessionStorage from "redux-persist/es/storage/session";

const initialState = {};
const persistConfig = {
    key: 'user',
    storage: sessionStorage,
    whitelist: ['user']
};
const myPersistReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];

const store = createStore(myPersistReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));
export const persistor = persistStore(store);

export default store;

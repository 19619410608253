import { useState, useEffect, useCallback } from 'react';
import cleaar from '../assets/clear.mp3';
export const useGameStatus = (rowsCleared, isViewer, soundEffects) => {
  const [score, setScore] = useState(0);
  const [rows, setRows] = useState(0);
  const [level, setLevel] = useState(0);
  const linePoints = [40, 100, 300, 1200];
  const [clear] = useState(new Audio(cleaar));

  const calcScore = useCallback(() => {
    if (isViewer) { return }
    // We have score
    if (rowsCleared > 0) {
      // This is how original Tetris score is calculated
      setScore(prev => prev + linePoints[rowsCleared - 1] * (level + 1));
      setRows(prev => prev + rowsCleared);
      if (soundEffects) {
        clear.play();
      }
    }
  }, [level, linePoints, rowsCleared]);

  useEffect(() => {
    if (isViewer) { return }
    calcScore();
  }, [calcScore, rowsCleared, score]);

  return [score, setScore, rows, setRows, level, setLevel];
};

import { useState, useEffect } from "react";
import { checkCollision, createStage, STAGE_HEIGHT } from "../Components/Game/gameHelpers";
import { socket } from "../socket/socket";

export const useStage = (
  player,
  nextPiece,
  resetPlayer,
  gameOver,
  start,
  userName,
  roomName,
  wall,
  wallData,
  addWall,
  isViewer,
  timeoutOver,
) => {
  const [stage, setStage] = useState(createStage());
  const [nextStage, setNextStage] = useState(createStage(4, 4));
  const [rowsCleared, setRowsCleared] = useState(0);
  const [isAddUsrWall, setIsAddUsrWal] = useState(false);
  const [sweepExactRows, setSweepExactRows] = useState([]);

  useEffect(() => {
    if (isViewer) { return }
    setRowsCleared(0);

    // Sweep completed rows
    const sweepRows = (newStage) => {
      let currentSweepExactRows = []
      let tmp = newStage.reduce((ack, row, idx) => {
        if (row.findIndex((cell) => cell[0] === 0) === -1) {
          setRowsCleared((prev) => prev + 1);
          ack.unshift(new Array(newStage[0].length).fill([0, "clear"]));
          currentSweepExactRows.push(idx)
          return ack;
        }

        ack.push(row);
        return ack;
      }, []);
      setSweepExactRows(currentSweepExactRows)
      return tmp
    }

    const getFreeList = (arr) => {
      //临时数组，用于存值
      let arrAdd = [...arr];
      for (let i = 1; i < arrAdd.length; i++) {
        const random = Math.floor(Math.random() * (i + 1));
        //交换两个数组
        [arrAdd[i], arrAdd[random]] = [arrAdd[random], arrAdd[i]];
      }
      return arrAdd;
    }

    const updateStage = (prevStage) => {
      // First flush the stage
      const newStage = prevStage.map((row) => row.map((cell) => (cell[1] === "merged" ? cell : [0, "clear"])));

      // Then draw the shadow of tetromino
      let shadow = 1;
      while (shadow < STAGE_HEIGHT && !checkCollision(player, stage, { x: 0, y: shadow })) {
        shadow++;
      }
      if (shadow) {
        player.tetromino.forEach((row, y) => {
          row.forEach((value, x) => {
            if (value !== 0) {
              newStage[y + player.pos.y + shadow - 1][x + player.pos.x] = [
                value + "S",
                `${player.collided ? "merged" : "clear"}`,
              ];
            }
          });
        });
      }

      //Emit the wall
      if (isAddUsrWall) {
        // socket.emit("addWall", { room: roomName, userName: userName });
        // insert a line at bottom
        let wall = new Array(9).fill(["Wall", "merged"]);
        wall.push(["0", "clear"]);
        // wall.push(["Wall", "merged"]);
        // wall.push(["Wall", "merged"]);
        // wall.push(["Wall", "merged"]);
        wall = getFreeList(wall);
        newStage.push(wall);
        newStage.shift();
        console.log(newStage)
        setIsAddUsrWal(false)
      }

      // Then draw the tetromino
      player.tetromino.forEach((row, y) => {
        row.forEach((value, x) => {
          if (value !== 0) {
            newStage[y + player.pos.y][x + player.pos.x] = [value, `${player.collided ? "merged" : "clear"}`];
          }
        });
      });

      // Then draw the next tetromino
      nextPiece.tetromino.forEach((row, y) => {
        row.forEach((value, x) => {
          if (value !== 0) {
            nextStage[y][x] = [value, `${nextPiece.collided ? "merged" : "clear"}`];
          }
        });
      });

      // solo mode timeoutOver is true
      if (timeoutOver && !gameOver) {
        resetPlayer(newStage);
        setNextStage(createStage(4, 4));
        return newStage
      }

      // Then check if we got some score if collided
      if (player.collided && !gameOver) {
        resetPlayer(newStage);
        setNextStage(createStage(4, 4));
        return sweepRows(newStage);
      }

      //Add the wall
      if (wall && userName === wallData.from.name) {
        let wall = new Array(10).fill(["Wall", "merged"]);
        newStage.push(wall);
        newStage.shift();
        addWall({ wall: false });
      }
      return newStage;
    };

    // Here are the updates
    if (!start) setStage((prev) => updateStage(prev));
  }, [
    player.collided,
    player.pos.x,
    player.pos.y,
    player.tetromino,
    resetPlayer,
    nextPiece,
    nextStage,
    gameOver,
    roomName,
    wall,
    addWall,
    isAddUsrWall,
    sweepExactRows,
    timeoutOver,
  ]);

  return [stage, nextStage, isAddUsrWall, sweepExactRows, rowsCleared, setStage, setNextStage, setIsAddUsrWal, setSweepExactRows];
};

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom'
import App from "./App";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
// import 'lib-flexible';
import 'amfe-flexible'
// import 'antd/dist/antd.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './language/i18n'
import { PersistGate } from "redux-persist/lib/integration/react";

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</PersistGate>
	</Provider>
	, document.getElementById("root"));

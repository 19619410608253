import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import zlib from "zlib";
import {
  StartGame,
  newTetriminos,
  updateRoomDetail,
  getRoomPlayerslist,
  getRoomViewerslist,
  getChatMessages,
  clearAllState,
  setStages,
  setScores,
  setProps,
  updateStages,
  userExists,
  deleteUserfromStages,
  updateRoomList,
  GameFinished,
  ClearStateAfterLeavedRoom,
  updateRoomData,
  getRooms,
  addWall,
  newRoom,
  newPLayer,
  roomExists,
  roomFull,
  UpdateGameAsyncTime
} from "../redux/actions/sockets/socketsActions";
import { socket } from "./socket";
import { toast } from "react-toastify";
import { shuffleArray } from "../tools/utils";
import { openNotification } from "../tools/notification";
import { t } from "i18next";

const Socketscapsule = (props) => {
  const navigate = useNavigate()
  const { tetriminos, Stages, Scores, PropsData, roomname, roomDetail, clearStagesName } = props;

  useEffect(() => {
    //initial Socket connection
    socket.on("connect", () => {
      console.log("connect")
      // try {
      //   props.getRooms();
      // }
      // catch { }
    });

    socket.on("disconnect", () => {
      openNotification(t("game.networkError"))
      console.log("disconnect")
      socket.emit("leaveRoom")
      navigate("/", { replace: true })
      try {
        props.clearAllState();
      }
      catch { }
    });

    socket.on("clearStages", (data) => {
      // console.log("clearStages", data)
      props.deleteUserfromStages(data.username, Stages);
    });

    // User Already Exists
    socket.on("useralready_exist", (res) => {
      // console.log("user already_exist", res)
      props.userExists(res.res);
      props.newPLayer(res.player?.name);
    });
    // call_new_user
    socket.on("call_new_user", () => {
      // openNotification("call_new_user")
      // console.log("call_new_user")
      // window.location.href = `${window.location.origin}/`;
    })

    // connot add this user from URL
    socket.on("cannot_add_user", (res) => {
      console.log("cannot_add_user", res)
      openNotification("cannot_add_user")
      if (res.res) {
        // window.location.href = `${window.location.origin}/`;
        toast("This user is already exists", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });

    // room_is_not_exist join room fail
    socket.on("room_is_not_exist", (msg) => {
      // console.log(msg)
      props.roomExists(true)
    })

    socket.on("room_full", (msg) => {
      props.roomFull(true)
    })

    // listen for starting the Game
    socket.on("startGame", (tetris) => {
      // console.log("startGame", tetris)
      props.StartGame(shuffleArray(tetris));
    });
    // get the new tetriminos from the server and update the state with it
    socket.on("newTetriminos", (tetris) => {
      // console.log("newTetriminos", tetriminos)
      props.newTetriminos(shuffleArray(tetris), tetriminos);
    });
    // get players Stages State
    socket.on("getstages", (stage) => {
      var buffer = new Buffer(stage.stage, 'base64');
      zlib.unzip(buffer, function (err, buffer) {
        if (!err) {
          // console.log('...', buffer.toString());
          stage.stage = JSON.parse(buffer.toString())
          props.setStages(Stages, stage, roomname, clearStagesName);
        }
      });
    });
    // get players Scores
    socket.on("getScores", (score) => {
      // console.log("getScores", score)
      props.setScores(Scores, score, roomname);
    });
    // get players props
    socket.on("useProps", (prop) => {
      // console.log("getProps", prop)
      props.setProps(PropsData, prop, roomname);
    });
    // Add a wall to the other players Stages
    socket.on("addWall", (wallData) => {
      // console.log("addWall", wallData)
      props.addWall({ wall: true, data: wallData });
    });
    socket.on("asyncTime", (data) => {
      // console.log("asyncTime", data)
      if (roomDetail.id === data.roomId) {
        props.UpdateGameAsyncTime(data.time)
      }
    });
    // Update the Stages State
    socket.on("updateStages", (stages) => {
      // console.log("updateStages", stages)
      props.updateStages(stages);
    });
    // Listen for the room Players list
    socket.on("roomPlayers", (playersList) => {
      // console.log("roomPlayers", playersList)
      props.getRoomPlayerslist(playersList);
    });
    // Update Room data
    socket.on("update_room_data", (room) => {
      // console.log("update_room_data", room)
      if (room.name !== roomname) {
        props.newRoom(room.name)
      }
      props.updateRoomDetail(room)
    });
    // Chat Listener
    socket.on("chat", (message) => {
      // console.log("chat", message)
      props.getChatMessages(message);
    });
    // Just admin can start the game
    socket.on("wait_admin", () => {
      // console.log("wait_admin")
      // toast("Wait until admin start the game", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    });
    // joined room access
    socket.on("joined_denided", () => {
      openNotification("joined_denided")
      // console.log("joined_denided")
      // window.location.href = `${window.location.origin}/`;
    });

    // Game finished
    socket.on("Game_finish", (data) => {
      // console.log("Game_finish", data)
      props.GameFinished(data?.rank, data?.invalid);
    });

    // leaved room
    socket.on("leaved_room", () => {
      // console.log("leaved_room")
      props.ClearStateAfterLeavedRoom();
    });
    //Room Already Exists
    socket.on("room_exist", () => {
      openNotification("room_exist")
      // console.log("room_exist")
      // window.location.href = `${window.location.origin}/`;
    })
    // room viewer update
    socket.on("roomViewers", (data) => {
      props.getRoomViewerslist(data);
      // console.log("roomViewers", data)
    })

    // welcome_error
    socket.on("welcome_error", () => {
      // console.log("加入游戏失败")
    })

    // welcome
    socket.on("welcome", () => {
      // console.log("加入游戏")
    })
    // Clean up the event listeners
    return () => {
      socket.off("connect");
      socket.off("leaved_room");
      socket.off("call_new_user");
      socket.off("useralready_exist");
      socket.off("room_is_not_exist");
      socket.off("room_full");
      socket.off("clearStages")
      socket.off("startGame");
      socket.off("newTetriminos");
      socket.off("getstages");
      socket.off("getScores");
      socket.off("useProps");
      socket.off("roomPlayers");
      socket.off("updateStages");
      socket.off("chat");
      socket.off("update_rooms");
      socket.off("joined_denided");
      socket.off("wait_admin");
      socket.off("asyncTime");
      socket.off("Game_finish");
      socket.off("update_room_data");
      socket.off("addWall");
      socket.off("room_exist");
      socket.off("disconnect");
      socket.off("roomViewers")
      socket.off("welcome_error");
      socket.off("welcome");
    };
  }, [props]);

  return props.children;
};

const mapStateToProps = (state) => ({
  sockets: state.sockets,
  tetriminos: state.sockets.tetriminos,
  Stages: state.sockets.Stages,
  Scores: state.sockets.Scores,
  PropsData: state.sockets.propsData,
  roomname: state.sockets.roomname,
  roomDetail: state.sockets.roomDetail,
  clearStagesName: state.sockets.ClearStagesName,
});

const mapDispatchToProps = {
  StartGame,
  newTetriminos,
  updateRoomDetail,
  getRoomPlayerslist,
  getRoomViewerslist,
  getChatMessages,
  clearAllState,
  setStages,
  setScores,
  setProps,
  updateStages,
  userExists,
  deleteUserfromStages,
  updateRoomList,
  GameFinished,
  ClearStateAfterLeavedRoom,
  updateRoomData,
  getRooms,
  addWall,
  newRoom,
  newPLayer,
  roomExists,
  roomFull,
  UpdateGameAsyncTime
};

export default connect(mapStateToProps, mapDispatchToProps)(Socketscapsule);

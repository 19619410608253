import React from 'react';
import { StyledCell } from './styles/StyledCell';
import { useSelector } from "react-redux";
import { TETROMINOS } from './tetriminos';
const shadowBlocks = ['IS', 'JS', 'LS', 'OS', 'SS', 'TS', 'ZS']

// React.memo makes sure we only re-render the changed cells
const Cell = ({ type, isOtherPlayer }) => {
  const disposalReminder = useSelector((state) => state.localSetting.soundSettings.disposalReminder)
  return (
    <StyledCell type={type} color={TETROMINOS[type].color} bg={shadowBlocks.findIndex(item=>item=== type) === -1 ? TETROMINOS[type].bg : (disposalReminder && !isOtherPlayer ? TETROMINOS[type].bg : '')}>
    </StyledCell>
  )
};

export default React.memo(Cell);

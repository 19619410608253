import styled from 'styled-components';

export const StyledNext = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.width}, 1fr);
  grid-template-rows: repeat(
    ${props => props.height},
    calc(${props => props.size} / ${props => props.width})
  );
  height: calc(${props => props.size} );
  width: calc(${props => props.size} );
  margin: calc(${props => props.size} / ${props => (props.shape === 'I' ) ? 1.2 : (props.shape === 'O' || props.shape === 'S' ||  props.shape === 'L' || props.shape === 'J' ||  props.shape === 'Z') ? 1.8 : 2} ) 0 0 calc(${props => props.size} / ${props => props.shape === 'I' ? 100 : ( props.shape === 'S' ||  props.shape === 'L' || props.shape === 'Z' || props.shape === 'T') ? 4 : props.shape === 'J' ? 3 : props.shape === 'O' ? 2.5 : 2} );
`;

import React from 'react';
import { StyledNext } from './styles/StyledNext';

import NextCell from './NextCell';

const Stage = ({ stage, size }) => {
  let shape = 'I'
  try {
    shape = stage[0][stage[0].findIndex(item => item[0] != 0) === -1 ? 0 : stage[0].findIndex(item => item[0] != 0)][0]
  } catch (e) {

  }
  return (
    <StyledNext size={size} width={stage[0].length} height={stage.length} shape={shape}>
      {stage.map(row => row.map((cell, x) => <NextCell key={x} type={cell[0]} />))}
    </StyledNext>
  )
};

export default Stage;

import React, { useEffect, useState } from 'react'
import { Spin, Dropdown, Button, Image } from 'antd';
import { t } from 'i18next';

import { postNftDetailList, setAvatar, postMarketplaceUsernft } from '../../../api';
import { viewCode } from '../../../tools/viewCode';
import { openNotification } from '../../../tools/notification';
import { IMAGE_BASEURL, NFTEXPIRED } from '../../../tools/gameConfig'

import ListSingleNft from '../../Market/ListSingleNft'
import ListNftConfirm from '../../Market/ListNftConfirm';
import ListInBulkNft from '../../Market/ListInBulkNft';
import MarketDetail from '../../Market/MarketDetail';
import ActivateNft from '../../Market/ActivateNft'
import RemoveNft from '../../Market/RemoveNft';
import CommonPagination from '../../PublicComponents/Pagination';
import NoData from '../../PublicComponents/NoData';

import styles from "../../../scss/Hall/PersonalCenter/MyNft.module.scss"

function MyNft(props) {

    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)

    const [showNftDetail, setShowNftDetail] = useState({})
    const [nftMarketplaceId, setNftMarketplaceId] = useState("")
    const [activateNFTIndex, setActivateNFTIndex] = useState(null)

    const [nftDetailList, setNftDetailList] = useState([])
    const [singleNftSelected, setSingleNftSelected] = useState({})
    const [bulkNftSelectedList, setBulkNftSelectedList] = useState([])
    const [bulkNftSelectedListConfirm, setBulkNftSelectedListConfirm] = useState([])
    const [nftSelectedListConfirm, setNftSelectedListConfirm] = useState([])
    const [removeNFTIndex, setRemoveNFTIndex] = useState(null)

    const [moveInterIndex, setMoveInterIndex] = useState(0)
    const [listinbulk, setListinbulk] = useState(false)

    const [rarityType, setRarityType] = useState("All")
    const [sellType, setSellType] = useState("All")
    const [validType, setValidType] = useState("All")

    const rarityTypeItems = [
        { key: "All", label: "ALL" },
        { key: "SSR", label: "SSR" },
        { key: "SR", label: "SR" },
        { key: "R", label: "R" },
        { key: "N", label: "N" },
        { key: "M", label: "M" },
    ]

    const sellTypeItems = [
        { key: "All", label: t("personalCenter.All") },
        { key: "InGame", label: t("personalCenter.InGame") },
        { key: "InMarket", label: t("personalCenter.InMarket") },
    ]

    const validTypeItems = [
        { key: "All", label: t("personalCenter.All") },
        { key: "InActivated", label: t("personalCenter.InActivated") },
        { key: "Valid", label: t("personalCenter.Valid") },
        { key: "Expired", label: t("personalCenter.Expired") },
    ]

    useEffect(() => {
        if (page && page !== 1 && !loading) {
            loadNftDetailList()
        }
        return () => {
        }
    }, [page])

    useEffect(() => {
        if (rarityType && validType && sellType && !loading) {
            getNftDetailList(1)
        }
        return () => {
        }
    }, [rarityType, validType, sellType])


    const loadNftDetailList = () => {
        getNftDetailList(page)
    }

    const getNftDetailList = (page, type) => {
        setLoading(true)
        postNftDetailList({
            "nftNetworkStatusFilter": sellType,
            "nftValidStatusFilter": validType,
            "nftQualityFilter": rarityType,
            "nftOrder": "time DESC",
            "pagination": {
                "page": page,
                "size": 12
            }
        }).then((res) => {
            if (!res.data.code) {
                if (res.data.data.userNFTs) {
                    filterGetMarketplaceIdForUserNft(res.data.data.userNFTs, type)
                    setTotal(res.data.data.pagination?.total)
                } else {
                    setTotal(0)
                    setLoading(false)
                    setNftDetailList([])
                }
            } else {
                setLoading(false)
            }
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    const filterGetMarketplaceIdForUserNft = (d, type1) => {
        let nfts = []
        let data = d
        data.map((item) => {
            if (item.NftNetworkStatus === "InMarket") {
                nfts.push(item.ID)
            }
        })
        if (nfts.length > 0) {
            postMarketplaceUsernft({ userNFTIds: nfts }).then((res) => {
                if (res.data.code) {
                    setLoading(false)
                    openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                } else if (res.data.data && res.data.data?.userNFTMarketplaceIds) {
                    data.map((item) => {
                        if (item.NftNetworkStatus === "InMarket") {
                            item.nftMarketplaceId = res.data.data?.userNFTMarketplaceIds.find((it) => { return it.userNFTId === item.ID })?.userNFTMarketplaceId
                        }
                    })
                    let newSelectNft = {}
                    if ((showNftDetail?.ID || nftMarketplaceId) && !type1) {
                        newSelectNft = data.find((item) => item.NftTokenIndex === showNftDetail?.NftTokenIndex) || {}
                    }
                    if (newSelectNft?.NftNetworkStatus === "InMarket") {
                        setNftMarketplaceId(newSelectNft?.nftMarketplaceId)
                        setShowNftDetail({})
                    } else {
                        setNftMarketplaceId("")
                        setShowNftDetail(newSelectNft)
                    }
                    setLoading(false)
                    setNftDetailList(data)

                } else {
                    setLoading(false)
                    setNftDetailList(data)
                }
            }).catch(() => {
                setLoading(false)
                openNotification(t("responseCode.networkFailed"))
            })
        } else {
            setLoading(false)
            setNftDetailList(data)
        }

    }

    const sellTypeChange = ({ key }) => {
        setSellType(key)
    }

    const validTypeChange = ({ key }) => {
        setValidType(key)
    }

    const LoadMore = () => {
        setPage(p => p + 1)
    }

    // action
    const setUserAvatar = (e, id) => {
        e.stopPropagation();
        setAvatar({
            "action": "change",
            "userNFTId": id
        }).then((res) => {
            if (!res.data.code) {
                if (res.data.data.userNFTId === id) {
                    openNotification(t("responseCode.success"))
                    props.updateUserInfo()
                } else {
                    openNotification(t("responseCode.failed"))
                }
            } else {
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            }
        }).catch((error) => {
            console.log(error)
            openNotification(t("responseCode.networkFailed"))
        })
    }
    const removeUserAvatar = (e, id) => {
        e.stopPropagation();
        setAvatar({
            "action": "remove",
            "userNFTId": id
        }).then((res) => {
            if (!res.data.code) {
                if (res.data.data.userNFTId === -1) {
                    openNotification(t("responseCode.success"))
                    props.updateUserInfo()
                } else {
                    openNotification(t("responseCode.failed"))
                }
            } else {
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            }
        }).catch((error) => {
            console.log(error)
            openNotification(t("responseCode.networkFailed"))
        })
    }
    const extractNft = (e) => {
        e.stopPropagation();
        props.goToTransferOutnNft()
    }
    const toTransferInNft = (e) => {
        e.stopPropagation();
        props.goToTransferInNft()
    }

    // single nft
    const setSingleNFT = (e, index) => {
        e.stopPropagation();
        window._czc && window._czc.push(["_trackEvent", "我的NFT页", "点击", "单个NFT上架页"]);
        setSingleNftSelected(nftDetailList[index])
    }
    const closeSingleNft = () => {
        setSingleNftSelected({})
    }
    const listNFTConfirm = (list) => {
        window._czc && window._czc.push(["_trackEvent", "我的NFT页", "点击", "NFT上架确定页"]);
        setNftSelectedListConfirm(list)
    }

    // activate
    const activateNft = (e, index) => {
        e.stopPropagation();
        window._czc && window._czc.push(["_trackEvent", "我的NFT页", "点击", "NFT激活页"]);
        setActivateNFTIndex(index)
    }
    const activateNftConfirm = () => {
        let newList = nftDetailList
        newList[activateNFTIndex].NftValidStatus = "Valid"
        newList[activateNFTIndex].NftExpiredAt = new Date().getTime() + NFTEXPIRED
        setNftDetailList(newList)
        setActivateNFTIndex(null)
    }
    const activateNftClose = () => {
        setActivateNFTIndex(null)
    }

    const addSelectedNft = (e, index) => {
        e.stopPropagation();
        if (nftDetailList[index].select) {
            let newNftList = nftDetailList
            newNftList[index].select = false
            setNftDetailList([...newNftList])
            let newBulkNftSelectedList = bulkNftSelectedList
            let index1 = newBulkNftSelectedList.findIndex((item) => { return item.ID === newNftList[index].ID })
            newBulkNftSelectedList.splice(index1, 1)
            setBulkNftSelectedList([...newBulkNftSelectedList])
        } else {
            let newNftList = nftDetailList
            newNftList[index].select = true
            setNftDetailList([...newNftList])
            setBulkNftSelectedList([...bulkNftSelectedList, nftDetailList[index]])
        }
    }
    const closeListInBulk = () => {
        setListinbulk(false)
        setBulkNftSelectedList([])
        let newNftList = nftDetailList
        newNftList.map((item) => {
            item.select = false
        })
        setNftDetailList(newNftList)
    }
    const confirmListInBulk = () => {
        window._czc && window._czc.push(["_trackEvent", "我的NFT页", "点击", "多个NFT上架页"]);
        setBulkNftSelectedListConfirm(bulkNftSelectedList)
    }
    const closeConfirmListInBulk = () => {
        setBulkNftSelectedListConfirm([])
    }

    // listNftConfirm
    const closeListNFTConfirm = () => {
        closeListInBulk()
        closeConfirmListInBulk()
        closeSingleNft()
        setNftSelectedListConfirm([])
        getNftDetailList(1)
    }
    const backListNFTConfirm = () => {
        setNftSelectedListConfirm([])
    }
    const confirmListNFTConfrim = () => {
        closeListNFTConfirm()
    }

    // nft detail
    const selectNftDetail = (index) => {
        window._czc && window._czc.push(["_trackEvent", "我的NFT页", "点击", "NFT详情页"]);
        if (nftDetailList[index].NftNetworkStatus === "InMarket") {
            setNftMarketplaceId(nftDetailList[index].nftMarketplaceId)
        } else {
            setShowNftDetail(nftDetailList[index])
        }
    }
    const closeSelectNftDetail = () => {
        setShowNftDetail({})
        setNftMarketplaceId("")
        getNftDetailList(1, "off")
    }
    const sellNowNFT = (nft) => {
        window._czc && window._czc.push(["_trackEvent", "NFT详情页", "点击", "单个NFT上架页"]);
        setSingleNftSelected(nft)
    }

    // remove
    const removeNft = (e, index) => {
        e.stopPropagation();
        window._czc && window._czc.push(["_trackEvent", "我的NFT页", "点击", "NFT下架页"]);
        setRemoveNFTIndex(index)
    }
    const removeNftConfirm = () => {
        let newList = nftDetailList
        newList[removeNFTIndex].NftNetworkStatus = "InGame"
        newList[removeNFTIndex].NftSellStatus = ""
        setNftDetailList(newList)
        setRemoveNFTIndex(null)
    }
    const removeNftClose = () => {
        setRemoveNFTIndex(null)
    }

    const pageChange = (e) => {
        setPage(e)
    }

    return (
        <>
            <div className={styles.my_nft_wrapper}>
                {loading ? <Spin /> :
                    <>
                        <div className={styles.nft_type_tabs}>
                            <div className={styles.rarity}>
                                {rarityTypeItems.map((item) => {
                                    return <div onClick={() => { setRarityType(item.key) }} key={item.key}>
                                        {item.label}
                                        <div className={item.key === rarityType ? "selected_button" : "unselected_button"}></div>
                                    </div>
                                })}
                            </div>
                            <div className={styles.filter_center}>
                                {listinbulk ?
                                    <><div className={styles.dropdown} onClick={closeListInBulk}>{t("market.cancel")}</div>
                                        {bulkNftSelectedList.length ? <div onClick={confirmListInBulk} className={styles.dropdown}>{t("market.confirm")}</div> : ""}
                                    </> :
                                    <div
                                        className={styles.dropdown}
                                        onClick={() => { setListinbulk(true) }}>
                                        {t("market.listInBulk")}
                                    </div>
                                }
                                <Dropdown
                                    className={styles.dropdown}
                                    menu={{
                                        items: validTypeItems,
                                        onClick: validTypeChange,
                                    }}
                                >
                                    <a onClick={(e) => e.preventDefault()}>
                                        {t(`personalCenter.${validType}`)}
                                        <div className='dropdown_ic'></div>
                                    </a>

                                </Dropdown>
                                <Dropdown
                                    className={styles.dropdown}
                                    menu={{
                                        items: sellTypeItems,
                                        onClick: sellTypeChange,
                                    }}
                                >
                                    <a onClick={(e) => e.preventDefault()}>
                                        {t(`personalCenter.${sellType}`)}
                                        <div className='dropdown_ic'></div>
                                    </a>

                                </Dropdown>
                            </div>
                            <div className={styles.filter_center}>
                                <CommonPagination onChange={pageChange} pageSize={12} current={page} total={total} />
                            </div>
                        </div>
                        <div className={styles.nft_list_container}>
                            {nftDetailList.length ?
                                <div className={styles.nft_list} onMouseLeave={() => { setMoveInterIndex(0) }}>
                                    {nftDetailList.map((item, index) => {
                                        return (
                                            <div className={styles.nft_item} key={item.ID} onMouseEnter={() => { setMoveInterIndex(index + 1) }} >
                                                <Image
                                                    preview={false}
                                                    className={styles.nft_img}
                                                    src={item.NftAttributes?.content ? IMAGE_BASEURL + item.NftAttributes?.content + ".png?imageView2/1/w/300/h/300" : ""}
                                                    placeholder={
                                                        <Image
                                                            preview={false}
                                                            className={styles.nft_img}
                                                            src={item.NftAttributes?.content ? IMAGE_BASEURL + item.NftAttributes?.content + ".png?imageView2/1/w/50/h/50" : ""}
                                                        />
                                                    }
                                                />
                                                <div className={styles.nft_detail}>
                                                    <div className={styles.nft_id}>NFT : {item.NftTokenIndex}</div>
                                                    <div className={styles.line + " " + item.NftType}></div>
                                                    <div className={styles.nft_type + " " + item.NftType}>{t("mint.rarity")} : {item.NftType}</div>
                                                </div>
                                                <div className={styles.nft_button}>
                                                    {item.NftValidStatus === "InActivated" && item.NftNetworkStatus !== "OnChain" ?
                                                        <Button className="tags_common buy_tag" onClick={(e) => { activateNft(e, index) }}>{t("market.activate")}</Button> :
                                                        <Button disabled={true} className="tags_common disabled_tag">{t("market.activated")}</Button>
                                                    }
                                                </div>
                                                {listinbulk ?
                                                    <>{item.NftValidStatus !== "Expired" && item.NftNetworkStatus === "InGame" && item.NftType !== "M" ?
                                                        (item.select === true || moveInterIndex === index + 1 ?
                                                            <div className={styles.nft_item_overlay} onClick={(e) => { addSelectedNft(e, index) }}>
                                                                {item.select === true ? <div className="select_ic"></div> : ""}
                                                            </div>
                                                            : <div className={styles.nft_item_overlay0}>
                                                            </div>)
                                                        : <div className={styles.nft_item_overlay}></div>
                                                    }</>
                                                    :
                                                    moveInterIndex === index + 1 ?
                                                        <div className={styles.nft_item_overlay} onClick={() => { selectNftDetail(index) }}>
                                                            {/* 未转入 */}
                                                            {item.NftNetworkStatus === "OnChain" ?
                                                                <Button className="tags_common activate_tag" onClick={(e) => { toTransferInNft(e) }}>{t("market.transferOut")}</Button> :
                                                                // 免费nft
                                                                item.NftType === "M" ? props.avatarUserNFTId !== item.ID ?
                                                                    <Button className="tags_common activate_tag" onClick={(e) => { setUserAvatar(e, item.ID) }} >{t("personalCenter.setAvatar")}</Button> :
                                                                    <Button className="tags_common activate_tag" onClick={(e) => { removeUserAvatar(e, item.ID) }} >{t("personalCenter.removeAvatar")}</Button>
                                                                    : <>
                                                                        {props.avatarUserNFTId !== item.ID ?
                                                                            <Button className="tags_common activate_tag" onClick={(e) => { setUserAvatar(e, item.ID) }} >{t("personalCenter.setAvatar")}</Button> :
                                                                            <Button className="tags_common activate_tag" onClick={(e) => { removeUserAvatar(e, item.ID) }} >{t("personalCenter.removeAvatar")}</Button>
                                                                        }
                                                                        {/* 出售状态 && 有效状态 */}
                                                                        {item.NftNetworkStatus === "InMarket" ?
                                                                            <Button className="tags_common activate_tag" onClick={(e) => { removeNft(e, index) }}>{t("market.remove")}</Button>
                                                                            : item.NftValidStatus !== "Expired" ?
                                                                                <Button className="tags_common activate_tag" onClick={(e) => { setSingleNFT(e, index) }}>{t("market.list")}</Button> : ""}
                                                                        <Button className="tags_common activate_tag" onClick={(e) => { extractNft(e) }}>{t("market.extract")}</Button>
                                                                    </>
                                                            }
                                                        </div> : ""}
                                            </div>
                                        )
                                    })
                                    }
                                </div> : <NoData />}
                        </div>
                    </>
                }
            </div >
            {showNftDetail.ID || nftMarketplaceId ?
                <MarketDetail TET2USD={props.TET2USD} isOverlay={true} loadIng={loading} nftDetail={showNftDetail} back={closeSelectNftDetail} userId={props.userId} buyNow={() => { }} sellNow={(nft) => { sellNowNFT(nft) }} nftMarketplaceId={nftMarketplaceId} />
                : ""}
            {nftSelectedListConfirm.length > 0 ?
                <ListNftConfirm TET2USD={props.TET2USD} onBack={backListNFTConfirm} onConfirm={confirmListNFTConfrim} nftDetailList={nftSelectedListConfirm} onClose={closeListNFTConfirm} />
                : singleNftSelected.ID ?
                    <ListSingleNft onClose={closeSingleNft} nftDetail={singleNftSelected} Confirm={(list) => { listNFTConfirm(list) }} />
                    : bulkNftSelectedListConfirm.length > 0 ?
                        <ListInBulkNft nftDetailList={bulkNftSelectedListConfirm} onClose={closeConfirmListInBulk} onConfirm={setNftSelectedListConfirm}></ListInBulkNft>
                        : ""}
            {activateNFTIndex !== null ? <ActivateNft onConfirm={activateNftConfirm} onClose={activateNftClose} activateNFT={nftDetailList[activateNFTIndex]} /> : ""}
            {removeNFTIndex !== null ? <RemoveNft TET2USD={props.TET2USD} onConfirm={removeNftConfirm} onClose={removeNftClose} removeNft={nftDetailList[removeNFTIndex]} /> : ""}
        </>
    )
}

export default MyNft
import React, { useState } from 'react'
import { Button, Spin } from 'antd'
import { t } from 'i18next'

import { postActivateNft } from '../../api'

import { viewCode } from '../../tools/viewCode'
import { openNotification } from '../../tools/notification'

import PublicPopup from '../PublicComponents/PublicPopup'
import "../../scss/Market/ListNftConfirm.scss"

function ActivateNft({ onClose, onConfirm, activateNFT }) {

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")

    const close = () => {
        if (message == "success") {
            onConfirm()
        } else {
            onClose()
        }
    }

    const activateNft = () => {
        setLoading(true)
        window._czc && window._czc.push(["_trackEvent", "NFT激活页", "点击", "激活NFT"]);
        postActivateNft({ "userNFTIds": [activateNFT.UserNftID || activateNFT.ID] }).then((res) => {
            if (res.data.code) {
                setMessage("filed")
                if (res.data.code === 3013) {
                    openNotification(t("market.activateNftFailedText"))
                } else {
                    openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                }
            } else {
                setMessage("success")
            }
        }).catch(() => {
            openNotification(t("responseCode.networkFailed"))
        }).finally(() => {
            setLoading(false)
        })
    }


    return (
        <div className='list_nft_confirm'>
            <PublicPopup isOverlay title={t("market.activateNftTitle")} closefun={close}>
                <div className="nft_content_activate">
                    {t("market.activateNftContent1")}
                    <br />
                    {t("market.activateNftContent2")}
                </div>
                <div className='nft_message'>
                    {loading ? <Spin />
                        : message == "success" ?
                            <>
                                <div className="success_ic"></div>
                                <div className='message_title message_success'>{t("market.activateNftSuccess")}</div>
                            </>
                            : message == "filed" ?
                                <>
                                    <div className="filed_ic"></div>
                                    <div className='message_title message_filed'>{t("market.activateNftFailed")}</div>
                                </>
                                : ""
                    }
                </div>
                <div className="nft_button">
                    {!loading ? message ?

                        <Button onClick={close} className='button_common primary_button'>{t("market.confirm")}</Button> :
                        <>
                            <Button onClick={activateNft} className='button_common primary_button'>{t("market.confirm")}</Button>
                            <Button onClick={close} className='button_common secondary_button'>{t("market.cancel")}</Button>
                        </>
                        : ""
                    }
                </div>

            </PublicPopup>
        </div>
    )
}

export default ActivateNft
import store from '../redux/store'
import http from '../tools/net'

const baseUrl = '/api'
const network = process.env.REACT_APP_DEPLOY_NET
const baseUrlExplorer = network === "T" ? "/testExplorerApi" : "/explorerApi"
const baseUrlTokenPrice = network === "T" ? "/testTokenPrice" : "/testTokenPrice"

// User
const postSendVerificationCode = (data) => http.post(baseUrl + "/user/email/verification", data)
const postRegister = (data) => http.post(baseUrl + "/user/account/register", data)
const postLogin = (data) => http.post(baseUrl + "/user/account/login", data)
const postSetVsysAddress = (data) => http.post(baseUrl + "/user/account/set/address/vsys", data)
const getInfo = () => http.get(baseUrl + "/user/info")
const setAvatar = (data) => http.post(baseUrl + "/user/account/set/avatar", data)
const postUserInfoNicknames = (data) => http.post(baseUrl + "/user/info/nicknames", data)
const postUserInfoByIds = (data) => http.post(baseUrl + "/user/info/by/ids", data)

// Price
// const getVSYS2USD = () => http.get(baseUrlExplorer + '/vsys/price')
// const getSwapTokenTRate = (data) => http.post(baseUrlTokenPrice + '/vswap/price/realtime', data)
const getVSYS2USD = () => http.get(baseUrl + '/price/vsys')
const getSwapTokenTRate = () => http.get(baseUrl + '/price/token')

// User Invitations
const postUserAccountInvitation = (data) => http.post(baseUrl + "/user/account/invitation", data)
const postInvitationRewardLatest = () => http.post(baseUrl + "/invitation/reward/latest")
const postInvitationRewardCheck = () => http.post(baseUrl + "/invitation/reward/check")
const postInvitationRewardClaim = () => http.post(baseUrl + "/invitation/reward/claim")

// Password
const postResetPassword = (data) => http.post(baseUrl + "/user/account/reset/password", data)
const postSecondPassword = (data) => http.post(baseUrl + '/user/account/set/second-password', data)
const postResetSecondPassword = (data) => http.post(baseUrl + "/user/account/reset/second-password", data)

// Token
const depositToken = (data) => http.post(baseUrl + '/token/deposit', data)
const withdrawToken = (data) => http.post(baseUrl + '/token/withdraw', data)
const tokenPrice = () => http.get(baseUrl + "/token/price")

// Ticket
const freeTicket = () => http.post(baseUrl + "/ticket/free")
const buyTicket = (data) => http.post(baseUrl + '/ticket/buy', data)
const postTicketList = (data) => http.post(baseUrl + "/ticket/get/list/id", data)
const postTicketListDetails = (data) => http.post(baseUrl + "/ticket/get/list/details", data)
const postTicketNumber = (data) => http.post(baseUrl + "/ticket/get/number", data)
const postRefundTicket = (data) => http.post(baseUrl + "/ticket/refund", data)

// user Transaction
const transactionStatus = (data) => http.post(baseUrl + "/transaction/status", data)
const transactionDetails = (data) => http.post(baseUrl + '/transaction/details', data)
const transactionListDetails = (data) => http.post(baseUrl + "/transaction/list/details", data)

// Nft
const mintNft = (data) => http.post(baseUrl + "/nft/mint", data)
const freeNft = () => http.get(baseUrl + "/nft/free")
const depositNft = (data) => http.post(baseUrl + "/nft/deposit", data)
const withdrawNft = (data) => http.post(baseUrl + "/nft/withdraw", data)
const postNftImage = (data) => http.post(baseUrl + "/nft/user/image", data, 'arraybuffer')
const postNftDetail = (data) => http.post(baseUrl + "/nft/user/details", data)
const postNftDetailList = (data) => http.post(baseUrl + "/nft/user/list/details", data)
const postNftDetailByNftTokenId = (data) => http.post(baseUrl + "/nft/tetris/by/user/nft", data)
const getUserOnlineNtfDetail = (data) => http.get(baseUrlExplorer + "/nft/address/contract/nfts", { params: data })
const postNftTetrisAttributes = (data) => http.post(baseUrl + "/nft/tetris/attributes", data)
const postNftTetrisAttributesByUserNftId = (data) => http.post(baseUrl + "/nft/tetris/attributes/by/user/nft", data)
const postNftDetailListQuality = (data) => http.post(baseUrl + "/nft/user/attributes/seq", data)

// Guest
const postGuestNftMint = (data) => http.post(baseUrl + "/guest/nft/mint", data)
const postGuestNftMintStatus = (data) => http.post(baseUrl + "/guest/nft/mint/status", data)
const postNftDiscountInfo = (data) => http.post(baseUrl + "/nft/discount/info", data)

// Items
const postItemValid = (data) => http.post(baseUrl + "/item/valid", data)

// Market
const postNftMarketplace = (data) => http.post(baseUrl + "/nft/marketplace", data)
const postNftMarketplaceAction = (data) => http.post(baseUrl + "/nft/marketplace/actions", data)
const postActivateNft = (data) => http.post(baseUrl + "/nft/activate", data)
const postListNft = (data) => http.post(baseUrl + "/nft/marketplace/user/list", data)
const postRemoveNft = (data) => http.post(baseUrl + "/nft/marketplace/user/remove", data)
const postBuyNft = (data) => http.post(baseUrl + "/nft/marketplace/user/buy", data)
const postMarketplaceUsernft = (data) => http.post(baseUrl + "/nft/marketplace/id/usernft", data)
const postMarketplaceInfo = (data) => http.post(baseUrl + "/nft/marketplace/info", data)

// Wheel
const postWheelSpin = (data) => http.post(baseUrl + "/wheel/spin", data)
const postWheelRecords = (data) => http.post(baseUrl + "/wheel/records", data)
const postWheelUserRecords = (data) => http.post(baseUrl + "/wheel/user/records", data)

export {
    // User
    postSendVerificationCode,
    postRegister,
    postLogin,
    postSetVsysAddress,
    getInfo,
    setAvatar,
    postUserInfoNicknames,
    postUserInfoByIds,

    // Price
    getVSYS2USD,
    getSwapTokenTRate,

    // User Invitations
    postUserAccountInvitation,
    postInvitationRewardLatest,
    postInvitationRewardCheck,
    postInvitationRewardClaim,

    // Password
    postResetPassword,
    postSecondPassword,
    postResetSecondPassword,

    // Token
    depositToken,
    withdrawToken,
    tokenPrice,

    // Ticker
    freeTicket,
    buyTicket,
    postTicketList,
    postTicketListDetails,
    postTicketNumber,
    postRefundTicket,

    // Transaction
    transactionStatus,
    transactionDetails,
    transactionListDetails,

    // Nft
    mintNft,
    freeNft,
    depositNft,
    withdrawNft,
    postNftImage,
    postNftDetail,
    postNftDetailList,
    postNftDetailByNftTokenId,
    getUserOnlineNtfDetail,
    postNftTetrisAttributes,
    postNftTetrisAttributesByUserNftId,
    postNftDetailListQuality,
    postNftDiscountInfo,

    // Guest
    postGuestNftMint,
    postGuestNftMintStatus,

    // Items
    postItemValid,

    // Market
    postNftMarketplace,
    postNftMarketplaceAction,
    postActivateNft,
    postListNft,
    postRemoveNft,
    postBuyNft,
    postMarketplaceUsernft,
    postMarketplaceInfo,

    // Wheel
    postWheelSpin,
    postWheelRecords,
    postWheelUserRecords,
} 

import styles from './Index.module.scss'

const blockMovement = () => {
    return <div className={styles.backMovement}>
        <div className={styles.loader}>
            {
                new Array(10).fill("span").map((item, index) => {
                    return <span key={index}>
                    </span>
                })
            }
        </div>
    </div>
}

export default blockMovement;

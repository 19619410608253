import React, { useEffect, useState } from 'react'
import { Dropdown } from 'antd'
import dayjs from 'dayjs';
import { t } from 'i18next'

import { postItemValid, postUserAccountInvitation, postUserInfoByIds } from '../../../api';
import { copy } from '../../../tools/notification'

import PublicPopup from '../../PublicComponents/PublicPopup'
import CommonPagination from '../../PublicComponents/Pagination'
import CommonTable from '../../PublicComponents/Table'

import styles from "../../../scss/Hall/PersonalCenter/InviteCashback.module.scss"


function InviteCashback({ onClose, uuid }) {

    const columns = [
        {
            title: t("tableData.UID"),
            dataIndex: 'nickname',
            key: 'nickname',
            align: 'center',
            width: '30%',
            ellipsis: true,
            render: (item) => {
                return item || "..."
            }
        },
        {
            title: "UUID",
            dataIndex: 'uuid',
            key: 'uuid',
            align: 'center',
            width: '30%',
            ellipsis: true,
            render: (item) => {
                return item || "..."
            }
        },
        {
            title: t("tableData.time"),
            dataIndex: 'UpdatedAt',
            key: 'UpdatedAt',
            align: 'center',
            width: '40%',
            ellipsis: true,
            render: (time) => {
                if (time.split("T").length) {
                    return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
                } else {
                    return time
                }
            }
        },
    ];

    const [inviteLink, setInviteLink] = useState("")

    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)

    const [itemValidTotal, setItemValidTotal] = useState(0)

    useEffect(() => {
        let link = window.location.origin + `/webRegister?inviteId=${uuid}`
        setInviteLink(link)
    }, [uuid])

    // Item
    useEffect(() => {
        getItemValid()
    }, [])
    const getItemValid = () => {
        postItemValid({
            "userItemType": "WithdrawFeeDiscount",
            "pagination": {
                "page": 1,
                "size": 1
            }
        }).then((res) => {
            if (!res.data.code) {
                setItemValidTotal(res.data.data?.pagination?.total)
            } else setItemValidTotal(0)
        }).catch(() => {
            setItemValidTotal(0)
        })
    }

    // Invitation
    useEffect(() => {
        getInvitation()
    }, [page])
    const getInvitation = () => {
        setLoading(true)
        postUserAccountInvitation({
            "pagination": {
                "page": page,
                "size": 5
            }
        }).then((res) => {
            setLoading(false)
            if (!res.data.code) {
                const data = res.data.data?.invitations.map(item => ({ ...item, key: item.ID }))
                setDataSource([...data])
                setTotal(res.data.data?.pagination?.total)
            } else {
                setDataSource([])
                setTotal(0)
            }
        }).catch((err) => {
            setLoading(false)
            setDataSource([])
            setTotal(0)
        })
    }

    // UserInfo
    useEffect(() => {
        if (dataSource.length > 0 && !dataSource[0].nickname && dataSource[0].InviteeUserID) {
            getUserInfo()
        }
    }, [dataSource])
    const getUserInfo = () => {
        let ids = []
        dataSource.map((item) => {
            ids.push(item.InviteeUserID)
        })
        postUserInfoByIds({ "userIds": ids }).then((res) => {
            if (!res.data.code) {
                let userInfo = res.data.data.userNicknames
                let newData = []
                dataSource.map((item) => {
                    let infoItem = userInfo.find((it) => it.id === item.InviteeUserID)
                    newData.push({
                        key: item.ID,
                        UpdatedAt: item.UpdatedAt,
                        nickname: infoItem?.nickname || "_",
                        uuid: infoItem?.uuid || "_"
                    })
                })
                setDataSource([...newData])
            }
        })
    }


    return (
        <PublicPopup isOverlay title={t("personalCenter.inviteCashback")} backfun={onClose}>
            <div className={styles.invite_cashback}>
                <div className={styles.invite_top}>
                    <div className={styles.invite_title}>
                        <div className={styles.main_title}>{t("personalCenter.inviteTitle")}</div>
                        <Dropdown
                            dropdownRender={(menu) => (
                                <div style={{ background: "#40077e", whiteSpace: "pre-line", borderRadius: "1vw", width: "18vw", height: "auto", color: "#fff", fontFamily: "Noto Sans", padding: "1.5vw", boxSizing: "border-box", fontSize: "0.78vw" }}>
                                    {t("personalCenter.inviteRules")}
                                </div>
                            )}
                            trigger={['click', 'hover']}
                        >
                            <div className={styles.subtitle}>{t("personalCenter.inviteSubtitle")} <div className="price_info_ic"></div></div>
                        </Dropdown>
                    </div>
                    <div className={styles.invite_content}>
                        <div className={styles.invite_item}>
                            <div className={styles.item_content}>
                                <div className={styles.item_item}>{uuid}</div>
                                <div className='copy_ic' onClick={() => { copy(uuid) }}></div>
                            </div>
                            <div className={styles.item_title}>{t("personalCenter.invitationCode")}</div>
                        </div>
                        <div className={styles.invite_item}>
                            <div className={styles.item_content}>
                                <div className={styles.item_item}>{inviteLink}</div>
                                <div className='copy_ic' onClick={() => { copy(inviteLink) }}></div>
                            </div>
                            <div className={styles.item_title}>{t("personalCenter.invitationLink")}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.invite_center}>
                    <div className={styles.step}>
                        <div className={styles.step1_icon}></div>
                        <div className={styles.step_content}>
                            <div className={styles.content_title}>{t("personalCenter.inviteStep1")}</div>
                            <div className={styles.content_subtitle}>{t("personalCenter.inviteStep1Content")}</div>
                        </div>
                    </div>
                    <div className={styles.step}>
                        <div className={styles.step2_icon}></div>
                        <div className={styles.step_content}>
                            <div className={styles.content_title}>{t("personalCenter.inviteStep2")}</div>
                            <div className={styles.content_subtitle}>{t("personalCenter.inviteStep2Content")}</div>
                        </div>
                    </div>
                    <div className={styles.step}>
                        <div className={styles.step3_icon}></div>
                        <div className={styles.step_content}>
                            <div className={styles.content_title}>{t("personalCenter.inviteStep3")}</div>
                            <div className={styles.content_subtitle}>{t("personalCenter.inviteStep3Content")}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.invite_bottom}>
                    <div className={styles.invite_overview}>
                        <div className={styles.overview_title}>{t("personalCenter.overview")}</div>
                        <div className={styles.overview_content}>
                            <div className={styles.overview_item}>
                                <div className={styles.item_content}>{total}</div>
                                <div className={styles.item_title}>{t("personalCenter.invitedTotal")}</div>
                            </div>
                            <div className={styles.overview_item}>
                                <div className={styles.item_content}>{itemValidTotal}</div>
                                <div className={styles.item_title}>{t("personalCenter.availableCash")}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.invitees}>
                        <div className={styles.invitees_pagination}>
                            <CommonPagination onChange={(e) => { setPage(e) }} pageSize={5} current={page} total={total} />
                        </div>
                        <CommonTable
                            dataSource={dataSource}
                            columns={columns}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </PublicPopup>
    )
}

export default InviteCashback
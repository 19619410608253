import React, { useState } from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/swiper-react.mjs';
import { Button, Image } from 'antd';

import leftArrow from "../../assets/public_icon/left_icon.svg";
import rightArrow from "../../assets/public_icon/right_icon.svg";
import { IMAGE_BASEURL } from '../../tools/gameConfig'

import 'swiper/swiper.scss';
import '../../scss/Hall/Slider.scss';

export default (props) => {
    const [mainCurrentIdx, setMainCurrentIdx] = useState(0)
    const [innerSliderContent, setInnerSliderContent] = useState([0])

    const langType = localStorage.getItem("language") || "en"

    const SwiperButtonPrev = ({ children }) => {
        const swiper = useSwiper();
        return <Button onClick={() => swiper.slidePrev()} className='arrow_button'>
            <Image preview={false} src={leftArrow} />
        </Button>
    };
    const SwiperButtonNext = ({ children }) => {
        const swiper = useSwiper();
        return <Button onClick={() => swiper.slideNext()} className='arrow_button arrow_right'>
            <Image preview={false} src={rightArrow} />
        </Button>
    };

    return (
        <div className='container'>
            <Swiper
                className='swiper_container'
                slidesPerView='auto'
                centeredSlides={true}
                loop={true}
                initialSlide={1}
            // onSlideChange={(e)=>sliderNext()}
            // onSwiper={(e)=>console.log(e)}
            // onSlideNextTransitionStart={()=>sliderNext()}
            >
                <SwiperButtonPrev slot="container-start"></SwiperButtonPrev>
                <SwiperButtonNext slot="container-start"></SwiperButtonNext>
                <SwiperSlide className='empty_card'>
                    <Image
                        preview={false}
                        src={`${IMAGE_BASEURL}tetraverse_banner_${langType}_1.png?imageView2/1/w/900/h/523`}
                        className='empty_poster'
                    />
                </SwiperSlide>
                <SwiperSlide className='empty_card'>
                    <Image
                        preview={false}
                        src={`${IMAGE_BASEURL}tetraverse_banner_${langType}_2.png?imageView2/1/w/900/h/523`}
                        className='empty_poster'
                    />
                </SwiperSlide>
                <SwiperSlide className='empty_card'>
                    <Image
                        preview={false}
                        src={`${IMAGE_BASEURL}tetraverse_banner_${langType}_3.png?imageView2/1/w/900/h/523`}
                        className='empty_poster'
                    />
                </SwiperSlide>
                <SwiperSlide className='card'>
                    <Swiper
                        className="card_inner"
                        onSlideChange={(e) => setMainCurrentIdx(e.activeIndex)}
                    >
                        {
                            innerSliderContent.map((item, idx) => <SwiperSlide className="slider_inner" key={idx}>
                                <Image
                                    preview={false}
                                    width={"93%"}
                                    src={`${IMAGE_BASEURL}tetraverse_banner_${langType}_1.png?imageView2/1/w/900/h/523`}
                                />
                            </SwiperSlide>)

                        }
                        <div slot="container-start" className="pagination">
                            {
                                innerSliderContent.map((item, idx) => {
                                    return (
                                        <div className="pointer" key={idx} style={{ backgroundColor: mainCurrentIdx === idx ? '#FFDA48' : '#CACACA' }}></div>
                                    )
                                })

                            }
                        </div>
                    </Swiper>
                </SwiperSlide>
                <SwiperSlide className='empty_card'>
                    <Image
                        preview={false}
                        src={`${IMAGE_BASEURL}tetraverse_banner_${langType}_2.png?imageView2/1/w/900/h/523`}
                        className='empty_poster'
                    />
                </SwiperSlide>
                <SwiperSlide className='empty_card'>
                    <Image
                        preview={false}
                        src={`${IMAGE_BASEURL}tetraverse_banner_${langType}_3.png?imageView2/1/w/900/h/523`}
                        className='empty_poster'
                    />
                </SwiperSlide>
            </Swiper>
        </div>
    );
}

import store from '../../store'
export const Login = (tokens) => {
    store.dispatch({
        type: "LOGIN",
        token: tokens,
    });
};

export const AddTransaction = (transaction) => {
    return async (dispatch) => {
        dispatch({
            type: "ADD_NFT_TRANSACTION",
            transaction: transaction
        })
    }
}

export const MoveTransaction = (key) => {
    return async (dispatch) => {
        dispatch({
            type: "MOVE_NFT_TRANSACTION",
            key: key
        })
    }
}

export const AddVSYSTransaction = (transaction) => {
    return async (dispatch) => {
        dispatch({
            type: "ADD_VSYS_NFT_TRANSACTION",
            transaction: transaction
        })
    }
}

export const MoveVSYSTransaction = (key) => {
    return async (dispatch) => {
        dispatch({
            type: "MOVE_VSYS_NFT_TRANSACTION",
            key: key
        })
    }
}


import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { Input, Button, Image } from 'antd';

import { postLogin } from '../../api';
import { Login } from '../../redux/actions/user/userActions';
import { openNotification } from '../../tools/notification'
import { viewCode } from '../../tools/viewCode';

import BlockMovement from '../../Components/SpecialEffects/BlockMovement/Index';
import "./Index.scss"
import OpenEye from "../../assets/web/open_eye.png"
import OffEye from "../../assets/web/off_eye.png"

const WebLogin = ({ token }) => {

    const navigate = useNavigate()
    const { t } = useTranslation()

    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [logining, setLogining] = useState(false)
    const location = useLocation()

    useEffect(() => {
        return () => {
            setEmail('')
            setPassword('')
            setLogining(false)
        }
    }, [])

    useEffect(() => {
        if (token) {
            if (location.state === "/invite") {
                navigate(-1)
            } else if (location.state !== null) {
                navigate(-2)
            } else {
                navigate("/", { replace: true })
            }
        }
    }, [token])

    const login = () => {
        if (logining) return
        let emailG = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{1,7}$/;
        let passwordG = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
        if (!password || !email) return openNotification(t("user.passwordEmailNull"))
        if (!emailG.test(email)) return openNotification(t("user.emailError"))
        if (!passwordG.test(password)) return openNotification(t("user.passwordError"))
        setLogining(true)
        postLogin({ email: email, password: password }).then((res) => {
            if (!res.data.code) {
                openNotification(t("user.loginSuccess"))
                window._czc && window._czc.push(["_trackEvent", "登录", "点击"]);
                Login(res.data.data.jwtToken)
                setLogining(false)
            } else if (res.data.code === 1001) {
                openNotification(t("user.loginFailed1001"))
                setLogining(false)
            } else {
                setLogining(false)
                openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
            }
        }).catch((err) => {
            setLogining(false)
            openNotification(t("responseCode.networkFailed"))
        })
    }

    return (
        <div className="web">
            <BlockMovement />
            <div className="logo"></div>
            <div className="form">
                <div className="web_title" >{t("user.loginAccount")}</div>
                <Input className="form_input" suffix={" "} onChange={(e) => { setEmail(e.target.value.trim()) }} value={email} placeholder={t("user.interEmail")} />
                <Input.Password
                    className="form_input"
                    placeholder={t("user.interPassword")}
                    value={password}
                    onChange={(e) => { setPassword(e.target.value.trim()) }}
                    iconRender={(visible) => (visible ? <Image preview={false} src={OpenEye} /> : <Image preview={false} src={OffEye} />)}
                />
                <div className="forgot_suffix_father">
                    <Button loading={logining} onClick={login} className="button_common primary_button">{t("user.login")}</Button>
                    {/* <Link to="/webRecoverPassword" className="form_link forgot_suffix" style={{ whiteSpace: 'nowrap' }} >{t("user.forgotPassword")}</Link> */}
                </div>
                <div className="form_space_between">
                    <Link to="/webRecoverPassword" className="form_link" onClick={() => { window._czc && window._czc.push(["_trackEvent", "去忘记密码页", "点击"]) }} >{t("user.forgotPassword")}</Link>
                    {/* <span className="form_link">{t("user.thirdPartyAccount")}</span> */}
                    <Link className="form_link" to="/webRegister" onClick={() => { window._czc && window._czc.push(["_trackEvent", "去注册", "点击"]) }} >{t("user.register")}</Link>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    token: state.user.token
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WebLogin);
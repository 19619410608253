import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Modal, Spin } from 'antd';
import { t } from 'i18next';
import dayjs from 'dayjs';

import { postRefundTicket, postTicketListDetails, postTicketNumber } from '../../../api';
import { openNotification } from '../../../tools/notification';

import CommonTable from '../../PublicComponents/Table';
import CommonPagination from '../../PublicComponents/Pagination';
import styles from '../../../scss/Hall/PersonalCenter/MyTicker.module.scss'

function MyTicker(props) {

    const [ticketType, setTicketType] = useState("all")
    const [tickersNumber, setTicketsNumber] = useState(null)
    const [freeTicketsNumber, setFreeTicketsNumber] = useState(null)
    const [paidTicketsNumber, setPaidTicketsNumber] = useState(null)
    const [earnedTicketsNumber, setEarnedTicketsNumber] = useState(null)
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState({})
    const [modalTitle, setModelTitle] = useState("")


    const columns = [
        {
            title: t("tableData.type"),
            dataIndex: 'TicketType',
            key: 'TicketType',
            align: 'center',
            width: '14%',
            ellipsis: true,
            render: (type) => {
                if (type === "free") {
                    return t("tableData.freeTicket")
                } else if (type === "paid") {
                    return t("tableData.paidTicket")
                } else if (type === "earned") {
                    return t("tableData.earnedTicket")
                } else {
                    return type
                }
            }
        },
        {
            title: t("tableData.expiredAt"),
            dataIndex: 'ExpiredAt',
            key: 'ExpiredAt',
            align: 'center',
            width: '20%',
            ellipsis: true,
            render: (time) => {
                return !time.startsWith(2) ? t("tableData.permanent") : dayjs(time).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        {
            title: t("tableData.price"),
            dataIndex: 'Price',
            key: 'Price',
            align: 'center',
            width: '12%',
            render: (type) => {
                return type ? type + " USD" : "-"
            }
        },
        {
            title: t("tableData.recipientType"),
            dataIndex: 'TicketType',
            key: 'TicketType',
            align: 'center',
            width: '12%',
            ellipsis: true,
            render: (type) => {
                if (type === "free") {
                    return t("tableData.free")
                } else if (type === "paid") {
                    return t("tableData.paid")
                } else if (type === "earned") {
                    return t("tableData.earned")
                } else {
                    return type
                }
            }
        },
        {
            title: t("tableData.status"),
            dataIndex: 'TicketStatus',
            key: 'TicketStatus',
            align: 'center',
            width: '12%',
            ellipsis: true,
            render: (type) => {
                if (type === "valid") {
                    return t("tableData.valid")
                } else if (type === "expired") {
                    return t("tableData.expired")
                } else if (type === "lockedInRoom") {
                    return t("tableData.lockedInRoom")
                } else if (type == "used") {
                    return t("tableData.used")
                } else if (type === "refunded") {
                    return t("tableData.refunded")
                } else {
                    return type
                }
            }
        },
        {
            title:
                <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", gap: "1%" }}>
                    <Dropdown
                        dropdownRender={(menu) => (
                            <div style={{ background: "rgba(25, 22, 41, 0.95)", boxShadow: "3px 3px 3px 0px rgba(73, 75, 134, 0.30)", borderRadius: "1vw", width: "auto", height: "auto", color: "#fff", fontFamily: "Noto Sans", padding: "1.5vw", boxSizing: "border-box", fontSize: "0.78vw" }}>
                                {t("tableData.exchangeFee")}
                            </div>
                        )}
                        trigger={['click', 'hover']}
                    >
                        <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", gap: "1%" }}>{t("tableData.action")} <div className="price_info_ic"></div></div>
                    </Dropdown>
                </div>,
            dataIndex: 'TicketType',
            key: 'TicketType',
            align: 'center',
            width: '10%',
            ellipsis: true,
            render: (type, ticketObj) => {
                if (ticketObj.TicketStatus === "valid" && type !== "free") {
                    return <Button className='tags_common activate_tag' onClick={() => { refundTicket(ticketObj) }}>{t("tableData.refund")}</Button>
                } else {
                    return <Button className='tags_common disabled_tag' disabled>{t("tableData.unrefund")}</Button>
                }
            }
        },
    ];

    useEffect(() => {
        if (page && ticketType && !loading) {
            getDetailList()
        }
    }, [page, ticketType])

    // get Data
    const getDetailList = () => {
        setLoading(true)
        setDataSource([])
        setTotal(0)
        // getTicketNumber()
        postTicketListDetails({
            "ticketTypeFilter": ticketType,
            "pagination": {
                "page": page,
                "size": 12
            }
        }).then((res) => {
            if (!res.data.code) {
                filterDataList(res.data.data.tickets)
                if (res.data.data.ticketTypeFilter === "all") {
                    setTicketsNumber(res.data.data.pagination.total)
                } else if (res.data.data.ticketTypeFilter === "free") {
                    setFreeTicketsNumber(res.data.data.pagination.total)
                } else if (res.data.data.ticketTypeFilter === "paid") {
                    setPaidTicketsNumber(res.data.data.pagination.total)
                } else if (res.data.data.ticketTypeFilter === "earned") {
                    setEarnedTicketsNumber(res.data.data.pagination.total)
                }
                setTotal(res.data.data.pagination.total)
            } else {
                setLoading(false)
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    const filterDataList = (list) => {
        let newlist = []
        if (!list || list.length <= 0) {
            setDataSource(newlist)
            setLoading(false)
            return
        }
        list.map((item, index) => {
            let newItem = {}
            newItem.key = item.ID
            newItem.TicketStatus = item.TicketStatus
            newItem.TicketType = item.TicketType
            newItem.ExpiredAt = item.ExpiredAt
            newItem.Price = item.Price
            newlist.push(newItem)
        })
        setDataSource(newlist)
        setLoading(false)
    }

    const getTicketNumber = () => {
        postTicketNumber({ "ticketTypeFilter": "all" }).then((res) => {
            setTicketsNumber(res.data.data.ticketsNumber || null)
            setFreeTicketsNumber(res.data.data.freeTicketsNumber || null)
            setPaidTicketsNumber(res.data.data.paidTicketsNumber || null)
            setEarnedTicketsNumber(res.data.data.earnedTicketsNumber || null)
        }).catch(() => { })
    }

    const refundTicket = (selected) => {
        setModelTitle(t("personalCenter.confirmRefundTitle"))
        setSelectedTicket(selected)
        setIsModalOpen(true)
    }
    const handleOk = () => {
        if (!selectedTicket.TicketType) return
        let ticketId = selectedTicket.key
        setSelectedTicket({})
        setConfirmLoading(true);
        postRefundTicket({
            "ticketId": ticketId
        }).then((res) => {
            setConfirmLoading(false);
            setIsModalOpen(false)
            if (!res.data.code) {
                Modal.success({
                    content: t("personalCenter.refundSuccess"),
                });
                getDetailList()
                props.updateUserInfo()
            } else {
                openNotification(t("personalCenter.refundFailed"))
            }
        }).catch(() => {
            setConfirmLoading(false);
            setIsModalOpen(false)
            openNotification(t("responseCode.networkFailed"))
        })
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }


    const pageChange = (e) => {
        setPage(e)
    }

    const changeTicketType = (type) => {
        setTicketType(type)
        setPage(1)
    }

    return (
        <div className={styles.my_ticker_wrapper}>
            {loading ? <Spin /> : <>
                <div className={styles.top}>
                    <div className={styles.ticker_type}>
                        <span onClick={() => { changeTicketType("all") }}>{t("tableData.all")}{tickersNumber === null ? "" : tickersNumber} <div className={ticketType === "all" ? "selected_button" : "unselected_button"}></div></span>
                        <span onClick={() => { changeTicketType("free") }}>{t("tableData.freeTicket")}{freeTicketsNumber === null ? "" : freeTicketsNumber}<div className={ticketType === "free" ? "selected_button" : "unselected_button"}></div></span>
                        <span onClick={() => { changeTicketType("paid") }}>{t("tableData.paidTicket")}{paidTicketsNumber === null ? "" : paidTicketsNumber} <div className={ticketType === "paid" ? "selected_button" : "unselected_button"}></div></span>
                        <span onClick={() => { changeTicketType("earned") }}>{t("tableData.earnedTicket")}{earnedTicketsNumber === null ? "" : earnedTicketsNumber} <div className={ticketType === "earned" ? "selected_button" : "unselected_button"}></div></span>
                    </div>
                    <div className={styles.pagination_div}>
                        <CommonPagination onChange={pageChange} pageSize={12} current={page} total={total} />
                    </div>
                </div>
                <div className={styles.table_div}>
                    <CommonTable
                        dataSource={dataSource}
                        columns={columns}
                    />
                </div></>
            }
            <Modal title={modalTitle} confirmLoading={confirmLoading}
                okText={t("mint.confirm")} cancelText={t("gameChooseModel.close")}
                open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                {t("tableData.exchangeFee")}
            </Modal>
        </div>
    )
}

export default MyTicker
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Image, InputNumber, Spin } from 'antd'
import { t } from 'i18next'
import dayjs from 'dayjs';

import { ALLFEE, CREATORFEE, IMAGE_BASEURL, TOKENNAME, TRANSACTIONFEE } from '../../tools/gameConfig'

import PublicPopup from '../PublicComponents/PublicPopup'
import "../../scss/Market/ListInBulkNft.scss"

function ListInBulkNft({ nftDetailList, onClose, onConfirm }) {

    const sortTypeItems = [
        { key: "vsys", label: TOKENNAME }
    ]

    const expirationItems = [
        { key: 1, label: t("market.hour1") },
        { key: 3, label: t("market.hour3") },
        { key: 6, label: t("market.hour6") },
        { key: 7 * 24, label: t("market.hour168") },
        { key: 14 * 24, label: t("market.hour336") },
        { key: 30 * 24, label: t("market.hour720") },
    ]
    const [expirationType, setExpiractionType] = useState(expirationItems[0].key)
    const [price, setPrice] = useState([])
    const [priceAll, setPriceAll] = useState(0)
    const [error, setError] = useState(false)
    const [nftList, setNftList] = useState([])
    const [loading, setLoading] = useState(false)

    const getPriceAll = (price) => {
        let all = 0
        price.map((item) => {
            all = all + item / 1
        })
        setPriceAll(all)
    }

    const confirm = () => {
        window._czc && window._czc.push(["_trackEvent", "多个NFT上架页", "点击", "NFT上架确定页"]);
        let confirmListNFTs = nftDetailList
        for (let index = 0; index < confirmListNFTs.length; index++) {
            const item = confirmListNFTs[index];
            if (price[index] > 0) {
                item.price = ((price[index] / 1).toFixed(2)) / 1
                item.expirationTime = expirationType
            } else {
                return setError(true)
            }
        }
        onConfirm(confirmListNFTs)
    }

    const expirationChange = ({ key }) => {
        setExpiractionType(key)
    }

    const changePrice = (index, key) => {
        let newPrice = price
        newPrice[index] = key
        setPrice(newPrice)
        getPriceAll(newPrice)
    }

    useEffect(() => {
        if (nftDetailList?.length > 0) {
            setPrice(new Array(nftDetailList.length).fill(null))
            setNftList(nftDetailList)
        }
    }, [nftDetailList])

    const clear = (index) => {
        setLoading(true)
        let newNftList = nftList
        let newPrice = price
        newNftList.splice(index, 1)
        newPrice.splice(index, 1)
        setPrice(newPrice)
        getPriceAll(newPrice)
        setNftList(newNftList)
        setTimeout(() => {
            setLoading(false)
        }, 0);
    }



    return (
        <div className='list_in_bulk_nft'>
            <PublicPopup isOverlay title={t("market.list")} closefun={onClose}>
                <div className="nft_content">
                    <div className="nft_content_left">
                        {loading ? <Spin /> :
                            <div className="nft_list">
                                {nftList.map((nftDetail, index) => {
                                    return (
                                        <div className='nft_detail' key={nftDetail.ID || index}>
                                            <div className="nft_right">
                                                <Image
                                                    className={"nft_img " + nftDetail?.NftType}
                                                    preview={false}
                                                    src={nftDetail?.NftAttributes?.content ? IMAGE_BASEURL + nftDetail?.NftAttributes.content + ".png?imageView2/1/w/100/h/100" : ""}
                                                    placeholder={
                                                        <Image
                                                            preview={false}
                                                            className={"nft_img " + nftDetail?.NftType}
                                                            src={nftDetail?.NftAttributes?.content ? IMAGE_BASEURL + nftDetail?.NftAttributes.content + ".png?imageView2/1/w/50/h/50" : ""}
                                                        />
                                                    }
                                                />
                                                <div className='nft_series'>
                                                    <div className="series">
                                                        <div className='series_ic'></div>
                                                        <div>{t("market.tetrisSeries")}</div>
                                                    </div>
                                                    <div>{t("market.tetrisSeries") + " #" + nftDetail?.NftTokenIndex}</div>
                                                </div>
                                            </div>
                                            <div className="nft_input">
                                                <div className="input_title">{t("market.price")}</div>
                                                <div className='input_content'>
                                                    <InputNumber onChange={(e) => { changePrice(index, e) }} min={0} className='button' />
                                                    <Dropdown
                                                        className='button dropdown'
                                                        menu={{
                                                            items: sortTypeItems,
                                                        }}
                                                    >
                                                        <a onClick={(e) => e.preventDefault()}>
                                                            {TOKENNAME}
                                                            <div className='dropdown_ic'></div>
                                                        </a>

                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="nft_item">
                                                <div className="nft_item_title">
                                                    NFT{t("market.expiration")}：
                                                    <br />
                                                    {nftDetail?.NftValidStatus === "InActivated" ?
                                                        t("personalCenter.InActivated")
                                                        : nftDetail?.ExpiredAt?.split("T")[0] ? dayjs(nftDetail?.ExpiredAt).format('YYYY-MM-DD') : ""
                                                    }
                                                </div>
                                            </div>
                                            <div className="clear_ic" onClick={() => { clear(index) }}></div>
                                        </div>)
                                })}
                            </div>
                        }
                    </div>
                    <div className='nft_content_right'>
                        <div className='nft_message'>
                            {error ?
                                <>
                                    <div className="filed_ic"></div>
                                    <div className='message_title message_filed'>{t("market.pleaseEnterPrice")}</div>
                                </>
                                : ""}
                        </div>
                        <div className="buy_fee">
                            <div className="tit">{t("market.handlingFee")}</div>
                            <div className="tit_content">{t("market.handlingFeeContent")}</div>
                            <div className='fee'>
                                <div>{t("market.transactionFee")} {TRANSACTIONFEE}</div>
                                <div>{t("market.creatorFee")} {CREATORFEE}</div>
                            </div>
                        </div>
                        <div className='nft_ear'>
                            {t("market.productAtPrice") + (priceAll || 0).toFixed(3) + TOKENNAME}
                            {" "}
                            {t("market.willEar") + (priceAll * ALLFEE || 0).toFixed(3) + TOKENNAME}
                        </div>
                        <div className="nft_input">
                            <div className="input_title">{t("market.listExpiration")}</div>
                            <Dropdown
                                className='button dropdown'
                                menu={{
                                    items: expirationItems,
                                    onClick: expirationChange
                                }}
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    {t(`market.hour${expirationType}`)}
                                    <div className='dropdown_ic'></div>
                                </a>

                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="nft_button">
                    <Button onClick={confirm} className='button_common primary_button'>{t("market.listButton")}</Button>
                </div>
            </PublicPopup>
        </div>
    )
}

export default ListInBulkNft
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { connect } from 'react-redux';

const Author = ({ children, token }) => {
    // const token1 = useSelector((state) => state.user.token, shallowEqual);
    const location = useLocation()
    if (token) {
        return <Outlet />;
    } else {
        if (children.findIndex(item => item === location.pathname) != -1) {
            return <Navigate to={location.pathname} state={location.search} />
        } else if (location.pathname === "/invite") {
            return <Navigate to="/webLogin" state={location.pathname} />;
        } else {
            return <Navigate to="/home" state={location.search} />;
        }
    }
}
const mapStateToProps = (state) => ({
    token: state.user.token
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Author);